import React from 'react';
import {FormGroup, Grid, Switch, Typography} from "@mui/material";
import PropTypes from "prop-types";
import CustomRow from "../typescript/custom-row/custom-row";
import {AuthService} from "../typescript/auth/auth.service";
import {DateUtil} from "../typescript/util/date-util";
import SelectOption from "../typescript/select-option/select-option";

const DocumentListForm = ({toReceive, setToReceive, byPayment, setByPayment, year, setYear, month, setMonth}) => {
    const isMaster = AuthService.userInRole("MASTER");
    const anoOptions = DateUtil.startYears();
    const mesOptions = DateUtil.startMonths();

    const renderSwitchAReceber = () => {
        return isMaster && (
            <FormGroup>
                <Typography component="div">
                    <Grid component="label" container alignItems="center" spacing={1}>
                        <Grid item>À pagar</Grid>
                        <Grid item>
                            <Switch color="primary" checked={toReceive}
                                    onChange={(event) => setToReceive(event.target.checked)}/>
                        </Grid>
                        <Grid item>À receber</Grid>
                    </Grid>
                </Typography>
            </FormGroup>
        )
    }

    const renderSwitchPorPagamento = () => {
        return (
            <FormGroup>
                <Typography component="div">
                    <Grid component="label" container alignItems="center" spacing={1}>
                        <Grid item>Por vencimento</Grid>
                        <Grid item>
                            <Switch color="primary" checked={byPayment}
                                    onChange={(event) => setByPayment(event.target.checked)}/>
                        </Grid>
                        <Grid item>Por pagamento</Grid>
                    </Grid>
                </Typography>
            </FormGroup>
        )
    }

    return (
        <React.Fragment>
            <CustomRow>
                {renderSwitchAReceber()}
                {renderSwitchPorPagamento()}
            </CustomRow>
            <CustomRow>
                <SelectOption fullWidth required label="Ano" value={year}
                              onChange={(event) => setYear(event.target.value)} items={anoOptions} itemValue='name'
                              itemDescription='description'/>
                <SelectOption fullWidth required label="Mês" value={month}
                              onChange={(event) => setMonth(event.target.value)} items={mesOptions} itemValue='name'
                              itemDescription='description'/>
            </CustomRow>
        </React.Fragment>
    )

}

DocumentListForm.propTypes = {
    toReceive: PropTypes.bool.isRequired,
    setToReceive: PropTypes.func.isRequired,
    byPayment: PropTypes.bool.isRequired,
    setByPayment: PropTypes.func.isRequired,
    year: PropTypes.number.isRequired,
    setYear: PropTypes.func.isRequired,
    month: PropTypes.number.isRequired,
    setMonth: PropTypes.func.isRequired
};

export default React.memo(DocumentListForm);