import React, {useCallback, useEffect} from "react";
import {CloseOptionsResponse} from "../../../../sale/close-options-response";
import CustomPanel from "../../../custom-panel/custom-panel";
import CustomRow from "../../../custom-row/custom-row";
import CustomTextField from "../../../custom-text-field/custom-text-field";
import TipoDePagamentoOption from "../../../tipo-de-pagamento-option/tipo-de-pagamento-option";
import {SaleService} from "../../../../sale/sale.service";
import BandeiraOption from "../../../bandeira-option/bandeira-option";
import {PaymentType} from "../../../../payment-type/payment-type";
import {Entrada} from "./entrada";
import {Brand} from "../../../brand/Brand";

interface CloseSaleAPrazoEntradaProps {
    entrada: Entrada;
    setEntrada: (entrada: Entrada) => void;
    options: CloseOptionsResponse;
    saleId: number;
}

const CloseSaleAPrazoEntrada = React.memo(({entrada, setEntrada, options, saleId}: CloseSaleAPrazoEntradaProps) => {

    const getCusto = useCallback(async () => {
        const custo: number = await SaleService.getValorDeCustoComLucro(saleId);
        setEntrada({
            ...entrada,
            value: custo
        })
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getCusto().then();
    }, [getCusto]);


    const changeDate = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEntrada({
            ...entrada,
            date: event.target.value
        })
    }

    const changeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEntrada({
            ...entrada,
            value: event.target.value
        })
    }

    const changeInstallmentOption = (tipoDePagamentoSelecionado: PaymentType | null) => {
        const cardBrand = (tipoDePagamentoSelecionado?.id !== SaleService.PAGAMENTO_CARTAO.id) ? null : entrada.cardBrand;
        const cardNumberOfParcels = (tipoDePagamentoSelecionado?.id !== SaleService.PAGAMENTO_CARTAO.id) ? "" : entrada.cardNumberOfParcels;
        setEntrada({
            ...entrada,
            installmentOption: tipoDePagamentoSelecionado,
            cardBrand: cardBrand,
            cardNumberOfParcels: cardNumberOfParcels
        });
    }

    const changeCardBrand = (bandeiraSelecionada: Brand | null) => {
        setEntrada({
            ...entrada,
            cardBrand: bandeiraSelecionada
        });
    }

    const changeCardNumberOfParcels = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEntrada({
            ...entrada,
            cardNumberOfParcels: event.target.value
        });
    }

    const renderBrand = () => {
        return entrada.installmentOption && entrada.installmentOption.id === SaleService.PAGAMENTO_CARTAO.id && (
            <BandeiraOption bandeiras={options.bandeiras} bandeiraSelecionada={entrada.cardBrand}
                            changeBandeira={changeCardBrand}/>
        )
    }

    const renderCardNumberOfParcels = () => {
        return entrada.installmentOption && entrada.installmentOption.id === SaleService.PAGAMENTO_CARTAO.id && (
            <CustomTextField fullWidth label="Número de parcelas do cartão" type="number" required
                             value={entrada.cardNumberOfParcels} onChange={changeCardNumberOfParcels}
                             InputProps={{inputProps: {min: 1}}}/>
        )
    }

    return entrada && (
        <CustomPanel title={"Dados da entrada"}>
            <CustomRow>
                <CustomTextField autoFocus fullWidth label="Data" type="date" value={entrada.date} required
                                 onChange={changeDate}/>
                <CustomTextField fullWidth required label="Valor (R$)" type="number" value={entrada.value}
                                 onChange={changeValue} InputProps={{inputProps: {min: 0, step: "any"}}}/>
            </CustomRow>
            <CustomRow>
                <TipoDePagamentoOption tiposDePagamento={options.tiposDePagamento}
                                       tipoDePagamentoSelecionado={entrada.installmentOption}
                                       changeTipoDePagamento={changeInstallmentOption}/>
                {renderBrand()}
                {renderCardNumberOfParcels()}
            </CustomRow>
        </CustomPanel>
    )

});

export default CloseSaleAPrazoEntrada;
