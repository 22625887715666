import React, {useCallback, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {UserService} from "./user.service";
import {changePageTitle} from "../typescript/redux/actions";
import CustomTextField from "../typescript/custom-text-field/custom-text-field";
import CustomButton from "../typescript/custom-button/custom-button";
import CustomButtonGroup from "../typescript/custom-button-group/custom-button-group";
import SelectOption from "../typescript/select-option/select-option";

export function WorkDetail({id, onClose, work}) {

    const [item, setItem] = useState({
        dayOfWeek: '',
        firstRoundEnter: '',
        firstRoundExit: '',
        secondRoundEnter: '',
        secondRoundExit: '',
        thirdRoundEnter: '',
        thirdRoundExit: ''
    });
    const dispatch = useDispatch();

    const findOne = useCallback(async () => {
        const result = await UserService.findOneWork(id, work);
        setItem(result);
    }, [id, work]);

    useEffect(() => {
        dispatch(changePageTitle(work ?
            "Dias de trabalho do usuário " + id + " - " + work :
            "Dias de trabalho do usuário " + id + " - Novo cadastro"));
        if (work) {
            findOne().then();
        }
    }, [dispatch, findOne, id, work]);

    const save = (event) => {
        event.preventDefault();

        UserService.saveWork(id, item).then((result) => {
            onClose(result.data);
        }).catch(() => {

        })
    }

    const setDayOfWeek = (event) => {
        setItem({
            ...item,
            dayOfWeek: event.target.value
        })
    }

    const setFirstRoundEnter = (event) => {
        setItem({
            ...item,
            firstRoundEnter: event.target.value
        })
    }

    const setFirstRoundExit = (event) => {
        setItem({
            ...item,
            firstRoundExit: event.target.value
        })
    }

    const setSecondRoundEnter = (event) => {
        setItem({
            ...item,
            secondRoundEnter: event.target.value
        })
    }

    const setSecondRoundExit = (event) => {
        setItem({
            ...item,
            secondRoundExit: event.target.value
        })
    }

    const setThirdRoundEnter = (event) => {
        setItem({
            ...item,
            thirdRoundEnter: event.target.value
        })
    }

    const setThirdRoundExit = (event) => {
        setItem({
            ...item,
            thirdRoundExit: event.target.value
        })
    }

    const cancel = () => {
        onClose();
    }

    const dayOfWeekOptions = [
        {name: 'SUNDAY', description: 'Domingo'},
        {name: 'MONDAY', description: 'Segunda-feira'},
        {name: 'TUESDAY', description: 'Terça-feira'},
        {name: 'WEDNESDAY', description: 'Quarta-feira'},
        {name: 'THURSDAY', description: 'Quinta-feira'},
        {name: 'FRIDAY', description: 'Sexta-feira'},
        {name: 'SATURDAY', description: 'Sábado'}];

    return (
        <form onSubmit={save}>
            <SelectOption required fullWidth label="Dia da semana" value={item.dayOfWeek || ''} onChange={setDayOfWeek}
                          items={dayOfWeekOptions} itemValue='name' itemDescription='description'/>

            <CustomTextField autoFocus fullWidth label="1° Turno - Início" type="time"
                             value={item.firstRoundEnter || ''}
                             onChange={setFirstRoundEnter}
                             InputLabelProps={{shrink: true}}/>

            <CustomTextField autoFocus fullWidth label="1° Turno - Fim" type="time" value={item.firstRoundExit || ''}
                             onChange={setFirstRoundExit}
                             InputLabelProps={{shrink: true}}/>

            <CustomTextField autoFocus fullWidth label="2° Turno - Início" type="time"
                             value={item.secondRoundEnter || ''}
                             onChange={setSecondRoundEnter}
                             InputLabelProps={{shrink: true}}/>

            <CustomTextField autoFocus fullWidth label="2° Turno - Fim" type="time" value={item.secondRoundExit || ''}
                             onChange={setSecondRoundExit}
                             InputLabelProps={{shrink: true}}/>

            <CustomTextField autoFocus fullWidth label="3° Turno - Início" type="time"
                             value={item.thirdRoundEnter || ''}
                             onChange={setThirdRoundEnter}
                             InputLabelProps={{shrink: true}}/>

            <CustomTextField autoFocus fullWidth label="3° Turno - Fim" type="time" value={item.thirdRoundExit || ''}
                             onChange={setThirdRoundExit}
                             InputLabelProps={{shrink: true}}/>
            <CustomButtonGroup>
                <CustomButton color="primary" type="submit">Salvar</CustomButton>
                <CustomButton type="button" color="neutral" onClick={cancel}>Cancelar</CustomButton>
            </CustomButtonGroup>
        </form>
    )
}