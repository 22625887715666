import React from "react";
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import theme from "../../../../theme/theme";
import SaleListBodyTableContent from "./sale-list-body-table-content";
import {Sale} from "../../../../../sale/sale";

interface SaleListBodyTableProps {
    sales?: Sale[];
    findAll: () => void;
}

const SaleListBodyTable = React.memo(({sales, findAll}: SaleListBodyTableProps) => {

    return (
        <TableContainer component={Paper} sx={theme.tableContainer}>
            <Table size={"small"} stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Cliente</TableCell>
                        <TableCell>Emissão</TableCell>
                        <TableCell>Total</TableCell>
                        {/*<TableCell>Laboratório</TableCell>*/}
                        <TableCell>Previsão de entrega</TableCell>
                        <TableCell>Vendedor</TableCell>
                        <TableCell>Fechada</TableCell>
                        <TableCell>Situação</TableCell>
                        <TableCell>Operações</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <SaleListBodyTableContent sales={sales} findAll={findAll}/>
                </TableBody>
            </Table>
        </TableContainer>
    )

});

export default SaleListBodyTable;
