import {AppService} from "../typescript/app/app.service";
import {Point} from "./point";
import {DateUtil} from "../typescript/util/2.0.0/date-util";
import {PointDto} from "./point-dto";

class PontoService {

    static API_URL = "/api/pontos";

    static get = (date: Date): Promise<PointDto> => {
        const params = {
            date: DateUtil.toJavaSqlDate(date)
        }
        return AppService.getAuthorized(this.API_URL + "/register", params).then((result) => {
            return result.data;
        }).catch(() => {
            return null;
        })
    }

    static register = (date: Date, time: string) => {
        const params = {
            date: DateUtil.toJavaSqlDate(date),
            time: time
        }
        return AppService.postAuthorized(this.API_URL + "/register", null, params);
    }

    static saveOrUpdate = (item: Point): Promise<Point> => {
        return AppService.postAuthorized(this.API_URL, item).then((result) => {
            return result.data;
        }).catch(() => {
            return null;
        })
    }

    static findAll = (ano: number, mes: number, userId: number): Promise<Point[]> => {
        return AppService.getAuthorized(this.API_URL, {
            year: ano,
            month: mes,
            user: userId
        }).then((result) => {
            return result.data;
        }).catch(() => {
            return null;
        })
    }
}

export {PontoService};
