import {AuthService} from "../typescript/auth/auth.service";
import {DateUtil} from "../typescript/util/date-util";
import {AppService} from "../typescript/app/app.service";
import {PageResponse} from "../typescript/util/page-response";
import {User} from "./user";
import {Vacation} from "./vacation";
import {Work} from "./work";
import {PersonService} from "../person/person.service";

class UserService {

    static API_URL = '/user';

    static getMe = () => {
        return AppService.getAuthorized(this.API_URL + '/me')
            .then((response) => {
                AuthService.saveUserDetails(response.data);
                return true;
            }).catch(() => {
                return false;
            })
    };

    static getAvatarOfCurrentUser() {
        return AppService.getAuthorized(this.API_URL + "/me/avatar", null, "arraybuffer").then((result) => {
            let image = window.btoa(new Uint8Array(result.data).reduce((data, byte) => data + String.fromCharCode(byte), ''));
            const avatar = `data:${result.headers['content-type']?.toLowerCase()};base64,${image}`;
            AuthService.saveAvatar(avatar);
            return avatar;
        }).catch(() => {
            return null;
        })
    }

    static findAll = (page?: number, size?: number, sort?: string, filter?: string): Promise<PageResponse<User>> => {
        if (filter) {
            let id = "";
            let username = "";
            if (sort?.startsWith('id')) {
                id = filter
            } else {
                username = filter;
            }
            return AppService.getAuthorized(this.API_URL, {
                page: page,
                size: size,
                sort: sort,
                id: id,
                username: username
            }).then((result) => {
                return result.data;
            }).catch(() => {
                return {
                    content: [],
                    totalElements: 0
                };
            })
        } else {
            return AppService.getAuthorized(this.API_URL, {page: page, size: size, sort: sort}).then((result) => {
                return result.data;
            }).catch(() => {
                return {
                    content: [],
                    totalElements: 0
                };
            })
        }
    };
    static deleteVacation(userId: number, vacationId: number) {
        return AppService.deleteAuthorized(this.API_URL + "/" + userId + '/vacation/' + vacationId);
    }
    static deleteWork(userId: number, workId: number) {
        return AppService.deleteAuthorized(this.API_URL + "/" + userId + '/work/' + workId);
    }

    static findOne(id: number) {
        return AppService.getAuthorized(this.API_URL + "/" + id).then((result) => {
            return result.data;
        }).catch(() => {
            return null;
        })
    }
    static findOneVacation(id: number, vacation: number) {
        return AppService.getAuthorized(this.API_URL + "/" + id + '/vacation/' + vacation).then((result) => {
            return result.data;
        }).catch(() => {
            return null;
        })
    }

    static findOneWork(id: number, work: number) {
        return AppService.getAuthorized(this.API_URL + "/" + id + '/work/' + work).then((result) => {
            return result.data;
        }).catch(() => {
            return null;
        })
    }

    static findSalaryHistory(id: number) {
        return AppService.getAuthorized(this.API_URL + "/" + id + "/salaryhistory").then((result) => {
            return result.data;
        }).catch(() => {
            return [];
        })
    }

    static activate(id: number) {
        return AppService.postAuthorized(this.API_URL + "/" + id + '/activate');
    }

    static inactivate(id: number) {
        return AppService.postAuthorized(this.API_URL + "/" + id + '/inactivate');
    }

    static save(item: User) {
        let itemToSave = item;
        if (itemToSave.salaryType === "") {
            itemToSave.salaryType = null;
        }
        if (itemToSave.person && !itemToSave.person.id) {
            itemToSave.person = null;
        }
        if (itemToSave.id) {
            return AppService.postAuthorized(this.API_URL + "/" + itemToSave.id, itemToSave);
        } else {
            return AppService.postAuthorized(this.API_URL, itemToSave);
        }
    }

    static saveVacation(userId: number, vacation: Vacation) {
        if (vacation.id) {
            return AppService.postAuthorized(this.API_URL + "/" + userId + '/vacation/' + vacation.id, vacation);
        } else {
            return AppService.postAuthorized(this.API_URL + "/" + userId + '/vacation', vacation);
        }
    }

    static saveWork(userId: number, work: Work) {
        if (work.id) {
            return AppService.postAuthorized(this.API_URL + "/" + userId + '/work/' + work.id, work);
        } else {
            return AppService.postAuthorized(this.API_URL + "/" + userId + '/work', work);
        }
    }

    static changePassword(id: number, currentPassword: string, newPassword: string) {
        return AppService.postAuthorized(this.API_URL + "/" + id + '/changepassword', {
            currentPassword: currentPassword,
            newPassword: newPassword
        });
    }

    static findAllVacations(id: number, page: number, size: number, sort: string) {
        return AppService.getAuthorized(this.API_URL + "/" + id + "/vacation", {
            page: page,
            size: size,
            sort: sort
        }).then((result) => {
            return result.data;
        }).catch(() => {
            return {
                content: [],
                totalElements: 0
            };
        })
    }

    static getMuralImage(id: number) {
        return AppService.getAuthorized(this.API_URL + "/" + id + "/mural", null, "arraybuffer").then((result) => {
            let image = window.btoa(new Uint8Array(result.data).reduce((data, byte) => data + String.fromCharCode(byte), ''));
            return `data:${result.headers['content-type']?.toLowerCase()};base64,${image}`;
        }).catch(() => {
            return null;
        })
    }

    static getAvatar(id: number) {
        return AppService.getAuthorized(this.API_URL + "/" + id + "/avatar", null, "arraybuffer").then((result) => {
            let image = window.btoa(new Uint8Array(result.data).reduce((data, byte) => data + String.fromCharCode(byte), ''));
            return `data:${result.headers['content-type']?.toLowerCase()};base64,${image}`;
        }).catch(() => {
            return null;
        })
    }

    static getFinalRoundMessage() {
        return AppService.getAuthorized(this.API_URL + "/me/roundMessage", null);
    }

    static deleteMuralImage(id: number) {
        return AppService.deleteAuthorized(this.API_URL + "/" + id + "/mural").then(() => {
            return null;
        }).catch(() => {
            return null;
        })
    }

    static deleteAvatar(id: number) {
        return AppService.deleteAuthorized(this.API_URL + "/" + id + "/avatar").then(() => {
            return null;
        }).catch(() => {
            return null;
        })
    }

    static saveMuralImage(id: number, muralImage: File) {
        let bodyFormData = new FormData();
        bodyFormData.append('file', muralImage);
        return AppService.postAuthorized(this.API_URL + "/" + id + "/mural", bodyFormData);
    }

    static saveAvatar(id: number, avatarImage: File) {
        let bodyFormData = new FormData();
        bodyFormData.append('file', avatarImage);
        return AppService.postAuthorized(this.API_URL + "/" + id + "/avatar", bodyFormData);
    }

    static findAllWorks(id: number, page: number, size: number, sort: string) {
        return AppService.getAuthorized(this.API_URL + "/" + id + "/work", {
            page: page,
            size: size,
            sort: sort
        }).then((result) => {
            return result.data;
        }).catch(() => {
            return {
                content: [],
                totalElements: 0
            };
        })
    }

    static createUser() {
        const user: User = {
            username: '',
            authorities: [],
            enabled: false,
            password: '',
            password2: '',
            activityStart: DateUtil.toJavaSqlDate(new Date()),
            salaryType: '',
            salary: null,
            person: PersonService.createPerson(false, false)
        };
        return user;
    }
}

export {UserService};
