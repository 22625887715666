import React from "react";
import {Backdrop, CircularProgress} from "@mui/material";
import {useSelector} from "react-redux";
import {RootState} from "../typescript/redux/store";

const AppLoader = React.memo(() => {
    const showLoader = useSelector((store: RootState) => store.pageState.showLoader);

    return showLoader && (
        <Backdrop style={{zIndex: 99999}} open={showLoader}>
            <CircularProgress color="inherit"/>
        </Backdrop>
    )
})

export default AppLoader;