import React, {useCallback, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {ColorService} from "./color.service";
import CustomTextField from "../typescript/custom-text-field/custom-text-field";
import CustomButton from "../typescript/custom-button/custom-button";
import CustomButtonGroup from "../typescript/custom-button-group/custom-button-group";
import {changePageTitle, setAlert} from "../typescript/redux/actions";

const ColorDetail = React.memo(({id, onClose, dialog}) => {

    const [item, setItem] = useState(ColorService.createColor());
    const dispatch = useDispatch();

    const findOne = useCallback(async () => {
        const result = await ColorService.findOne(id);
        setItem(result);
    }, [id]);

    useEffect(() => {
        if (!dialog) {
            dispatch(changePageTitle(id ? "Cor " + id : "Nova cor"));
        }
        if (id) {
            findOne().then();
        }
    }, [dispatch, findOne, id, dialog]);

    const save = (event) => {
        event.preventDefault();
        event.stopPropagation();
        ColorService.save(item).then((result) => {
            onClose(result.data);
        }).catch(() => {
            dispatch(setAlert({show: true, message: 'Erro ao salvar cor', severity: 'error'}));
        })
    }

    const cancel = () => {
        onClose();
    }

    const setDescription = (event) => {
        setItem({
            ...item,
            description: event.target.value
        })
    }

    return (
        <form onSubmit={save}>
            <CustomTextField fullWidth autoFocus required label="Descrição" value={item.description}
                             onChange={setDescription}/>
            <CustomButtonGroup>
                <CustomButton color="primary" type="submit">Salvar</CustomButton>
                <CustomButton type="button" color="neutral" onClick={cancel}>Cancelar</CustomButton>
            </CustomButtonGroup>
        </form>
    )
});

export default ColorDetail;