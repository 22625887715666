export class Alert {
    show: boolean;
    message: string;
    severity: string;

    constructor(show: boolean, message: string, severity: string) {
        this.show = show;
        this.message = message;
        this.severity = severity;
    }
}
