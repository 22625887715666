import React, {useCallback, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {changePageTitle, setAlert} from "../typescript/redux/actions";
import {Grid} from "@mui/material";
import {ForeignKey} from "../index";
import {ReserveService} from "./reserve.service";
import CustomTextField from "../typescript/custom-text-field/custom-text-field";
import CustomButton from "../typescript/custom-button/custom-button";
import CustomButtonGroup from "../typescript/custom-button-group/custom-button-group";
import {PersonService} from "../person/person.service";
import {ProductService} from "../product/product.service";

const ReserveDetail = ({id, onClose, dialog}) => {

    const [item, setItem] = useState(ReserveService.createReserve());
    const dispatch = useDispatch();

    const findOne = useCallback(async () => {
        const result = await ReserveService.findOne(id);
        setItem(result);
    }, [id]);

    useEffect(() => {
        if (!dialog) {
            dispatch(changePageTitle(id ? "Reserva " + id : "Nova reserva"));
        }
        if (id) {
            findOne().then();
        }
    }, [dispatch, findOne, id, dialog]);

    const save = (event) => {
        event.preventDefault();
        event.stopPropagation();
        ReserveService.save(item).then((result) => {
            onClose(result.data);
        }).catch(() => {
            dispatch(setAlert({show: true, message: 'Erro ao salvar reserva', severity: 'error'}));
        })
    }

    const cancel = () => {
        onClose();
    }

    const setDate = (event) => {
        setItem({
            ...item,
            date: event.target.value
        })
    }

    const setHour = (event) => {
        setItem({
            ...item,
            hour: event.target.value
        })
    }

    const setObservation = (event) => {
        setItem({
            ...item,
            observation: event.target.value
        })
    }

    const onChangePerson = async (personId) => {
        setItem({
            ...item,
            person: {
                id: personId
            }
        })
        if (personId) {
            const person = await PersonService.findOne(personId);
            onSelectPerson(person);
        }
    }

    const onSelectPerson = (person) => {
        if (person) {
            setItem({
                ...item,
                person: person
            })
        }
    }

    const onChangeProduct = async (productId) => {
        setItem({
            ...item,
            product: {
                id: productId
            }
        })
        if (productId) {
            const product = await ProductService.findOne(productId);
            onSelectProduct(product);
        }
    }

    const onSelectProduct = (product) => {
        if (product) {
            setItem({
                ...item,
                product: product
            })
        }
    }

    return (
        <form onSubmit={save}>
            <ForeignKey fullWidth autoFocus required label="Pessoa" value={item.person} fieldKey={'id'}
                        fieldDescription={'nome'} labelDescription='Nome' onChange={onChangePerson}
                        onSelect={onSelectPerson} findAll={PersonService.findAll}
                        dataColumnNames={['ID', 'Nome']} dataColumns={['id', 'nome']}
                        dataTypes={['number', 'text']}/>

            <ForeignKey fullWidth required label="Produto" value={item.product} fieldKey={'id'}
                        fieldDescription={'descricao'} labelDescription='Descrição' onChange={onChangeProduct}
                        onSelect={onSelectProduct} findAll={ProductService.findAll}
                        dataColumnNames={['ID', 'Descrição']} dataColumns={['id', 'descricao']}
                        dataTypes={['number', 'text']}/>


            <Grid container spacing={1}>
                <Grid item md xs={12}>
                    <CustomTextField fullWidth required label="Data" type="date" value={item.date || ''}
                                     InputLabelProps={{shrink: true}} onChange={setDate}/>
                </Grid>
                <Grid item md xs={12}>
                    <CustomTextField fullWidth required label="Hora" type="time" value={item.hour || ''}
                                     InputLabelProps={{shrink: true}} onChange={setHour}/>
                </Grid>
            </Grid>
            <CustomTextField fullWidth label="Observação" value={item.observation ? item.observation : ''}
                             onChange={setObservation}/>
            <CustomButtonGroup>
                <CustomButton color="primary" type="submit">Salvar</CustomButton>
                <CustomButton type="button" color="neutral" onClick={cancel}>Cancelar</CustomButton>
            </CustomButtonGroup>
        </form>
    )
}

export default React.memo(ReserveDetail);