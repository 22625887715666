import React, {FormEvent, RefObject} from 'react';
import {Grid, IconButton, Tooltip} from "@mui/material";
import {SaleListHeaderFilterEnum} from "./enum/sale-list-header-filter-enum";
import {Search} from "@mui/icons-material";
import SaleListHeaderFilterClosed from "./sale-list-header-filter-closed";
import {SaleListHeaderFilterClosedEnum} from "./enum/sale-list-header-filter-closed-enum";
import SaleListHeaderFilterReceived from "./sale-list-header-filter-received";
import {SaleListHeaderFilterReceivedEnum} from "./enum/sale-list-header-filter-received-enum";
import SaleListHeaderFilter from "./sale-list-header-filter";
import CustomButton from "../../../custom-button/custom-button";
import {useNavigate} from "react-router-dom";

interface SaleListHeaderProps {
    saleFilter: SaleListHeaderFilterEnum;
    startDate: string | undefined;
    endDate: string | undefined;
    saleId: number | undefined;
    personName: string | undefined;
    findAll: () => void;
    startDateRef: RefObject<HTMLInputElement>;
    endDateRef: RefObject<HTMLInputElement>;
    saleIdRef: RefObject<HTMLInputElement>;
    personNameRef: RefObject<HTMLInputElement>;
    saleClosed: SaleListHeaderFilterClosedEnum;
    saleClosedRef: RefObject<HTMLInputElement>;
    saleReceived: SaleListHeaderFilterReceivedEnum;
    saleReceivedRef: RefObject<HTMLInputElement>;
}

const SaleListHeader = React.memo(({
                                       saleFilter,
                                       startDate,
                                       endDate,
                                       saleId,
                                       personName,
                                       findAll,
                                       endDateRef,
                                       saleIdRef,
                                       personNameRef,
                                       startDateRef,
                                       saleClosed,
                                       saleClosedRef,
                                       saleReceived,
                                       saleReceivedRef
                                   }: SaleListHeaderProps) => {

    let navigate = useNavigate();

    const doFindAll = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        findAll();
    }

    const insertItem = () => {
        navigate('/sale');
    }

    return (
        <form onSubmit={doFindAll}>
            <Grid container spacing={1}>
                <Grid item md={1} xs={12}>
                    <CustomButton type="button" color={"primary"} onClick={insertItem}>
                        Inserir
                    </CustomButton>
                </Grid>
                <Grid item md={5} xs={12}>
                    <SaleListHeaderFilter endDate={endDate} endDateRef={endDateRef} personName={personName}
                                          personNameRef={personNameRef} saleFilter={saleFilter} saleId={saleId}
                                          saleIdRef={saleIdRef} startDate={startDate} startDateRef={startDateRef}/>
                </Grid>
                <Grid item md={5} xs={12}>
                    <Grid item md xs={12}>
                        <SaleListHeaderFilterClosed saleClosed={saleClosed} ref={saleClosedRef}/>
                    </Grid>
                    <Grid item md xs={12}>
                        <SaleListHeaderFilterReceived saleReceived={saleReceived} ref={saleReceivedRef}/>
                    </Grid>
                </Grid>
                <Grid item md={1} xs={12}>
                    <Tooltip title="Pesquisar">
                        <IconButton type={"submit"}>
                            <Search/>
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
        </form>
    )

});

export default SaleListHeader;
