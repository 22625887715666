import React, {useCallback, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {Checkbox, FormControl, FormControlLabel} from "@mui/material";
import {
    CategoryGroupDetail,
    ForeignKey
} from "../index";
import {CategoryService} from "./category.service";
import CustomTextField from "../typescript/custom-text-field/custom-text-field";
import CustomButton from "../typescript/custom-button/custom-button";
import CustomButtonGroup from "../typescript/custom-button-group/custom-button-group";
import {changePageTitle, setAlert} from "../typescript/redux/actions";
import {CategoryGroupService} from "../category-group/category-group.service";

export function CategoryDetail({id, onClose, dialog}) {

    const [item, setItem] = useState(CategoryService.createCategory());
    const dispatch = useDispatch();

    const findOne = useCallback(async () => {
        const result = await CategoryService.findOne(id);
        setItem(result);
    }, [id]);

    useEffect(() => {
        if (!dialog) {
            dispatch(changePageTitle(id ? "Categoria " + id : "Nova categoria"));
        }
        if (id) {
            findOne().then();
        }
    }, [dispatch, findOne, id, dialog]);

    const save = (event) => {
        event.preventDefault();
        event.stopPropagation();
        CategoryService.save(item).then((result) => {
            onClose(result.data);
        }).catch(() => {
            dispatch(setAlert({show: true, message: 'Erro ao salvar categoria', severity: 'error'}));
        })
    }

    const cancel = () => {
        onClose();
    }

    const setDescricao = (event) => {
        setItem({
            ...item,
            descricao: event.target.value
        })
    }

    const setNCM = (event) => {
        setItem({
            ...item,
            ncm: event.target.value
        })
    }

    const setVenda = (event) => {
        setItem({
            ...item,
            venda: event.target.checked
        })
    }

    const setLente = (event) => {
        setItem({
            ...item,
            lente: event.target.checked
        })
    }

    const setArmacao = (event) => {
        setItem({
            ...item,
            armacao: event.target.checked
        })
    }

    const setGeraContaAPagar = (event) => {
        setItem({
            ...item,
            geraContaAPagar: event.target.checked
        })
    }

    const setMaterialRequired = (event) => {
        setItem({
            ...item,
            materialRequired: event.target.checked
        })
    }

    const setFrameSizeRequired = (event) => {
        setItem({
            ...item,
            frameSizeRequired: event.target.checked
        })
    }

    const setSizeRequired = (event) => {
        setItem({
            ...item,
            sizeRequired: event.target.checked
        })
    }

    const setLensColorRequired = (event) => {
        setItem({
            ...item,
            lensColorRequired: event.target.checked
        })
    }

    const onChangeGrupo = async (groupId) => {
        setItem({
            ...item,
            group: {
                id: groupId
            }
        })
        if (groupId) {
            const group = await CategoryGroupService.findOne(groupId);
            if (group) {
                setItem({
                    ...item,
                    group: group
                })
            }
        }
    }

    const onAddGrupo = (group) => {
        if (group) {
            setItem({
                ...item,
                group: group
            })
        }
    }

    const findGrupos = (page, size, sort, filter) => {
        return CategoryGroupService.findAll(page, size, sort, filter);
    }

    const setEstoqueMinimo = (event) => {
        setItem({
            ...item,
            estoqueMinimo: event.target.value
        })
    }

    return (
        <form onSubmit={save}>
            <CustomTextField fullWidth autoFocus required label="Descrição" value={item.descricao}
                             onChange={setDescricao}/>
            <ForeignKey fullWidth required label="Grupo" value={item.group} fieldKey={'id'}
                        fieldDescription={'description'} labelDescription='Descrição' onChange={onChangeGrupo}
                        onSelect={onAddGrupo} onAdd={onAddGrupo} findAll={findGrupos}
                        dataColumnNames={['ID', 'Descrição']} dataColumns={['id', 'description']}
                        dataTypes={['number', 'text']} addComponent={<CategoryGroupDetail/>}/>
            <CustomTextField fullWidth label="NCM" value={item.ncm} onChange={setNCM} inputProps={{maxLength: 10}}/>
            <FormControl fullWidth>
                <FormControlLabel control={<Checkbox checked={item.venda} onChange={setVenda}/>} label="Venda direta"/>
                <FormControlLabel control={<Checkbox checked={item.lente} onChange={setLente}/>} label="Lente"/>
                <FormControlLabel control={<Checkbox checked={item.armacao} onChange={setArmacao}/>} label="Armação"/>
                <FormControlLabel control={<Checkbox checked={item.geraContaAPagar} onChange={setGeraContaAPagar}/>}
                                  label="Gerar conta a pagar"/>
                <FormControlLabel control={<Checkbox checked={item.materialRequired} onChange={setMaterialRequired}/>}
                                  label="Obrigatório informar material"/>
                <FormControlLabel control={<Checkbox checked={item.sizeRequired} onChange={setSizeRequired}/>}
                                  label="Obrigatório informar tamanho"/>
                <FormControlLabel control={<Checkbox checked={item.frameSizeRequired} onChange={setFrameSizeRequired}/>}
                                  label="Obrigatório informar medidas da armação"/>
                <FormControlLabel control={<Checkbox checked={item.lensColorRequired} onChange={setLensColorRequired}/>}
                                  label="Obrigatório informar cor das lentes"/>
            </FormControl>
            <CustomTextField fullWidth label="Estoque mínimo" value={item.estoqueMinimo || ""}
                             onChange={setEstoqueMinimo}/>
            <CustomButtonGroup>
                <CustomButton color="primary" type="submit">Salvar</CustomButton>
                <CustomButton type="button" color="neutral" onClick={cancel}>Cancelar</CustomButton>
            </CustomButtonGroup>
        </form>
    )
}