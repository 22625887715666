import React, {useCallback, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {UserService} from "./user.service";
import {Button, Card, CardMedia} from "@mui/material";
import CustomButton from "../typescript/custom-button/custom-button";
import CustomButtonGroup from "../typescript/custom-button-group/custom-button-group";
import {changePageTitle} from "../typescript/redux/actions";
import {FileUtil} from "../typescript/util/file-util";

const classes = {
    muralCard: {
        maxWidth: '800px'
    },
    muralImg: {
        height: '600px'
    }
};

export function MuralImage({id, onClose}) {

    const [mural, setMural] = useState('');
    const dispatch = useDispatch();

    const findAll = useCallback(async () => {
        const result = await UserService.getMuralImage(id);
        setMural(result);
    }, [id]);

    useEffect(() => {
        dispatch(changePageTitle("Mural dos sonhos do usuário " + id));
        findAll().then();
    }, [dispatch, findAll, id]);

    const deleteMural = async () => {
        await UserService.deleteMuralImage(id);
        setMural(null);
    }

    const renderMural = () => {
        return mural && (
            <Card sx={classes.muralCard}>
                <CardMedia component="img" sx={classes.muralImg} image={mural}/>
            </Card>
        )
    }

    const renderDeleteMural = () => {
        return mural && (
            <CustomButton color="primary" type="button" onClick={deleteMural}>Excluir mural</CustomButton>
        )
    }

    const handleCapture = async ({target}) => {
        const image = target.files[0];
        await UserService.saveMuralImage(id, image);
        const fileBase64 = await FileUtil.fileToBase64(image);
        setMural(fileBase64);
    };

    return (
        <React.Fragment>
            <CustomButtonGroup>
                <CustomButton type="button" onClick={onClose}>Voltar</CustomButton>
                {renderDeleteMural()}
                <Button color="primary" component="label">Selecionar mural
                    <input type="file" hidden accept="image/png" onChange={handleCapture}/>
                </Button>
            </CustomButtonGroup>
            {renderMural()}
        </React.Fragment>
    );
}