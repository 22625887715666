import React, {FormEvent, useCallback, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {PersonService} from "./person.service";
import {Checkbox, FormControl, FormControlLabel, Paper, Radio, RadioGroup, Tab, Tabs, Typography} from "@mui/material";
import {Delete as DeleteIcon} from "@mui/icons-material";
import CustomTextField from "../typescript/custom-text-field/custom-text-field";
import CustomButton from "../typescript/custom-button/custom-button";
import CustomButtonGroup from "../typescript/custom-button-group/custom-button-group";
import CustomRow from "../typescript/custom-row/custom-row";
import {CustomDialog} from "../typescript/custom-dialog/custom-dialog";
import {AuthService} from "../typescript/auth/auth.service";
import {changePageTitle, setAlert} from "../typescript/redux/actions";
import SelectOption from "../typescript/select-option/select-option";
import {CentroDeCustoService} from "../centro-de-custo/centro-de-custo.service";
import {NeighborhoodService} from "../neighborhood/neighborhood.service";
import {ParenteService} from "../parente/parente.service";
import {Person} from "./person";
import {DateUtil} from "../typescript/util/2.0.0/date-util";
import {Neighborhood} from "../neighborhood/neighborhood";
import ForeignKey from "../typescript/foreign-key/foreign-key";
import {CentroDeCusto} from "../centro-de-custo/centro-de-custo";
import {SelectChangeEvent} from "@mui/material/Select/SelectInput";
import {InfluenciaCompra} from "../influenciacompra/influencia-compra";
import {DadosAcessorios} from "../sale/dados-acessorios";
import {DecideCompra} from "../decidecompra/decide-compra";
import {EfetuaCompra} from "../efetuacompra/efetua-compra";
import {Estilo} from "../estilo/estilo";
import {Profissao} from "../profissao/profissao";
import {Preferencia} from "../preferencia/preferencia";
import {ConheceuLoja} from "../conheceuloja/conheceu-loja";
import Datatable from "../typescript/datatable/datatable";
import {Parente} from "../parente/parente";
import {Temperamento} from "../temperamento/temperamento";
import {ParenteDetail} from "../parente/parente-detail";
import {ColorIconEnum} from "../typescript/util/color-icon-enum";
import {ColorButtonEnum} from "../typescript/util/color-button-enum";

const classes = {
    personDetailTabBar: {
        marginBottom: '8px'
    }
};

interface PersonDetailProps {
    id?: number;
    dialog?: boolean;
    onClose?: (person?: Person) => void;
    tab?: number;
    cliente?: boolean;
    fornecedor?: boolean;
}

export function PersonDetail({id, onClose, tab, cliente, dialog, fornecedor}: PersonDetailProps) {

    const [item, setItem] = useState<Person>(PersonService.createPerson(cliente || true, fornecedor || false));
    const dispatch = useDispatch();
    const [tabValue, setTabValue] = useState(0);
    const [dadosAcessorios, setDadosAcessorios] = useState<DadosAcessorios>(PersonService.createDadosAcessorios());
    const [saleItems, setSaleItems] = useState([]);
    const [showParenteDialog, setShowParenteDialog] = useState(false);
    const [parenteToModal, setParenteToModal] = useState({item: ParenteService.createParente(), index: -1});
    const isAdmin = AuthService.userInRole("ADMIN");

    const findOne = useCallback(async (personId: number) => {
        const result = await PersonService.findOne(personId);
        const date = new Date(result.created_date);
        result.created_date = DateUtil.toJavaLocalDateTime(date);
        setItem(result);
    }, []);

    const getAllDadosAcessorios = useCallback(async () => {
        const result = await PersonService.getAllDadosAcessorios();
        setDadosAcessorios(result);
    }, []);

    const getAllSaleItemByPessoa = useCallback(async () => {
        if (id) {
            const result = await PersonService.getAllSaleItemByPessoa(id);
            setSaleItems(result);
        }
    }, [id]);

    useEffect(() => {
        if (!dialog) {
            dispatch(changePageTitle(id ? "Pessoa " + id : "Nova pessoa"));
        }
        if (id) {
            findOne(id).then();
        }
        if (tab) {
            if (tab === 1) {
                getAllDadosAcessorios().then();
            }
            if (tab === 2) {
                getAllSaleItemByPessoa().then();
            }
            setTabValue(tab)
        }
    }, [dispatch, findOne, id, tab, getAllDadosAcessorios, getAllSaleItemByPessoa, dialog]);

    const save = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        PersonService.save(item).then((result) => {
            if (onClose) {
                onClose(result.data);
            }
        }).catch((error) => {
            dispatch(setAlert({
                show: true,
                message: 'Erro ao salvar pessoa - ' + error.response.data.message,
                severity: 'error'
            }));
        })
    }

    const cancel = () => {
        if (onClose) {
            onClose();
        }
    }

    const setNome = (event: React.ChangeEvent<HTMLInputElement>) => {
        setItem({
            ...item,
            nome: event.target.value
        })
    }

    const setRua = (event: React.ChangeEvent<HTMLInputElement>) => {
        setItem({
            ...item,
            rua: event.target.value
        })
    }

    const setNumero = (event: React.ChangeEvent<HTMLInputElement>) => {
        setItem({
            ...item,
            numero: event.target.value
        })
    }

    const setComplemento = (event: React.ChangeEvent<HTMLInputElement>) => {
        setItem({
            ...item,
            complemento: event.target.value
        })
    }

    const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
        if (newValue === 1) {
            getAllDadosAcessorios().then();
        }
        if (newValue === 2) {
            getAllSaleItemByPessoa().then();
        }
        setTabValue(newValue);
    };

    const onChangeBairro = async (id: number) => {
        setItem({
            ...item,
            bairroCadastro: {
                id: id
            }
        })
        if (id) {
            const bairroCadastro = await NeighborhoodService.findOne(id);
            if (bairroCadastro) {
                setItem({
                    ...item,
                    bairroCadastro: bairroCadastro
                })
            }
        }
    }

    const onAddBairro = (bairroCadastro: Neighborhood) => {
        if (bairroCadastro) {
            setItem({
                ...item,
                bairroCadastro: bairroCadastro
            })
        }
    }

    const setCep = (event: React.ChangeEvent<HTMLInputElement>) => {
        setItem({
            ...item,
            cep: event.target.value
        })
    }

    const setDataNascimento = (event: React.ChangeEvent<HTMLInputElement>) => {
        setItem({
            ...item,
            dataNascimento: event.target.value
        })
    }

    const setCpf = (event: React.ChangeEvent<HTMLInputElement>) => {
        setItem({
            ...item,
            cpf: event.target.value
        })
    }

    const setInscricaoEstadual = (event: React.ChangeEvent<HTMLInputElement>) => {
        setItem({
            ...item,
            inscricaoEstadual: event.target.value
        })
    }

    const setFone1 = (event: React.ChangeEvent<HTMLInputElement>) => {
        setItem({
            ...item,
            fone1: event.target.value
        })
    }

    const setFone2 = (event: React.ChangeEvent<HTMLInputElement>) => {
        setItem({
            ...item,
            fone2: event.target.value
        })
    }

    const setFone3 = (event: React.ChangeEvent<HTMLInputElement>) => {
        setItem({
            ...item,
            fone3: event.target.value
        })
    }

    const setEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        setItem({
            ...item,
            email: event.target.value
        })
    }

    const setFacebook = (event: React.ChangeEvent<HTMLInputElement>) => {
        setItem({
            ...item,
            facebook: event.target.value
        })
    }

    const setSpc = (event: React.ChangeEvent<HTMLInputElement>) => {
        setItem({
            ...item,
            spc: event.target.checked
        })
    }

    const setCliente = (event: React.ChangeEvent<HTMLInputElement>) => {
        setItem({
            ...item,
            cliente: event.target.checked
        })
    }

    const setFornecedor = (event: React.ChangeEvent<HTMLInputElement>) => {
        const centrodecusto = (event.target.checked ? item.centrodecusto : null);
        setItem({
            ...item,
            fornecedor: event.target.checked,
            centrodecusto: centrodecusto
        })
    }

    const setObservacao = (event: React.ChangeEvent<HTMLInputElement>) => {
        setItem({
            ...item,
            observacao: event.target.value
        })
    }

    const onChangeCentrodecusto = async (id: number) => {
        setItem({
            ...item,
            centrodecusto: {
                id: id
            }
        })
        if (id) {
            const centrodecusto = await CentroDeCustoService.findOne(id);
            if (centrodecusto) {
                setItem({
                    ...item,
                    centrodecusto: centrodecusto
                })
            }
        }
    }

    const onAddCentrodecusto = (centrodecusto: CentroDeCusto | null) => {
        if (centrodecusto) {
            setItem({
                ...item,
                centrodecusto: centrodecusto
            })
        }
    }

    const renderCentroDeCusto = () => {
        return item.fornecedor && (
            <ForeignKey fullWidth required={item.fornecedor} label="Tipo de gasto" value={item.centrodecusto}
                        fieldKey={'id'} fieldDescription={'descricao'} labelDescription='Descrição'
                        onChange={onChangeCentrodecusto} onSelect={onAddCentrodecusto}
                        findAll={CentroDeCustoService.findAll} dataColumnNames={['ID', 'Descrição']}
                        dataColumns={['id', 'descricao']} dataTypes={['number', 'text']}/>
        )
    }

    const setDocumentMaster = (event: React.ChangeEvent<HTMLInputElement>) => {
        setItem({
            ...item,
            documentMaster: event.target.checked
        })
    }

    const onChangeType = (event: React.ChangeEvent<HTMLInputElement>) => {
        const typeSelected = event.target.value;
        if (typeSelected === 'LEGAL') {
            setItem({
                ...item,
                type: typeSelected,
                gender: null
            })
        } else {
            setItem({
                ...item,
                type: typeSelected,
                gender: 'MASCULINE'
            })
        }
    }

    const onChangeGender = (event: React.ChangeEvent<HTMLInputElement>) => {
        const genderSelected = event.target.value;
        setItem({
            ...item,
            gender: genderSelected
        })
    }

    const renderDocumentMaster = () => {
        return isAdmin && (
            <FormControlLabel control={<Checkbox checked={item.documentMaster} onChange={setDocumentMaster}/>}
                              label="Documentos a pagar para usuário master"/>
        )
    }

    const renderGender = () => {
        return item.type === 'PHYSICAL' && (
            <FormControl component="fieldset" required>
                <RadioGroup row value={item.gender} onChange={onChangeGender}>
                    <FormControlLabel value={'MASCULINE'} control={<Radio/>} label="Masculino"/>
                    <FormControlLabel value={'FEMININE'} control={<Radio/>} label="Feminino"/>
                    <FormControlLabel value={'OTHER'} control={<Radio/>} label="Outro"/>
                </RadioGroup>
            </FormControl>
        )
    }

    const renderDadosPrincipais = () => {
        return tabValue === 0 && (
            <div>
                <Typography variant="subtitle1" gutterBottom>
                    {item.created_date ? "Data de cadastro: " + DateUtil.javaSqlDateToDateToLocaleDateString(item.created_date) : null}
                </Typography>
                <CustomTextField autoFocus required fullWidth label="Nome" value={item.nome || ''} onChange={setNome}/>
                <CustomRow>
                    <CustomTextField fullWidth required label="Rua" value={item.rua || ''} onChange={setRua}/>
                    <CustomTextField fullWidth required label="Número" type="number" value={item.numero || ''}
                                     onChange={setNumero}/>
                    <CustomTextField fullWidth label="Complemento" value={item.complemento || ''}
                                     onChange={setComplemento}/>
                </CustomRow>
                <CustomRow>
                    <ForeignKey fullWidth required label="Bairro" value={item.bairroCadastro} fieldKey={'id'}
                                fieldDescription={'descriptionAndCity'} labelDescription='Descrição'
                                onChange={onChangeBairro} onSelect={onAddBairro} findAll={NeighborhoodService.findAll}
                                dataColumnNames={['ID', 'Descrição', 'Cidade']}
                                dataColumns={['id', 'descricao', 'cidade.descricao']}
                                dataTypes={['number', 'text', 'text']}/>
                    <CustomTextField fullWidth label="CEP" value={item.cep || ''} onChange={setCep} required/>
                </CustomRow>
                <CustomRow>
                    <CustomTextField fullWidth label="Data de nascimento" type="date" value={item.dataNascimento || ''}
                                     InputLabelProps={{shrink: true}} onChange={setDataNascimento} required/>
                    <CustomTextField fullWidth label="CPF" value={item.cpf || ''} onChange={setCpf} required/>
                    <CustomTextField fullWidth label="Inscrição estadual" value={item.inscricaoEstadual || ''}
                                     onChange={setInscricaoEstadual}/>
                </CustomRow>
                <CustomRow>
                    <CustomTextField fullWidth required label="Telefone 1" value={item.fone1 || ''}
                                     onChange={setFone1}/>
                    <CustomTextField fullWidth label="Telefone 2" value={item.fone2 || ''} onChange={setFone2}/>
                    <CustomTextField fullWidth label="Telefone 3" value={item.fone3 || ''} onChange={setFone3}/>
                </CustomRow>
                <CustomRow>
                    <CustomTextField fullWidth label="Email" value={item.email || ''} onChange={setEmail} required/>
                    <CustomTextField fullWidth label="Facebook" type="email" value={item.facebook || ''}
                                     onChange={setFacebook}/>
                </CustomRow>
                <CustomRow>
                    <FormControlLabel control={<Checkbox checked={item.spc} onChange={setSpc}/>} label="SPC"/>
                    <FormControlLabel control={<Checkbox checked={item.cliente} onChange={setCliente}/>}
                                      label="Cliente" disabled={cliente}/>
                    <FormControlLabel control={<Checkbox checked={item.fornecedor} onChange={setFornecedor}/>}
                                      label="Fornecedor"/>
                    {renderDocumentMaster()}
                </CustomRow>
                {renderCentroDeCusto()}
                <CustomRow>
                    <FormControl component="fieldset" required>
                        <RadioGroup row value={item.type} onChange={onChangeType}>
                            <FormControlLabel value={'PHYSICAL'} control={<Radio/>} label="Física"/>
                            <FormControlLabel value={'LEGAL'} control={<Radio/>} label="Jurídica"/>
                        </RadioGroup>
                    </FormControl>
                    {renderGender()}
                </CustomRow>
                <CustomTextField multiline fullWidth label="Observação" value={item.observacao || ''}
                                 onChange={setObservacao}/>
            </div>
        )
    }

    const onChangeInfluenciaCompras = (event: SelectChangeEvent<number[]>) => {
        const itensSelecionados = event.target.value as number[];
        let influenciaComprasSelecionados: InfluenciaCompra[] = [];
        dadosAcessorios.influenciaCompras.forEach(function (influenciaCompra) {
            let idParam: number;
            if (influenciaCompra.id) {
                idParam = influenciaCompra.id;
            } else {
                throw new Error('Influenciacompra id required');
            }
            if (itensSelecionados.indexOf(idParam) > -1) {
                influenciaComprasSelecionados.push(influenciaCompra);
            }
        })
        setItem({
            ...item,
            influenciacompras: influenciaComprasSelecionados
        })
    }

    const onChangeDecideCompras = (event: SelectChangeEvent<number[]>) => {
        const itensSelecionados = event.target.value as number[];
        let decideComprasSelecionados: DecideCompra[] = [];
        dadosAcessorios.decideCompras.forEach(function (decideCompra) {
            let idParam: number;
            if (decideCompra.id) {
                idParam = decideCompra.id;
            } else {
                throw new Error('Decidecompra id required');
            }
            if (itensSelecionados.indexOf(idParam) > -1) {
                decideComprasSelecionados.push(decideCompra);
            }
        })
        setItem({
            ...item,
            decidecompras: decideComprasSelecionados
        })
    }

    const onChangeEfetuaCompras = (event: SelectChangeEvent<number[]>) => {
        const itensSelecionados = event.target.value as number[];
        let efetuaComprasSelecionados: EfetuaCompra[] = [];
        dadosAcessorios.efetuaCompras?.forEach(function (efetuaCompra) {
            let idParam: number;
            if (efetuaCompra.id) {
                idParam = efetuaCompra.id;
            } else {
                throw new Error('Efetuacompra id required');
            }
            if (itensSelecionados.indexOf(idParam) > -1) {
                efetuaComprasSelecionados.push(efetuaCompra);
            }
        })
        setItem({
            ...item,
            efetuacompras: efetuaComprasSelecionados
        })
    }

    const onChangeEstilos = (event: SelectChangeEvent<number[]>) => {
        const itensSelecionados = event.target.value as number[];
        let estilosSelecionados: Estilo[] = [];
        dadosAcessorios.estilos?.forEach(function (estilo) {
            let idParam: number;
            if (estilo.id) {
                idParam = estilo.id;
            } else {
                throw new Error('Estilo id required');
            }
            if (itensSelecionados.indexOf(idParam) > -1) {
                estilosSelecionados.push(estilo);
            }
        })
        setItem({
            ...item,
            estilos: estilosSelecionados
        })
    }

    const onChangePreferencias = (event: SelectChangeEvent<number[]>) => {
        const itensSelecionados = event.target.value as number[];
        let preferenciasSelecionados: Preferencia[] = [];
        dadosAcessorios.preferencias?.forEach(function (preferencia) {
            let idParam: number;
            if (preferencia.id) {
                idParam = preferencia.id;
            } else {
                throw new Error('Preferencia id required');
            }
            if (itensSelecionados.indexOf(idParam) > -1) {
                preferenciasSelecionados.push(preferencia);
            }
        })
        setItem({
            ...item,
            preferencias: preferenciasSelecionados
        })
    }

    const onChangeProfissaos = (event: SelectChangeEvent<number[]>) => {
        const itensSelecionados = event.target.value as number[];
        let profissaosSelecionados: Profissao[] = [];
        dadosAcessorios.profissaos?.forEach(function (profissao) {
            let idParam: number;
            if (profissao.id) {
                idParam = profissao.id;
            } else {
                throw new Error('Profissao id required');
            }
            if (itensSelecionados.indexOf(idParam) > -1) {
                profissaosSelecionados.push(profissao);
            }
        })
        setItem({
            ...item,
            profissaos: profissaosSelecionados
        })
    }

    const onChangeConheceuLojas = (event: SelectChangeEvent<number[]>) => {
        const itensSelecionados = event.target.value as number[];
        let conheceuLojasSelecionados: ConheceuLoja[] = [];
        dadosAcessorios.conheceuLojas?.forEach(function (conheceuLoja) {
            let idParam: number;
            if (conheceuLoja.id) {
                idParam = conheceuLoja.id;
            } else {
                throw new Error('ConheceuLoja id required');
            }
            if (itensSelecionados.indexOf(idParam) > -1) {
                conheceuLojasSelecionados.push(conheceuLoja);
            }
        })
        setItem({
            ...item,
            conheceulojas: conheceuLojasSelecionados
        })
    }

    const onChangeTemperamentos = (event: SelectChangeEvent<number[]>) => {
        const itensSelecionados = event.target.value as number[];
        let temperamentosSelecionados: Temperamento[] = [];
        dadosAcessorios.temperamentos?.forEach(function (temperamento) {
            let idParam: number;
            if (temperamento.id) {
                idParam = temperamento.id;
            } else {
                throw new Error('Temperamento id required');
            }
            if (itensSelecionados.indexOf(idParam) > -1) {
                temperamentosSelecionados.push(temperamento);
            }
        })
        setItem({
            ...item,
            temperamentos: temperamentosSelecionados
        })
    }

    const renderDadosAcessorios = () => {
        return tabValue === 1 && (
            <div>
                <SelectOption multiple fullWidth label="Quem influencia a compra" value={item.influenciacompras}
                              onChange={onChangeInfluenciaCompras} items={dadosAcessorios.influenciaCompras}
                              itemValue='id' itemDescription='descricao'/>
                <SelectOption multiple fullWidth label="Quem decide a compra" value={item.decidecompras}
                              onChange={onChangeDecideCompras} items={dadosAcessorios.decideCompras} itemValue='id'
                              itemDescription='descricao'/>
                {dadosAcessorios.efetuaCompras &&
                    <SelectOption multiple fullWidth label="Quem efetua a compra" value={item.efetuacompras}
                                  onChange={onChangeEfetuaCompras} items={dadosAcessorios.efetuaCompras} itemValue='id'
                                  itemDescription='descricao'/>}
                {dadosAcessorios.estilos &&
                    <SelectOption multiple fullWidth label="Estilos" value={item.estilos} onChange={onChangeEstilos}
                                  items={dadosAcessorios.estilos} itemValue='id' itemDescription='descricao'/>}
                {dadosAcessorios.preferencias &&
                    <SelectOption multiple fullWidth label="Preferências" value={item.preferencias}
                                  onChange={onChangePreferencias} items={dadosAcessorios.preferencias} itemValue='id'
                                  itemDescription='descricao'/>}
                {dadosAcessorios.profissaos &&
                    <SelectOption multiple fullWidth label="Profissão" value={item.profissaos}
                                  onChange={onChangeProfissaos} items={dadosAcessorios.profissaos} itemValue='id'
                                  itemDescription='descricao'/>}
                <SelectOption multiple fullWidth label="Como conheceu a loja" value={item.conheceulojas}
                              onChange={onChangeConheceuLojas} items={dadosAcessorios.conheceuLojas} itemValue='id'
                              itemDescription='descricao'/>
                {dadosAcessorios.temperamentos &&
                    <SelectOption multiple fullWidth label="Temperamento" value={item.temperamentos}
                                  onChange={onChangeTemperamentos} items={dadosAcessorios.temperamentos} itemValue='id'
                                  itemDescription='descricao'/>}
            </div>
        )
    }

    const renderCompras = () => {
        return tabValue === 2 && (
            <Datatable data={saleItems} totalData={saleItems.length}
                       dataColumnNames={['Emissão', 'Produto', 'Categoria', 'Quantidade', 'Observação']}
                       dataColumns={['created_date', 'produto', 'categoriaDescricao', 'quantidade', 'observacao']}
                       dataTypes={['zonedatetime', 'text', 'text', 'number', 'text']}/>
        )
    }

    const deleteParente = (parenteToDelete: Parente, index: number) => {
        let {parentes} = item;
        parentes?.splice(index, 1);
        setItem({
            ...item,
            parentes: parentes
        })
    }

    const rowOperationsParentes = [
        {color: () => ColorIconEnum.PRIMARY, onClick: deleteParente, label: () => 'Excluir', icon: () => <DeleteIcon/>},
    ]

    const editParente = (parenteToEdit: Parente, index: number) => {
        setParenteToModal({
            item: parenteToEdit,
            index: index
        });
        openParenteDialog();
    }

    const insertParente = () => {
        setParenteToModal({
            item: ParenteService.createParente(),
            index: -1
        });
        openParenteDialog();
    }

    const headerOperationsParentes = [
        {color: () => ColorButtonEnum.PRIMARY, onClick: insertParente, label: () => 'Inserir'}
    ]

    const renderParenteDialog = () => {
        return showParenteDialog && (
            <CustomDialog open={showParenteDialog} onClose={closeParenteDialog} title={'Inserir parente'}>
                <ParenteDetail parent={parenteToModal.item}/>
            </CustomDialog>
        )
    }

    const renderParentes = () => {
        return tabValue === 3 && (
            <Datatable data={item.parentes} totalData={item.parentes.length} rowOperations={rowOperationsParentes}
                       dataColumnNames={['Nome', 'Grau de parentesco', 'Telefone', 'Data de nascimento']}
                       dataColumns={['nome', 'grau', 'telefone', 'dataNascimento']}
                       headerOperations={headerOperationsParentes} dataTypes={['text', 'enum', 'text', 'date']}
                       editData={editParente}/>
        )
    }

    const openParenteDialog = () => {
        setShowParenteDialog(true);
    }

    const closeParenteDialog = (parenteEditadoOuInserido: Parente | null) => {
        setShowParenteDialog(false);
        if (parenteEditadoOuInserido) {
            let {parentes} = item;
            if (parenteToModal.index !== -1) {
                parentes[parenteToModal.index] = parenteEditadoOuInserido;
            } else {
                parentes.push(parenteEditadoOuInserido);
            }
            setItem({
                ...item,
                parentes: parentes
            })
        }
    }

    return (
        <form onSubmit={save}>
            <Paper square sx={classes.personDetailTabBar}>
                <Tabs value={tabValue} indicatorColor="primary" textColor="primary" onChange={handleChangeTab}
                      variant={"scrollable"} scrollButtons={"auto"}>
                    <Tab label="Dados principais"/>
                    <Tab label="Dados acessórios"/>
                    <Tab label="Compras"/>
                    <Tab label="Parentes"/>
                </Tabs>
            </Paper>
            {renderDadosPrincipais()}
            {renderDadosAcessorios()}
            {renderCompras()}
            {renderParentes()}
            {renderParenteDialog()}
            <CustomButtonGroup>
                <CustomButton color="primary" type="submit">Salvar</CustomButton>
                <CustomButton type="button" color="neutral" onClick={cancel}>Cancelar</CustomButton>
            </CustomButtonGroup>
        </form>
    )

}
