import React, {useCallback, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {Delete as DeleteIcon} from "@mui/icons-material";
import {Datatable} from "../index";
import {ColorService} from "./color.service";
import {useNavigate} from "react-router-dom";
import {changePageTitle, setAlert} from "../typescript/redux/actions";

const ColorList = () => {

    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [items, setItems] = useState(null);
    const [number, setNumber] = useState('');
    const [size, setSize] = useState('');
    const [sort, setSort] = useState('');
    const [filter, setFilter] = useState('');

    const findAll = useCallback(async (numberP, sizeP, sortP, filterP) => {
        const result = await ColorService.findAll(numberP, sizeP, sortP, filterP);
        setItems(result);
        setNumber(numberP);
        setSize(sizeP);
        setSort(sortP);
        setFilter(filterP);
    }, []);

    useEffect(() => {
        dispatch(changePageTitle("Cores"));
        findAll(0, parseInt(process.env.REACT_APP_PAGE_SIZE), "id,asc", '').then();
    }, [dispatch, findAll]);

    const editItem = (item) => {
        navigate('/color/' + item.id);
    }

    const insertItem = () => {
        navigate('/color');
    }

    const deleteItem = (item) => {
        ColorService.delete(item.id).then(() => {
            findAll(number, size, sort, filter).then();
        }).catch(() => {
            dispatch(setAlert({show: true, message: 'Erro ao excluir cor', severity: 'error'}));
        })
    }

    const headerOperations = [
        {color: () => "primary", onClick: insertItem, label: () => 'Inserir'}
    ]

    const rowOperations = [
        {color: () => "primary", onClick: deleteItem, label: () => 'Excluir', icon: () => <DeleteIcon/>}
    ]

    return items && (
        <Datatable dataColumnNames={['ID', 'Descrição']} dataColumns={['id', 'description']}
                   number={number} size={size} sort={sort} filter={filter}
                   dataTypes={['number', 'text']} findAll={findAll} editData={editItem}
                   data={items.content} totalData={items.totalElements}
                   headerOperations={headerOperations} rowOperations={rowOperations}/>
    );
}

export default React.memo(ColorList);