import React, {useState} from "react";
import {Grid, Typography} from "@mui/material";
import {Datatable, DocumentPay} from "../index";
import {AttachMoney} from "@mui/icons-material";
import {CustomDialog} from "../typescript/custom-dialog/custom-dialog";
import {NumberUtil} from "../typescript/util/number-util";
import CustomPanel from "../typescript/custom-panel/custom-panel";

const DocumentosEmAberto = (props) => {

    const {documentos, title, findAll} = props;
    const [itemToModal, setItemToModal] = useState({
        match: null,
        index: -1,
        show: false
    });

    const getTotal = () => {
        const valorTotal = documentos.reduce((valorInicial, item) => valorInicial + item.saldo, 0)
        return NumberUtil.currencyFormat(valorTotal);
    }

    const pagar = async (item, index) => {
        setItemToModal({
            match: {
                params: {
                    id: item.id
                }
            },
            index: index,
            show: true
        })
    }

    const rowOperations = [
        {color: () => "primary", onClick: pagar, label: () => 'Pagar', icon: () => <AttachMoney/>}
    ]

    const closeItemDialog = (success) => {
        setItemToModal({
            match: null,
            index: -1,
            show: false
        });
        if (success) {
            findAll();
        }
    }

    const renderItemDialog = () => {
        return itemToModal.show && (
            <CustomDialog open={itemToModal.show} onClose={closeItemDialog} title={'Pagar documento'}>
                <DocumentPay match={itemToModal.match}/>
            </CustomDialog>
        )
    }

    return (
        <React.Fragment>
            {renderItemDialog()}
            <Grid item md xs={12}>
                <CustomPanel title={title}>
                    <Datatable data={documentos} totalData={documentos.length} maxHeight={440}
                               dataColumnNames={['Pessoa', 'Vencimento', 'Saldo']}
                               dataColumns={['pessoa.nome', 'vencimento', 'saldo']}
                               dataTypes={['text', 'date', 'currency']} rowOperations={rowOperations}/>
                </CustomPanel>
                <Typography variant="subtitle1" align={"center"}>Total: {getTotal()}</Typography>
            </Grid>
        </React.Fragment>
    );

}

export default React.memo(DocumentosEmAberto);