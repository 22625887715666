import React, {useCallback, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {AttachMoney, Cancel, Check, Delete as DeleteIcon, Edit, Print, Search} from "@mui/icons-material";
import {
    Datatable
} from "../index";
import {ConsultaService} from "./consulta.service";
import {Grid, IconButton, Tooltip} from "@mui/material";
import {useNavigate} from "react-router-dom";
import CustomTextField from "../typescript/custom-text-field/custom-text-field";
import {changePageTitle, setAlert} from "../typescript/redux/actions";
import {DateUtil} from "../typescript/util/date-util";
import * as StringUtil from "../typescript/util/string-util";
import {FileUtil} from "../typescript/util/file-util";
import ButtonSelect from "../typescript/button-select/button-select";
import {RelatorioService} from "../typescript/relatorio/relatorio.service";

export function ConsultaList() {

    let navigate = useNavigate();
    const [pageParams, setPageParams] = useState(null);
    const dispatch = useDispatch();
    const [dataInicial, setDataInicial] = useState(DateUtil.firstDayOfCurrentMonthInJavaSqlDate());
    const [dataFinal, setDataFinal] = useState(DateUtil.lastDayOfCurrentMonthInJavaSqlDate());

    const findAll = useCallback(async (dataInicial, dataFinal) => {
        const items = await ConsultaService.findAll(dataInicial, dataFinal);
        setPageParams({
            dataInicial: dataInicial,
            dataFinal: dataFinal,
            items: items
        });
    }, []);

    useEffect(() => {
        dispatch(changePageTitle("Consultas"));
        findAll(DateUtil.firstDayOfCurrentMonthInJavaSqlDate(), DateUtil.lastDayOfCurrentMonthInJavaSqlDate()).then();
    }, [dispatch, findAll]);

    const editItem = (item) => {
        navigate('/consulta/' + item.id);
    }

    const insertItem = () => {
        navigate('/consulta');
    }

    const search = (event) => {
        event.preventDefault();
        event.stopPropagation();
        findAll(dataInicial, dataFinal).then();
    }

    const deleteItem = (item) => {
        ConsultaService.delete(item.id).then(() => {
            findAll(dataInicial, dataFinal).then();
        }).catch(() => {

        })
    }

    const headerOperations = [
        {color: () => "primary", onClick: insertItem, label: () => 'Inserir'}
    ]

    const imprime = (item) => {
        RelatorioService.imprimeConsulta(item.id).then(function (result) {
            FileUtil.openPdfInNewTab(result);
        }, (result) => {
            const error = StringUtil.decodeUint8Array(result);
            dispatch(setAlert({show: true, message: error.message, severity: 'error'}));
        })
    }

    const rowOperations = [
        {color: () => "primary", onClick: deleteItem, label: () => 'Excluir', icon: () => <DeleteIcon/>},
        {color: () => "primary", onClick: imprime, label: () => 'Imprimir', icon: () => <Print/>}
    ]

    const status1 = [
        {id: null, descricao: 'Neutro', icon: <Edit/>, color: "default"},
        {id: 'CONFIRMADO', descricao: 'Confirmado', icon: <Check/>, color: "primary"},
        {id: 'DESISTENCIA', descricao: 'Desistência', icon: <Cancel/>, color: "error"}
    ]

    const status2 = [
        {id: null, descricao: 'Neutro', icon: <Edit/>, color: "default"},
        {id: 'COMPROU', descricao: 'Comprou', icon: <Check/>, color: "primary"},
        {id: 'FEZ_ORCAMENTO', descricao: 'Fez orçamento', icon: <AttachMoney/>, color: "primary"},
        {id: 'FEZ_EM_OUTRO_LUGAR', descricao: 'Fez em outro lugar', icon: <Cancel/>, color: "error"},
        {id: 'NAO_PRECISOU', descricao: 'Não precisou', icon: <Cancel/>, color: "error"},
        {id: 'ACHOU_CARO', descricao: 'Achou caro', icon: <Cancel/>, color: "error"}
    ]

    const onChangeStatus1 = async (status1, item) => {
        const result = await ConsultaService.updateStatus1(item.id, status1);
        let itemsToChange = [...pageParams.items];
        for (let i = 0; i < itemsToChange.length; i++) {
            if (itemsToChange[i].id === item.id) {
                itemsToChange[i] = result.data;
                break;
            }
        }
        setPageParams({
            ...pageParams,
            items: itemsToChange
        })
    }

    const onChangeStatus2 = async (status2, item) => {
        const result = await ConsultaService.updateStatus2(item.id, status2);
        let itemsToChange = [...pageParams.items];
        for (let i = 0; i < itemsToChange.length; i++) {
            if (itemsToChange[i].id === item.id) {
                itemsToChange[i] = result.data;
                break;
            }
        }
        setPageParams({
            ...pageParams,
            items: itemsToChange
        })
    }

    const renderDescriptionStatus1 = (id) => {
        const itemSelected = status1.filter(item => item.id === id);
        if (itemSelected[0]) {
            return itemSelected[0].descricao;
        }
    }

    const renderDescriptionStatus2 = (id) => {
        const itemSelected = status2.filter(item => item.id === id);
        if (itemSelected[0]) {
            return itemSelected[0].descricao;
        }
    }

    const renderStatus1 = (item, dataColumn) => {
        return (
            <ButtonSelect items={status1} id={item[dataColumn]} onChange={(status1) => onChangeStatus1(status1, item)}
                          title={renderDescriptionStatus1(item[dataColumn])}/>
        )
    }

    const renderStatus2 = (item, dataColumn) => {
        return (
            <ButtonSelect items={status2} id={item[dataColumn]} onChange={(status2) => onChangeStatus2(status2, item)}
                          title={renderDescriptionStatus2(item[dataColumn])}/>
        )
    }

    const changeDataInicial = (event) => {
        setDataInicial(event.target.value);
    }

    const changeDataFinal = (event) => {
        setDataFinal(event.target.value);
    }

    return pageParams && (
        <React.Fragment>
            <form onSubmit={search}>
                <Grid container spacing={1}>
                    <Grid item md>
                        <Grid container spacing={1}>
                            <Grid item md xs={12}>
                                <CustomTextField fullWidth autoFocus required label="Data inicial" type="date"
                                                 value={dataInicial || ''}
                                                 InputLabelProps={{shrink: true}} onChange={changeDataInicial}/>
                            </Grid>
                            <Grid item md xs={12}>
                                <CustomTextField fullWidth required label="Data final" type="date"
                                                 value={dataFinal || ''}
                                                 InputLabelProps={{shrink: true}} onChange={changeDataFinal}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Tooltip title="Pesquisar">
                            <IconButton type={"submit"}>
                                <Search/>
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </form>
            <Datatable
                dataColumnNames={['ID', 'Nome', 'Fone 1', 'Fone 2', 'Dr.', 'Data', 'Hora', 'Status 1', 'Status 2']}
                dataColumns={['id', 'nome', 'fone1', 'fone2', 'medico', 'data', 'hora', 'status1', 'status2']}
                number={pageParams.number} size={pageParams.size} sort={pageParams.sort} filter={pageParams.filter}
                dataTypes={['number', 'text', 'text', 'text', 'text', 'date', 'hour', 'enum', 'enum']} findAll={findAll}
                editData={editItem}
                data={pageParams.items} totalData={pageParams.items.length}
                dataValues={[null, null, null, null, null, null, null, renderStatus1, renderStatus2]}
                headerOperations={headerOperations} rowOperations={rowOperations}/>
        </React.Fragment>
    );
}
