import React from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {PlanoDeContasDetail} from "./plano-de-contas-detail";

const PlanoDeContasDetailPage = React.memo(() => {

    const {id} = useParams();
    let navigate = useNavigate();

    const onClose = () => {
        navigate('/planodecontas');
    }

    return (
        <PlanoDeContasDetail id={id} onClose={onClose}/>
    )

});

export default PlanoDeContasDetailPage;