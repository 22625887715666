import React, {useEffect, useState} from "react";
import {ForeignKey, PersonDetail} from "../index";
import CustomTextField from "../typescript/custom-text-field/custom-text-field";
import CustomButton from "../typescript/custom-button/custom-button";
import CustomButtonGroup from "../typescript/custom-button-group/custom-button-group";
import {AuthService} from "../typescript/auth/auth.service";
import NumberInput from "../typescript/number-input/number-input";
import {PersonService} from "../person/person.service";
import {ProductService} from "./product.service";

export function ValorDetail(props) {

    const [item, setItem] = useState(ProductService.createValor());

    useEffect(() => {
        let itemTemp = props.item;
        setItem(itemTemp);
    }, [props.item]);

    const save = (event) => {
        event.preventDefault();
        event.stopPropagation();
        props.onClose(item);
    }

    const cancel = () => {
        props.onClose();
    }

    const setCusto = (event) => {
        setItem({
            ...item,
            custo: event.target.value
        })
    }

    const setValor = (event) => {
        setItem({
            ...item,
            valor: event.target.value
        })
    }

    const onChangeFornecedor = async (id) => {
        setItem({
            ...item,
            fornecedor: {
                id: id
            }
        })
        if (id) {
            const fornecedor = await PersonService.findOneFornecedor(id);
            if (fornecedor) {
                setItem({
                    ...item,
                    fornecedor: fornecedor
                })
            }
        }
    }

    const onAddFornecedor = (fornecedor) => {
        if (fornecedor) {
            setItem({
                ...item,
                fornecedor: fornecedor
            })
        }
    }

    const findFornecedores = (page, size, sort, filter) => {
        return PersonService.findAll(page, size, sort, filter, null, true);
    }

    const changeDataInicial = (event) => {
        setItem({
            ...item,
            dataInicial: event.target.value
        })
    }

    const changeDataFinal = (event) => {
        setItem({
            ...item,
            dataFinal: event.target.value
        })
    }

    function renderCusto() {
        return AuthService.userInRole("MASTER") && (
            <NumberInput fullWidth required label="Custo" value={item.custo} onChange={setCusto}/>
        );
    }

    return (
        <form onSubmit={save}>
            <ForeignKey autoFocus fullWidth required label="Fornecedor" value={item.fornecedor} fieldKey={'id'}
                        fieldDescription={'nome'} labelDescription='Nome' onChange={onChangeFornecedor}
                        onSelect={onAddFornecedor} onAdd={onAddFornecedor} findAll={findFornecedores}
                        dataColumnNames={['ID', 'Nome']} dataColumns={['id', 'nome']}
                        dataTypes={['number', 'text']} addComponent={<PersonDetail/>}/>
            {renderCusto()}
            <NumberInput fullWidth required label="Valor de venda" value={item.valor} onChange={setValor}/>
            <CustomTextField fullWidth required label="Data inicial" type="date" value={item.dataInicial || ''}
                             InputLabelProps={{shrink: true}} onChange={changeDataInicial}/>
            <CustomTextField fullWidth required label="Data final" type="date" value={item.dataFinal || ''}
                             InputLabelProps={{shrink: true}} onChange={changeDataFinal}/>
            <CustomButtonGroup>
                <CustomButton color="primary" type="submit">Salvar</CustomButton>
                <CustomButton type="button" color="neutral" onClick={cancel}>Cancelar</CustomButton>
            </CustomButtonGroup>
        </form>
    )

}