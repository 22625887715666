import React from "react";
import {
    ActivityInsert,
    ActivityList,
    App,
    BrandList,
    CashRegisterList,
    CategoryGroupList,
    CategoryList,
    CentroDeCustoList,
    ChangePassword,
    ColorList,
    ComissionList,
    ComissionProductList,
    ConheceulojaList,
    ConsultaList,
    ConsultSales,
    CurvaABC,
    DecidecompraList,
    DocumentDeleteLogList,
    DocumentList,
    DocumentParcels,
    DocumentPay,
    DocumentPaySaleAfter,
    DocumentSaleItem,
    DocumentUpdateLogList,
    DreReport,
    EfetuacompraList,
    EstiloList,
    ExpenseAnalysis,
    FinancialVision,
    FiscalmodelList,
    GoalAnalysis,
    Home,
    InfluenciacompraList,
    LaboratoryList,
    MonthlyBudget,
    MonthlyBudgetAnalysis,
    NeighborhoodList,
    NFeConsulta,
    ParameterList,
    PaymentFlux,
    PaymentTypeList,
    PersonList,
    PlanoDeContasList,
    PontoManagement,
    PontoReport,
    PreferenciaList,
    ProductList,
    ProfissaoList,
    ReserveList,
    SalaryHistoryList,
    SaleAfterList,
    SaleAnalysis,
    SaleOverview,
    SaleProfile,
    StockMovement,
    TemperamentoList,
    UpdateProductValue,
    UserAvatar,
    UserGoalList,
    UserList,
    VacationList,
    WalletOverview,
    WorkList
} from "../index";
import {createRoutesFromElements, Navigate, Route} from "react-router-dom";
import StateDetailPage from "../typescript/state/state-detail-page";
import CityDetailPage from "../typescript/city/city-detail-page";
import BrandDetailPage from "../brand/brand-detail-page";
import CashRegisterDetailPage from "../cash-register/cash-register-detail-page";
import CategoryDetailPage from "../category/category-detail-page";
import CategoryGroupDetailPage from "../category-group/category-group-detail-page";
import CentroDeCustoDetailPage from "../centro-de-custo/centro-de-custo-detail-page";
import ColorDetailPage from "../color/color-detail-page";
import ComissionDetailPage from "../comission/comission-detail-page";
import ComissionProductDetailPage from "../comission-product/comission-product-detail-page";
import ConheceuLojaDetailPage from "../conheceuloja/conheceu-loja-detail-page";
import ConsultaDetailPage from "../consulta/consulta-detail-page";
import DecideCompraDetailPage from "../decidecompra/decide-compra-detail-page";
import DocumentDetailPage from "../document/document-detail-page";
import EfetuaCompraDetailPage from "../efetuacompra/efetua-compra-detail-page";
import EstiloDetailPage from "../estilo/estilo-detail-page";
import FiscalModelDetailPage from "../fiscalmodel/fiscal-model-detail-page";
import InfluenciaCompraDetailPage from "../influenciacompra/influencia-compra-detail-page";
import InstagramDetailPage from "../instagram/instagram-detail-page";
import LaboratoryDetailPage from "../laboratory/laboratory-detail-page";
import NeighborhoodDetailPage from "../neighborhood/neighborhood-detail-page";
import PaymentTypeDetailPage from "../payment-type/payment-type-detail-page";
import PersonDetailPage from "../person/person-detail-page";
import PlanoDeContasDetailPage from "../plano-de-contas/plano-de-contas-detail-page";
import PreferenciaDetailPage from "../preferencia/preferencia-detail-page";
import ProductDetailPage from "../product/product-detail-page";
import ProfissaoDetailPage from "../profissao/profissao-detail-page";
import ReserveDetailPage from "../reserve/reserve-detail-page";
import TemperamentoDetailPage from "../temperamento/temperamento-detail-page";
import UserDetailPage from "../user/user-detail-page";
import UserGoalDetailPage from "../user-goal/user-goal-detail-page";
import VacationDetailPage from "../user/vacation-detail-page";
import WalletDetailPage from "../typescript/wallet/wallet-detail-page";
import WorkDetailPage from "../user/work-detail-page";
import MuralImagePage from "../user/mural-image-page";
import CheckoutOpen from "../typescript/checkout/checkout-open";
import CheckoutClose from "../typescript/checkout/checkout-close";
import {StateList} from "../typescript/state/state-list";
import {CityList} from "../typescript/city/city-list";
import WalletMovement from "../typescript/wallet/wallet-movement";
import {WalletList} from "../typescript/wallet/wallet-list";
import SecuredRoute from "../typescript/secured-route/secured-route";
import {Forbidden} from "../typescript/forbidden/forbidden";
import Aniversariante from "../typescript/aniversariante/aniversariante";
import CashFlowList from "../cash-flow/cash-flow-list";
import CampaignTypeList from "../typescript/campaign/campaign-type/campaign-type-list";
import CampaignTypeDetailPage from "../typescript/campaign/campaign-type/campaign-type-detail-page";
import CampaignContactTypeDetailPage from "../typescript/campaign/campaign-contact/campaign-contact-type-detail-page";
import CampaignContactTypeList from "../typescript/campaign/campaign-contact/campaign-contact-type-list";
import CampaignList from "../typescript/campaign/campaign/campaign-list";
import CampaignGenerate from "../typescript/campaign/campaign/campaign-generate";
import CampaignDetailPage from "../typescript/campaign/campaign/campaign-detail-page";
import CampaignPersonList from "../typescript/campaign/campaign-person/campaign-person-list";
import {Login} from "../login/login";
import LastSale from "../last-sale/last-sale";
import DocumentFromSaleList from "../document/document-from-sale-list";
import InstagramList from "../instagram/instagram-list";
import {DocumentBaixaLogList} from "../document/document-baixa-log-list";
import {PersonCampaignList} from "../person/campaign/person-campaign-list";
import {HolidayList} from "../typescript/holiday/holiday-list";
import HolidayDetailPage from "../typescript/holiday/holiday-detail-page";
import SaleDetailPage from "../typescript/sale/detail/sale-detail-page";
import SaleList from "../typescript/sale/list/sale-list";
import {PontoDetail} from "../ponto/ponto-detail";
import BrandTaxList from "../typescript/brand-tax/brand-tax-list";
import BrandTaxDetailPage from "../typescript/brand-tax/brand-tax-detail-page";

const appRoutes = createRoutesFromElements(<Route path="/" element={<App/>}>
        {/*Allow to all*/}
        <Route path="/forbidden" element={<Forbidden/>}/>
        <Route path="/login" element={<Login/>}/>

        {/*Allow to Authority ADMIN*/}
        <Route path="/users" element={<SecuredRoute authority="ADMIN"><UserList/></SecuredRoute>}/>
        <Route path="/parameters" element={<SecuredRoute authority="ADMIN"><ParameterList/></SecuredRoute>}/>
        <Route path="/user" element={<SecuredRoute authority="ADMIN"><UserDetailPage/></SecuredRoute>}/>
        <Route path="/user/:id" element={<SecuredRoute authority="ADMIN"><UserDetailPage/></SecuredRoute>}/>
        <Route path="/user/:id/changepassword"
               element={<SecuredRoute authority="ADMIN"><ChangePassword/></SecuredRoute>}/>
        <Route path="/user/:id/salaryhistory"
               element={<SecuredRoute authority="ADMIN"><SalaryHistoryList/></SecuredRoute>}/>
        <Route path="/user/:id/vacations" element={<SecuredRoute authority="ADMIN"><VacationList/></SecuredRoute>}/>
        <Route path="/user/:id/vacation"
               element={<SecuredRoute authority="ADMIN"><VacationDetailPage/></SecuredRoute>}/>
        <Route path="/user/:id/vacation/:vacation"
               element={<SecuredRoute authority="ADMIN"><VacationDetailPage/></SecuredRoute>}/>
        <Route path="/user/:id/works" element={<SecuredRoute authority="ADMIN"><WorkList/></SecuredRoute>}/>
        <Route path="/user/:id/work" element={<SecuredRoute authority="ADMIN"><WorkDetailPage/></SecuredRoute>}/>
        <Route path="/user/:id/work/:work" element={<SecuredRoute authority="ADMIN"><WorkDetailPage/></SecuredRoute>}/>
        <Route path="/user/:id/mural" element={<SecuredRoute authority="ADMIN"><MuralImagePage/></SecuredRoute>}/>
        <Route path="/usergoals" element={<SecuredRoute authority="ADMIN"><UserGoalList/></SecuredRoute>}/>
        <Route path="/usergoal" element={<SecuredRoute authority="ADMIN"><UserGoalDetailPage/></SecuredRoute>}/>
        <Route path="/usergoal/:id" element={<SecuredRoute authority="ADMIN"><UserGoalDetailPage/></SecuredRoute>}/>
        <Route path="/comissions" element={<SecuredRoute authority="ADMIN"><ComissionList/></SecuredRoute>}/>
        <Route path="/comission" element={<SecuredRoute authority="ADMIN"><ComissionDetailPage/></SecuredRoute>}/>
        <Route path="/comission/:id" element={<SecuredRoute authority="ADMIN"><ComissionDetailPage/></SecuredRoute>}/>
        <Route path="/ponto/management"
               element={<SecuredRoute authority="ADMIN"><PontoManagement/></SecuredRoute>}/>
        <Route path="/comissionproducts"
               element={<SecuredRoute authority="ADMIN"><ComissionProductList/></SecuredRoute>}/>
        <Route path="/comissionproduct"
               element={<SecuredRoute authority="ADMIN"><ComissionProductDetailPage/></SecuredRoute>}/>
        <Route path="/comissionproduct/:id"
               element={<SecuredRoute authority="ADMIN"><ComissionProductDetailPage/></SecuredRoute>}/>
        <Route path="/activity" element={<SecuredRoute authority="ADMIN"><ActivityInsert/></SecuredRoute>}/>
        <Route path="/products/updatevalues"
               element={<SecuredRoute authority="ADMIN"><UpdateProductValue/></SecuredRoute>}/>
        <Route path="/documents/logs/updates"
               element={<SecuredRoute authority="ADMIN"><DocumentUpdateLogList/></SecuredRoute>}/>
        <Route path="/documents/logs/deletes"
               element={<SecuredRoute authority="ADMIN"><DocumentDeleteLogList/></SecuredRoute>}/>
        <Route path="/documents/logs/baixas"
               element={<SecuredRoute authority="ADMIN"><DocumentBaixaLogList/></SecuredRoute>}/>
        <Route path="/sales/overview" element={<SecuredRoute authority="ADMIN"><SaleOverview/></SecuredRoute>}/>
        <Route path="/sales/profile" element={<SecuredRoute authority="ADMIN"><SaleProfile/></SecuredRoute>}/>
        <Route path="/goal/analysis" element={<SecuredRoute authority="ADMIN"><GoalAnalysis/></SecuredRoute>}/>
        <Route path="/sales/analysis" element={<SecuredRoute authority="ADMIN"><SaleAnalysis/></SecuredRoute>}/>
        <Route path="/sales/last" element={<SecuredRoute authority="ADMIN"><LastSale/></SecuredRoute>}/>
        <Route path="/curvaABC" element={<SecuredRoute authority="ADMIN"><CurvaABC/></SecuredRoute>}/>
        <Route path="/drereports" element={<SecuredRoute authority="ADMIN"><DreReport/></SecuredRoute>}/>
        <Route path="/pontoreport" element={<SecuredRoute authority="ADMIN"><PontoReport/></SecuredRoute>}/>
        <Route path="/financial/overview/wallet/:id"
               element={<SecuredRoute authority="ADMIN"><WalletOverview/></SecuredRoute>}/>
        <Route path="/paymentflux" element={<SecuredRoute authority="ADMIN"><PaymentFlux/></SecuredRoute>}/>
        <Route path="/monthlybudget" element={<SecuredRoute authority="ADMIN"><MonthlyBudget/></SecuredRoute>}/>
        <Route path="/monthlybudgetanalysis"
               element={<SecuredRoute authority="ADMIN"><MonthlyBudgetAnalysis/></SecuredRoute>}/>
        <Route path="/cashregister" element={<SecuredRoute authority="ADMIN"><CashRegisterDetailPage/></SecuredRoute>}/>
        <Route path="/expenseanalysis" element={<SecuredRoute authority="ADMIN"><ExpenseAnalysis/></SecuredRoute>}/>
        <Route path="/campaigntypes" element={<SecuredRoute authority="ADMIN"><CampaignTypeList/></SecuredRoute>}/>
        <Route path="/campaigntype" element={<SecuredRoute><CampaignTypeDetailPage/></SecuredRoute>}/>
        <Route path="/campaigntype/:id" element={<SecuredRoute><CampaignTypeDetailPage/></SecuredRoute>}/>
        <Route path="/campaigncontacttypes"
               element={<SecuredRoute authority="ADMIN"><CampaignContactTypeList/></SecuredRoute>}/>
        <Route path="/campaigncontacttype" element={<SecuredRoute><CampaignContactTypeDetailPage/></SecuredRoute>}/>
        <Route path="/campaigncontacttype/:id" element={<SecuredRoute><CampaignContactTypeDetailPage/></SecuredRoute>}/>
        <Route path="/campaigns" element={<SecuredRoute><CampaignList/></SecuredRoute>}/>
        <Route path="/campaign" element={<SecuredRoute><CampaignGenerate/></SecuredRoute>}/>
        <Route path="/campaign/:id" element={<SecuredRoute><CampaignDetailPage/></SecuredRoute>}/>
        <Route path="/campaign/:id/persons" element={<SecuredRoute><CampaignPersonList/></SecuredRoute>}/>

        {/*Allow to Authority MASTER*/}
        <Route path="/sales/after/:sale/payables"
               element={<SecuredRoute authority="MASTER"><DocumentFromSaleList/></SecuredRoute>}/>
        <Route path="/sales/after/:sale/payables/:document/pay"
               element={<SecuredRoute authority="MASTER"><DocumentPaySaleAfter/></SecuredRoute>}/>
        <Route path="/financial/overview"
               element={<SecuredRoute authority="MASTER"><FinancialVision/></SecuredRoute>}/>
        <Route path="/cashregisters" element={<SecuredRoute authority="MASTER"><CashRegisterList/></SecuredRoute>}/>
        <Route path="/cashregister/:id"
               element={<SecuredRoute authority="MASTER"><CashRegisterDetailPage/></SecuredRoute>}/>
        <Route path="/cashflow" element={<SecuredRoute authority="MASTER"><CashFlowList/></SecuredRoute>}/>

        {/*Allow to Authority ANY*/}
        <Route path="/user/:id/avatar" element={<SecuredRoute><UserAvatar/></SecuredRoute>}/>
        <Route path="/states" element={<SecuredRoute><StateList/></SecuredRoute>}/>
        <Route path="/state" element={<SecuredRoute><StateDetailPage/></SecuredRoute>}/>
        <Route path="/state/:id" element={<SecuredRoute><StateDetailPage/></SecuredRoute>}/>
        <Route path="/holidays" element={<SecuredRoute><HolidayList/></SecuredRoute>}/>
        <Route path="/holiday" element={<SecuredRoute><HolidayDetailPage/></SecuredRoute>}/>
        <Route path="/holiday/:id" element={<SecuredRoute><HolidayDetailPage/></SecuredRoute>}/>
        <Route path="/cities" element={<SecuredRoute><CityList/></SecuredRoute>}/>
        <Route path="/city" element={<SecuredRoute><CityDetailPage/></SecuredRoute>}/>
        <Route path="/city/:id" element={<SecuredRoute><CityDetailPage/></SecuredRoute>}/>
        <Route path="/neighborhoods" element={<SecuredRoute><NeighborhoodList/></SecuredRoute>}/>
        <Route path="/neighborhood" element={<SecuredRoute><NeighborhoodDetailPage/></SecuredRoute>}/>
        <Route path="/neighborhood/:id" element={<SecuredRoute><NeighborhoodDetailPage/></SecuredRoute>}/>
        <Route path="/categorygroups" element={<SecuredRoute><CategoryGroupList/></SecuredRoute>}/>
        <Route path="/categorygroup" element={<SecuredRoute><CategoryGroupDetailPage/></SecuredRoute>}/>
        <Route path="/categorygroup/:id" element={<SecuredRoute><CategoryGroupDetailPage/></SecuredRoute>}/>
        <Route path="/categories" element={<SecuredRoute><CategoryList/></SecuredRoute>}/>
        <Route path="/category" element={<SecuredRoute><CategoryDetailPage/></SecuredRoute>}/>
        <Route path="/category/:id" element={<SecuredRoute><CategoryDetailPage/></SecuredRoute>}/>
        <Route path="/planodecontas" element={<SecuredRoute><PlanoDeContasList/></SecuredRoute>}/>
        <Route path="/planodeconta" element={<SecuredRoute><PlanoDeContasDetailPage/></SecuredRoute>}/>
        <Route path="/planodeconta/:id" element={<SecuredRoute><PlanoDeContasDetailPage/></SecuredRoute>}/>
        <Route path="/centrodecustos" element={<SecuredRoute><CentroDeCustoList/></SecuredRoute>}/>
        <Route path="/centrodecusto" element={<SecuredRoute><CentroDeCustoDetailPage/></SecuredRoute>}/>
        <Route path="/centrodecusto/:id" element={<SecuredRoute><CentroDeCustoDetailPage/></SecuredRoute>}/>
        <Route path="/persons" element={<SecuredRoute><PersonList/></SecuredRoute>}/>
        <Route path="/person" element={<SecuredRoute><PersonDetailPage/></SecuredRoute>}/>
        <Route path="/person/:person/documents" element={<SecuredRoute><DocumentList/></SecuredRoute>}/>
        <Route path="/person/:person/campaigns" element={<SecuredRoute><PersonCampaignList/></SecuredRoute>}/>
        <Route path="/person/:id">
            <Route index element={<SecuredRoute><PersonDetailPage/></SecuredRoute>}/>
            <Route path=":tab" element={<SecuredRoute><PersonDetailPage/></SecuredRoute>}/>
        </Route>
        <Route path="/laboratories" element={<SecuredRoute><LaboratoryList/></SecuredRoute>}/>
        <Route path="/laboratory" element={<SecuredRoute><LaboratoryDetailPage/></SecuredRoute>}/>
        <Route path="/laboratory/:id" element={<SecuredRoute><LaboratoryDetailPage/></SecuredRoute>}/>
        <Route path="/conheceulojas" element={<SecuredRoute><ConheceulojaList/></SecuredRoute>}/>
        <Route path="/conheceuloja" element={<SecuredRoute><ConheceuLojaDetailPage/></SecuredRoute>}/>
        <Route path="/conheceuloja/:id" element={<SecuredRoute><ConheceuLojaDetailPage/></SecuredRoute>}/>
        <Route path="/decidecompras" element={<SecuredRoute><DecidecompraList/></SecuredRoute>}/>
        <Route path="/decidecompra" element={<SecuredRoute><DecideCompraDetailPage/></SecuredRoute>}/>
        <Route path="/decidecompra/:id" element={<SecuredRoute><DecideCompraDetailPage/></SecuredRoute>}/>
        <Route path="/efetuacompras" element={<SecuredRoute><EfetuacompraList/></SecuredRoute>}/>
        <Route path="/efetuacompra" element={<SecuredRoute><EfetuaCompraDetailPage/></SecuredRoute>}/>
        <Route path="/efetuacompra/:id" element={<SecuredRoute><EfetuaCompraDetailPage/></SecuredRoute>}/>
        <Route path="/estilos" element={<SecuredRoute><EstiloList/></SecuredRoute>}/>
        <Route path="/estilo" element={<SecuredRoute><EstiloDetailPage/></SecuredRoute>}/>
        <Route path="/estilo/:id" element={<SecuredRoute><EstiloDetailPage/></SecuredRoute>}/>
        <Route path="/influenciacompras" element={<SecuredRoute><InfluenciacompraList/></SecuredRoute>}/>
        <Route path="/influenciacompra" element={<SecuredRoute><InfluenciaCompraDetailPage/></SecuredRoute>}/>
        <Route path="/influenciacompra/:id" element={<SecuredRoute><InfluenciaCompraDetailPage/></SecuredRoute>}/>
        <Route path="/preferencias" element={<SecuredRoute><PreferenciaList/></SecuredRoute>}/>
        <Route path="/preferencia" element={<SecuredRoute><PreferenciaDetailPage/></SecuredRoute>}/>
        <Route path="/preferencia/:id" element={<SecuredRoute><PreferenciaDetailPage/></SecuredRoute>}/>
        <Route path="/profissaos" element={<SecuredRoute><ProfissaoList/></SecuredRoute>}/>
        <Route path="/profissao" element={<SecuredRoute><ProfissaoDetailPage/></SecuredRoute>}/>
        <Route path="/profissao/:id" element={<SecuredRoute><ProfissaoDetailPage/></SecuredRoute>}/>
        <Route path="/temperamentos" element={<SecuredRoute><TemperamentoList/></SecuredRoute>}/>
        <Route path="/temperamento" element={<SecuredRoute><TemperamentoDetailPage/></SecuredRoute>}/>
        <Route path="/temperamento/:id" element={<SecuredRoute><TemperamentoDetailPage/></SecuredRoute>}/>
        <Route path="/consultas" element={<SecuredRoute><ConsultaList/></SecuredRoute>}/>
        <Route path="/consulta" element={<SecuredRoute><ConsultaDetailPage/></SecuredRoute>}/>
        <Route path="/consulta/:id" element={<SecuredRoute><ConsultaDetailPage/></SecuredRoute>}/>
        <Route path="/ponto" element={<SecuredRoute><PontoDetail/></SecuredRoute>}/>
        <Route path="/activities" element={<SecuredRoute><ActivityList/></SecuredRoute>}/>
        <Route path="/products" element={<SecuredRoute><ProductList/></SecuredRoute>}/>
        <Route path="/products/stockmovement" element={<SecuredRoute><StockMovement/></SecuredRoute>}/>
        <Route path="/product" element={<SecuredRoute><ProductDetailPage/></SecuredRoute>}/>
        <Route path="/product/:id" element={<SecuredRoute><ProductDetailPage/></SecuredRoute>}/>
        <Route path="/brands" element={<SecuredRoute><BrandList/></SecuredRoute>}/>
        <Route path="/brand" element={<SecuredRoute><BrandDetailPage/></SecuredRoute>}/>
        <Route path="/brand/:id" element={<SecuredRoute><BrandDetailPage/></SecuredRoute>}/>
        <Route path="/brand/:brand/taxes" element={<SecuredRoute><BrandTaxList/></SecuredRoute>}/>
        <Route path="/brand/:brand/tax" element={<SecuredRoute><BrandTaxDetailPage/></SecuredRoute>}/>
        <Route path="/brand/:brand/tax/:tax" element={<SecuredRoute><BrandTaxDetailPage/></SecuredRoute>}/>
        <Route path="/wallets" element={<SecuredRoute><WalletList/></SecuredRoute>}/>
        <Route path="/wallets/movement" element={<SecuredRoute><WalletMovement/></SecuredRoute>}/>
        <Route path="/wallet" element={<SecuredRoute><WalletDetailPage/></SecuredRoute>}/>
        <Route path="/wallet/:id" element={<SecuredRoute><WalletDetailPage/></SecuredRoute>}/>
        <Route path="/fiscalmodels" element={<SecuredRoute><FiscalmodelList/></SecuredRoute>}/>
        <Route path="/fiscalmodel" element={<SecuredRoute><FiscalModelDetailPage/></SecuredRoute>}/>
        <Route path="/fiscalmodel/:id" element={<SecuredRoute><FiscalModelDetailPage/></SecuredRoute>}/>
        <Route path="/paymenttypes" element={<SecuredRoute><PaymentTypeList/></SecuredRoute>}/>
        <Route path="/paymenttype" element={<SecuredRoute><PaymentTypeDetailPage/></SecuredRoute>}/>
        <Route path="/paymenttype/:id" element={<SecuredRoute><PaymentTypeDetailPage/></SecuredRoute>}/>
        <Route path="/documents" element={<SecuredRoute><DocumentList/></SecuredRoute>}/>
        <Route path="/document" element={<SecuredRoute><DocumentDetailPage/></SecuredRoute>}/>
        <Route path="/document/parcels" element={<SecuredRoute><DocumentParcels/></SecuredRoute>}/>
        <Route path="/document/:id" element={<SecuredRoute><DocumentDetailPage/></SecuredRoute>}/>
        <Route path="/document/:id/pay" element={<SecuredRoute><DocumentPay/></SecuredRoute>}/>
        <Route path="/document/:id/saleitem" element={<SecuredRoute><DocumentSaleItem/></SecuredRoute>}/>
        <Route path="/sales" element={<SecuredRoute><SaleList/></SecuredRoute>}/>
        <Route path="/sales/after" element={<SecuredRoute><SaleAfterList/></SecuredRoute>}/>
        <Route path="/sales/after/:sale/receivables"
               element={<SecuredRoute><DocumentFromSaleList/></SecuredRoute>}/>
        <Route path="/sales/after/:sale/receivables/:document/pay"
               element={<SecuredRoute><DocumentPaySaleAfter/></SecuredRoute>}/>
        <Route path="/sale" element={<SecuredRoute><SaleDetailPage/></SecuredRoute>}/>
        <Route path="/sale/:id" element={<SecuredRoute><SaleDetailPage/></SecuredRoute>}/>
        <Route path="/aniversariantes" element={<SecuredRoute><Aniversariante/></SecuredRoute>}/>
        <Route path="/consultsales" element={<SecuredRoute><ConsultSales/></SecuredRoute>}/>
        <Route path="/nfe/consulta" element={<SecuredRoute><NFeConsulta/></SecuredRoute>}/>
        <Route path="/checkout/open" element={<SecuredRoute><CheckoutOpen/></SecuredRoute>}/>
        <Route path="/checkout/close" element={<SecuredRoute><CheckoutClose/></SecuredRoute>}/>
        <Route path="/reserves" element={<SecuredRoute><ReserveList/></SecuredRoute>}/>
        <Route path="/reserve" element={<SecuredRoute><ReserveDetailPage/></SecuredRoute>}/>
        <Route path="/reserve/:id" element={<SecuredRoute><ReserveDetailPage/></SecuredRoute>}/>
        <Route path="/colors" element={<SecuredRoute><ColorList/></SecuredRoute>}/>
        <Route path="/color" element={<SecuredRoute><ColorDetailPage/></SecuredRoute>}/>
        <Route path="/color/:id" element={<SecuredRoute><ColorDetailPage/></SecuredRoute>}/>
        <Route path="/instagrams" element={<SecuredRoute><InstagramList/></SecuredRoute>}/>
        <Route path="/instagram" element={<SecuredRoute><InstagramDetailPage/></SecuredRoute>}/>
        <Route path="/instagram/:id" element={<SecuredRoute><InstagramDetailPage/></SecuredRoute>}/>
        <Route path="/" element={<SecuredRoute><Home/></SecuredRoute>}/>
        <Route path="*" element={<Navigate to="/" replace/>}/>
    </Route>
)

export default appRoutes;
