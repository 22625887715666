import {Typography} from "@mui/material";
import {ForeignKey} from "../index";
import React, {useEffect, useState} from "react";
import {DocumentService} from "./document.service";
import {useDispatch} from "react-redux";
import CustomTextField from "../typescript/custom-text-field/custom-text-field";
import CustomButton from "../typescript/custom-button/custom-button";
import CustomButtonGroup from "../typescript/custom-button-group/custom-button-group";
import CustomRow from "../typescript/custom-row/custom-row";
import {DateUtil} from "../typescript/util/date-util";
import {setAlert} from "../typescript/redux/actions";
import {FileUtil} from "../typescript/util/file-util";
import {NumberUtil} from "../typescript/util/number-util";
import {WalletService} from "../typescript/wallet/wallet.service";
import {BrandService} from "../brand/brand.service";
import {PaymentTypeService} from "../payment-type/payment-type.service";
import {SaleService} from "../sale/sale.service";

export function DocumentPayTotal(props) {

    const [item, setItem] = useState({
        dataPagamento: DateUtil.toJavaSqlDate(DateUtil.currentDate()),
        tipoDePagamento: PaymentTypeService.createPaymentType(),
        conta: WalletService.createWallet(),
        bandeira: BrandService.createBrand(),
        valorFinal: '',
        valorDesconto: 0,
        valorMulta: 0,
        valorJurosDia: 0,
        cardNumberOfParcels: ''
    })
    const dispatch = useDispatch();

    useEffect(() => {
        const documentFromProps = props.document;

        const valorDesconto = documentFromProps.valorDesconto || '';
        const valorMulta = documentFromProps.valorMulta || '';
        const valorJurosDia = documentFromProps.valorJurosDia || '';
        let tipoDePagamento = PaymentTypeService.createPaymentType();
        let conta = WalletService.createWallet();
        if (documentFromProps.tipopagamento) {
            tipoDePagamento = documentFromProps.tipopagamento;
            if (documentFromProps.tipopagamento.id === SaleService.PAGAMENTO_CARTAO.id && documentFromProps.bandeira && documentFromProps.bandeira.carteira) {
                conta = documentFromProps.bandeira.carteira;
            } else if (documentFromProps.tipopagamento.carteira) {
                conta = documentFromProps.tipopagamento.carteira;
            }
        }
        const valorFinal = DocumentService.getValorFinal(documentFromProps.valor, documentFromProps.valorDesconto, documentFromProps.valorMulta,
            documentFromProps.valorJurosDia, DateUtil.toJavaSqlDate(DateUtil.currentDate()), documentFromProps.dataVencimento);

        setItem({
            dataPagamento: DateUtil.toJavaSqlDate(DateUtil.currentDate()),
            tipoDePagamento: tipoDePagamento,
            conta: conta,
            valorDesconto: valorDesconto,
            valorMulta: valorMulta,
            valorJurosDia: valorJurosDia,
            valorFinal: valorFinal
        })

    }, [props.document]);

    const setDataPagamento = (event) => {
        const newDataDePagamento = event.target.value;
        const valorFinal = DocumentService.getValorFinal(props.document.valor, item.valorDesconto, item.valorMulta,
            item.valorJurosDia, newDataDePagamento, props.document.dataVencimento);
        setItem({
            ...item,
            dataPagamento: newDataDePagamento,
            valorFinal: valorFinal
        })
    }

    const onChangeTipoDePagamento = async (id) => {
        setItem({
            ...item,
            tipoDePagamento: {
                id: id
            },
            conta: WalletService.createWallet(),
            bandeira: BrandService.createBrand()
        })
        if (id) {
            const tipoDePagamento = await PaymentTypeService.findOne(id);
            if (tipoDePagamento) {
                let conta = WalletService.createWallet();
                if (tipoDePagamento.carteira) {
                    conta = tipoDePagamento.carteira;
                }
                setItem({
                    ...item,
                    tipoDePagamento: tipoDePagamento,
                    conta: conta,
                    bandeira: BrandService.createBrand()
                })
            }
        }
    }

    const onSelectTipoDePagamento = (tipoDePagamento) => {
        let conta = WalletService.createWallet();
        if (tipoDePagamento.conta) {
            conta = tipoDePagamento.conta;
        }
        if (tipoDePagamento) {
            setItem({
                ...item,
                tipoDePagamento: tipoDePagamento,
                conta: conta,
                bandeira: BrandService.createBrand()
            })
        }
    }

    const findTipoDePagamentos = (page, size, sort, filter) => {
        return PaymentTypeService.findAll(page, size, sort, filter);
    }

    const onChangeConta = async (id) => {
        setItem({
            ...item,
            conta: {
                id: id
            }
        })
        if (id) {
            const conta = await WalletService.findOne(id);
            if (conta) {
                setItem({
                    ...item,
                    conta: conta
                })
            }
        }
    }

    const onSelectConta = (conta) => {
        if (conta) {
            setItem({
                ...item,
                conta: conta
            })
        }
    }

    const findContas = (page, size, sort, filter) => {
        return WalletService.findAll(page, size, sort, filter);
    }

    const setDesconto = (event) => {
        const newDesconto = event.target.value;
        const newValorFinal = DocumentService.getValorFinal(props.document.valor, newDesconto, item.valorMulta,
            item.valorJurosDia, item.dataPagamento, props.document.dataVencimento);
        setItem({
            ...item,
            valorDesconto: newDesconto,
            valorFinal: newValorFinal,
        })
    }

    const setMulta = (event) => {
        const newMulta = event.target.value;
        const newValorFinal = DocumentService.calculaValorFinal2(props.document.valor, item.valorDesconto, newMulta,
            item.valorJurosDia, item.dataPagamento, props.document.dataVencimento);
        setItem({
            ...item,
            valorMulta: newMulta,
            valorFinal: newValorFinal,
        })
    }

    const setJuros = (event) => {
        const newJuros = event.target.value;
        const newValorFinal = DocumentService.calculaValorFinal2(props.document.valor, item.desconto, item.multa,
            newJuros, item.dataPagamento, props.document.dataVencimento);
        setItem({
            ...item,
            valorJurosDia: newJuros,
            valorFinal: newValorFinal,
        })
    }
    const onChangeBandeira = async (id) => {
        setItem({
            ...item,
            bandeira: {
                id: id
            }
        })
        if (id) {
            const bandeira = await BrandService.findOne(id);
            if (bandeira) {
                setItem({
                    ...item,
                    bandeira: bandeira
                })
            }
        }
    }

    const onSelectBandeira = (bandeira) => {
        if (bandeira) {
            setItem({
                ...item,
                bandeira: bandeira
            })
        }
    }

    const findBandeiras = (page, size, sort, filter) => {
        return BrandService.findAll(page, size, sort, filter);
    }

    const renderBandeira = () => {
        return PaymentTypeService.isCartao(item.tipoDePagamento) && (
            <CustomRow>
                <ForeignKey fullWidth required label="Bandeira" value={item.bandeira} fieldKey={'id'}
                            fieldDescription={'descricao'} labelDescription='Descrição' onChange={onChangeBandeira}
                            onSelect={onSelectBandeira} findAll={findBandeiras}
                            dataColumnNames={['ID', 'Descrição']} dataColumns={['id', 'descricao']}
                            dataTypes={['number', 'text']}/>
                <CustomTextField type="number" fullWidth required label="Número de parcelas para cartão"
                                 value={item.cardNumberOfParcels} onChange={setCardNumberOfParcels}/>
            </CustomRow>
        )
    }

    const setCardNumberOfParcels = (event) => {
        setItem({
            ...item,
            cardNumberOfParcels: event.target.value
        })
    }

    const payTotal = (event) => {
        event.preventDefault();
        event.stopPropagation();
        DocumentService.payTotal(props.document.id, item).then(() => {
            DocumentService.recibo58x135(props.document.id).then(function (result) {
                if (!props.document.aPagar) {
                    FileUtil.openPdfInNewTab(result);
                }
            });
            dispatch(setAlert({show: true, message: 'Documento pago com sucesso', severity: 'success'}));
            props.back(true);
        }).catch((error) => {
            let message = 'Erro ao pagar documento';
            if (error.response.data.message) {
                message = (message + ' - ' + error.response.data.message);
            }
            dispatch(setAlert({show: true, message: message, severity: 'error'}));
        })
    }

    const back = () => {
        props.back();
    }

    return (
        <form onSubmit={payTotal}>
            <Typography variant="subtitle1" gutterBottom>
                Valor do documento: {NumberUtil.currencyFormat(props.document.valor)}
            </Typography>
            <CustomTextField fullWidth autoFocus required label="Data de pagamento" type="date"
                             value={item.dataPagamento}
                             onChange={setDataPagamento} InputLabelProps={{shrink: true}}/>
            <CustomRow>
                <ForeignKey fullWidth required label="Tipo de pagamento" value={item.tipoDePagamento} fieldKey={'id'}
                            fieldDescription={'descricao'} labelDescription='Descrição'
                            onChange={onChangeTipoDePagamento}
                            onSelect={onSelectTipoDePagamento} findAll={findTipoDePagamentos}
                            dataColumnNames={['ID', 'Descrição']}
                            dataColumns={['id', 'descricao']} dataTypes={['number', 'text']}/>
                <ForeignKey fullWidth required label="Conta" value={item.conta} fieldKey={'id'}
                            fieldDescription={'descricao'} labelDescription='Descrição' onChange={onChangeConta}
                            onSelect={onSelectConta} findAll={findContas}
                            dataColumnNames={['ID', 'Descrição']} dataColumns={['id', 'descricao']}
                            dataTypes={['number', 'text']}/>
            </CustomRow>
            {renderBandeira()}
            <CustomRow>
                <CustomTextField fullWidth label="Desconto (R$)" type="number" value={item.valorDesconto || ''}
                                 onChange={setDesconto}/>
                <CustomTextField fullWidth label="Multa (R$)" type="number" value={item.valorMulta || ''}
                                 onChange={setMulta}/>
                <CustomTextField fullWidth label="Juros por dia (R$)" type="number" value={item.valorJurosDia || ''}
                                 onChange={setJuros}/>
            </CustomRow>
            <Typography variant="subtitle1" gutterBottom>
                Valor final: {NumberUtil.currencyFormat(item.valorFinal)}
            </Typography>
            <CustomButtonGroup>
                <CustomButton color="primary" type="submit">Pagar</CustomButton>
                <CustomButton type="button" color="neutral" onClick={back}>Cancelar</CustomButton>
            </CustomButtonGroup>
        </form>
    )
}
