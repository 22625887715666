import React from "react";
import {IconButton, Tooltip} from "@mui/material";
import {Build, Delete, FileCopy, Lock, Print, ViewWeek} from "@mui/icons-material";
import {SaleService} from "../../../../../sale/sale.service";
import {setAlert} from "../../../../redux/actions";
import {useDispatch} from "react-redux";
import {Sale} from "../../../../../sale/sale";
import CloseSaleButton from "../../../close/close-sale-button";
import OrcamentoButton from "../../../orcamento/orcamento-button";
import {FileUtil} from "../../../../util/file-util";
import * as StringUtil from "../../../../util/string-util";
import GeraNfeButton from "../../../nfe/gera-nfe-button";
import {NfeService} from "../../../../../nfe/nfe.service";
import CancelaNfeButton from "../../../nfe/cancela-nfe-button";

interface SaleListBodyTableContentRowOperationsProps {
    sale: Sale;
    findAll: () => void;
}

const SaleListBodyTableContentRowOperations = React.memo(({
                                                              sale,
                                                              findAll
                                                          }: SaleListBodyTableContentRowOperationsProps) => {
    const dispatch = useDispatch();

    let saleId: number;
    if (sale.id) {
        saleId = sale.id;
    } else {
        throw new Error('Sale id required');
    }

    const deleteItem = () => {
        SaleService.delete(saleId).then(() => {
            dispatch(setAlert({show: true, message: 'Venda excluída com sucesso', severity: 'success'}));
            findAll();
        }).catch((error) => {
            let message = 'Erro ao excluir venda';
            if (error.response.data.message) {
                message += ' - ' + error.response.data.message;
            }
            dispatch(setAlert({show: true, message: message, severity: 'error'}));
        })
    }

    const duplicateItem = () => {
        SaleService.duplica(saleId).then(() => {
            dispatch(setAlert({show: true, message: 'Venda duplicada com sucesso', severity: 'success'}));
            findAll();
        }).catch((error) => {
            let message = 'Erro ao duplicar venda';
            if (error.response.data.message) {
                message += ' - ' + error.response.data.message;
            }
            dispatch(setAlert({show: true, message: message, severity: 'error'}));
        })
    }

    const printItem = () => {
        SaleService.laboratoryOrder(saleId).then(function (result) {
            FileUtil.openPdfInNewTab(result);
            imprimeRecibo();
        }, (error) => {
            if (error.response.status === 404) {
                imprimeRecibo();
            } else {
                const errorDecoded = StringUtil.decodeUint8Array(error);
                dispatch(setAlert({show: true, message: errorDecoded.message, severity: 'error'}));
            }
        })
    }

    const imprimeRecibo = () => {
        SaleService.recibo(saleId).then(function (result) {
            FileUtil.openPdfInNewTab(result);
        }, (error) => {
            const errorDecoded = StringUtil.decodeUint8Array(error);
            dispatch(setAlert({show: true, message: errorDecoded.message, severity: 'error'}));
        })
    }

    const warrantyItem = () => {
        SaleService.laboratoryWarranty(saleId).then(function (result) {
            FileUtil.openPdfInNewTab(result);
        }, (error) => {
            const errorDecoded = StringUtil.decodeUint8Array(error);
            dispatch(setAlert({show: true, message: errorDecoded.message, severity: 'error'}));
        })
    }

    const confissionItem = () => {
        SaleService.confissao(saleId).then(function (result) {
            FileUtil.openPdfInNewTab(result);
        });
    }

    const reimprimeNfe = () => {
        NfeService.imprimeDanfe(sale.nota.numero).then(function (result) {
            FileUtil.openPdfInNewTab(result);
        }, (result) => {
            const error = StringUtil.decodeUint8Array(result);
            dispatch(setAlert({show: true, message: error.message, severity: 'error'}));
        })
    }

    return (
        <React.Fragment>
            <Tooltip title={'Excluir'}>
                    <span>
                        <IconButton color={'primary'} onClick={deleteItem} disabled={!!sale.nota}>
                            <Delete/>
                        </IconButton>
                    </span>
            </Tooltip>
            <Tooltip title={'Duplicar'}>
                    <span>
                        <IconButton color={'primary'} onClick={duplicateItem}>
                            <FileCopy/>
                        </IconButton>
                    </span>
            </Tooltip>
            {!sale.dataHoraFechamento && <CloseSaleButton sale={sale} findAll={findAll}/>}
            {!sale.dataHoraFechamento && <OrcamentoButton sale={sale}/>}
            <Tooltip title={'Imprimir'} hidden={!sale.dataHoraFechamento}>
                    <span>
                        <IconButton color={'primary'} onClick={printItem}>
                            <Print/>
                        </IconButton>
                    </span>
            </Tooltip>
            <Tooltip title={'Garantia'} hidden={!sale.dataHoraFechamento}>
                    <span>
                        <IconButton color={'primary'} onClick={warrantyItem}>
                            <Build/>
                        </IconButton>
                    </span>
            </Tooltip>
            <Tooltip title={'Confissão de dívida'} hidden={!sale.dataHoraFechamento}>
                    <span>
                        <IconButton color={'primary'} onClick={confissionItem}>
                            <Lock/>
                        </IconButton>
                    </span>
            </Tooltip>
            {!sale.nota && <GeraNfeButton sale={sale} findAll={findAll}/>}
            <Tooltip title={'Reimprimir NF-e'} hidden={!sale.nota}>
                    <span>
                        <IconButton color={'default'} onClick={reimprimeNfe} disabled={!sale.nota}>
                            <ViewWeek/>
                        </IconButton>
                    </span>
            </Tooltip>
            {!!sale.nota && <CancelaNfeButton sale={sale} findAll={findAll}/>}
        </React.Fragment>
    )

});

export default SaleListBodyTableContentRowOperations;
