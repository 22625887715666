import React from "react";
import {FormControl, IconButton, Input, InputAdornment, InputLabel, Tooltip} from "@mui/material";
import {Search} from "@mui/icons-material";

interface DatatableFilterProps {
    filter: string;
    setFilter: (filter: string) => void;
    doFilter: () => void;
    type: string;
}

const DatatableFilter = ({filter, setFilter, doFilter, type}: DatatableFilterProps) => {

    const onChangeFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilter(event.target.value);
    }

    const search = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            doFilter();
        }
    }

    return (
        <FormControl fullWidth variant={"standard"}>
            <InputLabel shrink>Pesquisar</InputLabel>
            <Input autoFocus value={filter} onChange={onChangeFilter} onKeyDown={search} type={type}
                   endAdornment={
                       <InputAdornment position="end">
                           <Tooltip title="Pesquisar">
                               <IconButton onClick={doFilter}>
                                   <Search/>
                               </IconButton>
                           </Tooltip>
                       </InputAdornment>
                   }
            />
        </FormControl>
    )

}

const areEqual = (prevProps: DatatableFilterProps, nextProps: DatatableFilterProps) => {
    return (prevProps.filter === nextProps.filter) && (prevProps.type === nextProps.type);
}

export default React.memo(DatatableFilter, areEqual);
