import React from "react";
import {Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import CustomPanel from "../typescript/custom-panel/custom-panel";

const classes = {
    tableHeader: {
        "& th": {
            fontWeight: 'bold'
        }
    }
};

const LowStockCategories = (props) => {

    const {categories} = props;

    const renderHeader = () => {
        return (
            <TableHead sx={classes.tableHeader}>
                <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Descrição</TableCell>
                    <TableCell align={"center"}>Estoque atual</TableCell>
                    <TableCell align={"center"}>Estoque mínimo</TableCell>
                </TableRow>
            </TableHead>
        );
    }

    const renderBody = () => {
        if (categories && categories.length > 0) {
            return (
                <TableBody>
                    {categories.map((category, index) => (
                        <TableRow key={index}>
                            <TableCell>{category.id}</TableCell>
                            <TableCell>{category.description}</TableCell>
                            <TableCell align={"center"}>{category.currentStock}</TableCell>
                            <TableCell align={"center"}>{category.minimumStock}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            );
        } else {
            return (
                <TableBody>
                    <TableRow>
                        <TableCell colSpan={99}>
                            Nenhum registro encontrado
                        </TableCell>
                    </TableRow>
                </TableBody>
            )
        }
    }

    return (
        <Grid item md xs={12}>
            <CustomPanel title="Categorias com estoque abaixo do mínimo">
                <TableContainer component={Paper}>
                    <Table size={"small"}>
                        {renderHeader()}
                        {renderBody()}
                    </Table>
                </TableContainer>
            </CustomPanel>
        </Grid>
    );

}

export default React.memo(LowStockCategories);