import React, {FormEvent, useEffect, useState} from "react";
import {changePageTitle, setAlert} from "../typescript/redux/actions";
import {useDispatch} from "react-redux";
import {Grid, IconButton, Tooltip} from "@mui/material";
import {RelatorioService} from "../typescript/relatorio/relatorio.service";
import {Search} from "@mui/icons-material";
import CustomTextField from "../typescript/custom-text-field/custom-text-field";
import * as StringUtil from "../typescript/util/string-util";
import {FileUtil} from "../typescript/util/file-util";
import {DateUtil} from "../typescript/util/2.0.0/date-util";

const NFeConsulta = () => {
    const dispatch = useDispatch();
    const [dataInicial, setDataInicial] = useState(DateUtil.toJavaSqlDate(DateUtil.currentDate()));
    const [dataFinal, setDataFinal] = useState(DateUtil.toJavaSqlDate(DateUtil.currentDate()));

    useEffect(() => {
        dispatch(changePageTitle("Notas emitidas"));
    }, [dispatch]);

    const search = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        RelatorioService.getNotasEmitidas(dataInicial, dataFinal).then(function (result) {
            FileUtil.openPdfInNewTab(result);
        }, (result) => {
            const error = StringUtil.decodeUint8Array(result);
            dispatch(setAlert({show: true, message: error.message, severity: 'error'}));
        })
    }

    const changeDataInicial = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDataInicial(event.target.value);
    }

    const changeDataFinal = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDataFinal(event.target.value);
    }

    return (
        <form onSubmit={search}>
            <Grid container spacing={1}>
                <Grid item md>
                    <Grid container spacing={1}>
                        <Grid item md xs={12}>
                            <CustomTextField fullWidth autoFocus required label="Data inicial" type="date"
                                             value={dataInicial || ''} InputLabelProps={{shrink: true}}
                                             onChange={changeDataInicial}/>
                        </Grid>
                        <Grid item md xs={12}>
                            <CustomTextField fullWidth required label="Data final" type="date" value={dataFinal || ''}
                                             InputLabelProps={{shrink: true}} onChange={changeDataFinal}/>
                        </Grid>

                    </Grid>
                </Grid>
                <Grid item>
                    <Tooltip title="Pesquisar">
                        <IconButton type={"submit"}>
                            <Search/>
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
        </form>
    )
}

export default React.memo(NFeConsulta);
