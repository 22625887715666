import {Menu, MenuItem} from "@mui/material";
import React from "react";
import {AuthService} from "../typescript/auth/auth.service";

interface AppMenuUserListProps {
    anchorEl: HTMLElement | null;
    onClose: () => void;
}

const AppMenuUserList = ({anchorEl, onClose}: AppMenuUserListProps) => {

    const username = AuthService.getUsername();

    return anchorEl && (
        <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={onClose}>
            <MenuItem disabled>{username}</MenuItem>
            <MenuItem component="a" href="/ponto">Ponto</MenuItem>
            <MenuItem component="a" href="/login">Logout</MenuItem>
        </Menu>
    )
}

export default React.memo(AppMenuUserList);