import React from "react";
import {TableFooter, TablePagination, TableRow} from "@mui/material";

const DatatableTableFooter = React.memo(({totalData, setNumber, setSize, number, size}) => {

    const onPageChange = (event, page) => {
        setNumber(page);
    }

    const onRowsPerPageChange = (event) => {
        const rows = parseInt(event.target.value, 10);
        setSize(rows);
    }

    return (
        <TableFooter>
            <TableRow>
                <TablePagination rowsPerPageOptions={[5, 10, 20, 25, 50, 100]} count={totalData}
                                 onPageChange={onPageChange} page={number}
                                 rowsPerPage={size} onRowsPerPageChange={onRowsPerPageChange}
                                 labelRowsPerPage="Itens por página"/>
            </TableRow>
        </TableFooter>
    )

})

export default DatatableTableFooter;