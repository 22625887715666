import React, {useCallback, useEffect, useState} from "react";
import {setAlert} from "../typescript/redux/actions";
import {useDispatch} from "react-redux";
import {SaleService} from "../sale/sale.service";
import {
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip
} from "@mui/material";
import {DocumentService} from "./document.service";
import {AttachMoney, Cancel, Edit, MoneyOff, Save} from "@mui/icons-material";
import {useNavigate, useParams, useLocation} from "react-router-dom";
import CustomTextField from "../typescript/custom-text-field/custom-text-field";
import CustomButton from "../typescript/custom-button/custom-button";
import {changePageTitle} from "../typescript/redux/actions";
import {NumberUtil} from "../typescript/util/number-util";
import {DateUtil} from "../typescript/util/date-util";
import {AuthService} from "../typescript/auth/auth.service";

const classes = {
    datatableTableHeader: {
        "& th": {
            fontWeight: 'bold'
        }
    }
};

const DocumentFromSaleList = () => {

    const isMaster = AuthService.userInRole("MASTER");
    const location = useLocation();
    let navigate = useNavigate();
    const {sale} = useParams();
    const dispatch = useDispatch();
    const [items, setItems] = useState(SaleService.createDocumentsBySaleResponse());
    const [editing, setEditing] = useState(null);
    const [newTotal, setNewTotal] = useState('');
    const lastPart = location.pathname.split('/').pop();
    const payable = (lastPart === 'payables');

    const findAll = useCallback(async () => {
        const result = await SaleService.findAllDocumentsBySale(sale, payable);
        setItems(result);
    }, [sale, payable]);

    useEffect(() => {
        if (payable) {
            dispatch(changePageTitle("Documentos a pagar da venda " + sale));
        } else {
            dispatch(changePageTitle("Documentos a receber da venda " + sale));
        }
        findAll().then();
    }, [dispatch, sale, findAll, payable]);

    const goBack = () => {
        navigate('/sales/after');
    }

    const renderTableHeader = () => {
        return (
            <TableHead sx={classes.datatableTableHeader}>
                <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Pessoa</TableCell>
                    <TableCell>Telefone</TableCell>
                    <TableCell>Valor</TableCell>
                    <TableCell>Saldo</TableCell>
                    <TableCell>Vencimento</TableCell>
                    <TableCell>Pagamento</TableCell>
                    <TableCell>Parcela</TableCell>
                    <TableCell/>
                </TableRow>
            </TableHead>
        )
    }

    const saveItem = (document) => {
        if (!newTotal || newTotal === '0' || Number(newTotal) < 0) {
            return;
        }
        DocumentService.saveNewTotal(document, newTotal).then(() => {
            dispatch(setAlert({show: true, message: 'Novo total salvo com sucesso', severity: 'success'}));
            setEditing(null);
            findAll().then();
        }).catch((error) => {
            dispatch(setAlert({
                show: true,
                message: 'Erro ao salvar novo total - ' + error.data.message,
                severity: 'error'
            }));
            cancelEdit();
        })
    }

    const cancelEdit = () => {
        setNewTotal('');
        setEditing(null);
    }

    const editItem = (document, index) => {
        setNewTotal(document.total);
        setEditing(index);
    }

    const goToPay = (document) => {
        if (payable) {
            navigate('/sales/after/' + sale + '/payables/' + document.id + '/pay');
        } else {
            navigate('/sales/after/' + sale + '/receivables/' + document.id + '/pay');
        }
    }

    const cancelPay = (document) => {
        DocumentService.cancelPayment(document.id).then(() => {
            dispatch(setAlert({show: true, message: 'Pagamento cancelado com sucesso', severity: 'success'}));
            findAll().then();
        }).catch((error) => {
            let message = 'Erro ao cancelar pagamento';
            if (error.response.data.message) {
                message += ' - ' + error.response.data.message;
            }
            dispatch(setAlert({show: true, message: message, severity: 'error'}));
        })
    }

    const renderPay = (document) => {
        if (!document.paymentDate) {
            return (
                <Tooltip title={'Pagar'}>
                    <IconButton color={'primary'} onClick={() => goToPay(document)}>
                        <AttachMoney/>
                    </IconButton>
                </Tooltip>
            )
        }
    }

    const renderCancelPay = (document) => {
        if (isMaster && document.paymentDate) {
            return (
                <Tooltip title={'Cancelar pagamento'}>
                    <IconButton color={'primary'} onClick={() => cancelPay(document)}>
                        <MoneyOff/>
                    </IconButton>
                </Tooltip>
            )
        }
    }

    const renderEditOrSave = (document, index) => {
        if (editing === index) {
            return (
                <React.Fragment>
                    <Tooltip title={'Salvar'}>
                        <IconButton color={'primary'} onClick={() => saveItem(document)}>
                            <Save/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={'Cancelar'}>
                        <IconButton color={'default'} onClick={cancelEdit}>
                            <Cancel/>
                        </IconButton>
                    </Tooltip>
                </React.Fragment>
            )
        }
        return !document.paymentDate && (
            <Tooltip title={'Editar'}>
                <IconButton color={'primary'} onClick={() => editItem(document, index)}>
                    <Edit/>
                </IconButton>
            </Tooltip>
        )
    }

    const onChangeTotal = (event) => {
        setNewTotal(event.target.value)
    }

    const renderTotal = (document, index) => {
        if (editing === index) {
            return (
                <CustomTextField type="number" fullWidth required label="Valor (R$)" value={newTotal} onChange={onChangeTotal}
                                 InputProps={{inputProps: {min: 0, step: "any"}}}/>
            )
        }
        return NumberUtil.currencyFormat(document.total);
    }

    const renderItems = () => {
        if (items.documents && items.documents.length > 0) {
            return items.documents.map((document, index) => {
                return (
                    <TableRow key={index}>
                        <TableCell>{document.id}</TableCell>
                        <TableCell>{document.person.name}</TableCell>
                        <TableCell>{document.person.phone}</TableCell>
                        <TableCell>{renderTotal(document, index)}</TableCell>
                        <TableCell>{NumberUtil.currencyFormat(document.balance)}</TableCell>
                        <TableCell>{document.dueDate ? DateUtil.javaSqlDateToDateToLocaleDateString(document.dueDate) : null}</TableCell>
                        <TableCell>{document.paymentDate ? DateUtil.javaSqlDateToDateToLocaleDateString(document.paymentDate) : null}</TableCell>
                        <TableCell>{document.parcel}</TableCell>
                        <TableCell>
                            {renderEditOrSave(document, index)}
                            {renderPay(document)}
                            {renderCancelPay(document)}
                        </TableCell>
                    </TableRow>
                )
            });
        }
        return (
            <TableRow>
                <TableCell colSpan={99}>
                    Nenhum registro encontrado
                </TableCell>
            </TableRow>
        )
    }

    const renderTableBody = () => {
        return (
            <TableBody>
                {renderItems()}
            </TableBody>
        )
    }

    const renderTable = () => {
        return (
            <TableContainer component={Paper}>
                <Table size={"small"} stickyHeader>
                    {renderTableHeader()}
                    {renderTableBody()}
                </Table>
            </TableContainer>
        )
    }

    return (
        <React.Fragment>
            <CustomButton variant="outlined" type="button" onClick={goBack}>Voltar</CustomButton>
            {renderTable()}
        </React.Fragment>
    );
}

export default React.memo(DocumentFromSaleList);
