import React, {useState} from 'react';
import CustomTextField from "../../../custom-text-field/custom-text-field";

interface SaleListHeaderFilterEmissionEndDateProps {
    endDate: string | undefined;
}

const SaleListHeaderFilterEmissionEndDate = React.memo(React.forwardRef<HTMLInputElement, SaleListHeaderFilterEmissionEndDateProps>(({endDate}, ref) => {

        const [internalEndDate, setInternalEndDate] = useState<string | undefined>(endDate);

        const onChangeEndDate = (event: React.ChangeEvent<HTMLInputElement>) => {
            setInternalEndDate(event.target.value);
        }

        return (
            <CustomTextField fullWidth required label="Emissão final" type="date" value={internalEndDate || ''}
                             InputLabelProps={{shrink: true}} onChange={onChangeEndDate} ref={ref}/>
        )

    }
));

export default SaleListHeaderFilterEmissionEndDate;
