import React, {useState} from "react";
import {IconButton, Tooltip} from "@mui/material";
import {Done} from "@mui/icons-material";
import CloseSaleDialog from "./close-sale-dialog";
import {Sale} from "../../../sale/sale";
import {PersonService} from "../../../person/person.service";
import PreencheDadosAcessoriosDialog from "../../preenche-dados-acessorios/preenche-dados-acessorios-dialog";
import {Person} from "../../../person/person";

interface CloseSaleButtonProps {
    sale: Sale;
    findAll: () => void;
}

const CloseSaleButton = React.memo(({sale, findAll}: CloseSaleButtonProps) => {

    let personId: number;
    if (sale.pessoa?.id) {
        personId = sale.pessoa.id;
    } else {
        throw new Error('Sale person id required');
    }

    const [saleToClose, setSaleToClose] = useState<Sale | null>();
    const [personToPreencheDadosAcessorios, setPersonToPreencheDadosAcessorios] = useState<Person | null>();

    const closeItem = () => {
        setSaleToClose(sale);
    }

    const closeCloseSaleDialog = (success: boolean) => {
        setSaleToClose(null);
        if (success) {
            findPerson().then();
        }
    }

    const closePreencheDadosAcessoriosDialog = () => {
        setPersonToPreencheDadosAcessorios(null);
        findAll();
    }

    const findPerson = async () => {
        const person = await PersonService.findOne(personId);
        const accessoryDataFilled = (person.influenciacompras.length > 0 &&
            person.decidecompras.length > 0 && person.conheceulojas.length > 0);
        if (accessoryDataFilled) {
            findAll();
        } else {
            setPersonToPreencheDadosAcessorios(person);
        }
    }

    const renderCloseSaleDialog = () => {
        return saleToClose && <CloseSaleDialog onClose={closeCloseSaleDialog} sale={saleToClose}/>;
    }

    const renderPreencheDadosAcessorios = () => {
        return personToPreencheDadosAcessorios &&
            <PreencheDadosAcessoriosDialog onClose={closePreencheDadosAcessoriosDialog}
                                           person={personToPreencheDadosAcessorios}/>;
    }

    return (
        <React.Fragment>
            {renderCloseSaleDialog()}
            {renderPreencheDadosAcessorios()}
            <Tooltip title={'Fechar venda'}>
                    <span>
                        <IconButton color={'primary'} onClick={closeItem}
                                    disabled={!!sale.nota || !!sale.dataHoraFechamento}>
                            <Done/>
                        </IconButton>
                    </span>
            </Tooltip>
        </React.Fragment>
    )

});

export default CloseSaleButton;
