import React, {useEffect, useState} from "react";
import {FormControl, FormControlLabel, Grid, IconButton, Radio, RadioGroup, Tooltip} from "@mui/material";
import {Search} from "@mui/icons-material";
import CustomTextField from "../typescript/custom-text-field/custom-text-field";
import {DateUtil} from "../typescript/util/date-util";

const SaleAfterListForm = (props) => {

    const {findAll} = props;

    const [startDate, setStartDate] = useState(DateUtil.firstDayOfCurrentMonthInJavaSqlDate());
    const [endDate, setEndDate] = useState(DateUtil.lastDayOfCurrentMonthInJavaSqlDate());
    const [filter, setFilter] = useState("emission");
    const [id, setId] = useState('');
    const [personName, setPersonName] = useState('');

    useEffect(() => {
        findAll(startDate, endDate, id, personName).then();
    }, [findAll]); // eslint-disable-line react-hooks/exhaustive-deps

    const onChangeStartDate = (event) => {
        if (event.target.value) {
            setStartDate(event.target.value);
        }
    }

    const onChangeEndDate = (event) => {
        if (event.target.value) {
            setEndDate(event.target.value);
        }
    }

    const doFindAll = (event) => {
        event.preventDefault();
        event.stopPropagation();
        findAll(startDate, endDate, id, personName).then();
    }

    const onChangeFilter = (event) => {
        const newFilter = event.target.value;
        setFilter(newFilter);
        setId('');
        setPersonName('');
        if (newFilter === 'emission') {
            setStartDate(DateUtil.firstDayOfCurrentMonthInJavaSqlDate());
            setEndDate(DateUtil.lastDayOfCurrentMonthInJavaSqlDate());
        } else {
            setStartDate('');
            setEndDate('');
        }
    }

    const renderFilter = () => {
        return (
            <FormControl component="fieldset" required>
                <RadioGroup row value={filter} onChange={onChangeFilter}>
                    <FormControlLabel value={'emission'} control={<Radio/>} label="Emissão da Venda"/>
                    <FormControlLabel value={'id'} control={<Radio/>} label="ID da Venda"/>
                    <FormControlLabel value={'personName'} control={<Radio/>} label="Nome do cliente"/>
                </RadioGroup>
            </FormControl>
        )
    }

    const renderFilterEmission = () => {
        return filter === 'emission' && (
            <React.Fragment>
                <Grid item md={5} xs={12}>
                    <CustomTextField fullWidth autoFocus required label="Emissão inicial" type="date" value={startDate}
                                     InputLabelProps={{shrink: true}} onChange={onChangeStartDate}/>
                </Grid>
                <Grid item md={6} xs={12}>
                    <CustomTextField fullWidth required label="Emissão final" type="date" value={endDate}
                                     InputLabelProps={{shrink: true}} onChange={onChangeEndDate}/>
                </Grid>
            </React.Fragment>
        )
    }

    const onChangeId = (event) => {
        setId(event.target.value);
    }

    const renderFilterId = () => {
        return filter === 'id' && (
            <Grid item md={11} xs={12}>
                <CustomTextField fullWidth autoFocus required label="ID da OS" type="number" value={id}
                                 onChange={onChangeId}/>
            </Grid>
        )
    }

    const onChangePersonName = (event) => {
        setPersonName(event.target.value);
    }

    const renderFilterPersonName = () => {
        return filter === 'personName' && (
            <Grid item md={11} xs={12}>
                <CustomTextField fullWidth autoFocus required label="Nome do cliente" type="text" value={personName}
                                 onChange={onChangePersonName}/>
            </Grid>
        )
    }

    return (
        <form onSubmit={doFindAll}>
            {renderFilter()}
            <Grid container spacing={1}>
                {renderFilterEmission()}
                {renderFilterId()}
                {renderFilterPersonName()}
                <Grid item md={1} xs={12}>
                    <Tooltip title="Pesquisar">
                        <IconButton type={"submit"}>
                            <Search/>
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
        </form>
    )
}

export default React.memo(SaleAfterListForm);