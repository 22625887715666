import {AppService} from "../typescript/app/app.service";

class MonthlyBudgetService {

    static API_URL = "/api/orcamentomess/v2";

    static findByAnoAndMes(ano: number, mes: number) {
        return AppService.getAuthorized(this.API_URL + "/findByAnoAndMes", {
            ano: ano,
            mes: mes
        }).then((result) => {
            return result.data;
        }).catch(() => {
            return [];
        })
    }

    static getMonthlyBudgetAnalysis(ano: number, mes: number) {
        return AppService.getAuthorized(this.API_URL + "/analiseDeOrcamentos", {
            ano: ano,
            mes: mes
        }).then((result) => {
            return result.data;
        }).catch(() => {
            return [];
        })
    }

    static addOrRemove(ano: number, mes: number, centrodecusto: number, valor: number, add: boolean) {
        return AppService.postAuthorized(this.API_URL + "/addOrRemove", {
            ano: ano,
            mes: mes,
            centrodecusto: centrodecusto,
            valor: valor,
            add: add
        });
    }

    static copyToNextMonth(ano: number, mes: number) {
        return AppService.postAuthorized(this.API_URL + "/copiarParaOProximoMes", null, {
            ano: ano,
            mes: mes
        });
    }
}

export {MonthlyBudgetService};