import {AppService} from "../typescript/app/app.service";
import {WalletService} from "../typescript/wallet/wallet.service";
import {PageResponse} from "../typescript/util/page-response";
import {Brand} from "../typescript/brand/Brand";
import {PersonService} from "../person/person.service";
import {BrandTax} from "../typescript/brand-tax/brand-tax";

class BrandService {

    static API_URL = "/api/bandeiras/v2";

    static findAll = (page?: number, size?: number, sort?: string, filter?: string): Promise<PageResponse<Brand>> => {
        return AppService.getAuthorized(this.API_URL, {
            page: page,
            size: size,
            sort: sort,
            id: sort?.startsWith('id') ? filter : '',
            descricao: sort?.startsWith('descricao') ? filter : ''
        }).then((result) => {
            return result.data;
        }).catch(() => {
            return {
                content: [],
                totalElements: 0
            };
        })
    };

    static findOne(id: number) {
        return AppService.getAuthorized(this.API_URL + "/" + id).then((result) => {
            return result.data;
        }).catch(() => {
            return null;
        })
    }

    static save(item: Brand) {
        if (item.id) {
            return AppService.postAuthorized(this.API_URL + "/" + item.id, item);
        } else {
            return AppService.postAuthorized(this.API_URL, item);
        }
    }

    static delete(id: number) {
        return AppService.deleteAuthorized(this.API_URL + "/" + id);
    }

    static createBrand() {
        const brand: Brand = {
            descricao: '',
            prazoParaEntrar: 0,
            pessoa: PersonService.createPerson(false, false),
            carteira: WalletService.createWallet()
        }
        return brand;
    }

    static getAllTaxesByBrand = (id: number): Promise<BrandTax[]> => {
        return AppService.getAuthorized(this.API_URL + "/" + id + "/tax").then((result) => {
            return result.data;
        }).catch(() => {
            return [];
        })
    }

    static deleteTax(brandId: number, taxId: number) {
        return AppService.deleteAuthorized(this.API_URL + "/" + brandId + '/tax/' + taxId);
    }

    static createTax = (): BrandTax => {
        return {
            parcelNumber: '',
            tax: ''
        };
    }

    static findTax = (brandId: number, taxId: number) => {
        return AppService.getAuthorized(this.API_URL + "/" + brandId + '/tax/' + taxId)
            .then((result) => {
                return result.data;
            }).catch(() => {
                return null;
            })
    }

    static saveTax = (brandId: number, tax: BrandTax) => {
        if (tax.id) {
            return AppService.postAuthorized(this.API_URL + "/" + brandId + '/tax/' + tax.id, tax);
        } else {
            return AppService.postAuthorized(this.API_URL + "/" + brandId + '/tax', tax);
        }
    }

}

export {BrandService};