import {AppService} from "../typescript/app/app.service";
import {Reserve} from "./reserve";
import {PersonService} from "../person/person.service";
import {ProductService} from "../product/product.service";

class ReserveService {

    static API_URL = "/api/reserves";

    static findAll = (startDate: string, endDate: string) => {
        return AppService.getAuthorized(this.API_URL, {
            startDate: startDate,
            endDate: endDate
        }).then((result) => {
            return result.data;
        }).catch(() => {
            return []
        })
    };

    static findOne(id: number) {
        return AppService.getAuthorized(this.API_URL + "/" + id).then((result) => {
            return result.data;
        }).catch(() => {
            return null;
        })
    }

    static save(item: Reserve) {
        let itemToSave = item;
        itemToSave.status1 = itemToSave.status1 ? itemToSave.status1 : null;
        itemToSave.status2 = itemToSave.status2 ? itemToSave.status2 : null;
        if (itemToSave.id) {
            return AppService.postAuthorized(this.API_URL + "/" + itemToSave.id, itemToSave);
        } else {
            return AppService.postAuthorized(this.API_URL, itemToSave);
        }
    }

    static updateStatus1(id: number, status1: string) {
        return AppService.postAuthorized(this.API_URL + "/" + id + "/status1", null, {status1: status1});
    }

    static updateStatus2(id: number, status2: string) {
        return AppService.postAuthorized(this.API_URL + "/" + id + "/status2", null, {status2: status2});
    }

    static delete(id: number) {
        return AppService.deleteAuthorized(this.API_URL + "/" + id);
    }

    static createReserve() {
        const reserve: Reserve = {
            date: '',
            hour: '',
            confirmed: false,
            person: PersonService.createPerson(false, false),
            product: ProductService.createProduct(),
            observation: '',
            status1: '',
            status2: ''
        }
        return reserve;
    }
}

export {ReserveService};