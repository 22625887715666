import {AppService} from "../../app/app.service";

class CampaignPersonService {

    static API_URL = "/api/campaignpersons";

    static updateContact(id: number, contact: string) {
        return AppService.postAuthorized(this.API_URL + "/" + id + "/contact", null, {contact: contact});
    }

    static updateReturned(id: number, returned: boolean) {
        return AppService.postAuthorized(this.API_URL + "/" + id + "/returned", null, {returned: returned});
    }

    static updateInterested(id: number, interested: boolean) {
        return AppService.postAuthorized(this.API_URL + "/" + id + "/interested", null, {interested: interested});
    }

    static updateAsked(id: number, asked: boolean) {
        return AppService.postAuthorized(this.API_URL + "/" + id + "/asked", null, {asked: asked});
    }

    static updateBought(id: number, bought: boolean) {
        return AppService.postAuthorized(this.API_URL + "/" + id + "/bought", null, {bought: bought});
    }

}

export {CampaignPersonService}