import React from "react";
import {Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip} from 'recharts';
import {CustomChartProps} from "../util/custom-chart-props";
import {GraphService} from "../../sale-overview/graph.service";

const CustomPieChart = ({data, formatter, dataKey, name}: CustomChartProps) => {
    const COLORS = GraphService.getColors();
    return (
        <ResponsiveContainer width={"100%"} height={300}>
            <PieChart>
                <Pie nameKey={name} dataKey={dataKey} data={data} innerRadius={50}>
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>
                    ))}
                </Pie>
                <Tooltip formatter={formatter}/>
                <Legend verticalAlign={"top"}/>
            </PieChart>
        </ResponsiveContainer>
    );
}

export default React.memo(CustomPieChart);