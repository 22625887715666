import React, {FormEvent, useCallback, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {Delete as DeleteIcon, Search} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import {changePageTitle, setAlert} from "../redux/actions";
import Datatable from "../datatable/datatable";
import {ColorButtonEnum} from "../util/color-button-enum";
import {ColorIconEnum} from "../util/color-icon-enum";
import {HolidayService} from "./holiday.service";
import {Holiday} from "./holiday";
import {DateUtil} from "../util/2.0.0/date-util";
import CustomTextField from "../custom-text-field/custom-text-field";
import {Grid, IconButton, Tooltip} from "@mui/material";

export function HolidayList() {

    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [year, setYear] = useState(DateUtil.currentYear());
    const [items, setItems] = useState<Holiday[]>([]);

    const findAll = useCallback(async () => {
        const result = await HolidayService.findAllByYear(year);
        setItems(result);
    }, [year]);

    useEffect(() => {
        dispatch(changePageTitle("Feriados"));
        findAll().then();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const editItem = (item: Holiday) => {
        navigate('/holiday/' + item.id);
    }

    const insertItem = () => {
        navigate('/holiday');
    }

    const deleteItem = (item: Holiday) => {
        if (item.id === undefined) {
            return;
        }
        HolidayService.delete(item.id).then(() => {
            findAll().then();
        }).catch((error) => {
            dispatch(setAlert({
                show: true,
                message: 'Erro ao excluir registro - ' + error.message,
                severity: 'error'
            }));
        })
    }

    const headerOperations = [{color: () => ColorButtonEnum.PRIMARY, onClick: insertItem, label: () => 'Inserir'}];
    const rowOperations = [{
        color: () => ColorIconEnum.PRIMARY,
        onClick: deleteItem,
        label: () => 'Excluir',
        icon: () => <DeleteIcon/>
    }];

    const onChangeYear = (event: React.ChangeEvent<HTMLInputElement>) => {
        setYear(parseInt(event.target.value));
    }

    const search = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        findAll().then();
    }

    return (
        <form onSubmit={search}>
            <Grid container spacing={1}>
                <Grid item md xs={6}>
                    <CustomTextField fullWidth autoFocus required label="Ano" type="number" value={year || ''}
                                     onChange={onChangeYear}/>
                </Grid>
                <Grid item md xs={6}>
                    <Tooltip title="Pesquisar">
                        <IconButton type={"submit"}>
                            <Search/>
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
            <Datatable dataColumnNames={['ID', 'Descrição', 'Data']} dataColumns={['id', 'description', 'date']}
                       dataTypes={['number', 'text', 'date']} findAll={findAll} editData={editItem} data={items}
                       totalData={items.length} headerOperations={headerOperations} rowOperations={rowOperations}/>
        </form>
    );
}
