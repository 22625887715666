import React, {useState} from "react";
import {IconButton, Tooltip} from "@mui/material";
import {ViewWeek} from "@mui/icons-material";
import {Sale} from "../../../sale/sale";
import GeraNfeDialog from "./gera-nfe-dialog";

interface GeraNfeButtonProps {
    sale: Sale;
    findAll: () => void;
}

const GeraNfeButton = React.memo(({sale, findAll}: GeraNfeButtonProps) => {

    const [saleToNfe, setSaleToNfe] = useState<Sale | null>();

    const nfeItem = () => {
        setSaleToNfe(sale);
    }

    const closeNfeDialog = (success: boolean) => {
        setSaleToNfe(null);
        if (success) {
            findAll();
        }
    }

    const renderNfeDialog = () => {
        return saleToNfe && <GeraNfeDialog onClose={closeNfeDialog} sale={saleToNfe}/>;
    }

    return (
        <React.Fragment>
            {renderNfeDialog()}
            <Tooltip title={'Gerar NF-e'}>
                    <span>
                        <IconButton color={'primary'} onClick={nfeItem}>
                            <ViewWeek/>
                        </IconButton>
                    </span>
            </Tooltip>
        </React.Fragment>
    )

});

export default GeraNfeButton;
