import React, {useState} from "react";
import {IconButton, Tooltip} from "@mui/material";
import {ViewWeek} from "@mui/icons-material";
import {Sale} from "../../../sale/sale";
import FormDialog from "../../form-dialog/form-dialog";
import {FormDialogField} from "../../form-dialog/form-dialog-field";
import {FormDialogParam} from "../../form-dialog/form-dialog-param";
import {NfeService} from "../../../nfe/nfe.service";
import {setAlert} from "../../redux/actions";
import {useDispatch} from "react-redux";

interface CancelaNfeButtonProps {
    sale: Sale;
    findAll: () => void;
}

const CancelaNfeButton = React.memo(({sale, findAll}: CancelaNfeButtonProps) => {

    const [paramsToCancelaNfe, setParamsToCancelaNfe] = useState<FormDialogParam | null>();
    const dispatch = useDispatch();

    const cancelaItem = () => {
        const params = {
            titulo: 'Cancelamento nota ' + sale.nota.numero,
            campos: [{
                descricao: 'Justificativa',
                tipo: 'text',
                obrigatorio: true,
                autoFocus: true,
                minLength: 15,
                maxLength: 256
            }]
        };

        setParamsToCancelaNfe(params);
    }

    const closeCancelaNfeDialog = (campos: FormDialogField[] | null) => {
        setParamsToCancelaNfe(null);
        if (campos && campos[0].valor) {
            cancelNFe(sale.nota.numero, campos[0].valor);
        }
    }

    const cancelNFe = (nota: number, justificativa: string) => {
        NfeService.cancelaNFe(nota, justificativa).then(function () {
            dispatch(setAlert({show: true, message: 'Nota cancelada com sucesso', severity: 'success'}));
            findAll();
        }, (error) => {
            dispatch(setAlert({
                show: true,
                message: 'Erro ao cancelar nfe - ' + error.response.data.message,
                severity: 'error'
            }));
        });
    }

    const renderNfeDialog = () => {
        return paramsToCancelaNfe &&
            <FormDialog open={true} onClose={closeCancelaNfeDialog} param={paramsToCancelaNfe}/>;
    }

    return (
        <React.Fragment>
            {renderNfeDialog()}
            <Tooltip title={'Cancelar NF-e'}>
                    <span>
                        <IconButton color={'error'} onClick={cancelaItem} disabled={!sale.nota}>
                            <ViewWeek/>
                        </IconButton>
                    </span>
            </Tooltip>
        </React.Fragment>
    )

});

export default CancelaNfeButton;
