import {PaymentTypeService} from "../payment-type/payment-type.service";
import {DateUtil} from "../typescript/util/date-util";
import {NumberUtil} from "../typescript/util/number-util";
import {AppService} from "../typescript/app/app.service";
import {PageResponse} from "../typescript/util/page-response";
import {Person} from "../person/person";
import {CentroDeCusto} from "../centro-de-custo/centro-de-custo";
import {Document} from "./document";
import {PaymentTotalRequest} from "./payment-total-request";
import {PaymentTotalRequestToSend} from "./payment-total-request-to-send";
import {PaymentPartialRequest} from "./payment-partial-request";
import {PaymentPartialRequestToSend} from "./payment-partial-request-to-send";
import {PersonService} from "../person/person.service";
import {CentroDeCustoService} from "../centro-de-custo/centro-de-custo.service";
import {SaleService} from "../sale/sale.service";
import {DocumentMovementLog} from "./document-movement-log";

class DocumentService {

    static API_URL = "/api/documentos/v3";

    static findAll = (toReceive: boolean, byPayment: boolean, year: number, month: number, person: number) => {
        return AppService.getAuthorized(this.API_URL, {
            toReceive: toReceive,
            byPayment: byPayment,
            year: year,
            month: month,
            person: person
        }).then((result) => {
            return result.data;
        }).catch(() => {
            return this.createDocumentResponse();
        })
    };

    static findAllUpdateLogs = (page?: number, size?: number, sort?: string, filter?: string): Promise<PageResponse<Document>> => {
        return AppService.getAuthorized(this.API_URL + '/logs/updates', {
            page: page,
            size: size,
            sort: sort,
            id: sort?.startsWith('id') ? filter : '',
            documentId: sort?.startsWith('documentId') ? filter : ''
        }).then((result) => {
            return result.data;
        }).catch(() => {
            return {
                content: [],
                totalElements: 0
            };
        })
    };

    static findAllDeleteLogs = (page?: number, size?: number, sort?: string, filter?: string): Promise<PageResponse<Document>> => {
        return AppService.getAuthorized(this.API_URL + '/logs/deletes', {
            page: page,
            size: size,
            sort: sort,
            id: sort?.startsWith('id') ? filter : '',
            documentId: sort?.startsWith('documentId') ? filter : '',
            date: sort?.startsWith('date') ? filter : '',
            username: sort?.startsWith('username') ? filter : '',
            person: sort?.startsWith('person') ? filter : '',
            valor: sort?.startsWith('valor') ? filter : ''
        }).then((result) => {
            return result.data;
        }).catch(() => {
            return {
                content: [],
                totalElements: 0
            };
        })
    };

    static findAllBaixaLogs = (page?: number, size?: number, sort?: string, filter?: string): Promise<PageResponse<DocumentMovementLog>> => {
        return AppService.getAuthorized(this.API_URL + '/logs/baixas', {
            page: page,
            size: size,
            sort: sort,
            id: sort?.startsWith('id') ? filter : '',
            documentId: sort?.startsWith('documentId') ? filter : '',
            date: sort?.startsWith('date') ? filter : '',
            username: sort?.startsWith('username') ? filter : '',
            person: sort?.startsWith('person') ? filter : '',
            value: sort?.startsWith('value') ? filter : '',
            description: sort?.startsWith('description') ? filter : ''
        }).then((result) => {
            return result.data;
        }).catch(() => {
            return {
                content: [],
                totalElements: 0
            };
        })
    };

    static getSaleItems = (documentId: number) => {
        return AppService.getAuthorized(this.API_URL + '/' + documentId + '/ositens').then((result) => {
            return result.data;
        }).catch(() => {
            return [];
        })
    };

    static generateParcels = (valorTotal: number, parcelas: number, dia: number, mes: number, ano: number, pessoa: Person, centroDeCusto: CentroDeCusto) => {
        return AppService.getAuthorized(this.API_URL + '/generateParcels', {
            valorTotal: valorTotal,
            parcelas: parcelas,
            dia: dia,
            mes: mes,
            ano: ano,
            pessoa: pessoa.id,
            centroDeCusto: centroDeCusto.id
        });
    };

    static recibo58x135 = (documentId: number) => {
        return AppService.getAuthorized(this.API_URL + '/' + documentId + '/recibo58x135', null, "arraybuffer");
    }

    static reciboPagamentoParcial = (movementId: number) => {
        return AppService.getAuthorized(this.API_URL + '/' + movementId + '/reciboPagamentoParcial', null, "arraybuffer");
    }

    static findOne(id: number) {
        return AppService.getAuthorized(this.API_URL + "/" + id).then((result) => {
            return result.data;
        }).catch(() => {
            return null;
        })
    }

    static save(item: Document) {
        let itemToSave = item;
        if (itemToSave.centrodecusto && !itemToSave.centrodecusto.id) {
            itemToSave.centrodecusto = null;
        }
        if (itemToSave.os && !itemToSave.os.id) {
            itemToSave.os = null;
        }
        if (itemToSave.id) {
            return AppService.postAuthorized(this.API_URL + "/" + itemToSave.id, itemToSave);
        } else {
            return AppService.postAuthorized(this.API_URL, itemToSave);
        }
    }

    static saveNewTotal = (document: Document, newTotal: number) => {
        return AppService.postAuthorized(this.API_URL + "/" + document.id + '/newTotal', newTotal);
    }

    static payTotal(documentId: number, paymentRequest: PaymentTotalRequest) {
        let paymentRequestToSend: PaymentTotalRequestToSend = {
            dataPagamento: paymentRequest.dataPagamento,
            valorDesconto: paymentRequest.valorDesconto,
            valorMulta: paymentRequest.valorMulta,
            valorJurosDia: paymentRequest.valorJurosDia,
            valorFinal: paymentRequest.valorFinal
        };
        if (paymentRequest.tipoDePagamento && paymentRequest.tipoDePagamento.id) {
            paymentRequestToSend.tipoDePagamento = paymentRequest.tipoDePagamento.id;
        }
        if (paymentRequest.conta && paymentRequest.conta.id) {
            paymentRequestToSend.conta = paymentRequest.conta.id;
        }
        if (paymentRequest.bandeira && paymentRequest.bandeira.id) {
            paymentRequestToSend.bandeira = paymentRequest.bandeira.id;
        }
        if (paymentRequest.cardNumberOfParcels) {
            paymentRequestToSend.cardNumberOfParcels = paymentRequest.cardNumberOfParcels;
        }
        return AppService.postAuthorized(this.API_URL + "/" + documentId + "/paytotal", paymentRequestToSend);
    }

    static payPartial(documentId: number, paymentRequest: PaymentPartialRequest) {
        let paymentRequestToSend: PaymentPartialRequestToSend = {
            dataPagamento: paymentRequest.dataPagamento,
            valor: paymentRequest.valor
        };
        if (paymentRequest.tipoDePagamento && paymentRequest.tipoDePagamento.id) {
            paymentRequestToSend.tipoDePagamento = paymentRequest.tipoDePagamento.id;
        }
        if (paymentRequest.conta && paymentRequest.conta.id) {
            paymentRequestToSend.conta = paymentRequest.conta.id;
        }
        if (paymentRequest.bandeira && paymentRequest.bandeira.id) {
            paymentRequestToSend.bandeira = paymentRequest.bandeira.id;
            paymentRequestToSend.parcelas = paymentRequest.parcelas;
        }
        return AppService.postAuthorized(this.API_URL + "/" + documentId + "/paypartial", paymentRequestToSend);
    }

    static cancelPayment(documentId: number) {
        return AppService.getAuthorized(this.API_URL + "/" + documentId + "/cancelpay");
    }

    static delete(id: number) {
        return AppService.deleteAuthorized(this.API_URL + "/" + id);
    }

    /**
     * @deprecated
     */
    static calculaValorFinal(valor: number, percentualDesconto: number, percentualMulta: number, jurosDia: number, dataPagamento: string, dataVencimento: string) {
        let desconto = 0;
        let multa = 0;
        let juros = 0;
        if (percentualDesconto) {
            desconto = valor * percentualDesconto / 100;
        }
        if (percentualMulta) {
            multa = valor * percentualMulta / 100;
        }
        const date1 = DateUtil.javaSqlDateToDate(dataVencimento);
        const date2 = DateUtil.javaSqlDateToDate(dataPagamento);
        if (jurosDia && date1 && date2) {
            const daysBetween = DateUtil.daysBetween(date1, date2);
            if (daysBetween > 0) {
                juros = valor * jurosDia * daysBetween / 100;
            }
        }

        return NumberUtil.arredonda(valor - desconto + multa + juros, 2);
    }

    /**
     * @deprecated
     */
    static calculaValorFinal2(valor: number, percentualDesconto: number, percentualMulta: number, jurosDia: number, dataPagamento: string, dataVencimento: string) {
        let desconto = 0;
        let multa = 0;
        let juros = 0;
        if (percentualDesconto) {
            desconto = valor * percentualDesconto / 100;
        }
        if (percentualMulta) {
            multa = valor * percentualMulta / 100;
        }
        const date1 = DateUtil.javaSqlDateToDate(dataVencimento);
        const date2 = DateUtil.javaSqlDateToDate(dataPagamento);
        if (jurosDia && date1 && date2) {
            const daysBetween = DateUtil.daysBetween(date1, date2);
            if (daysBetween > 0) {
                juros = valor * jurosDia * daysBetween / 100;
            }
        }

        const valorDesconto = NumberUtil.arredonda(desconto, 2);
        const valorMulta = NumberUtil.arredonda(multa, 2);
        const valorJuros = NumberUtil.arredonda(juros, 2);
        const valorFinal = NumberUtil.arredonda(valor - valorDesconto + valorMulta + valorJuros, 2);

        return {
            valor: valor,
            valorDesconto: valorDesconto,
            valorMulta: valorMulta,
            valorJuros: valorJuros,
            valorFinal: valorFinal
        }
    }

    static getValorFinal(valorOriginal: number, valorDesconto: number, valorMulta: number, valorJurosDia: number, dataPagamento: string, dataVencimento: string) {
        let valorJurosDiaToCalc = 0;
        const valorOriginalToCalc = valorOriginal || 0;
        const valorDescontoToCalc = valorDesconto || 0;
        const valorMultaToCalc = valorMulta || 0;
        const date1 = DateUtil.javaSqlDateToDate(dataVencimento);
        const date2 = DateUtil.javaSqlDateToDate(dataPagamento);
        if (valorJurosDia && date1 && date2) {
            const daysBetween = DateUtil.daysBetween(date1, date2);
            if (daysBetween > 0) {
                valorJurosDiaToCalc = valorJurosDia * daysBetween;
            }
        }
        return NumberUtil.arredonda(valorOriginalToCalc - valorDescontoToCalc + valorMultaToCalc + valorJurosDiaToCalc, 2);
    }

    static createDocumentResponse = () => {
        return {
            documentos: [],
            resumoMes: {
                receita: {},
                despesa: {},
                saldo: {}
            },
            totalInadimplente: 0,
            totalAVencer: 0,
            totalPago: 0
        }
    }

    static createDocument() {
        const document: Document = {
            valor: '',
            dataEmissao: DateUtil.toJavaSqlDate(DateUtil.currentDate()),
            dataVencimento: '',
            dataPagamento: '',
            parcela: '',
            cardNumberOfParcels: '',
            aPagar: false,
            multa: '',
            jurosDia: '',
            desconto: '',
            valorFinal: '',
            tipopagamento: PaymentTypeService.createPaymentType(),
            pessoa: PersonService.createPerson(false, false),
            centrodecusto: CentroDeCustoService.createCentroDeCusto(),
            observacao: '',
            saldo: '',
            totalPago: '',
            os: SaleService.createSale()
        }
        return document;
    }

    static getPaymentFlux() {
        return AppService.getAuthorized(this.API_URL + '/paymentFlux').then((result) => {
            return result.data;
        }).catch(() => {
            return null;
        })
    }

    static getCashFlow(dataInicial: string, dataFinal: string, tipoDePagamento?: number) {
        return AppService.getAuthorized(this.API_URL + '/fluxoDeCaixa', {
            dataInicial: dataInicial,
            dataFinal: dataFinal,
            tipoDePagamento: tipoDePagamento
        }).then((result) => {
            return result.data;
        }).catch(() => {
            return null;
        })
    }

    static getExpensesByPaymentType(dataInicial: string, dataFinal: string) {
        return AppService.getAuthorized(this.API_URL + '/despesasPorTipoDePagamento', {
            dataInicial: dataInicial,
            dataFinal: dataFinal
        }).then((result) => {
            return result.data;
        }).catch(() => {
            return null;
        })
    }

}

export {DocumentService};
