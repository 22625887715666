import React, {useCallback, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {changePageTitle, setAlert} from "../typescript/redux/actions";
import {Delete as DeleteIcon} from "@mui/icons-material";
import {Datatable} from "../index";
import {PlanoDeContasService} from "./plano-de-contas.service";
import {useNavigate} from "react-router-dom";

export function PlanoDeContasList() {

    let navigate = useNavigate();
    const [pageParams, setPageParams] = useState(null);
    const dispatch = useDispatch();

    const findAll = useCallback(async (number, size, sort, filter) => {
        const items = await PlanoDeContasService.findAll(number, size, sort, filter);
        setPageParams({
            number: number,
            size: size,
            sort: sort,
            filter: filter,
            items: items
        });
    }, []);

    useEffect(() => {
        dispatch(changePageTitle("Planos de contas"));
        findAll(0, parseInt(process.env.REACT_APP_PAGE_SIZE), "id,asc", '').then();
    }, [dispatch, findAll]);

    const editItem = (item) => {
        navigate('/planodeconta/' + item.id);
    }

    const insertItem = () => {
        navigate('/planodeconta');
    }

    const deleteItem = (item) => {
        PlanoDeContasService.delete(item.id).then(() => {
            findAll(pageParams.number, pageParams.size, pageParams.sort, pageParams.filter).then();
        }).catch((error) => {
            dispatch(setAlert({
                show: true,
                message: 'Erro ao excluir plano de contas - ' + error.data.message,
                severity: 'error'
            }));
        })
    }

    const headerOperations = [
        {color: () => "primary", onClick: insertItem, label: () => 'Inserir'}
    ]

    const rowOperations = [
        {color: () => "primary", onClick: deleteItem, label: () => 'Excluir', icon: () => <DeleteIcon/>},
    ]

    return pageParams && (
        <Datatable data={pageParams.items.content} totalData={pageParams.items.totalElements}
                   dataColumnNames={['ID', 'Descrição', 'Código estruturado']}
                   dataColumns={['id', 'descricao', 'codigoEstruturado']}
                   dataTypes={['number', 'text', 'text']} findAll={findAll}
                   number={pageParams.number} size={pageParams.size} sort={pageParams.sort} filter={pageParams.filter}
                   editData={editItem} headerOperations={headerOperations} rowOperations={rowOperations}/>
    );
}