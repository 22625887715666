import React from "react";
import {IconButton, TableCell, TableRow, Tooltip} from "@mui/material";
import DatatableTableBodyRowContent from "./datatable-table-body-row-content";

const DatatableTableBodyRow = React.memo(({item, dataColumns, dataTypes, dataValues, editData, cursorClass, rowOperations, rowClass, rowIndex}) => {

    const getDataType = (columnIndex) => {
        return (dataTypes ? dataTypes[columnIndex] : 'text');
    }

    const renderTableCellDataValue = (itemParam, dataColumn, columnIndex) => {
        const value = dataValues[columnIndex];
        return value(itemParam, dataColumn, columnIndex);
    }

    const show = (operation, itemParam, rowIndexParam) => {
        if (operation.show === undefined) {
            return true;
        }
        return operation.show(itemParam, rowIndexParam);
    }

    const disabled = (operation, itemParam, rowIndexParam) => {
        if (operation.disabled) {
            return !!operation.disabled(itemParam, rowIndexParam);
        }
        return false;
    }

    const renderRowOperations = (itemParam, rowIndexParam) => {
        return rowOperations.map((operation, keyIndex) => {
            return show(operation, itemParam, rowIndexParam) && (
                <Tooltip key={keyIndex} title={operation.label(itemParam, rowIndexParam)}>
                    <span>
                        <IconButton color={operation.color(itemParam, rowIndexParam)}
                                    onClick={() => operation.onClick(itemParam, rowIndexParam)}
                                    disabled={disabled(operation, itemParam, rowIndexParam)}>
                            {operation.icon(itemParam, rowIndexParam)}
                        </IconButton>
                    </span>
                </Tooltip>
            )
        })
    }

    const renderTableRowOperations = (itemParam, rowIndexParam) => {
        return rowOperations && (
            <TableCell>
                {renderRowOperations(itemParam, rowIndexParam)}
            </TableCell>
        )
    }

    const applyRowClass = (rowIndexParam) => {
        return rowClass && (
            rowClass(rowIndexParam)
        );
    }

    return (
        <TableRow sx={applyRowClass(rowIndex)}>
            {dataColumns.map((dataColumn, columnIndex) => {
                if (dataValues && dataValues[columnIndex]) {
                    return (
                        <TableCell key={columnIndex} onClick={editData} sx={cursorClass}>
                            {renderTableCellDataValue(item, dataColumn, columnIndex)}
                        </TableCell>
                    )
                }
                return (
                    <DatatableTableBodyRowContent key={columnIndex} item={item} dataColumn={dataColumn}
                                                  type={getDataType(columnIndex)}
                                                  editData={editData} cursorClass={cursorClass}/>
                )

            })}
            {renderTableRowOperations(item, rowIndex)}
        </TableRow>
    )

})

export default DatatableTableBodyRow;