import React, {FormEvent, useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import CustomTextField from "../custom-text-field/custom-text-field";
import {FormDialogField} from "./form-dialog-field";
import {FormDialogParam} from "./form-dialog-param";

interface FormDialogProps {
    onClose: (campos: FormDialogField[] | null) => void;
    open: boolean;
    param: FormDialogParam;
}

const FormDialog = ({onClose, open, param}: FormDialogProps) => {

    const [campos, setCampos] = useState(param.campos);

    const handleCloseNo = () => {
        onClose(null);
    };

    const ok = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        onClose(campos);
    }

    const cancel = () => {
        handleCloseNo();
    }

    const onChangeCampo = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, campo: FormDialogField, index: number) => {
        let camposToChange = [...campos];
        camposToChange[index].valor = event.target.value;
        setCampos(camposToChange);
    }

    const renderParam = (campo: FormDialogField, index: number) => {
        return (
            <CustomTextField key={index} autoFocus={campo.autoFocus} fullWidth label={campo.descricao}
                             value={campo.valor || ''} type={campo.tipo} disabled={campo.readOnly}
                             required={campo.obrigatorio} onChange={(event) => onChangeCampo(event, campo, index)}
                             inputProps={{minLength: campo.minLength || '', maxLength: campo.maxLength || ''}}
            />
        )
    }

    return (
        <Dialog open={open} onClose={handleCloseNo} aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
            <form onSubmit={ok}>
                <DialogTitle id="alert-dialog-title">{param.titulo}</DialogTitle>
                <DialogContent>
                    {campos.map((campo, index) => {
                        return renderParam(campo, index);
                    })}
                </DialogContent>
                <DialogActions>
                    <Button type={"submit"} color="primary">OK</Button>
                    <Button type={"button"} color="neutral" onClick={cancel}>Cancelar</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}

export default React.memo(FormDialog);