import React, {useState} from "react";
import {Grid, IconButton, Tooltip} from "@mui/material";
import {Add, Search} from "@mui/icons-material";
import SearchDialog from "../search-dialog/search-dialog";
import {PageResponse} from "../util/page-response";
import {CustomDialog} from "../custom-dialog/custom-dialog";
import CustomTextField from "../custom-text-field/custom-text-field";

interface ForeignKeyProps {
    initialSort?: string;
    label: string;
    value: any;
    fieldKey: string;
    fieldDescription: string;
    onAdd?: (item?: any) => void;
    onSelect?: (item: any, index: number) => void;
    onChange?: (item: any, index?: number) => void;
    findAll: (number?: number, size?: number, sort?: string, filter?: string) => Promise<PageResponse<any>>;
    dataColumnNames: string[];
    dataColumns: string[];
    dataTypes: string[];
    addComponent?: any;
    disabled?: boolean;
    fullWidth: boolean;
    autoFocus?: boolean;
    required?: boolean;
    labelDescription: string;
}

const ForeignKey = React.memo(({
                                   value,
                                   fieldKey,
                                   fieldDescription,
                                   onAdd,
                                   onSelect,
                                   onChange,
                                   findAll,
                                   dataColumnNames,
                                   dataColumns,
                                   dataTypes,
                                   label,
                                   initialSort,
                                   addComponent,
                                   disabled,
                                   fullWidth,
                                   autoFocus,
                                   required,
                                   labelDescription
                               }: ForeignKeyProps) => {

    const [showSearchDialog, setShowSearchDialog] = useState(false);

    const [showAddDialog, setShowAddDialog] = useState(false);

    const getValueKey = () => {
        if (value) {
            return value[fieldKey] || '';
        } else {
            return ''
        }
    }
    const getValueDescription = () => {
        if (value) {
            return value[fieldDescription] || '';
        } else {
            return '';
        }
    }

    const openSearchDialog = () => {
        setShowSearchDialog(true);
    }

    const closeSearchDialog = () => {
        setShowSearchDialog(false);
    }

    const openAddDialog = () => {
        setShowAddDialog(true);
    }

    const closeAddDialog = (item?: any) => {
        setShowAddDialog(false);
        if (onAdd) {
            onAdd(item);
        }
    }

    const selectDataFromSearchDialog = (item: any, index: number) => {
        closeSearchDialog();
        if (onSelect) {
            onSelect(item, index);
        }
    }

    const renderSearchDialog = () => {
        return showSearchDialog && (
            <SearchDialog open={showSearchDialog} onClose={closeSearchDialog} findAll={findAll}
                          dataColumnNames={dataColumnNames} dataColumns={dataColumns}
                          dataTypes={dataTypes} label={label}
                          onSelectData={selectDataFromSearchDialog} initialSort={initialSort}/>
        )
    }

    const renderAddDialog = () => {
        return showAddDialog && (
            <CustomDialog open={showAddDialog} onClose={closeAddDialog}
                          title={label + ' - Inserir'}>{addComponent}</CustomDialog>
        )
    }

    const onChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
            onChange(event.target.value);
        }
    }

    function renderAddButton() {
        if (!onAdd) {
            return null;
        }
        if (disabled) {
            return (
                <IconButton color={"primary"} disabled onClick={() => {
                    openAddDialog()
                }}><Add/></IconButton>
            );
        }
        return (
            <Tooltip title='Inserir'>
                <IconButton color={"primary"} onClick={() => {
                    openAddDialog()
                }}><Add/></IconButton>
            </Tooltip>
        );
    }

    const renderSearchButton = () => {
        if (disabled) {
            return (
                <IconButton color={"primary"} disabled onClick={() => {
                    openSearchDialog()
                }}><Search/></IconButton>
            )
        } else {
            return (
                <Tooltip title='Pesquisar'>
                    <IconButton color={"primary"} onClick={() => {
                        openSearchDialog()
                    }}><Search/></IconButton>
                </Tooltip>
            )
        }
    }

    return (
        <React.Fragment>
            {renderSearchDialog()}
            {renderAddDialog()}
            <Grid container spacing={1}>
                <Grid item>
                    {renderAddButton()}
                    {renderSearchButton()}
                </Grid>
                <Grid item xs>
                    <Grid container spacing={1}>
                        <Grid item xs={6} md={3}>
                            <CustomTextField fullWidth={fullWidth} autoFocus={autoFocus}
                                             required={required}
                                             label={label} value={getValueKey()} disabled={disabled}
                                             onChange={onChangeValue}/>
                        </Grid>
                        <Grid item xs={6} md={9}>
                            <CustomTextField fullWidth={fullWidth} label={labelDescription}
                                             disabled={disabled}
                                             value={getValueDescription()}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    )

});

export default ForeignKey;