import React, {RefObject, useState} from 'react';
import {SaleListHeaderFilterEnum} from "./enum/sale-list-header-filter-enum";
import {FormControl, FormControlLabel, Radio, RadioGroup} from "@mui/material";
import SaleListHeaderFilterItems from "./sale-list-header-filter-items";

interface SaleListHeaderFilterProps {
    saleFilter: SaleListHeaderFilterEnum;
    startDate: string | undefined;
    endDate: string | undefined;
    saleId: number | undefined;
    personName: string | undefined;
    startDateRef: RefObject<HTMLInputElement>;
    endDateRef: RefObject<HTMLInputElement>;
    saleIdRef: RefObject<HTMLInputElement>;
    personNameRef: RefObject<HTMLInputElement>;
}

const SaleListHeaderFilter = React.memo(({
                                             saleFilter,
                                             startDate,
                                             endDate,
                                             saleId,
                                             personName,
                                             startDateRef,
                                             endDateRef,
                                             saleIdRef,
                                             personNameRef
                                         }: SaleListHeaderFilterProps) => {

    const [saleFilterInternal, setSaleFilterInternal] = useState<SaleListHeaderFilterEnum>(saleFilter);

    const onChangeInternalFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSaleFilterInternal(Number(event.target.value));
    }

    return (
        <React.Fragment>
            <FormControl component="fieldset" required>
                <RadioGroup row value={saleFilterInternal} onChange={onChangeInternalFilter}>
                    <FormControlLabel value={SaleListHeaderFilterEnum.EMISSION} control={<Radio/>}
                                      label="Emissão da venda"/>
                    <FormControlLabel value={SaleListHeaderFilterEnum.SALE_ID} control={<Radio/>} label="ID da venda"/>
                    <FormControlLabel value={SaleListHeaderFilterEnum.PERSON_NAME} control={<Radio/>}
                                      label="Nome do cliente"/>
                </RadioGroup>
            </FormControl>
            <SaleListHeaderFilterItems saleFilter={saleFilterInternal} startDate={startDate} endDate={endDate}
                                       saleId={saleId} personName={personName} startDateRef={startDateRef}
                                       endDateRef={endDateRef} saleIdRef={saleIdRef} personNameRef={personNameRef}/>
        </React.Fragment>
    )

});

export default SaleListHeaderFilter;
