import React from "react";
import {TableCell, TableRow} from "@mui/material";

const SaleListBodyTableContentRowBusy = React.memo(() => {

    return (
        <TableRow>
            <TableCell colSpan={99}>
                Carregando registros...
            </TableCell>
        </TableRow>
    )

});

export default SaleListBodyTableContentRowBusy;
