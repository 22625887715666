import React from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {BrandDetail} from "./brand-detail";

const BrandDetailPage = React.memo(() => {

    const {id} = useParams();
    let navigate = useNavigate();

    const onClose = () => {
        navigate('/brands');
    }

    return (
        <BrandDetail id={id} onClose={onClose}/>
    )

});

export default BrandDetailPage;