import React, {useState} from "react";
import {Sale} from "../../../sale/sale";
import CustomTextField from "../../custom-text-field/custom-text-field";
import {NumberUtil} from "../../util/number-util";
import CustomRow from "../../custom-row/custom-row";
import {Checkbox, FormControl, FormControlLabel, Radio, RadioGroup} from "@mui/material";
import * as StringUtil from "../../util/string-util";

interface OrcamentoItemProps {
    sale: Sale;
    type: string;
    setCondicoesDePagamento: (type: string) => void;
}

const OrcamentoItem = React.memo(({sale, type, setCondicoesDePagamento}: OrcamentoItemProps) => {

    const [item, setItem] = useState({
        checked: false,
        parcelas: 1,
        juros: 0,
        comEntrada: false,
        valorEntrada: 0,
        valorParcelas: 0,
        texto: ''
    });

    const renderEntrada = () => {
        return item.comEntrada && (
            <CustomTextField fullWidth label="Valor da entrada" type="number" value={item.valorEntrada || ''}
                             onChange={changeValorEntrada}/>
        )
    }

    const changeChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
        const checked = event.target.checked;
        const valorParcelas = calculaValorParcelas(item.valorEntrada, item.parcelas, item.juros);
        const texto = calculaTexto(item.valorEntrada, item.parcelas, item.juros, valorParcelas);
        setItem({
            ...item,
            checked: checked,
            valorParcelas: valorParcelas,
            texto: texto
        });
        setCondicoesDePagamento(checked ? texto : '');
    }

    const changeParcelas = (event: React.ChangeEvent<HTMLInputElement>) => {
        const parcelas = Number(event.target.value);
        const valorParcelas = calculaValorParcelas(item.valorEntrada, parcelas, item.juros);
        const texto = calculaTexto(item.valorEntrada, parcelas, item.juros, valorParcelas);
        setItem({
            ...item,
            parcelas: parcelas,
            valorParcelas: valorParcelas,
            texto: texto
        });
        setCondicoesDePagamento(item.checked ? texto : '');
    }

    const changeJuros = (event: React.ChangeEvent<HTMLInputElement>) => {
        const juros = Number(event.target.value);
        const valorParcelas = calculaValorParcelas(item.valorEntrada, item.parcelas, juros);
        const texto = calculaTexto(item.valorEntrada, item.parcelas, juros, valorParcelas);
        setItem({
            ...item,
            juros: juros,
            valorParcelas: valorParcelas,
            texto: texto
        });
        setCondicoesDePagamento(item.checked ? texto : '');
    }

    const changeComEntrada = (event: React.ChangeEvent<HTMLInputElement>) => {
        const comEntrada = (event.target.value === 'true');
        const valorEntrada = comEntrada ? item.valorEntrada : 0;
        const valorParcelas = calculaValorParcelas(valorEntrada, item.parcelas, item.juros);
        const texto = calculaTexto(valorEntrada, item.parcelas, item.juros, valorParcelas);
        setItem({
            ...item,
            comEntrada: comEntrada,
            valorEntrada: valorEntrada,
            valorParcelas: valorParcelas,
            texto: texto
        });
        setCondicoesDePagamento(item.checked ? texto : '');
    }

    const changeValorEntrada = (event: React.ChangeEvent<HTMLInputElement>) => {
        const valorEntrada = Number(event.target.value);
        const valorParcelas = calculaValorParcelas(valorEntrada, item.parcelas, item.juros);
        const texto = calculaTexto(valorEntrada, item.parcelas, item.juros, valorParcelas);
        setItem({
            ...item,
            valorEntrada: valorEntrada,
            valorParcelas: valorParcelas,
            texto: texto
        });
        setCondicoesDePagamento(item.checked ? texto : '');
    }

    const changeTexto = (event: React.ChangeEvent<HTMLInputElement>) => {
        const texto = event.target.value;
        setItem({
            ...item,
            texto: texto
        });
        setCondicoesDePagamento(item.checked ? texto : '');
    }

    const calculaValorParcelas = (valorEntrada: number, nParcelas: number, juros: number) => {
        const valorParaParcelar = Number(sale.valorTotal) - valorEntrada;
        if (juros === 0) {
            return Math.round(valorParaParcelar / nParcelas * 100) / 100;
        } else {
            let valorParcela = 1 - Math.pow(1 + juros / 100, (-1 * nParcelas));

            if (valorParcela > 0) {
                valorParcela = valorParcela / (juros / 100);
                return Math.round(valorParaParcelar / valorParcela * 100) / 100;
            } else {
                return 0;
            }
        }
    }

    const calculaTexto = (valorEntrada: number, nParcelas: number, juros: number, valorParcelas: number) => {
        if (valorEntrada > 0) {
            return 'Entrada de ' + NumberUtil.currencyFormat(valorEntrada) + ' mais ' + nParcelas + ' parcela(s) de ' +
                NumberUtil.currencyFormat(valorParcelas) + ' em ' + type + ', totalizando ' +
                NumberUtil.currencyFormat(valorEntrada + (nParcelas * valorParcelas)) + '.';
        } else {
            return nParcelas + ' parcela(s) de ' + NumberUtil.currencyFormat(valorParcelas) + ' em ' + type + ', totalizando ' +
                NumberUtil.currencyFormat(nParcelas * valorParcelas) + '.';
        }
    }

    const renderDetalhes = () => {
        return item.checked && (
            <React.Fragment>
                <CustomRow>
                    <CustomTextField fullWidth label="Parcelas" type="number" value={item.parcelas || ''}
                                     onChange={changeParcelas} InputProps={{inputProps: {min: 1}}}/>
                    <CustomTextField fullWidth label="Juros (%)" type="number" value={item.juros || ''}
                                     onChange={changeJuros} InputProps={{inputProps: {min: 0, step: "any"}}}/>
                </CustomRow>
                <FormControl component="fieldset" required>
                    <RadioGroup row value={item.comEntrada} onChange={changeComEntrada}>
                        <FormControlLabel value={true} control={<Radio/>} label="Com entrada"/>
                        <FormControlLabel value={false} control={<Radio/>} label="Sem entrada"/>
                    </RadioGroup>
                </FormControl>
                {renderEntrada()}
                <CustomTextField multiline fullWidth label={'Orçamento em ' + type} type="text" value={item.texto || ''}
                                 onChange={changeTexto}/>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <FormControl fullWidth>
                <FormControlLabel control={<Checkbox checked={item.checked} onChange={changeChecked}/>}
                                  label={StringUtil.capitalizeFirst(type)}/>
            </FormControl>
            {renderDetalhes()}
        </React.Fragment>
    )

});

export default OrcamentoItem;
