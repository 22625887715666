import React, {useCallback, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {Datatable} from "../index";
import {DocumentService} from "./document.service";
import {useNavigate, useParams} from "react-router-dom";
import CustomButton from "../typescript/custom-button/custom-button";
import {changePageTitle} from "../typescript/redux/actions";

export function DocumentSaleItem() {

    const {id} = useParams();
    let navigate = useNavigate();

    const [items, setItems] = useState([]);

    const dispatch = useDispatch();

    const findAll = useCallback(async () => {
        const result = await DocumentService.getSaleItems(id);
        setItems(result);
    }, [id]);

    useEffect(() => {
        dispatch(changePageTitle("Itens vendidos do documento " + id));
        findAll().then();
    }, [dispatch, findAll, id]);

    const back = () => {
        navigate('/documents');
    }

    return (
        <React.Fragment>
            <CustomButton type="button" onClick={back}>Voltar</CustomButton>
            <Datatable data={items} totalData={items.length}
                       dataColumnNames={['Nº venda', 'Produto', 'Quantidade', 'Valor', 'Vendedor']}
                       dataColumns={['os', 'produto', 'quantidade', 'valor', 'vendedor']}
                       dataTypes={['number', 'text', 'number', 'currency', 'text']}/>
        </React.Fragment>
    )
}