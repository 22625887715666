import React from "react";
import {Chip, Grid} from "@mui/material";
import {Cancel, CheckCircle, Error} from "@mui/icons-material";
import theme from "../../../theme/theme";

interface SaleListBodyStatus2Props {
    comprou: number;
    fezOrcamento: number;
    fezEmOutroLugar: number;
    naoPrecisou: number;
    achouCaro: number;
}

const SaleListBodyStatus2 = React.memo(({
                                      comprou,
                                      fezOrcamento,
                                      fezEmOutroLugar,
                                      naoPrecisou,
                                      achouCaro
                                  }: SaleListBodyStatus2Props) => {

    return (
        <Grid container spacing={1} justifyContent={"center"}>
            <Chip color={"info"} icon={<CheckCircle/>} label={'Comprou: ' + comprou} sx={theme.chips}/>
            <Chip color={"success"} icon={<Error/>} label={'Fez orçamento: ' + fezOrcamento} sx={theme.chips}/>
            <Chip color={"error"} icon={<Error/>} label={'Fez em outro lugar: ' + fezEmOutroLugar} sx={theme.chips}/>
            <Chip color={"neutral"} icon={<Cancel/>} label={'Não precisou: ' + naoPrecisou} sx={theme.chips}/>
            <Chip color={"warning"} icon={<Cancel/>} label={'Achou caro: ' + achouCaro} sx={theme.chips}/>
        </Grid>
    )

});

export default SaleListBodyStatus2;
