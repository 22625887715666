import {AppService} from "../typescript/app/app.service";
import {PageResponse} from "../typescript/util/page-response";
import {Comission} from "./comission";
import {UserService} from "../user/user.service";

class ComissionService {

    static API_URL = "/api/comissions";

    static findAll = (page?: number, size?: number, sort?: string, filter?: string): Promise<PageResponse<Comission>> => {
        return AppService.getAuthorized(this.API_URL, {
            page: page,
            size: size,
            sort: sort,
            id: sort?.startsWith('id') ? filter : '',
            username: sort?.startsWith('username') ? filter : ''
        }).then((result) => {
            return result.data;
        }).catch(() => {
            return {
                content: [],
                totalElements: 0
            };
        })
    };

    static findOne(id: number) {
        return AppService.getAuthorized(this.API_URL + "/" + id).then((result) => {
            return result.data;
        }).catch(() => {
            return null;
        })
    }

    static save(item: Comission) {
        if (item.id) {
            return AppService.postAuthorized(this.API_URL + "/" + item.id, item);
        } else {
            return AppService.postAuthorized(this.API_URL, item);
        }
    }

    static delete(id: number) {
        return AppService.deleteAuthorized(this.API_URL + "/" + id);
    }

    static createComission() {
        const comission: Comission = {
            user: UserService.createUser(),
            minimumGoalPercentual: null,
            basicGoalPercentual: null,
            masterGoalPercentual: null
        }
        return comission;
    }

    static generateComission(userId: number, year: number, month: number) {
        const request = {
            userId: userId,
            year: year,
            month: month
        }
        return AppService.postAuthorized(this.API_URL + "/generateComission", request);
    }

    static getGoalAccomplishedText(goalAccomplishedValue: string) {
        switch (goalAccomplishedValue) {
            case 'MINIMUM':
                return 'Minima';
            case 'BASIC':
                return 'Básica';
            case 'MASTER':
                return 'Master';
            default:
                return 'Nenhuma';
        }
    }
}

export {ComissionService};
