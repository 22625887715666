import React from 'react';
import SelectDataOption from "../select-data-option/select-data-option";
import {SelectChangeEvent} from "@mui/material/Select/SelectInput";
import {PaymentType} from "../../payment-type/payment-type";

interface TipoDePagamentoOptionProps {
    changeTipoDePagamento: (tipoDePagamento: PaymentType | null) => void;
    tiposDePagamento: PaymentType[];
    tipoDePagamentoSelecionado: PaymentType | null;
}

const TipoDePagamentoOption = ({
                                   changeTipoDePagamento,
                                   tiposDePagamento,
                                   tipoDePagamentoSelecionado
                               }: TipoDePagamentoOptionProps) => {

    const getTipoDePagamentoSelecionado = (idSelecionado: number) => {
        let novoTipoDePagamentoSelecionado = null;
        for (let i = 0; i < tiposDePagamento.length; i++) {
            if (tiposDePagamento[i].id === idSelecionado) {
                novoTipoDePagamentoSelecionado = tiposDePagamento[i];
                break;
            }
        }
        return novoTipoDePagamentoSelecionado;
    }

    const onChange = (event: SelectChangeEvent<any>) => {
        const idSelecionado = parseInt(event.target.value);
        let novoTipoDePagamentoSelecionado = getTipoDePagamentoSelecionado(idSelecionado);
        changeTipoDePagamento(novoTipoDePagamentoSelecionado);
    }

    return (
        <SelectDataOption fullWidth required label="Forma de pagamento" onChange={onChange} items={tiposDePagamento}
                          value={tipoDePagamentoSelecionado} valueIndex='id' valueDescription='descricao'/>
    )

}

export default React.memo(TipoDePagamentoOption);
