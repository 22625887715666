import React from "react";
import {Dialog, DialogContent} from "@mui/material";
import ItemToInsert from "./item-to-insert";
import {SaleItem} from "../../../sale/sale-item";

interface SaleItemDialogProps {
    onClose: (itemEditado: SaleItem | null) => void;
    open: boolean;
    saleItem: SaleItem;
    saleItemClosed: boolean;
    warrantyOperation: boolean;
}

const SaleItemDialog = ({onClose, open, saleItem, saleItemClosed, warrantyOperation}: SaleItemDialogProps) => {

    const editItem = (itemEditado: SaleItem | null) => {
        onClose(itemEditado);
    }

    return (
        <Dialog open={open} fullWidth maxWidth={"xl"}>
            <DialogContent>
                <ItemToInsert insertItem={editItem} saleItem={saleItem} saleItemClosed={saleItemClosed} warrantyOperation={warrantyOperation}/>
            </DialogContent>
        </Dialog>
    )

}

export default React.memo(SaleItemDialog);
