import React from "react";
import {TableBody, TableCell, TableRow} from "@mui/material";
import DatatableTableBodyRow from "./datatable-table-body-row";

const classes = {
    datatableCursorPointer: {
        cursor: 'pointer'
    }
};

const DatatableTableBody = React.memo(({data, dataColumns, dataTypes, dataValues, editData, rowOperations, rowClass}) => {

    const editRow = (item, index) => {
        if (editData) {
            editData(item, index);
        }
    }

    const cursorClass = (editData ? classes.datatableCursorPointer : null);

    const renderTableBodyRow = () => {
        if (data && data.length > 0) {
            return data.map((item, index) => (
                <DatatableTableBodyRow key={index} item={item} dataColumns={dataColumns} dataTypes={dataTypes}
                                       rowIndex={index}
                                       dataValues={dataValues} editData={() => editRow(item, index)} rowClass={rowClass}
                                       cursorClass={cursorClass} rowOperations={rowOperations}/>
            ))
        }
        return (
            <TableRow>
                <TableCell colSpan={99}>
                    Nenhum registro encontrado
                </TableCell>
            </TableRow>
        )
    }

    return (
        <TableBody>
            {renderTableBodyRow()}
        </TableBody>
    )

})

export default DatatableTableBody;