import React from "react";
import {TableCell} from "@mui/material";
import * as StringUtil from "../typescript/util/string-util";
import {getTableCellContent} from "../typescript/util/string-util";

const DatatableTableBodyRowContent = React.memo(({item, dataColumn, type, editData, cursorClass}) => {

    const contentFormatted = () => {
        const content = getTableCellContent(item, dataColumn);
        return StringUtil.contentFormatted(content, type);
    }

    return (
        <TableCell sx={cursorClass} onClick={editData}>
            {contentFormatted()}
        </TableCell>
    )

})

export default DatatableTableBodyRowContent;
