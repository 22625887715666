import React, {useState} from "react";
import {Checkbox, TableCell} from "@mui/material";
import {CampaignPerson} from "../campaign-person";
import {CampaignPersonService} from "../campaign-person.service";
import {setAlert} from "../../../redux/actions";
import {useDispatch} from "react-redux";

const classes = {
    rowDanger: {
        backgroundColor: '#f2dedeb8'
    },
    rowWarning: {
        backgroundColor: '#fcf8e3'
    },
};

interface InterestedCellProps {
    campaignPerson: CampaignPerson;
    disabled?: boolean;
}

const InterestedCell = ({campaignPerson, disabled}: InterestedCellProps) => {

    const dispatch = useDispatch();
    const [item, setItem] = useState<CampaignPerson>(campaignPerson);

    const rowClassInterested = () => {
        return item.interested ? classes.rowWarning : classes.rowDanger;
    }

    const onChangeInterested = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newInterested = event.target.checked;
        CampaignPersonService.updateInterested(item.id, newInterested).then(() => {
            setItem({
                ...campaignPerson,
                interested: newInterested
            })
            dispatch(setAlert({show: true, message: 'Registro com sucesso', severity: 'success'}));
        }).catch((error) => {
            dispatch(setAlert({
                show: true,
                message: 'Erro ao salvar registro - ' + error.data.message,
                severity: 'error'
            }));
        })
    }

    return (
        <TableCell sx={rowClassInterested} align={"center"}>
            <Checkbox checked={item.interested} onChange={(event) => onChangeInterested(event)} disabled={disabled}/>
        </TableCell>
    )
}

export default React.memo(InterestedCell);