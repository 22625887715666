import React, {useCallback, useEffect, useState} from "react";
import {ComissionProductService} from "./comission-product.service";
import {useDispatch} from "react-redux";
import {
    Datatable,
    ForeignKey
} from "../index";
import {Delete as DeleteIcon} from "@mui/icons-material";
import {ComissionProductItemDetail} from "./comission-product-item-detail";
import CustomTextField from "../typescript/custom-text-field/custom-text-field";
import CustomButton from "../typescript/custom-button/custom-button";
import CustomButtonGroup from "../typescript/custom-button-group/custom-button-group";
import {CustomDialog} from "../typescript/custom-dialog/custom-dialog";
import {changePageTitle, setAlert} from "../typescript/redux/actions";
import {UserService} from "../user/user.service";

export function ComissionProductDetail({id, onClose, dialog}) {

    const [item, setItem] = useState(ComissionProductService.createComissionProduct());
    const [itemToModal, setItemToModal] = useState({
        item: ComissionProductService.createComissionProductItem(),
        index: -1
    });
    const [showItemDialog, setShowItemDialog] = useState(false);
    const dispatch = useDispatch();

    const findOne = useCallback(async () => {
        const result = await ComissionProductService.findOne(id);
        setItem(result);
    }, [id]);

    useEffect(() => {
        if (!dialog) {
            dispatch(changePageTitle(id ? "Comissão sobre produto " + id : "Nova comissão sobre produto"));
        }
        if (id) {
            findOne().then();
        }
    }, [dispatch, findOne, id, dialog]);

    const validateForm = () => {
        if (!item.items || item.items.length === 0) {
            dispatch(setAlert({show: true, message: 'A lista de produtos não pode ser vazia', severity: 'error'}));
            return false;
        }
        return true;
    }

    const save = (event) => {
        event.preventDefault();
        event.stopPropagation();
        if (!validateForm()) {
            return
        }
        ComissionProductService.save(item).then((result) => {
            onClose(result.data);
        }).catch(() => {

        })
    }

    const cancel = () => {
        onClose();
    }

    const onChangeUsuario = async (id) => {
        setItem({
            ...item,
            user: {
                id: id
            }
        })
        if (id) {
            const user = await UserService.findOne(id);
            if (user) {
                setItem({
                    ...item,
                    user: user
                })
            }
        }
    }

    const onAddUsuario = (user) => {
        if (user) {
            setItem({
                ...item,
                user: user
            })
        }
    }

    const findUsuarios = (page, size, sort, filter) => {
        return UserService.findAll(page, size, sort, filter);
    }

    const setComissionValue = (event) => {
        setItem({
            ...item,
            comissionValue: event.target.value
        })
    }

    const deleteItem = (ItemToDelete, index) => {
        let {items} = item;
        items.splice(index, 1);
        setItem({
            ...item,
            items: items
        })
    }

    const rowOperationsItems = [
        {color: () => "primary", onClick: deleteItem, label: () => 'Excluir', icon: () => <DeleteIcon/>},
    ]

    const openItemDialog = () => {
        setShowItemDialog(true);
    }

    const insertItem = () => {
        setItemToModal({
            item: ComissionProductService.createComissionProductItem(),
            index: -1
        });
        openItemDialog();
    }

    const headerOperationsItems = [
        {color: () => "primary", onClick: insertItem, label: () => 'Inserir item'}
    ]

    const editItem = (itemToEdit, index) => {
        setItemToModal({
            item: itemToEdit,
            index: index
        });
        openItemDialog();
    }

    const closeItemDialog = (itemEditadoOuInserido) => {
        setShowItemDialog(false);
        if (itemEditadoOuInserido) {
            let {items} = item;
            if (itemToModal.index !== -1) {
                items[itemToModal.index] = itemEditadoOuInserido;
            } else {
                items.push(itemEditadoOuInserido);
            }
            setItem({
                ...item,
                items: items
            })
        }
    }

    const renderItemDialog = () => {
        return showItemDialog && (
            <CustomDialog open={showItemDialog} onClose={closeItemDialog}
                          title={'Inserir item'}><ComissionProductItemDetail item={itemToModal.item}/></CustomDialog>
        )
    }

    return (
        <form onSubmit={save}>
            {renderItemDialog()}
            <ForeignKey fullWidth autoFocus required label="Usuário" value={item.user} fieldKey={'id'}
                        fieldDescription={'username'} labelDescription='Usuário' onChange={onChangeUsuario}
                        onSelect={onAddUsuario} findAll={findUsuarios}
                        dataColumnNames={['ID', 'Usuário']} dataColumns={['id', 'username']}
                        dataTypes={['number', 'text']}/>
            <CustomTextField type="number" fullWidth required label="Valor da comissão (R$)" value={item.comissionValue}
                             onChange={setComissionValue}/>

            <Datatable data={item.items} totalData={item.items.length} rowOperations={rowOperationsItems}
                       dataColumnNames={['Quantidade', 'Produto']}
                       dataColumns={['quantity', 'product.descricao']} headerOperations={headerOperationsItems}
                       dataTypes={['number', 'text']} editData={editItem}/>


            <CustomButtonGroup>
                <CustomButton color="primary" type="submit" disabled={item.document}>Salvar</CustomButton>
                <CustomButton type="button" color="neutral" onClick={cancel}>Cancelar</CustomButton>
            </CustomButtonGroup>
        </form>
    )
}