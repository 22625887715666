import React, {useState} from 'react';
import {FormControl, FormControlLabel, Radio, RadioGroup} from "@mui/material";
import {SaleListHeaderFilterReceivedEnum} from "./enum/sale-list-header-filter-received-enum";

interface SaleListHeaderFilterReceivedProps {
    saleReceived: SaleListHeaderFilterReceivedEnum;
}

const SaleListHeaderFilterReceived = React.memo(React.forwardRef<HTMLInputElement, SaleListHeaderFilterReceivedProps>(({saleReceived}, ref) => {

        const [saleReceivedInternal, setSaleReceivedInternal] = useState<SaleListHeaderFilterReceivedEnum>(saleReceived);

        const onChangeReceived = (event: React.ChangeEvent<HTMLInputElement>) => {
            setSaleReceivedInternal(Number(event.target.value));
        }

        return (
            <FormControl component="fieldset" required>
                <RadioGroup row value={saleReceivedInternal} onChange={onChangeReceived} ref={ref}>
                    <FormControlLabel value={SaleListHeaderFilterReceivedEnum.RECEIVED} control={<Radio/>} label="Recebida"/>
                    <FormControlLabel value={SaleListHeaderFilterReceivedEnum.NOT_RECEIVED} control={<Radio/>} label="Não recebida"/>
                    <FormControlLabel value={SaleListHeaderFilterReceivedEnum.BOTH} control={<Radio/>} label="Ambas"/>
                </RadioGroup>
            </FormControl>
        )

    }
));

export default SaleListHeaderFilterReceived;
