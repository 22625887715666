import React, {FormEvent, useCallback, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {ActivityService} from "./activity.service";
import {Grid, IconButton, Tooltip} from "@mui/material";
import {Check, Delete, Search} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import CustomTextField from "../typescript/custom-text-field/custom-text-field";
import {AuthService} from "../typescript/auth/auth.service";
import {changePageTitle, setAlert} from "../typescript/redux/actions";
import {UserService} from "../user/user.service";
import {User} from "../user/user";
import {Activity} from "./activity";
import {DateUtil} from "../typescript/util/2.0.0/date-util";
import ForeignKey from "../typescript/foreign-key/foreign-key";
import Datatable from "../typescript/datatable/datatable";
import {ColorButtonEnum} from "../typescript/util/color-button-enum";
import {ColorIconEnum} from "../typescript/util/color-icon-enum";

export function ActivityList() {

    let navigate = useNavigate();
    const dispatch = useDispatch();
    const isAdmin = AuthService.userInRole("ADMIN");
    const userWithDetails = AuthService.getUserDetails();
    if (!userWithDetails) {
        throw new Error('User not logged');
    }
    const [startDate, setStartDate] = useState(DateUtil.toJavaSqlDate(DateUtil.currentDate()));
    const [endDate, setEndDate] = useState(DateUtil.toJavaSqlDate(DateUtil.currentDate()));
    const [user, setUser] = useState(userWithDetails);
    const [items, setItems] = useState<Activity[]>([]);

    const findAll = useCallback(async () => {
        if (!user.id) {
            throw new Error('User without id');
        }
        const items = isAdmin ? await ActivityService.findAll(startDate, endDate, user.id) :
            await ActivityService.findAllOfCurrentUser(startDate, endDate);
        setItems(items);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        dispatch(changePageTitle("Atividades"));
        findAll().then();
    }, [dispatch, findAll]);

    const insertItem = () => {
        navigate('/activity');
    }

    const deleteItem = (item: Activity) => {
        ActivityService.delete(item.id).then(() => {
            findAll().then();
        }).catch((error) => {
            dispatch(setAlert({
                show: true,
                message: 'Erro ao excluir registro - ' + error.message,
                severity: 'error'
            }));
        })
    }

    const accomplishedItem = (item: Activity) => {
        ActivityService.accomplished(item.id, new Date()).then(() => {
            findAll().then();
        }).catch(() => {

        })
    }

    const onChangeStartDate = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStartDate(event.target.value)
    }

    const onChangeEndDate = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEndDate(event.target.value)
    }

    const onChangeUsuario = async (id: number) => {
        setUser({id: id});
        if (id) {
            const user = await UserService.findOne(id);
            if (user) {
                setUser(user)
            }
        }
    }

    const onSelectUsuario = (user: User) => {
        if (user) {
            setUser(user);
        }
    }

    const search = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        if (!user.id) {
            throw new Error('User without id');
        }
        const items = isAdmin ? await ActivityService.findAll(startDate, endDate, user.id) :
            await ActivityService.findAllOfCurrentUser(startDate, endDate);
        setItems(items);
    }

    const showAccomplishedItem = (item: Activity) => {
        return !item.accomplished;
    }

    let headerOperations;
    let rowOperations;
    if (isAdmin) {
        headerOperations = [{color: () => ColorButtonEnum.PRIMARY, onClick: insertItem, label: () => 'Inserir'}];
        rowOperations = [
            {color: () => ColorIconEnum.PRIMARY, onClick: deleteItem, label: () => 'Excluir', icon: () => <Delete/>},
            {
                color: () => ColorIconEnum.PRIMARY,
                onClick: accomplishedItem,
                label: () => 'Marcar como realizado',
                icon: () => <Check/>,
                show: showAccomplishedItem
            }
        ];
    } else {
        rowOperations = [{
            color: () => ColorIconEnum.PRIMARY,
            onClick: accomplishedItem,
            label: () => 'Marcar como realizado',
            icon: () => <Check/>,
            show: showAccomplishedItem
        }];
    }

    function renderUserForeignKey() {
        return isAdmin && (
            <Grid item md xs={6}>
                <ForeignKey fullWidth autoFocus required label="Usuário" value={user} fieldKey={'id'}
                            fieldDescription={'username'} labelDescription='Usuário' onChange={onChangeUsuario}
                            onSelect={onSelectUsuario} findAll={UserService.findAll}
                            dataColumnNames={['ID', 'Usuário']} dataColumns={['id', 'username']}
                            dataTypes={['number', 'text']}/>
            </Grid>
        );
    }

    const renderResume = () => {
        const total = items.length;
        let totalAccomplished = 0;
        for (let i = 0; i < items.length; i++) {
            if (items[i].accomplished) {
                totalAccomplished++;
            }
        }
        const totalDontAccomplished = total - totalAccomplished;
        const percentual = Math.round(totalAccomplished / total * 100);
        return (
            <p><strong>Total atividades:</strong> {total | 0} - <strong>Atividades
                realizadas:</strong> {totalAccomplished | 0} - <strong>Atividades não
                realizadas:</strong> {totalDontAccomplished | 0} - <strong>Índice:</strong> {percentual | 0} %</p>
        )
    }

    return (
        <React.Fragment>
            <form onSubmit={search}>
                <Grid container spacing={1}>
                    <Grid item md xs={6}>
                        <CustomTextField fullWidth required label="Data inicial" type="date" value={startDate || ''}
                                         InputLabelProps={{shrink: true}} onChange={onChangeStartDate}/>
                    </Grid>
                    <Grid item md xs={6}>
                        <CustomTextField fullWidth required label="Data final" type="date" value={endDate || ''}
                                         InputLabelProps={{shrink: true}} onChange={onChangeEndDate}/>
                    </Grid>
                    {renderUserForeignKey()}
                    <Grid item>
                        <Tooltip title="Pesquisar">
                            <IconButton type={"submit"}>
                                <Search/>
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </form>
            {isAdmin && renderResume()}
            <Datatable dataColumnNames={['Data', 'Descrição', 'Período', 'Realizado']}
                       dataColumns={['date', 'descricao', 'round', 'accomplished']}
                       dataTypes={['date', 'text', 'text', 'datetime']} findAll={findAll}
                       data={items} totalData={items.length}
                       headerOperations={headerOperations} rowOperations={rowOperations}/>
        </React.Fragment>
    );
}
