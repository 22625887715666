import React, {useCallback, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {changePageTitle, setAlert} from "../typescript/redux/actions";
import {InstagramService} from "./instagram.service";
import CustomTextField from "../typescript/custom-text-field/custom-text-field";
import CustomButton from "../typescript/custom-button/custom-button";
import CustomButtonGroup from "../typescript/custom-button-group/custom-button-group";
import CustomRow from "../typescript/custom-row/custom-row";

const InstagramDetail = ({id, onClose, dialog}) => {

    const [item, setItem] = useState(InstagramService.createInstagram());
    const dispatch = useDispatch();

    const findOne = useCallback(async () => {
        const result = await InstagramService.findOne(id);
        setItem(result);
    }, [id]);

    useEffect(() => {
        if (!dialog) {
            dispatch(changePageTitle(id ? "Instagram " + id : "Novo instagram"));
        }
        if (id) {
            findOne().then();
        }
    }, [dispatch, findOne, id, dialog]);

    const save = (event) => {
        event.preventDefault();
        event.stopPropagation();
        InstagramService.save(item).then((result) => {
            onClose(result.data);
        }).catch(() => {
            dispatch(setAlert({show: true, message: 'Erro ao salvar instagram', severity: 'error'}));
        })
    }

    const cancel = () => {
        onClose();
    }

    const setDate = (event) => {
        setItem({
            ...item,
            date: event.target.value
        })
    }

    const setStories = (event) => {
        setItem({
            ...item,
            stories: event.target.value
        })
    }

    const setReels = (event) => {
        setItem({
            ...item,
            reels: event.target.value
        })
    }

    const setFeed = (event) => {
        setItem({
            ...item,
            feed: event.target.value
        })
    }

    const setStatus = (event) => {
        setItem({
            ...item,
            status: event.target.value
        })
    }

    const setSponsored = (event) => {
        setItem({
            ...item,
            sponsored: event.target.value
        })
    }

    const setInvestment = (event) => {
        setItem({
            ...item,
            investment: event.target.value
        })
    }

    return (
        <form onSubmit={save}>
            <CustomTextField fullWidth required label="Data" type="date" value={item.date || ''}
                             InputLabelProps={{shrink: true}} onChange={setDate} autoFocus/>
            <CustomRow>
                <CustomTextField type="number" fullWidth label="Stories" value={item.stories || ''}
                                 onChange={setStories} InputProps={{inputProps: {step: 1}}}/>
                <CustomTextField type="number" fullWidth label="Reels" value={item.reels || ''}
                                 onChange={setReels} InputProps={{inputProps: {step: 1}}}/>
                <CustomTextField type="number" fullWidth label="Feed" value={item.feed || ''}
                                 onChange={setFeed} InputProps={{inputProps: {step: 1}}}/>
                <CustomTextField type="number" fullWidth label="Status" value={item.status || ''}
                                 onChange={setStatus} InputProps={{inputProps: {step: 1}}}/>
            </CustomRow>
            <CustomRow>
                <CustomTextField fullWidth label="Fluxo de pessoas" type="number" value={item.sponsored || ''}
                                 onChange={setSponsored} InputProps={{inputProps: {min: 0, step: "any"}}}/>
                <CustomTextField fullWidth label="Investimento (R$)" type="number" value={item.investment || ''}
                                 onChange={setInvestment} InputProps={{inputProps: {min: 0, step: "any"}}}/>
            </CustomRow>
            <CustomButtonGroup>
                <CustomButton color="primary" type="submit">Salvar</CustomButton>
                <CustomButton type="button" color="neutral" onClick={cancel}>Cancelar</CustomButton>
            </CustomButtonGroup>
        </form>
    )
}

export default React.memo(InstagramDetail);