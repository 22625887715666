import React, {FormEvent, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {FormControl, FormControlLabel, FormLabel, Radio, RadioGroup} from "@mui/material";
import CustomButton from "../typescript/custom-button/custom-button";
import CustomButtonGroup from "../typescript/custom-button-group/custom-button-group";
import {changePageTitle, setAlert} from "../typescript/redux/actions";
import {FileUtil} from "../typescript/util/file-util";
import * as StringUtil from "../typescript/util/string-util";
import {RelatorioService} from "../typescript/relatorio/relatorio.service";
import {ProductService} from "../product/product.service";
import {CategoryService} from "../category/category.service";
import {Product} from "../product/product";
import {Category} from "../category/category";
import {ReportSalesParam} from "../typescript/relatorio/report-sales-param";
import ForeignKey from "../typescript/foreign-key/foreign-key";

const ConsultSales = () => {
    const [porProduto, setPorProduto] = useState(true);
    const [produto, setProduto] = useState<Product | null>(null);
    const [categoria, setCategoria] = useState<Category | null>(null);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(changePageTitle("Consultar vendas"));
    }, [dispatch]);

    const print = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        let params: ReportSalesParam = {
            porProduto: porProduto
        }
        if (porProduto) {
            params.produtoId = produto?.id
        } else {
            params.categoriaId = categoria?.id
        }
        RelatorioService.getVendas(params).then(function (result) {
            FileUtil.openPdfInNewTab(result);
        }, (result) => {
            const error = StringUtil.decodeUint8Array(result);
            dispatch(setAlert({show: true, message: error.message, severity: 'error'}));
        })
    }

    const onChangeProduto = async (id: number) => {
        setProduto({
            id: id
        });
        if (id) {
            const produto = await ProductService.findOne(id);
            onSelectProduto(produto);
        }
    }

    const onSelectProduto = (produto: Product) => {
        if (produto) {
            setProduto(produto);
        }
    }

    const onChangePorProduto = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setPorProduto(value === 'true');
    }

    const onChangeCategoria = async (id: number) => {
        setCategoria({
            id: id
        });
        if (id) {
            const categoria = await ProductService.findOne(id);
            onSelectCategoria(categoria);
        }
    }

    const onSelectCategoria = (categoria: Category) => {
        if (categoria) {
            setCategoria(categoria);
        }
    }

    const renderPorProduto = () => {
        return porProduto && (
            <React.Fragment>
                <ForeignKey fullWidth required label="Produto" value={produto} fieldKey={'id'}
                            fieldDescription={'descricao'} labelDescription='Descrição' onChange={onChangeProduto}
                            onSelect={onSelectProduto} findAll={ProductService.findAllSimple}
                            dataColumnNames={['ID', 'Descrição']} dataColumns={['id', 'descricao']}
                            dataTypes={['number', 'text']}/>
            </React.Fragment>
        )
    }

    const renderPorCategoria = () => {
        return !porProduto && (
            <ForeignKey fullWidth required label="Categoria" value={categoria} fieldKey={'id'}
                        fieldDescription={'descricao'} labelDescription='Descrição' onChange={onChangeCategoria}
                        onSelect={onSelectCategoria} findAll={CategoryService.findAll}
                        dataColumnNames={['ID', 'Descrição']} dataColumns={['id', 'descricao']}
                        dataTypes={['number', 'text']}/>
        )
    }

    return (
        <form onSubmit={print}>
            <FormControl component="fieldset" required>
                <FormLabel component="legend">Classificação</FormLabel>
                <RadioGroup value={porProduto} onChange={onChangePorProduto}>
                    <FormControlLabel value={true} control={<Radio/>} label="Por produto"/>
                    <FormControlLabel value={false} control={<Radio/>} label="Por categoria"/>
                </RadioGroup>
            </FormControl>
            {renderPorProduto()}
            {renderPorCategoria()}

            <CustomButtonGroup>
                <CustomButton color="primary" type="submit">Imprimir</CustomButton>
            </CustomButtonGroup>
        </form>
    );
}

export default React.memo(ConsultSales);
