import React from "react";
import {DocumentPayTabs} from "../index";
import {useNavigate, useParams} from "react-router-dom";

const DocumentPay = (props) => {

    let navigate = useNavigate();

    const {id} = useParams();
    const {onClose} = props;

    const back = (success) => {
        if (onClose) {
            onClose(success)
        } else {
            navigate('/documents');
        }
    }
    return (
        <DocumentPayTabs id={id} onClose={onClose} onBack={back}/>
    )
}

export default React.memo(DocumentPay);