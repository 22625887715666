import React from "react";
import {TableCell, TableFooter, TableRow} from "@mui/material";
import {contentFormatted} from "../util/string-util";

interface DatatableTableSummaryProps {
    summarize?: (string | null)[];
    data: any[];
    dataTypes: string[];
}

const DatatableTableSummary = React.memo(({summarize, data, dataTypes}: DatatableTableSummaryProps) => {

    const getDataType = (columnIndex: number) => {
        return (dataTypes ? dataTypes[columnIndex] : 'text');
    }

    const renderItem = (itemSummarize: string | null, index: number) => {
        if (itemSummarize) {
            let total = 0;
            for (let value of data) {
                total = total + parseFloat(value[itemSummarize]);

            }
            const type = getDataType(index);
            const content = contentFormatted(total.toString(), type);
            return (
                <TableCell key={index}>{content}</TableCell>
            )
        }
        return (
            <TableCell key={index}/>
        )
    }

    return (
        <TableFooter>
            <TableRow>
                {summarize?.map((itemSummarize, index) => renderItem(itemSummarize, index))}
            </TableRow>
        </TableFooter>
    )

})

export default DatatableTableSummary;