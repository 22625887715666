import {useDispatch} from 'react-redux'
import React, {useCallback, useEffect, useState} from "react";
import BalancesByAccount from "./balances-by-account";
import IncomeExpense from "./income-expense";
import BalancesPerMonth from "./balances-per-month";
import BillingExpenseProgression from "./billing-expense-progression";
import ActivitiesPerUser from "./activities-per-user";
import {Grid} from "@mui/material";
import Comissions from "./comissions";
import LowStockCategories from "./low-stock-categories";
import ProductsWithoutValue from "./products-without-value";
import InstagramRoi from "./instagram-roi";
import VendasPorTempo from "./vendas-por-tempo";
import {AuthService} from "../typescript/auth/auth.service";
import {changePageTitle} from "../typescript/redux/actions";
import {DateUtil} from "../typescript/util/date-util";
import {DashboardService} from "../typescript/dashboard/dashboard.service";

const FinancialVision = () => {
    const dispatch = useDispatch();
    const [dashboard, setDashboard] = useState(null);
    const [params, setParams] = useState({
        comission: {
            year: DateUtil.currentYear(),
            month: DateUtil.currentMonth()
        },
        productWithoutValue: {
            category: {
                id: 4,
                descricao: 'LENTES MULTIFOCAIS'
            }
        }
    });

    const findAll = useCallback(async () => {
        const result = await DashboardService.getFinancialVision(params);
        setDashboard(result);
    }, [params]);

    useEffect(() => {
        dispatch(changePageTitle("Visão financeira"));
        findAll().then();
    }, [dispatch, findAll]);

    const setComissions = (itemsToChange) => {
        setDashboard({
            ...dashboard,
            comissions: itemsToChange
        })
    }

    const setComissionYear = (event) => {
        setParams({
            ...params,
            comission: {
                year: event.target.value,
                month: params.comission.month
            }
        })
    }

    const setComissionMonth = (event) => {
        setParams({
            ...params,
            comission: {
                year: params.comission.year,
                month: event.target.value
            }
        })
    }

    const setProductsWithoutValue = (productWithoutValue) => {
        setParams({
            ...params,
            productWithoutValue: productWithoutValue
        })
    }

    const renderOnlyAdmin = () => {
        return AuthService.userInRole("ADMIN") && (
            <React.Fragment >
                <Grid container spacing={2}>
                    <Grid item md={6} xs={12}>
                        <BalancesByAccount balances={dashboard.contas}/>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <IncomeExpense incomeExpenses={dashboard.receitaDespesa}/>
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item md={6} xs={12}>
                        <BalancesPerMonth balances={dashboard.saldosPorMes}/>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <BillingExpenseProgression progression={dashboard.progressao}/>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }

    return dashboard && (
        <React.Fragment>
            {renderOnlyAdmin()}

            <ActivitiesPerUser activities={dashboard.atividades}/>
            <Comissions comissions={dashboard.comissions} setComissions={setComissions}
                        setComissionYear={setComissionYear} setComissionMonth={setComissionMonth}
                        params={params.comission}/>

            <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                    <LowStockCategories categories={dashboard.lowStockCategories}/>
                </Grid>
                <Grid item md={6} xs={12}>
                    <ProductsWithoutValue productsWithoutValue={dashboard.productsWithoutValue}
                                          params={params.productWithoutValue}
                                          setParams={setProductsWithoutValue}/>
                </Grid>
            </Grid>

            <InstagramRoi/>

            <VendasPorTempo/>

        </React.Fragment>
    )

}

export default React.memo(FinancialVision);