import React, {FormEvent, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import CustomButton from "../custom-button/custom-button";
import CustomButtonGroup from "../custom-button-group/custom-button-group";
import CustomRow from "../custom-row/custom-row";
import {changePageTitle, setAlert} from "../redux/actions";
import {DateUtil} from "../util/date-util";
import * as StringUtil from "../util/string-util";
import {FileUtil} from "../util/file-util";
import SelectOption from "../select-option/select-option";
import {RelatorioService} from "../relatorio/relatorio.service";
import {SelectChangeEvent} from "@mui/material/Select/SelectInput";

const Aniversariante = () => {
    const dispatch = useDispatch();
    const months = DateUtil.getAllMonthsEnum();
    const days = DateUtil.getAllDaysEnum();
    const [month, setMonth] = useState(1);
    const [day, setDay] = useState(0);

    useEffect(() => {
        dispatch(changePageTitle("Aniversariantes"));
    }, [dispatch]);

    const print = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        const dia = (day === 0 ? '' : day);
        RelatorioService.getAniversariantes(month, dia).then(function (result) {
            FileUtil.openPdfInNewTab(result);
        }, (result) => {
            const error = StringUtil.decodeUint8Array(result);
            dispatch(setAlert({show: true, message: error.message, severity: 'error'}));
        })
    }

    const onChangeMonth = (event: SelectChangeEvent<any>) => {
        setMonth(parseInt(event.target.value));
    }

    const onChangeDay = (event: SelectChangeEvent<any>) => {
        setDay(parseInt(event.target.value));
    }

    return (
        <form onSubmit={print}>
            <CustomRow>
                <SelectOption required fullWidth label="Mês" value={month} onChange={onChangeMonth}
                              items={months} itemValue='name' itemDescription='description'/>
                <SelectOption required fullWidth label="Dia" value={day} onChange={onChangeDay}
                              items={days} itemValue='name' itemDescription='description'/>
            </CustomRow>
            <CustomButtonGroup>
                <CustomButton color="primary" type="submit">Imprimir</CustomButton>
            </CustomButtonGroup>
        </form>
    );
}

export default React.memo(Aniversariante);