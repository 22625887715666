import {NeighborhoodService} from "../neighborhood/neighborhood.service";
import {CentroDeCustoService} from "../centro-de-custo/centro-de-custo.service";
import {DateUtil} from "../typescript/util/date-util";
import {AppService} from "../typescript/app/app.service";
import {PageResponse} from "../typescript/util/page-response";
import {Person} from "./person";
import {DadosAcessorios} from "../sale/dados-acessorios";
import {TotalSaleCloseByRange} from "./total-sale-close-by-range";

class PersonService {

    static API_URL = "/api/pessoas/v3";

    static findAll = (page?: number, size?: number, sort?: string, filter?: string, cliente?: boolean, fornecedor?: boolean): Promise<PageResponse<Person>> => {
        return AppService.getAuthorized(this.API_URL, {
            page: page,
            size: size,
            sort: sort,
            id: sort?.startsWith('id') ? filter : '',
            nome: sort?.startsWith('nome') ? filter : '',
            cliente: cliente,
            fornecedor: fornecedor
        }).then((result) => {
            return result.data;
        }).catch(() => {
            return {
                content: [],
                totalElements: 0
            };
        })
    };

    static findOne(id: number) {
        return AppService.getAuthorized(this.API_URL + "/" + id).then((result) => {
            return result.data;
        }).catch(() => {
            return null;
        })
    }

    static findOneFornecedor(id: boolean) {
        return AppService.getAuthorized(this.API_URL + "/" + id, {fornecedor: true}).then((result) => {
            return result.data;
        }).catch(() => {
            return null;
        })
    }

    static findOneCliente(id: number) {
        return AppService.getAuthorized(this.API_URL + "/" + id, {cliente: true}).then((result) => {
            return result.data;
        }).catch(() => {
            return null;
        })
    }

    static getTotalSaleClosedByRange(startDate: string, endDate: string): Promise<TotalSaleCloseByRange[]> {
        return AppService.getAuthorized(this.API_URL + "/totalSaleClosedByRange", {startDate: startDate, endDate: endDate}).then((result) => {
            return result.data;
        }).catch(() => {
            return [];
        })
    }

    static getAllDadosAcessorios() {
        return AppService.getAuthorized(this.API_URL + "/allDadosAcessorios").then((result) => {
            return result.data;
        }).catch(() => {
            return []
        })
    }

    static getAllSaleItemByPessoa(id: number) {
        return AppService.getAuthorized(this.API_URL + "/" + id + "/osItems").then((result) => {
            return result.data;
        }).catch(() => {
            return []
        })
    }

    static getAllCampaignsByPerson(id: number) {
        return AppService.getAuthorized(this.API_URL + "/" + id + "/campaigns").then((result) => {
            return result.data;
        }).catch(() => {
            return []
        })
    }

    static save(item: Person) {
        let itemToSave = item;
        if (itemToSave.centrodecusto && !itemToSave.centrodecusto.id) {
            itemToSave.centrodecusto = null;
        }
        if (itemToSave.cidade && !itemToSave.cidade.id) {
            itemToSave.cidade = null;
        }
        if (itemToSave.id) {
            return AppService.postAuthorized(this.API_URL + "/" + itemToSave.id, itemToSave);
        } else {
            return AppService.postAuthorized(this.API_URL, itemToSave);
        }
    }

    static atualizaDadosAcessorios(personId: number, dadosAcessorios: DadosAcessorios) {
        return AppService.postAuthorized(this.API_URL + "/" + personId + "/atualizaDadosAcessorios", dadosAcessorios);
    }

    static delete(id: number) {
        return AppService.deleteAuthorized(this.API_URL + "/" + id);
    }

    static createDadosAcessorios(): DadosAcessorios {
        return {
            influenciaCompras: [],
            decideCompras: [],
            efetuaCompras: [],
            estilos: [],
            preferencias: [],
            profissaos: [],
            conheceuLojas: [],
            temperamentos: []
        }
    }

    static createPerson(cliente: boolean, fornecedor: boolean) {
        const person: Person = {
            nome: '',
            created_date: DateUtil.toJavaLocalDateTime(new Date()),
            rua: '',
            numero: '',
            complemento: '',
            bairroCadastro: NeighborhoodService.createNeighborhood(),
            cep: '',
            dataNascimento: '',
            cpf: '',
            inscricaoEstadual: '',
            fone1: '',
            fone2: '',
            fone3: '',
            email: '',
            facebook: '',
            spc: false,
            cliente: cliente,
            fornecedor: fornecedor,
            observacao: '',
            centrodecusto: CentroDeCustoService.createCentroDeCusto(),
            influenciacompras: [],
            decidecompras: [],
            efetuacompras: [],
            estilos: [],
            preferencias: [],
            profissaos: [],
            conheceulojas: [],
            temperamentos: [],
            parentes: [],
            documentMaster: false,
            type: 'PHYSICAL',
            gender: 'MASCULINE'
        }
        return person;
    }

}

export {PersonService};
