import React from 'react';
import {Navigate, useLocation} from "react-router-dom";
import {AuthService} from "../auth/auth.service";
import {AppService} from "../app/app.service";

interface SecuredRouteProps {
    authority?: string;
    children: any[];
}

const SecuredRoute = ({authority, children}: SecuredRouteProps) => {
    let location = useLocation();

    if (!AuthService.isAuthenticated()) {
        let from = location.pathname || "/";
        AppService.setFrom(from);
        return <Navigate to="/login"/>
    }
    if (authority && !AuthService.getUserDetails()?.authorities?.includes(authority)) {
        return <Navigate to="/forbidden"/>
    }
    return (
        <div style={{margin: "24px"}}>
            {children}
        </div>
    )
}

export default SecuredRoute;