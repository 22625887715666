import React from "react";
import {TableCell, TableHead, TableRow} from "@mui/material";
import DatatableTableHeaderSort from "./datatable-table-header-sort";
import {DirectionEnum} from "../util/direction-enum";

const classes = {
    datatableTableHeader: {
        "& th": {
            fontWeight: 'bold'
        }
    }
};

interface DatatableTableHeaderProps {
    dataColumnNames: string[];
    sortIndex: number;
    sortDirection: DirectionEnum;
    setSortBy: (index: number) => void;
    hasRowOperations: boolean;
    sortColumns?: string[];
}

const DatatableTableHeader = React.memo(({
                                             dataColumnNames,
                                             sortIndex,
                                             sortDirection,
                                             setSortBy,
                                             hasRowOperations,
                                             sortColumns
                                         }: DatatableTableHeaderProps) => {

    const getActive = (index: number) => {
        return (sortIndex === index);
    }

    const inSortColumns = (item: string) => {
        if (sortColumns) {
            return sortColumns.includes(item);
        } else {
            return true;
        }
    }

    return (
        <TableHead sx={classes.datatableTableHeader}>
            <TableRow>
                {dataColumnNames.map((item, index) => (
                    (sortIndex === -1 || !inSortColumns(item)) ?
                        <TableCell key={index}>{item}</TableCell>
                        :
                        <DatatableTableHeaderSort key={index} item={item} active={getActive(index)}
                                                  direction={sortDirection} onClick={() => setSortBy(index)}/>
                ))}
                {hasRowOperations ? <TableCell/> : null}
            </TableRow>
        </TableHead>
    )

})

export default DatatableTableHeader;