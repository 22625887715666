export class NumberUtil {

    static currencyFormat(valor: number) {
        if (valor === null || valor === undefined) {
            valor = 0;
        }
        return valor.toLocaleString("pt-BR", {minimumFractionDigits: 2, style: 'currency', currency: 'BRL'});
    }

    static decimalFormat(valor: number) {
        if (valor === null || valor === undefined) {
            valor = 0;
        }
        return valor.toLocaleString("pt-BR", {minimumFractionDigits: 4, style: 'decimal'});
    }

    static percentualFormat(valor: number) {
        return NumberUtil.decimalFormat(valor) + "%";
    }

    static arredonda(valor: number, casasDecimais: number) {
        return parseFloat(valor.toFixed(casasDecimais));
    }

    static ajustaDecimal(valor: number) {
        const valorTemp = valor.toString().replace('.', '')
            .replace(',', '.');
        return parseFloat(valorTemp);
    }

    static subtraction(value1: number, value2: number) {
        return (value1 * 10 - value2 * 10) / 10;
    }

}

