import React, {useEffect} from 'react';
import DatatableTable from "./datatable-table";
import DatatableFilter from "./datatable-filter";
import DatatableHeader from "./datatable-header";
import PropTypes from 'prop-types';
import {HeaderOperation} from "./header-operation";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material";
import {RowOperation} from "./row-operation";
import PageParamsUtil from "../util/page-params-util";

interface DatatableProps {
    page?: number;
    size?: number;
    sort?: string;
    filter?: string;
    findAll?: (page?: number, size?: number, sort?: string, filter?: string) => void;
    dataColumnNames: string[];
    dataColumns: string[];
    dataTypes: string[];
    dataValues?: ((item: any, dataColumn: string, columnIndex: number) => any)[];
    editData?: (item: any, index: number) => void;
    headerOperations?: HeaderOperation[];
    maxHeight?: number
    rowClass?: (index: number) => SxProps<Theme>;
    rowOperations?: RowOperation[];
    sortColumns?: string[];
    summarize?: (string | null)[];
    data: any[];
    totalData: number
}

const areEqual = (prevProps: DatatableProps, nextProps: DatatableProps) => {
    return (prevProps.page === nextProps.page) &&
        (prevProps.size === nextProps.size) &&
        (prevProps.sort === nextProps.sort) &&
        (prevProps.filter === nextProps.filter) &&
        (JSON.stringify(prevProps.dataColumnNames) === JSON.stringify(nextProps.dataColumnNames)) &&
        (JSON.stringify(prevProps.dataColumns) === JSON.stringify(nextProps.dataColumns)) &&
        (JSON.stringify(prevProps.dataTypes) === JSON.stringify(nextProps.dataTypes)) &&
        (prevProps.dataValues === nextProps.dataValues) &&
        (prevProps.headerOperations === nextProps.headerOperations) &&
        (prevProps.maxHeight === nextProps.maxHeight) &&
        (prevProps.rowOperations === nextProps.rowOperations) &&
        (prevProps.sortColumns === nextProps.sortColumns) &&
        (prevProps.summarize === nextProps.summarize) &&
        (prevProps.data === nextProps.data) &&
        (prevProps.totalData === nextProps.totalData);
}

const Datatable = ({
                       page,
                       size,
                       sort,
                       filter,
                       findAll,
                       dataColumnNames,
                       dataColumns,
                       dataTypes,
                       dataValues,
                       editData,
                       headerOperations,
                       maxHeight,
                       rowClass,
                       rowOperations,
                       sortColumns,
                       summarize,
                       data,
                       totalData
                   }: DatatableProps) => {

    const [internalFilter, setInternalFilter] = React.useState(filter);

    useEffect(() => {
        setInternalFilter(filter);
    }, [filter])

    const doFilter = React.useCallback(() => {
        if (findAll) {
            findAll(0, size, sort, internalFilter);
        }
    }, [findAll, internalFilter, size, sort]);

    const setSort = React.useCallback((newSort: string, changedSortField: boolean) => {
        if (findAll) {
            const newFilter = changedSortField ? '' : filter;
            findAll(page, size, newSort, newFilter);
        }
    }, [findAll, filter, page, size]);

    const setPage = React.useCallback((newPage: number) => {
        if (findAll) {
            findAll(newPage, size, sort, filter);
        }
    }, [findAll, filter, size, sort]);

    const setSize = React.useCallback((newSize: number) => {
        if (findAll) {
            findAll(page, newSize, sort, filter);
        }
    }, [findAll, filter, page, sort]);

    const sortParams = PageParamsUtil.getSortParams(sort, dataColumns, dataTypes);

    const renderFilter = () => {
        return (internalFilter || internalFilter === '') &&
            <DatatableFilter doFilter={doFilter} filter={internalFilter} type={sortParams.type}
                             setFilter={setInternalFilter}/>
    }

    const renderHeader = () => {
        return headerOperations && <DatatableHeader headerOperations={headerOperations}/>
    }

    return (
        <React.Fragment>
            {renderHeader()}
            {renderFilter()}
            <DatatableTable dataColumnNames={dataColumnNames} dataColumns={dataColumns} page={page ? page : 0}
                            size={size ? size : -1} setSort={setSort} dataTypes={dataTypes} sort={sort} sortColumns={sortColumns}
                            totalData={totalData} setPage={setPage} setSize={setSize}
                            data={data} dataValues={dataValues} editData={editData} rowClass={rowClass}
                            rowOperations={rowOperations} summarize={summarize} maxHeight={maxHeight}/>
        </React.Fragment>
    )

}

Datatable.propTypes = {
    page: PropTypes.number,
    size: PropTypes.number,
    sort: PropTypes.string,
    filter: PropTypes.string,
    findAll: PropTypes.func,
    dataColumnNames: PropTypes.arrayOf(PropTypes.string).isRequired,
    dataColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
    dataTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
    dataValues: PropTypes.arrayOf(PropTypes.func),
    editData: PropTypes.func,
    headerOperations: PropTypes.arrayOf(PropTypes.object),
    maxHeight: PropTypes.number,
    rowClass: PropTypes.func,
    rowOperations: PropTypes.arrayOf(PropTypes.object),
    sortColumns: PropTypes.arrayOf(PropTypes.string),
    summarize: PropTypes.arrayOf(PropTypes.string),
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    totalData: PropTypes.number.isRequired
};

export default React.memo(Datatable, areEqual);