import React, {useCallback, useEffect, useState} from "react";
import {changePageTitle} from "../../typescript/redux/actions";
import {useDispatch} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {PersonCampaign} from "./person-campaign";
import {PersonService} from "../person.service";
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import ContactCell from "../../typescript/campaign/campaign-person/component/contact-cell";
import ReturnedCell from "../../typescript/campaign/campaign-person/component/returned-cell";
import InterestedCell from "../../typescript/campaign/campaign-person/component/interested-cell";
import AskedCell from "../../typescript/campaign/campaign-person/component/asked-cell";
import BoughtCell from "../../typescript/campaign/campaign-person/component/bought-cell";
import {CampaignPerson} from "../../typescript/campaign/campaign-person/campaign-person";
import CustomButtonGroup from "../../typescript/custom-button-group/custom-button-group";
import CustomButton from "../../typescript/custom-button/custom-button";

export function PersonCampaignList() {

    const dispatch = useDispatch();
    let navigate = useNavigate();
    const {person} = useParams();
    if (!person) {
        throw new Error('Person required');
    }
    const [personCampaigns, setPersonCampaigns] = useState<PersonCampaign[]>([]);

    const findAll = useCallback(async () => {
        const result = await PersonService.getAllCampaignsByPerson(parseInt(person));
        setPersonCampaigns(result);
    }, [person]);

    useEffect(() => {
        dispatch(changePageTitle("Campanhas da pessoa " + person));
        findAll().then();
    }, [person]); // eslint-disable-line react-hooks/exhaustive-deps

    const back = () => {
        navigate('/persons');
    }

    const newCampaignPerson = (personCampaign: PersonCampaign) => {
        const campaign: CampaignPerson = {
            id: 0,
            person: {
                id: 0,
                nome: '',
                fone1: '',
                dataNascimento: ''
            },
            min: 0,
            max: 0,
            contact: personCampaign.contact,
            returned: personCampaign.returned,
            interested: personCampaign.interested,
            asked: personCampaign.asked,
            bought: personCampaign.bought
        }
        return campaign;
    }

    return (
        <React.Fragment>
            <CustomButtonGroup>
                <CustomButton type="button" color="neutral" onClick={back}>Voltar</CustomButton>
            </CustomButtonGroup>
            <TableContainer component={Paper} sx={{marginBottom: '10px'}}>
                <Table size={"small"} stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>Campanha</TableCell>
                            <TableCell>Início</TableCell>
                            <TableCell>Fim</TableCell>
                            <TableCell>Tipo</TableCell>
                            <TableCell>Tipo contato</TableCell>
                            <TableCell>Vendedor</TableCell>
                            <TableCell>Categoria</TableCell>
                            <TableCell>Faixa</TableCell>
                            <TableCell align={"center"}>Data contato</TableCell>
                            <TableCell align={"center"}>Retornou</TableCell>
                            <TableCell align={"center"}>Demonstrou interesse</TableCell>
                            <TableCell align={"center"}>Pediu produtos</TableCell>
                            <TableCell align={"center"}>Comprou</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {personCampaigns.map((personCampaign, index) => (
                            <TableRow key={index}>
                                <TableCell>{personCampaign.campaign.description}</TableCell>
                                <TableCell>{personCampaign.campaign.start}</TableCell>
                                <TableCell>{personCampaign.campaign.end}</TableCell>
                                <TableCell>{personCampaign.campaign.campaignType.description}</TableCell>
                                <TableCell>{personCampaign.campaign.campaignContactType.description}</TableCell>
                                <TableCell>{personCampaign.campaign.user.username}</TableCell>
                                <TableCell>{personCampaign.campaign.category.descricao}</TableCell>
                                <TableCell>{personCampaign.range}</TableCell>
                                <ContactCell campaignPerson={newCampaignPerson(personCampaign)} disabled/>
                                <ReturnedCell campaignPerson={newCampaignPerson(personCampaign)} disabled/>
                                <InterestedCell campaignPerson={newCampaignPerson(personCampaign)} disabled/>
                                <AskedCell campaignPerson={newCampaignPerson(personCampaign)} disabled/>
                                <BoughtCell campaignPerson={newCampaignPerson(personCampaign)} disabled/>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </React.Fragment>
    )
}
