import {AppService} from "../app/app.service";
import {PageResponse} from "../util/page-response";
import {City} from "./city";
import {StateService} from "../state/state.service";

class CityService {

    static API_URL = "/api/cidades/v2";

    static findAll = (page?: number, size?: number, sort?: string, filter?: string): Promise<PageResponse<City>> => {
        return AppService.getAuthorized(this.API_URL, {
            page: page,
            size: size,
            sort: sort,
            id: sort?.startsWith('id') ? filter : '',
            descricao: sort?.startsWith('descricao') ? filter : ''
        }).then((result) => {
            return result.data;
        }).catch(() => {
            return {
                content: [],
                totalElements: 0
            };
        })
    };

    static findOne(id: number) {
        return AppService.getAuthorized(this.API_URL + "/" + id)
            .then((result) => {
                return result.data;
            }).catch(() => {
                return null;
            })
    }

    static save(item: City) {
        if (item.id) {
            return AppService.postAuthorized(this.API_URL + "/" + item.id, item);
        } else {
            return AppService.postAuthorized(this.API_URL, item);
        }
    }

    static delete(id: number) {
        return AppService.deleteAuthorized(this.API_URL + "/" + id);
    }

    static createCity() {
        const state = StateService.createState();
        const city: City = {
            descricao: '',
            codigoIbge: '',
            estado: state
        };
        return city;
    }
}

export {CityService};