import {AppService} from "../typescript/app/app.service";
import {Parameter} from "./parameter";

class ParameterService {

    static API_URL = "/api/parametros";

    static getAll = () => {
        return AppService.getAuthorized(this.API_URL).then((result) => {
            return result.data;
        }).catch(() => {
            return null;
        })
    };

    static save(parameter: Parameter) {
        return AppService.postAuthorized(this.API_URL, parameter);
    }

    static getClientePadrao() {
        return AppService.getAuthorized(this.API_URL + "/clientePadrao").then((result) => {
            return result.data;
        }).catch(() => {
            return null;
        })
    }
}

export {ParameterService};