import React from "react";
import {TableCell, TableFooter, TableRow} from "@mui/material";
import * as StringUtil from "../typescript/util/string-util";

const DatatableTableSummary = React.memo(({summarize, data, dataTypes}) => {

    const getDataType = (columnIndex) => {
        return (dataTypes ? dataTypes[columnIndex] : 'text');
    }

    const renderItem = (itemSummarize, index) => {
        if (itemSummarize) {
            let total = 0;
            for (let value of data) {
                total = total + value[itemSummarize];

            }
            const type = getDataType(index);
            const contentFormatted = StringUtil.contentFormatted(total, type);
            return (
                <TableCell key={index}>{contentFormatted}</TableCell>
            )
        }
        return (
            <TableCell key={index}/>
        )
    }

    return (
        <TableFooter>
            <TableRow>
                {summarize.map((itemSummarize, index) => renderItem(itemSummarize, index))}
            </TableRow>
        </TableFooter>
    )

})

export default DatatableTableSummary;