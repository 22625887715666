import {AppService} from "../typescript/app/app.service";

class StockService {

    static API_URL = "/api/stock";

    static resetStock() {
        return AppService.getAuthorized(this.API_URL + "/reset");
    }

    static getInventory() {
        return AppService.getAuthorized(this.API_URL + "/inventory", null, "arraybuffer");
    }
}

export {StockService};