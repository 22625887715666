import React, {useCallback, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {changePageTitle} from "../typescript/redux/actions";
import {Checkbox, FormControl, FormControlLabel} from "@mui/material";
import {
    ForeignKey,
    PlanoDeContasDetail,
} from "../index";
import {CentroDeCustoService} from "./centro-de-custo.service";
import CustomTextField from "../typescript/custom-text-field/custom-text-field";
import CustomButton from "../typescript/custom-button/custom-button";
import CustomButtonGroup from "../typescript/custom-button-group/custom-button-group";
import {PlanoDeContasService} from "../plano-de-contas/plano-de-contas.service";

export function CentroDeCustoDetail({id, onClose, dialog}) {

    const [item, setItem] = useState(CentroDeCustoService.createCentroDeCusto());
    const dispatch = useDispatch();

    const findOne = useCallback(async () => {
        const result = await CentroDeCustoService.findOne(id);
        setItem(result);
    }, [id]);

    useEffect(() => {
        if (!dialog){
            dispatch(changePageTitle(id ? "Tipo de gasto " + id : "Novo tipo de gasto"));
        }
        if (id) {
            findOne().then();
        }
    }, [dispatch, findOne, id, dialog]);

    const save = (event) => {
        event.preventDefault();
        event.stopPropagation();
        CentroDeCustoService.save(item).then((result) => {
            onClose(result.data);
        }).catch(() => {

        })
    }

    const cancel = () => {
        onClose();
    }

    const setDescricao = (event) => {
        setItem({
            ...item,
            descricao: event.target.value
        })
    }

    const setFixo = (event) => {
        setItem({
            ...item,
            fixo: event.target.checked
        })
    }

    const onChangePlanoDeContas = async (id) => {
        setItem({
            ...item,
            planoDeContas: {
                id: id
            }
        })
        if (id) {
            const planoDeContas = await PlanoDeContasService.findOne(id);
            if (planoDeContas) {
                setItem({
                    ...item,
                    planoDeContas: planoDeContas
                })
            }
        }
    }

    const onAddPlanoDeContas = (planoDeContas) => {
        if (planoDeContas) {
            setItem({
                ...item,
                planoDeContas: planoDeContas
            })
        }
    }

    const findPlanoDeContas = (page, size, sort, filter) => {
        return PlanoDeContasService.findAll(page, size, sort, filter);
    }

    return (
        <form onSubmit={save}>
            <CustomTextField fullWidth autoFocus required label="Descrição" value={item.descricao}
                             onChange={setDescricao}/>
            <ForeignKey fullWidth required label="Plano de contas" value={item.planoDeContas} fieldKey={'id'}
                        fieldDescription={'descricao'} labelDescription='Descrição' onChange={onChangePlanoDeContas}
                        onSelect={onAddPlanoDeContas} onAdd={onAddPlanoDeContas} findAll={findPlanoDeContas}
                        dataColumnNames={['ID', 'Descrição']} dataColumns={['id', 'descricao']}
                        dataTypes={['number', 'text']} addComponent={<PlanoDeContasDetail/>}
            />
            <FormControl fullWidth>
                <FormControlLabel control={<Checkbox checked={item.fixo} onChange={setFixo}/>} label="Fixo"/>
            </FormControl>
            <CustomButtonGroup>
                <CustomButton color="primary" type="submit">Salvar</CustomButton>
                <CustomButton type="button" color="neutral" onClick={cancel}>Cancelar</CustomButton>
            </CustomButtonGroup>
        </form>
    )
}