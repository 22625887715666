import React, {useState} from 'react';
import CustomTextField from "../../../custom-text-field/custom-text-field";

interface SaleListHeaderFilterEmissionStartDateProps {
    startDate: string | undefined;
}

const SaleListHeaderFilterEmissionStartDate = React.memo(React.forwardRef<HTMLInputElement, SaleListHeaderFilterEmissionStartDateProps>(({startDate}, ref) => {

        const [internalStartDate, setInternalStartDate] = useState<string | undefined>(startDate);

        const onChangeStartDate = (event: React.ChangeEvent<HTMLInputElement>) => {
            setInternalStartDate(event.target.value);
        }

        return (
            <CustomTextField fullWidth autoFocus required label="Emissão inicial" type="date" value={internalStartDate || ''}
                             InputLabelProps={{shrink: true}} onChange={onChangeStartDate} ref={ref}/>
        )

    }
));

export default SaleListHeaderFilterEmissionStartDate;
