import React, {useCallback, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {Delete as DeleteIcon, Search} from "@mui/icons-material";
import {Datatable} from "../index";
import {Grid, IconButton, Tooltip} from "@mui/material";
import {InstagramService} from "./instagram.service";
import {useNavigate} from "react-router-dom";
import CustomTextField from "../typescript/custom-text-field/custom-text-field";
import {changePageTitle, setAlert} from "../typescript/redux/actions";
import {DateUtil} from "../typescript/util/date-util";

const InstagramList = () => {

    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [startDate, setStartDate] = useState(DateUtil.firstDayOfCurrentMonthInJavaSqlDate());
    const [endDate, setEndDate] = useState(DateUtil.lastDayOfCurrentMonthInJavaSqlDate());
    const [items, setItems] = useState(null);

    const findAll = useCallback(async () => {
        if (startDate && endDate) {
            const result = await InstagramService.findAll(startDate, endDate);
            setItems(result);
        }
    }, [startDate, endDate]);

    useEffect(() => {
        dispatch(changePageTitle("Instagram"));
        findAll().then();
    }, [dispatch, findAll]);

    const editItem = (item) => {
        navigate('/instagram/' + item.id);
    }

    const insertItem = () => {
        navigate('/instagram');
    }

    const search = (event) => {
        event.preventDefault();
        event.stopPropagation();
        findAll().then();
    }

    const deleteItem = (item) => {
        InstagramService.delete(item.id).then(() => {
            findAll().then();
        }).catch(() => {
            dispatch(setAlert({show: true, message: 'Erro ao excluir instagram', severity: 'error'}));
        })
    }

    const headerOperations = [
        {color: () => "primary", onClick: insertItem, label: () => 'Inserir'}
    ]

    const rowOperations = [
        {color: () => "primary", onClick: deleteItem, label: () => 'Excluir', icon: () => <DeleteIcon/>}
    ]

    const changeStartDate = (event) => {
        setStartDate(event.target.value);
    }

    const changeEndDate = (event) => {
        setEndDate(event.target.value);
    }

    return items && (
        <React.Fragment>
            <form onSubmit={search}>
                <Grid container spacing={1}>
                    <Grid item md>
                        <Grid container spacing={1}>
                            <Grid item md xs={12}>
                                <CustomTextField fullWidth autoFocus required label="Data inicial" type="date"
                                                 value={startDate || ''} InputLabelProps={{shrink: true}}
                                                 onChange={changeStartDate}/>
                            </Grid>
                            <Grid item md xs={12}>
                                <CustomTextField fullWidth required label="Data final" type="date" value={endDate || ''}
                                                 InputLabelProps={{shrink: true}} onChange={changeEndDate}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Tooltip title="Pesquisar">
                            <IconButton type={"submit"}>
                                <Search/>
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </form>
            <Datatable
                dataColumnNames={['ID', 'Data', 'Stories', 'Reels', 'Feed', 'Status', 'Patrocinado', 'Investimento']}
                dataColumns={['id', 'date', 'stories', 'reels', 'feed', 'status', 'sponsored', 'investment']}
                dataTypes={['number', 'date', 'number', 'number', 'number', 'number', 'currency', 'currency']}
                sortColumns={[null, 'Data', null, null, null, null]}
                findAll={findAll} editData={editItem} data={items} totalData={items.length}
                headerOperations={headerOperations} rowOperations={rowOperations}/>
        </React.Fragment>
    );
}

export default React.memo(InstagramList);