import React, {FormEvent, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {setAlert} from "../../redux/actions";
import {CampaignService} from "./campaign.service";
import CustomButtonGroup from "../../custom-button-group/custom-button-group";
import CustomButton from "../../custom-button/custom-button";
import {useNavigate} from "react-router-dom";
import CampaignDetail from "./campaign-detail";
import {Campaign} from "./campaign";
import {GenerateCampaignRequestByBirthday} from "./generate-campaign-request-by-birthday";
import CustomTextField from "../../custom-text-field/custom-text-field";
import CustomRow from "../../custom-row/custom-row";

const CampaignGenerateByBirthday = () => {

    const dispatch = useDispatch();
    let navigate = useNavigate();

    const [request, setRequest] = useState<GenerateCampaignRequestByBirthday>(CampaignService.createCampaignRequestByBirthday());

    useEffect(() => {
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const generate = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        CampaignService.generateByBirthday(request).then(() => {
            navigate('/campaigns');
        }).catch((error) => {
            dispatch(setAlert({
                show: true,
                message: 'Erro ao salvar registro - ' + error.response.data.message,
                severity: 'error'
            }));
        })
    }

    const cancel = () => {
        navigate('/campaigns');
    }

    const setCampaign = (campaign: Campaign) => {
        setRequest({
            ...request,
            campaign: campaign
        })
    }

    const setStartBirthdayDay = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRequest({
            ...request,
            startBirthdayDay: Number(event.target.value)
        })
    }

    const setStartBirthdayMonth = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRequest({
            ...request,
            startBirthdayMonth: Number(event.target.value)
        })
    }

    const setEndBirthdayDay = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRequest({
            ...request,
            endBirthdayDay: Number(event.target.value)
        })
    }

    const setEndBirthdayMonth = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRequest({
            ...request,
            endBirthdayMonth: Number(event.target.value)
        })
    }

    const onChangeCreatedFrom = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRequest({
            ...request,
            createdFrom: event.target.value
        })
    }

    return (
        <form onSubmit={generate}>

            <CustomRow>
                <CustomTextField fullWidth autoFocus required label="Dia inicial" value={request.startBirthdayDay}
                                 type="number" onChange={setStartBirthdayDay}/>
                <CustomTextField fullWidth required label="Mês inicial" value={request.startBirthdayMonth} type="number"
                                 onChange={setStartBirthdayMonth}/>
                <CustomTextField fullWidth required label="Dia final" value={request.endBirthdayDay} type="number"
                                 onChange={setEndBirthdayDay}/>
                <CustomTextField fullWidth required label="Mês final" value={request.endBirthdayMonth} type="number"
                                 onChange={setEndBirthdayMonth}/>
                <CustomTextField fullWidth label="Cadastros criados a partir de" type="date" value={request.createdFrom}
                                 onChange={onChangeCreatedFrom} InputLabelProps={{shrink: true}} required/>
            </CustomRow>

            <CampaignDetail item={request.campaign} setItem={setCampaign}/>

            <CustomButtonGroup>
                <CustomButton color="primary" type="submit">Gerar</CustomButton>
                <CustomButton type="button" color="neutral" onClick={cancel}>Cancelar</CustomButton>
            </CustomButtonGroup>
        </form>
    )

}

export default React.memo(CampaignGenerateByBirthday);
