import React, {FormEvent, useEffect, useState} from "react";
import {PersonService} from "../../person/person.service";
import {useDispatch} from "react-redux";
import {setAlert} from "../redux/actions";
import {Dialog, DialogContent, DialogTitle} from "@mui/material";
import SelectOption from "../select-option/select-option";
import CustomButtonGroup from "../custom-button-group/custom-button-group";
import CustomButton from "../custom-button/custom-button";
import {InfluenciaCompra} from "../../influenciacompra/influencia-compra";
import {DecideCompra} from "../../decidecompra/decide-compra";
import {ConheceuLoja} from "../../conheceuloja/conheceu-loja";
import {SelectChangeEvent} from "@mui/material/Select/SelectInput";
import {Person} from "../../person/person";

interface PreencheDadosAcessoriosDialogProps {
    person: Person;
    onClose: (success: boolean) => void;
}

const PreencheDadosAcessoriosDialog = React.memo(({person, onClose}: PreencheDadosAcessoriosDialogProps) => {

    let personId: number;
    if (person.id) {
        personId = person.id;
    } else {
        throw new Error('Person id required');
    }

    const [dadosAcessorios, setDadosAcessorios] = React.useState(PersonService.createDadosAcessorios());
    const [influenciaCompras, setInfluenciaCompras] = useState<InfluenciaCompra[]>([]);
    const [decideCompras, setDecideCompras] = useState<DecideCompra[]>([]);
    const [conheceuLojas, setConheceuLojas] = useState<ConheceuLoja[]>([]);
    const dispatch = useDispatch();

    useEffect(() => {
        const getAllDadosAcessorios = async () => {
            const result = await PersonService.getAllDadosAcessorios();
            setDadosAcessorios(result);
        }
        getAllDadosAcessorios().then();
    }, []);

    const save = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        const request = {
            influenciaCompras: influenciaCompras,
            decideCompras: decideCompras,
            conheceuLojas: conheceuLojas
        };
        PersonService.atualizaDadosAcessorios(personId, request).then(function () {
            onClose(true);
        }, (error) => {
            dispatch(setAlert({show: true, message: error.data.message, severity: 'error'}));
        });
    }

    const onCloseDialog = (event: Object, reason: string) => {
        if (reason !== 'backdropClick') {
            onClose(false);
        }
    }

    const onChangeInfluenciaCompras = (event: SelectChangeEvent<number[]>) => {
        const itensSelecionados = event.target.value as number[];
        let influenciaComprasSelecionados: InfluenciaCompra[] = [];
        dadosAcessorios.influenciaCompras.forEach(function (influenciaCompra) {
            let id: number;
            if (influenciaCompra.id) {
                id = influenciaCompra.id;
            } else {
                throw new Error('Influenciacompra id required');
            }
            if (itensSelecionados.indexOf(id) > -1) {
                influenciaComprasSelecionados.push(influenciaCompra);
            }
        })
        setInfluenciaCompras(influenciaComprasSelecionados);
    }

    const onChangeDecideCompras = (event: SelectChangeEvent<number[]>) => {
        const itensSelecionados = event.target.value as number[];
        let decideComprasSelecionados: DecideCompra[] = [];
        dadosAcessorios.decideCompras.forEach(function (decideCompra) {
            let id: number;
            if (decideCompra.id) {
                id = decideCompra.id;
            } else {
                throw new Error('Decidecompra id required');
            }
            if (itensSelecionados.indexOf(id) > -1) {
                decideComprasSelecionados.push(decideCompra);
            }
        })
        setDecideCompras(decideComprasSelecionados);
    }

    const onChangeConheceuLojas = (event: SelectChangeEvent<number[]>) => {
        const itensSelecionados = event.target.value as number[];
        let conheceuLojasSelecionados: ConheceuLoja[] = [];
        dadosAcessorios.conheceuLojas.forEach(function (conheceuLoja) {
            let id: number;
            if (conheceuLoja.id) {
                id = conheceuLoja.id;
            } else {
                throw new Error('Conheceuloja id required');
            }
            if (itensSelecionados.indexOf(id) > -1) {
                conheceuLojasSelecionados.push(conheceuLoja);
            }
        })
        setConheceuLojas(conheceuLojasSelecionados);
    }

    const disabled = () => {
        return influenciaCompras.length === 0 || decideCompras.length === 0 || conheceuLojas.length === 0;
    }

    return (
        <Dialog fullWidth onClose={onCloseDialog} open={true} disableEscapeKeyDown>
            <DialogTitle>Dados acessórios - {person.nome}</DialogTitle>
            <DialogContent>
                <form onSubmit={save}>
                    <SelectOption multiple fullWidth label="Quem influencia a compra" value={influenciaCompras}
                                  onChange={onChangeInfluenciaCompras} items={dadosAcessorios.influenciaCompras}
                                  itemValue='id' itemDescription='descricao'/>
                    <SelectOption multiple fullWidth label="Quem decide a compra" value={decideCompras}
                                  onChange={onChangeDecideCompras} items={dadosAcessorios.decideCompras} itemValue='id'
                                  itemDescription='descricao'/>
                    <SelectOption multiple fullWidth label="Como conheceu a loja" value={conheceuLojas}
                                  onChange={onChangeConheceuLojas} items={dadosAcessorios.conheceuLojas} itemValue='id'
                                  itemDescription='descricao'/>
                    <CustomButtonGroup>
                        <CustomButton color="primary" type="submit" disabled={disabled()}>Salvar</CustomButton>
                    </CustomButtonGroup>
                </form>
            </DialogContent>
        </Dialog>
    )

});

export default PreencheDadosAcessoriosDialog;
