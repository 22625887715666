import React, {FormEvent, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {FormControl, FormControlLabel, FormLabel, Radio, RadioGroup} from "@mui/material";
import CustomButton from "../typescript/custom-button/custom-button";
import CustomButtonGroup from "../typescript/custom-button-group/custom-button-group";
import CustomRow from "../typescript/custom-row/custom-row";
import {changePageTitle, setAlert} from "../typescript/redux/actions";
import {FileUtil} from "../typescript/util/file-util";
import * as StringUtil from "../typescript/util/string-util";
import SelectOption from "../typescript/select-option/select-option";
import {RelatorioService} from "../typescript/relatorio/relatorio.service";
import {DateUtil} from "../typescript/util/2.0.0/date-util";

const PontoReport = () => {
    const [xlsx, setXlsx] = useState(true);
    const [ano, setAno] = useState(DateUtil.currentYear());
    const [mes, setMes] = useState(DateUtil.currentMonth());

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(changePageTitle("Relatório de ponto"));
    }, [dispatch]);

    const print = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        RelatorioService.getPonto(xlsx ? 'XLSX' : 'PDF', ano, mes).then(function (result) {
            FileUtil.saveAs(result);
        }, (result) => {
            const error = StringUtil.decodeUint8Array(result);
            dispatch(setAlert({show: true, message: error.message, severity: 'error'}));
        })
    }

    const onChangeXlsx = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setXlsx(value === 'true');
    }

    const anoOptions = DateUtil.startYears();
    const mesOptions = DateUtil.startMonths();

    return (
        <form onSubmit={print}>
            <CustomRow>
                <SelectOption fullWidth required label="Ano" value={ano}
                              onChange={(event) => setAno(event.target.value)}
                              items={anoOptions} itemValue='name' itemDescription='description'/>
                <SelectOption fullWidth required label="Mês" value={mes}
                              onChange={(event) => setMes(event.target.value)}
                              items={mesOptions} itemValue='name' itemDescription='description'/>
                <FormControl component="fieldset" required>
                    <FormLabel component="legend">Tipo</FormLabel>
                    <RadioGroup value={xlsx} onChange={onChangeXlsx}>
                        <FormControlLabel value={true} control={<Radio/>} label="XLSX"/>
                        <FormControlLabel value={false} control={<Radio/>} label="PDF"/>
                    </RadioGroup>
                </FormControl>
            </CustomRow>
            <CustomButtonGroup>
                <CustomButton color="primary" type="submit">Imprimir</CustomButton>
            </CustomButtonGroup>
        </form>
    );
}

export default React.memo(PontoReport);
