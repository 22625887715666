import React, {useCallback, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {Delete as DeleteIcon} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import {changePageTitle, setAlert} from "../redux/actions";
import Datatable from "../datatable/datatable";
import {ColorButtonEnum} from "../util/color-button-enum";
import {ColorIconEnum} from "../util/color-icon-enum";
import {StateService} from "./state.service";
import {State} from "./state";
import {PageParams} from "../util/page-params";

export function StateList() {

    let navigate = useNavigate();
    const [pageParams, setPageParams] = useState<PageParams>({
        number: 0,
        size: parseInt(process.env.REACT_APP_PAGE_SIZE || "0"),
        sort: "id,asc",
        filter: "",
        items: [],
        totalElements: 0
    });
    const dispatch = useDispatch();

    const findAll = useCallback(async (number?: number, size?: number, sort?: string, filter?: string) => {
        const result = await StateService.findAll(number, size, sort, filter);
        setPageParams({
            number: number,
            size: size,
            sort: sort,
            filter: filter,
            items: result.content,
            totalElements: result.totalElements
        });

    }, []);

    useEffect(() => {
        dispatch(changePageTitle("Estados"));
        findAll(pageParams.number, pageParams.size, pageParams.sort, pageParams.filter).then();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const editItem = (item: State) => {
        navigate('/state/' + item.id);
    }

    const insertItem = () => {
        navigate('/state');
    }

    const deleteItem = (item: State) => {
        if (item.id === undefined) {
            return;
        }
        StateService.delete(item.id).then(() => {
            findAll(pageParams.number, pageParams.size, pageParams.sort, pageParams.filter).then();
        }).catch((error) => {
            dispatch(setAlert({
                show: true,
                message: 'Erro ao excluir registro - ' + error.message,
                severity: 'error'
            }));
        })
    }

    const headerOperations = [{color: () => ColorButtonEnum.PRIMARY, onClick: insertItem, label: () => 'Inserir'}];
    const rowOperations = [{
        color: () => ColorIconEnum.PRIMARY,
        onClick: deleteItem,
        label: () => 'Excluir',
        icon: () => <DeleteIcon/>
    }];

    return pageParams && (
        <Datatable dataColumnNames={['ID', 'Descrição']} dataColumns={['id', 'descricao']}
                   page={pageParams.number} size={pageParams.size} sort={pageParams.sort} filter={pageParams.filter}
                   dataTypes={['number', 'text']} findAll={findAll} editData={editItem}
                   data={pageParams.items} totalData={pageParams.totalElements}
                   headerOperations={headerOperations} rowOperations={rowOperations}/>
    );
}