import React from "react";
import {
    Bar,
    BarChart,
    CartesianGrid,
    Cell,
    Label,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from "recharts";
import {blue, red} from "@mui/material/colors";
import {useNavigate} from "react-router-dom";
import {NumberUtil} from "../typescript/util/number-util";
import CustomPanel from "../typescript/custom-panel/custom-panel";

const BalancesByAccount = (props) => {
    const {balances} = props;
    let navigate = useNavigate();

    const fillColor = (data) => {
        return data.saldo > 0 ? blue["700"] : red["700"];
    }

    const formatter = (value) => {
        return NumberUtil.currencyFormat(value);
    }

    const onClickBar = (data) => {
        navigate('/financial/overview/wallet/' + data.id);
    }

    return (
        <CustomPanel title={"Saldo nas contas"}>
            <ResponsiveContainer width={"100%"} height={300}>
                <BarChart data={balances.carteiras} margin={{top: 10, right: 10, left: 20, bottom: 30}}>
                    <CartesianGrid/>
                    <XAxis dataKey="descricao">
                        <Label value={"Conta"} position="bottom"/>
                    </XAxis>
                    <YAxis>
                        <Label value={"Saldo"} position="left" angle={-90}/>
                    </YAxis>
                    <Tooltip formatter={formatter}/>
                    <Bar dataKey="saldo" onClick={onClickBar} background={{fill: "#f5f5f5"}} name={"Saldo"}>
                        {balances.carteiras.map((entry, index) => (
                            <Cell cursor="pointer" key={`cell-${index}`} fill={fillColor(entry)}/>
                        ))}
                    </Bar>
                    <ReferenceLine y={0} stroke="#000"/>
                </BarChart>
            </ResponsiveContainer>
        </CustomPanel>
    )

}

export default React.memo(BalancesByAccount);