import {PageResponse} from "../../util/page-response";
import {AppService} from "../../app/app.service";
import {Campaign} from "./campaign";
import {CampaignTypeService} from "../campaign-type/campaign-type.service";
import {CampaignContactTypeService} from "../campaign-contact/campaign-contact-type.service";
import {CategoryService} from "../../../category/category.service";
import {UserService} from "../../../user/user.service";
import {NumberUtil} from "../../util/number-util";
import {GenerateCampaignRequestByValue} from "./generate-campaign-request-by-value";
import {GenerateCampaignRequestByBirthday} from "./generate-campaign-request-by-birthday";
import {GenerateCampaignRequestByRest} from "./generate-campaign-request-by-rest";
import {DateUtil} from "../../util/2.0.0/date-util";
import {AxiosResponse} from "axios";
import {CampaignWithPerson} from "../campaign-person/campaign-with-person";

class CampaignService {

    static API_URL = "/api/campaigns";

    static findAll = async (page?: number, size?: number, sort?: string, filter?: string): Promise<PageResponse<Campaign>> => {
        return AppService.getAuthorized(this.API_URL, {
            page: page,
            size: size,
            sort: sort,
            id: sort?.startsWith('id') ? filter : '',
            description: sort?.startsWith('description') ? filter : ''
        }).then((result: AxiosResponse<PageResponse<Campaign>>) => {
            return result.data;
        }).catch(() => {
            return {
                content: [],
                totalElements: 0
            };
        })
    };

    static findOne = async (id: number): Promise<Campaign> => {
        return AppService.getAuthorized(this.API_URL + "/" + id)
            .then((result: AxiosResponse<Campaign>) => {
                return result.data;
            });
    }

    static findPersons = async (personId: number): Promise<CampaignWithPerson> => {
        return AppService.getAuthorized(this.API_URL + "/" + personId + "/persons")
            .then((result: AxiosResponse<CampaignWithPerson>) => {
                return result.data;
            });
    }

    static save(item: Campaign) {
        if (item.id) {
            return AppService.postAuthorized(this.API_URL + "/" + item.id, item);
        } else {
            return AppService.postAuthorized(this.API_URL, item);
        }
    }

    static generateByValue(request: GenerateCampaignRequestByValue) {
        return AppService.postAuthorized(this.API_URL + "/generate", request);
    }

    static generateByBirthday(request: GenerateCampaignRequestByBirthday) {
        return AppService.postAuthorized(this.API_URL + "/generateByBirthday", request);
    }

    static generateByRest(request: GenerateCampaignRequestByRest) {
        return AppService.postAuthorized(this.API_URL + "/generateByRest", request);
    }

    static delete(id: number) {
        return AppService.deleteAuthorized(this.API_URL + "/" + id);
    }

    static createCampaign = () => {
        const campaign: Campaign = {
            description: '',
            campaignType: CampaignTypeService.createCampaignType(),
            campaignContactType: CampaignContactTypeService.createCampaignContactType(),
            category: CategoryService.createCategory(),
            user: UserService.createUser(),
            start: '',
            end: ''
        }
        return campaign;
    }

    static createCampaignRequestByValue = () => {
        const campaignRequest: GenerateCampaignRequestByValue = {
            startCloseSaleDate: '2021-01-01',
            endCloseSaleDate: '2021-12-31',
            campaign: this.createCampaign(),
            values: []
        }
        return campaignRequest;
    }

    static createCampaignRequestByBirthday = () => {
        const campaignRequest: GenerateCampaignRequestByBirthday = {
            startBirthdayDay: '',
            startBirthdayMonth: '',
            endBirthdayDay: '',
            endBirthdayMonth: '',
            createdFrom: '2021-01-01',
            campaign: this.createCampaign()
        }
        return campaignRequest;
    }

    static createCampaignRequestByRest = () => {
        const campaignRequest: GenerateCampaignRequestByRest = {
            start: DateUtil.firstDayOfCurrentMonthInJavaSqlDate(),
            end: DateUtil.lastDayOfCurrentMonthInJavaSqlDate(),
            createdFrom: '2021-01-01',
            campaign: this.createCampaign()
        }
        return campaignRequest;
    }

    static range = (min: number | null, max: number | null) => {
        if (!min) {
            return "Até " + NumberUtil.currencyFormat(Number(max));
        }
        if (!max) {
            return "Mais de " + NumberUtil.currencyFormat(Number(min));
        }
        return "Entre " + NumberUtil.currencyFormat(Number(min)) + " e " + NumberUtil.currencyFormat(Number(max));
    }

}

export {CampaignService}
