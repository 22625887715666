import React from "react";
import CustomTextField from "../../custom-text-field/custom-text-field";
import {CampaignTypeService} from "../campaign-type/campaign-type.service";
import {CampaignType} from "../campaign-type/campaign-type";
import ForeignKey from "../../foreign-key/foreign-key";
import {CampaignContactType} from "../campaign-contact/campaign-contact-type";
import {CampaignContactTypeService} from "../campaign-contact/campaign-contact-type.service";
import {User} from "../../../user/user";
import {UserService} from "../../../user/user.service";
import {CategoryService} from "../../../category/category.service";
import {Category} from "../../../category/category";
import CustomRow from "../../custom-row/custom-row";
import {Campaign} from "./campaign";

interface CampaignDetailProps {
    item: Campaign;
    setItem?: (campaign: Campaign) => void;
}

const CampaignDetail = ({item, setItem}: CampaignDetailProps) => {

    const disabled = !setItem;

    const setDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (setItem) {
            setItem({
                ...item,
                description: event.target.value
            })
        }
    }

    const onChangeCampaignType = async (campaignTypeId: number) => {
        if (setItem) {
            setItem({
                ...item,
                campaignType: {
                    id: campaignTypeId
                }
            })
            if (campaignTypeId) {
                const campaignType = await CampaignTypeService.findOne(campaignTypeId);
                onSelectCampaignType(campaignType);
            }
        }
    }

    const onSelectCampaignType = (campaignType: CampaignType) => {
        if (campaignType && setItem) {
            setItem({
                ...item,
                campaignType: campaignType
            })
        }
    }

    const onChangeCampaignContactType = async (campaignContactTypeId: number) => {
        if (setItem) {
            setItem({
                ...item,
                campaignContactType: {
                    id: campaignContactTypeId
                }
            })
            if (campaignContactTypeId) {
                const campaignContactType = await CampaignContactTypeService.findOne(campaignContactTypeId);
                onSelectCampaignContactType(campaignContactType);
            }
        }
    }

    const onSelectCampaignContactType = (campaignContactType: CampaignContactType) => {
        if (campaignContactType && setItem) {
            setItem({
                ...item,
                campaignContactType: campaignContactType
            })
        }
    }

    const onChangeUser = async (userId: number) => {
        if (setItem) {
            setItem({
                ...item,
                user: {
                    id: userId
                }
            })
            if (userId) {
                const user = await UserService.findOne(userId);
                onSelectUser(user);
            }
        }
    }

    const onSelectUser = (user: User) => {
        if (user && setItem) {
            setItem({
                ...item,
                user: user
            })
        }
    }

    const onChangeCategory = async (categoryId: number) => {
        if (setItem) {
            setItem({
                ...item,
                category: {
                    id: categoryId
                }
            })
            if (categoryId) {
                const category = await CategoryService.findOne(categoryId);
                onSelectCategory(category);
            }
        }
    }

    const onSelectCategory = (category: Category) => {
        if (category && setItem) {
            setItem({
                ...item,
                category: category
            })
        }
    }

    const onChangeStart = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (setItem) {
            setItem({
                ...item,
                start: event.target.value
            })
        }
    }

    const onChangeEnd = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (setItem) {
            setItem({
                ...item,
                end: event.target.value
            })
        }
    }

    return (
        <React.Fragment>
            <CustomRow>
                <CustomTextField fullWidth required label="Descrição" value={item.description}
                                 onChange={setDescription} disabled={disabled}/>
                <CustomTextField fullWidth label="Início" type="date" value={item.start} onChange={onChangeStart}
                                 InputLabelProps={{shrink: true}} required disabled={disabled}/>
                <CustomTextField fullWidth label="Fim" type="date" value={item.end} onChange={onChangeEnd}
                                 InputLabelProps={{shrink: true}} required disabled={disabled}/>
            </CustomRow>
            <CustomRow>
                <ForeignKey fullWidth required label="Tipo" value={item.campaignType} fieldKey={'id'}
                            fieldDescription={'description'} labelDescription='Descrição'
                            onChange={onChangeCampaignType} disabled={disabled}
                            onSelect={onSelectCampaignType} findAll={CampaignTypeService.findAll}
                            dataColumnNames={['ID', 'Descrição']} dataColumns={['id', 'description']}
                            dataTypes={['number', 'text']}/>
                <ForeignKey fullWidth required label="Tipo de contato" value={item.campaignContactType} fieldKey={'id'}
                            fieldDescription={'description'} labelDescription='Descrição'
                            onChange={onChangeCampaignContactType} disabled={disabled}
                            onSelect={onSelectCampaignContactType} findAll={CampaignContactTypeService.findAll}
                            dataColumnNames={['ID', 'Descrição']} dataColumns={['id', 'description']}
                            dataTypes={['number', 'text']}/>
            </CustomRow>
            <CustomRow>
                <ForeignKey fullWidth required label="Vendedor" value={item.user} fieldKey={'id'}
                            fieldDescription={'username'} labelDescription='Username' onChange={onChangeUser}
                            onSelect={onSelectUser} findAll={UserService.findAll} disabled={disabled}
                            dataColumnNames={['ID', 'Username']} dataColumns={['id', 'username']}
                            dataTypes={['number', 'text']}/>
                <ForeignKey fullWidth required label="Categoria" value={item.category} fieldKey={'id'}
                            fieldDescription={'descricao'} labelDescription='Descrição' onChange={onChangeCategory}
                            onSelect={onSelectCategory} findAll={CategoryService.findAll} disabled={disabled}
                            dataColumnNames={['ID', 'Descrição']} dataColumns={['id', 'descricao']}
                            dataTypes={['number', 'text']}/>
            </CustomRow>
        </React.Fragment>
    )

}

export default React.memo(CampaignDetail);