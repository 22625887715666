import React, {useEffect} from 'react';
import DatatableTable from "./datatable-table";
import DatatableFilter from "./datatable-filter";
import DatatableHeader from "./datatable-header";
import PropTypes from 'prop-types';
import PageParamsUtil from "../typescript/util/page-params-util";

const areEqual = (prevProps, nextProps) => {
    return (prevProps.number === nextProps.number) &&
        (prevProps.size === nextProps.size) &&
        (prevProps.sort === nextProps.sort) &&
        (prevProps.filter === nextProps.filter) &&
        (JSON.stringify(prevProps.dataColumnNames) === JSON.stringify(nextProps.dataColumnNames)) &&
        (JSON.stringify(prevProps.dataColumns) === JSON.stringify(nextProps.dataColumns)) &&
        (JSON.stringify(prevProps.dataTypes) === JSON.stringify(nextProps.dataTypes)) &&
        (prevProps.dataValues === nextProps.dataValues) &&
        (prevProps.headerOperations === nextProps.headerOperations) &&
        (prevProps.maxHeight === nextProps.maxHeight) &&
        (prevProps.rowOperations === nextProps.rowOperations) &&
        (prevProps.sortColumns === nextProps.sortColumns) &&
        (prevProps.summarize === nextProps.summarize) &&
        (prevProps.data === nextProps.data) &&
        (prevProps.totalData === nextProps.totalData);
}

/**
 * @deprecated
 */
const Datatable = React.memo(({
                                  number,
                                  size,
                                  sort,
                                  filter,
                                  findAll,
                                  dataColumnNames,
                                  dataColumns,
                                  dataTypes,
                                  dataValues,
                                  editData,
                                  headerOperations,
                                  maxHeight,
                                  rowClass,
                                  rowOperations,
                                  sortColumns,
                                  summarize,
                                  data,
                                  totalData
                              }) => {

    const [internalFilter, setInternalFilter] = React.useState(filter);

    useEffect(() => {
        setInternalFilter(filter);
    }, [filter])

    const doFilter = React.useCallback(() => {
        findAll(0, size, sort, internalFilter);
    }, [findAll, internalFilter, size, sort]);

    const setSort = React.useCallback((newSort, changedSortField) => {
        const newFilter = changedSortField ? '' : filter;
        findAll(number, size, newSort, newFilter);
    }, [findAll, filter, number, size]);

    const setNumber = React.useCallback((newNumber) => {
        findAll(newNumber, size, sort, filter);
    }, [findAll, filter, size, sort]);

    const setSize = React.useCallback((newSize) => {
        findAll(number, newSize, sort, filter);
    }, [findAll, filter, number, sort]);

    const sortParams = PageParamsUtil.getSortParams(sort, dataColumns, dataTypes);

    const renderFilter = () => {
        return (filter || filter === '') &&
            <DatatableFilter doFilter={doFilter} filter={internalFilter} type={sortParams.type}
                             setFilter={setInternalFilter}/>
    }

    const renderHeader = () => {
        return headerOperations && <DatatableHeader headerOperations={headerOperations}/>
    }

    return (
        <React.Fragment>
            {renderHeader()}
            {renderFilter()}
            <DatatableTable dataColumnNames={dataColumnNames} dataColumns={dataColumns} number={number}
                            size={size} setSort={setSort} dataTypes={dataTypes} sort={sort} sortColumns={sortColumns}
                            totalData={totalData} setNumber={setNumber} setSize={setSize}
                            data={data} dataValues={dataValues} editData={editData} rowClass={rowClass}
                            rowOperations={rowOperations} summarize={summarize} maxHeight={maxHeight}/>
        </React.Fragment>
    )

}, areEqual)

Datatable.propTypes = {
    number: PropTypes.number,
    size: PropTypes.number,
    sort: PropTypes.string,
    filter: PropTypes.string,
    findAll: PropTypes.func,
    dataColumnNames: PropTypes.arrayOf(PropTypes.string).isRequired,
    dataColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
    dataTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
    dataValues: PropTypes.arrayOf(PropTypes.func),
    editData: PropTypes.func,
    headerOperations: PropTypes.arrayOf(PropTypes.object),
    maxHeight: PropTypes.number,
    rowClass: PropTypes.func,
    rowOperations: PropTypes.arrayOf(PropTypes.object),
    sortColumns: PropTypes.arrayOf(PropTypes.string),
    summarize: PropTypes.arrayOf(PropTypes.string),
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    totalData: PropTypes.number.isRequired
};

export default Datatable;
