import React from 'react';
import {useNavigate, useParams} from "react-router-dom";
import CampaignContactTypeDetail from "./campaign-contact-type-detail";

const CampaignContactTypeDetailPage = () => {

    const {id} = useParams();
    let navigate = useNavigate();

    const onClose = () => {
        navigate('/campaigncontacttypes');
    }

    return (
        <CampaignContactTypeDetail id={id ? parseInt(id) : null} onClose={onClose}/>
    )

};

export default React.memo(CampaignContactTypeDetailPage);