import React from 'react';
import SelectDataOption from "../select-data-option/select-data-option";
import {SelectChangeEvent} from "@mui/material/Select/SelectInput";
import {Brand} from "../brand/Brand";

interface BandeiraOptionProps {
    bandeiras: Brand[];
    bandeiraSelecionada: Brand | null;
    changeBandeira: (bandeiraSelecionada: Brand | null) => void;
}

const BandeiraOption = ({bandeiras, bandeiraSelecionada, changeBandeira}: BandeiraOptionProps) => {

    const getBandeiraSelecionada = (idSelecionado: number) => {
        let bandeiraSelecionada = null;
        for (let i = 0; i < bandeiras.length; i++) {
            if (bandeiras[i].id === idSelecionado) {
                bandeiraSelecionada = bandeiras[i];
                break;
            }
        }
        return bandeiraSelecionada;
    }

    const onChange = (event: SelectChangeEvent<any>) => {
        const idSelecionado = event.target.value;
        let bandeiraSelecionada = getBandeiraSelecionada(idSelecionado);
        changeBandeira(bandeiraSelecionada);
    }

    return (
        <SelectDataOption fullWidth required label="Bandeira do cartão" onChange={onChange} items={bandeiras}
                          value={bandeiraSelecionada} valueIndex='id' valueDescription='descricao'/>
    )

}

export default React.memo(BandeiraOption);
