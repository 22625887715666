import React, {useState} from "react";
import ParameterDetail from "./parameter-detail";
import {Paper, Tab, Tabs} from "@mui/material";
import DigitalCertificate from "./digital-certificate";

const classes = {
    tabBar: {
        marginBottom: '8px'
    }
};

const ParameterList = () => {
    const [tab, setTab] = useState(0);

    const onChangeTab = (event, newValue) => {
        setTab(newValue);
    };

    const renderParameters = () => {
        return tab === 0 && (
            <ParameterDetail/>
        )
    }

    const renderDigitalCertificate = () => {
        return tab === 1 && (
            <DigitalCertificate/>
        )
    }

    return (
        <React.Fragment>
            <Paper square sx={classes.tabBar}>
                <Tabs value={tab} indicatorColor="primary" textColor="primary" onChange={onChangeTab}
                      variant={"scrollable"} scrollButtons={"auto"}>
                    <Tab label="Parâmetros"/>
                    <Tab label="Certificado digital"/>
                </Tabs>
            </Paper>
            {renderParameters()}
            {renderDigitalCertificate()}
        </React.Fragment>
    );
}

export default React.memo(ParameterList);