import {useDispatch} from "react-redux";
import React, {useCallback, useEffect, useState} from "react";
import {changePageTitle} from "../typescript/redux/actions";
import {Delete as DeleteIcon} from "@mui/icons-material";
import {Datatable} from "../index";
import {UserGoalService} from "./user-goal.service";
import {useNavigate} from "react-router-dom";

export function UserGoalList() {

    let navigate = useNavigate();
    const [pageParams, setPageParams] = useState(null);
    const dispatch = useDispatch();

    const findAll = useCallback(async (number, size, sort, filter) => {
        const items = await UserGoalService.findAll(number, size, sort, filter);
        setPageParams({
            number: number,
            size: size,
            sort: sort,
            filter: filter,
            items: items
        });
    }, []);

    useEffect(() => {
        dispatch(changePageTitle("Metas dos usuários"));
        findAll(0, parseInt(process.env.REACT_APP_PAGE_SIZE), "vendedor,asc", '').then();
    }, [dispatch, findAll]);

    const editItem = (item) => {
        navigate('/usergoal/' + item.id);
    }

    const insertItem = () => {
        navigate('/usergoal');
    }

    const deleteItem = (item) => {
        UserGoalService.delete(item.id).then(() => {
            findAll(pageParams.number, pageParams.size, pageParams.sort, pageParams.filter).then();
        }).catch(() => {

        })
    }

    const headerOperations = [
        {color: () => "primary", onClick: insertItem, label: () => 'Inserir'}
    ]

    const rowOperations = [
        {color: () => "primary", onClick: deleteItem, label: () => 'Excluir', icon: () => <DeleteIcon/>},
    ]

    return pageParams && (
        <Datatable data={pageParams.items.content} totalData={pageParams.items.totalElements}
                   dataColumnNames={['Vendedor', 'Ano', 'Mês', 'Meta mínima', 'Meta básica', 'Meta master', 'Realizado', 'Diferença mínima', 'Diferença básica', 'Diferença master']}
                   dataColumns={['vendedor', 'ano', 'mes', 'metaMinima', 'metaBasica', 'metaMaster', 'realizado', 'diferencaMinima', 'diferencaBasica', 'diferencaMaster']}
                   dataTypes={['text', 'integer', 'integer', 'currency', 'currency', 'currency', 'currency', 'currency', 'currency', 'currency']}
                   sortColumns={['Vendedor', 'Ano', 'Mês', 'Meta mínima', 'Meta básica', 'Meta master']}
                   number={pageParams.number} size={pageParams.size} sort={pageParams.sort} filter={pageParams.filter}
                   findAll={findAll}
                   editData={editItem} headerOperations={headerOperations} rowOperations={rowOperations}/>
    );

}