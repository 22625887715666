import React from "react";
import {Sale} from "../../../../../sale/sale";
import {TableCell, TableRow} from "@mui/material";
import {DateUtil} from "../../../../util/2.0.0/date-util";
import {NumberUtil} from "../../../../util/number-util";
import theme from "../../../../theme/theme";
import SaleListBodyTableContentRowStatus2 from "./sale-list-body-table-content-row-status2";
import SaleListBodyTableContentRowOperations from "./sale-list-body-table-content-row-operations";
import {useNavigate} from "react-router-dom";

interface SaleListBodyTableContentRowProps {
    sale: Sale;
    findAll: () => void;
}

const areEqual = (prevProps: SaleListBodyTableContentRowProps, nextProps: SaleListBodyTableContentRowProps) => {
    return JSON.stringify(prevProps.sale) === JSON.stringify(nextProps.sale);
}

const SaleListBodyTableContentRow = React.memo(({sale, findAll}: SaleListBodyTableContentRowProps) => {

    let userId: number;
    if (sale.vendedor?.id) {
        userId = sale.vendedor?.id;
    } else {
        throw new Error('User id required');
    }

    let navigate = useNavigate();
    const rowClass = sale.dataHoraFechamento ? theme.tableRowInactive : null;

    const editItem = () => {
        navigate('/sale/' + sale.id);
    }

    return (
        <TableRow sx={rowClass}>
            <TableCell sx={theme.tableEdit} onClick={editItem}>{sale.id}</TableCell>
            <TableCell sx={theme.tableEdit} onClick={editItem}>{sale.pessoa?.nome}</TableCell>
            <TableCell sx={theme.tableEdit}
                       onClick={editItem}>{sale.createdDate ? DateUtil.fromZonedDateTime(sale.createdDate) : null}</TableCell>
            <TableCell sx={theme.tableEdit}
                       onClick={editItem}>{sale.valorTotal ? NumberUtil.currencyFormat(sale.valorTotal) : null}</TableCell>
            {/*<TableCell sx={theme.tableEdit} onClick={editItem}>{sale.laboratorio?.description}</TableCell>*/}
            <TableCell sx={theme.tableEdit}
                       onClick={editItem}>{sale.previsaoEntrega ? DateUtil.javaSqlDateToDateToLocaleDateString(sale.previsaoEntrega) : null}</TableCell>
            <TableCell sx={theme.tableEdit} onClick={editItem}>{sale.vendedor?.username}</TableCell>
            <TableCell sx={theme.tableEdit}
                       onClick={editItem}>{sale.dataHoraFechamento ? DateUtil.fromZonedDateTime(sale.dataHoraFechamento) : null}</TableCell>
            <TableCell>
                <SaleListBodyTableContentRowStatus2 sale={sale} userId={userId} findAll={findAll}/>
            </TableCell>
            <TableCell>
                <SaleListBodyTableContentRowOperations sale={sale} findAll={findAll}/>
            </TableCell>
        </TableRow>
    );

}, areEqual);

export default SaleListBodyTableContentRow;
