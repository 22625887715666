import React from "react";
import {NumberUtil} from "../typescript/util/number-util";
import CustomPanel from "../typescript/custom-panel/custom-panel";
import CustomAreaChart from "../typescript/custom-area-chart/custom-area-chart";

const BalancesPerMonth = (props) => {
    const {balances} = props;

    const formatter = (value) => {
        return NumberUtil.currencyFormat(value);
    }

    return (
        <CustomPanel title={"Saldo geral acumulado"}>
            <CustomAreaChart data={balances} dataKeyXAxis={"mes"} formatter={formatter} name={"Saldo"} dataKey={"saldo"}
                             labelX={"Mês"} labelY={"Saldo"}/>
        </CustomPanel>
    )

}

export default React.memo(BalancesPerMonth);