import React, {FormEvent, useCallback, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {setAlert} from "../../redux/actions";
import {CampaignService} from "./campaign.service";
import CustomButtonGroup from "../../custom-button-group/custom-button-group";
import CustomButton from "../../custom-button/custom-button";
import {useNavigate} from "react-router-dom";
import {PersonService} from "../../../person/person.service";
import {
    Checkbox,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip
} from "@mui/material";
import {TotalSaleCloseByRange} from "../../../person/total-sale-close-by-range";
import CustomTextField from "../../custom-text-field/custom-text-field";
import {Search} from "@mui/icons-material";
import CustomRow from "../../custom-row/custom-row";
import CampaignDetail from "./campaign-detail";
import {Campaign} from "./campaign";
import {GenerateCampaignRequestByValue} from "./generate-campaign-request-by-value";

const CampaignGenerateByValue = () => {

    const dispatch = useDispatch();
    let navigate = useNavigate();

    const [request, setRequest] = useState<GenerateCampaignRequestByValue>(CampaignService.createCampaignRequestByValue());
    const [items, setItems] = useState<TotalSaleCloseByRange[]>([]);

    const findAll = useCallback(async (startCloseSaleDate: string, endCloseSaleDate: string) => {
        const result = await PersonService.getTotalSaleClosedByRange(startCloseSaleDate, endCloseSaleDate);
        setItems(result);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        findAll(request.startCloseSaleDate, request.endCloseSaleDate).then();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const generate = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        if (!items.some(hasGenerate)) {
            return
        }

        request.values = items.filter(hasGenerate);

        CampaignService.generateByValue(request).then(() => {
            navigate('/campaigns');
        }).catch((error) => {
            dispatch(setAlert({
                show: true,
                message: 'Erro ao salvar registro - ' + error.response.data.message,
                severity: 'error'
            }));
        })
    }

    const cancel = () => {
        navigate('/campaigns');
    }

    const onChangeGenerate = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const newItems = items.map((item, i) => {
            if (i === index) {
                return {...item, generate: event.target.checked};
            }
            return item;
        });
        setItems(newItems);
    }

    const onChangeStart = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRequest({
            ...request,
            startCloseSaleDate: event.target.value
        })
    }

    const onChangeEnd = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRequest({
            ...request,
            endCloseSaleDate: event.target.value
        })
    }

    const search = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        findAll(request.startCloseSaleDate, request.endCloseSaleDate).then();
    };

    const hasGenerate = (element: TotalSaleCloseByRange) => {
        return element.generate;
    }

    const setCampaign = (campaign: Campaign) => {
        setRequest({
            ...request,
            campaign: campaign
        })
    }

    const renderCampaignDetail = () => {
        return items.some(hasGenerate) && (
            <CampaignDetail item={request.campaign} setItem={setCampaign}/>
        )
    }

    return (
        <React.Fragment>
            <form onSubmit={search}>
                <CustomRow>
                    <CustomTextField fullWidth label="Considerar vendas fechadas a partir de" type="date"
                                     value={request.startCloseSaleDate}
                                     InputLabelProps={{shrink: true}} onChange={onChangeStart} required/>
                    <CustomTextField fullWidth label="Considerar vendas fechadas até" type="date"
                                     value={request.endCloseSaleDate}
                                     InputLabelProps={{shrink: true}} onChange={onChangeEnd} required/>
                    <Tooltip title="Buscar">
                        <IconButton type={"submit"}>
                            <Search/>
                        </IconButton>
                    </Tooltip>
                </CustomRow>
            </form>

            <form onSubmit={generate}>
                <TableContainer component={Paper} sx={{marginBottom: '10px'}}>
                    <Table size={"small"} stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>Faixas de valores</TableCell>
                                <TableCell>Número de clientes</TableCell>
                                <TableCell>Gerar</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell>{CampaignService.range(item.min, item.max)}</TableCell>
                                    <TableCell>{item.total}</TableCell>
                                    <TableCell>
                                        <Checkbox checked={item.generate || false}
                                                  onChange={(event) => onChangeGenerate(event, index)}/>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                {renderCampaignDetail()}

                <CustomButtonGroup>
                    <CustomButton color="primary" type="submit">Gerar</CustomButton>
                    <CustomButton type="button" color="neutral" onClick={cancel}>Cancelar</CustomButton>
                </CustomButtonGroup>
            </form>
        </React.Fragment>
    )

}

export default React.memo(CampaignGenerateByValue);
