import React, {FormEvent, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import CustomTextField from "../custom-text-field/custom-text-field";
import CustomButton from "../custom-button/custom-button";
import CustomButtonGroup from "../custom-button-group/custom-button-group";
import CustomRow from "../custom-row/custom-row";
import {AuthService} from "../auth/auth.service";
import {changePageTitle, setAlert} from "../redux/actions";
import {DateUtil} from "../util/date-util";
import {NumberUtil} from "../util/number-util";
import {WalletService} from "./wallet.service";
import {Wallet} from "./wallet";
import ForeignKey from "../foreign-key/foreign-key";

const WalletMovement = () => {
    const dispatch = useDispatch();
    const [originWallet, setOriginWallet] = useState<Wallet | null>(null);
    const [destinyWallet, setDestinyWallet] = useState<Wallet | null>(null);
    const [amount, setAmount] = useState('');
    const [originBalance, setOriginBalance] = useState('');
    const [destinyBalance, setDestinyBalance] = useState('');

    useEffect(() => {
        dispatch(changePageTitle("Movimentação entre contas"));
    }, [dispatch]);

    const getOriginBalance = async (walletId: number) => {
        const finalDate = DateUtil.toJavaSqlDate(DateUtil.currentDate());
        if (finalDate) {
            const balance = await WalletService.getSaldoFinal(walletId, finalDate);
            if (balance !== null) {
                setOriginBalance(NumberUtil.decimalFormat(balance));
            }
        }
    }

    const getDestinyBalance = async (walletId: number) => {
        const finalDate = DateUtil.toJavaSqlDate(DateUtil.currentDate());
        if (finalDate) {
            const balance = await WalletService.getSaldoFinal(walletId, finalDate);
            if (balance !== null) {
                setDestinyBalance(NumberUtil.decimalFormat(balance));
            }
        }
    }

    const onChangeOriginWallet = async (id: number) => {
        setOriginWallet({id: id});
        setOriginBalance('');
        if (id) {
            const wallet = await WalletService.findOne(id);
            onSelectOriginWallet(wallet)
        }
    }

    const onSelectOriginWallet = (wallet: Wallet) => {
        if (wallet && wallet.id) {
            setOriginWallet(wallet);
            getOriginBalance(wallet.id).then();
        }
    }

    const onChangeDestinyWallet = async (id: number) => {
        setDestinyWallet({id: id});
        setDestinyBalance('');
        if (id) {
            const wallet = await WalletService.findOne(id);
            onSelectDestinyWallet(wallet)
        }
    }

    const onSelectDestinyWallet = (wallet: Wallet) => {
        if (wallet?.id) {
            setDestinyWallet(wallet);
            getDestinyBalance(wallet.id).then();
        }
    }

    const addMovement = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        if (originWallet?.id && destinyWallet?.id) {
            WalletService.movement(originWallet.id, destinyWallet.id, parseFloat(amount)).then(() => {
                setOriginWallet(null);
                setDestinyWallet(null)
                setAmount('');
                setDestinyBalance('');
                setOriginBalance('');
                dispatch(setAlert({
                    show: true,
                    message: 'Movimentação entre contas realizada com sucesso',
                    severity: 'success'
                }));
            }, (error) => {
                dispatch(setAlert({
                    show: true,
                    message: 'Erro ao movimentar entre contas - ' + error.data.message,
                    severity: 'error'
                }));
            })
        }
    }

    const onChangeAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newAmount = event.target.value;
        setAmount(newAmount);
    }

    const renderSaldoDestino = () => {
        if (AuthService.userInRole("ADMIN")) {
            return <CustomTextField type="text" fullWidth label="Saldo (R$)" value={destinyBalance} disabled/>;
        }
    }

    const renderSaldoOrigem = () => {
        if (AuthService.userInRole("ADMIN")) {
            return <CustomTextField type="text" fullWidth label="Saldo (R$)" value={originBalance} disabled/>;
        }
    }

    return (
        <form onSubmit={addMovement}>
            <CustomRow>
                <ForeignKey autoFocus fullWidth required label="Conta de origem" value={originWallet} fieldKey={'id'}
                            fieldDescription={'descricao'} labelDescription='Descrição' onChange={onChangeOriginWallet}
                            onSelect={onSelectOriginWallet} findAll={WalletService.findAll}
                            dataColumnNames={['ID', 'Descrição']} dataColumns={['id', 'descricao']}
                            dataTypes={['number', 'text']}/>
                {renderSaldoOrigem()}
            </CustomRow>
            <CustomRow>
                <ForeignKey fullWidth required label="Conta de destino" value={destinyWallet} fieldKey={'id'}
                            fieldDescription={'descricao'} labelDescription='Descrição' onChange={onChangeDestinyWallet}
                            onSelect={onSelectDestinyWallet} findAll={WalletService.findAll}
                            dataColumnNames={['ID', 'Descrição']} dataColumns={['id', 'descricao']}
                            dataTypes={['number', 'text']}/>
                {renderSaldoDestino()}
            </CustomRow>
            <CustomTextField type="number" fullWidth required label="Valor (R$)" value={amount}
                             onChange={onChangeAmount} InputProps={{inputProps: {min: 0, step: "any"}}}/>
            <CustomButtonGroup>
                <CustomButton color="primary" type="submit">Movimentar</CustomButton>
            </CustomButtonGroup>
        </form>
    );
}

export default React.memo(WalletMovement);