import React from "react";
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {green, lightGreen, orange, red, yellow} from "@mui/material/colors";
import CustomPanel from "../typescript/custom-panel/custom-panel";

const classes = {
    indice20: {
        backgroundColor: red["700"]
    },
    indice40: {
        backgroundColor: orange["700"]
    },
    indice60: {
        backgroundColor: yellow["700"]
    },
    indice80: {
        backgroundColor: lightGreen["700"]
    },
    indice100: {
        backgroundColor: green["700"]
    }
};

const ActivitiesPerUser = (props) => {
    const {activities} = props;

    const calculaIndice = (indice) => {
        if (indice < 20) {
            return classes.indice20;
        } else if (indice < 40) {
            return classes.indice40;
        } else if (indice < 60) {
            return classes.indice60;
        } else if (indice < 80) {
            return classes.indice80;
        } else {
            return classes.indice100;
        }
    }

    return (
        <CustomPanel title={"Atividades por usuário"}>
            <TableContainer component={Paper}>
                <Table size={"small"}>
                    <TableHead>
                        <TableRow>
                            <TableCell/>
                            <TableCell colSpan={4}>Hoje</TableCell>
                            <TableCell colSpan={4}>Mês</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Usuário</TableCell>
                            <TableCell>Total</TableCell>
                            <TableCell>Realizadas</TableCell>
                            <TableCell>Não realizadas</TableCell>
                            <TableCell>Índice (%)</TableCell>
                            <TableCell>Total</TableCell>
                            <TableCell>Realizadas</TableCell>
                            <TableCell>Não realizadas</TableCell>
                            <TableCell>Índice (%)</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {activities.map((activity, index) => (
                            <TableRow key={index}>
                                <TableCell>{activity.usuario}</TableCell>
                                <TableCell
                                    sx={calculaIndice(activity.indiceHoje)}>{activity.totalAtividadesHoje}</TableCell>
                                <TableCell
                                    sx={calculaIndice(activity.indiceHoje)}>{activity.totalAtividadesRealizadasHoje}</TableCell>
                                <TableCell
                                    sx={calculaIndice(activity.indiceHoje)}>{activity.totalAtividadesNaoRealizadasHoje}</TableCell>
                                <TableCell
                                    sx={calculaIndice(activity.indiceHoje)}>{activity.indiceHoje}</TableCell>
                                <TableCell
                                    sx={calculaIndice(activity.indiceMes)}>{activity.totalAtividadesMes}</TableCell>
                                <TableCell
                                    sx={calculaIndice(activity.indiceMes)}>{activity.totalAtividadesRealizadasMes}</TableCell>
                                <TableCell
                                    sx={calculaIndice(activity.indiceMes)}>{activity.totalAtividadesNaoRealizadasMes}</TableCell>
                                <TableCell
                                    sx={calculaIndice(activity.indiceMes)}>{activity.indiceMes}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>


        </CustomPanel>
    )

}

export default React.memo(ActivitiesPerUser);