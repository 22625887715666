import React, {FormEvent, useState} from "react";
import {Dialog, DialogContent, DialogTitle} from "@mui/material";
import {Sale} from "../../../sale/sale";
import CustomButtonGroup from "../../custom-button-group/custom-button-group";
import CustomButton from "../../custom-button/custom-button";
import OrcamentoItem from "./orcamento-item";
import {RelatorioService} from "../../relatorio/relatorio.service";
import {FileUtil} from "../../util/file-util";
import * as StringUtil from "../../util/string-util";
import {setAlert} from "../../redux/actions";
import {useDispatch} from "react-redux";
import {NumberUtil} from "../../util/number-util";

interface OrcamentoDialogProps {
    onClose: (success: boolean) => void;
    sale: Sale
}

const OrcamentoDialog = React.memo(({sale, onClose}: OrcamentoDialogProps) => {

    let saleId: number;
    if (sale.id) {
        saleId = sale.id;
    } else {
        throw new Error('Sale id required');
    }

    const dispatch = useDispatch();
    const [condicoesDePagamento, setCondicoesDePagamento] = useState({
        dinheiro: '',
        boleto: '',
        cheque: '',
        cartao: ''
    });

    const setDinheiro = (dinheiro: string) => {
        setCondicoesDePagamento({
            ...condicoesDePagamento,
            dinheiro: dinheiro
        })
    }

    const setBoleto = (boleto: string) => {
        setCondicoesDePagamento({
            ...condicoesDePagamento,
            boleto: boleto
        })
    }

    const setCheque = (cheque: string) => {
        setCondicoesDePagamento({
            ...condicoesDePagamento,
            cheque: cheque
        })
    }

    const setCartao = (cartao: string) => {
        setCondicoesDePagamento({
            ...condicoesDePagamento,
            cartao: cartao
        })
    }

    const onCloseDialog = (event: Object, reason: string) => {
        if (reason !== 'backdropClick') {
            onClose(false);
        }
    }

    const gera = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        RelatorioService.orcamento(saleId, condicoesDePagamento.dinheiro, condicoesDePagamento.boleto,
            condicoesDePagamento.cheque, condicoesDePagamento.cartao).then(function (result) {
            FileUtil.openPdfInNewTab(result);
            onClose(true);
        }, (result) => {
            const error = StringUtil.decodeUint8Array(result);
            dispatch(setAlert({show: true, message: error.message, severity: 'error'}));
        })
    }

    const cancela = () => {
        onClose(false);
    }

    return (
        <Dialog fullWidth maxWidth={"xl"} onClose={onCloseDialog} open={true}>
            <DialogTitle>Orçamento venda {sale.id} - Total da
                venda: {NumberUtil.currencyFormat(sale.valorTotal || 0)}</DialogTitle>
            <DialogContent>
                <form onSubmit={gera}>
                    <OrcamentoItem sale={sale} type={'dinheiro'} setCondicoesDePagamento={setDinheiro}/>
                    <OrcamentoItem sale={sale} type={'boleto'} setCondicoesDePagamento={setBoleto}/>
                    <OrcamentoItem sale={sale} type={'cheque'} setCondicoesDePagamento={setCheque}/>
                    <OrcamentoItem sale={sale} type={'cartão'} setCondicoesDePagamento={setCartao}/>
                    <CustomButtonGroup>
                        <CustomButton color="primary" type="submit">Gerar</CustomButton>
                        <CustomButton type="button" color="neutral" onClick={cancela}>Cancelar</CustomButton>
                    </CustomButtonGroup>
                </form>
            </DialogContent>
        </Dialog>
    )

});

export default OrcamentoDialog;
