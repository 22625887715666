import React, {useCallback, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {Datatable} from "../../index";
import {MonthlyBudgetService} from "../monthly-budget.service";
import CustomRow from "../../typescript/custom-row/custom-row";
import {changePageTitle} from "../../typescript/redux/actions";
import {DateUtil} from "../../typescript/util/date-util";
import SelectOption from "../../typescript/select-option/select-option";

const classes = {
    listDanger: {
        backgroundColor: 'rgb(255,200,200)'
    },
    listSuccess: {
        backgroundColor: 'rgb(200,255,200)'
    }
};

const MonthlyBudgetAnalysis = () => {
    const [items, setItems] = useState(null);
    const dispatch = useDispatch();
    const [ano, setAno] = useState(DateUtil.currentYear());
    const [mes, setMes] = useState(DateUtil.currentMonth());

    const findAll = useCallback(async () => {
        const result = await MonthlyBudgetService.getMonthlyBudgetAnalysis(ano, mes);
        setItems(result);
    }, [ano, mes]);
    const anoOptions = DateUtil.startYears();
    const mesOptions = DateUtil.startMonths();

    useEffect(() => {
        dispatch(changePageTitle("Análise de orçamentos"));
        findAll().then();
    }, [dispatch, findAll]);

    const rowClass = (index) => {
        return items.orcamentos[index].diferencaOrcadoPrevisto < 0 ? classes.listDanger : classes.listSuccess;
    }

    return items && (
        <React.Fragment>
            <CustomRow>
                <SelectOption fullWidth required label="Ano" value={ano}
                              onChange={(event) => setAno(event.target.value)}
                              items={anoOptions} itemValue='name' itemDescription='description'/>
                <SelectOption fullWidth required label="Mês" value={mes}
                              onChange={(event) => setMes(event.target.value)}
                              items={mesOptions} itemValue='name' itemDescription='description'/>
            </CustomRow>

            <Datatable data={items.orcamentos} totalData={items.orcamentos.length} rowClass={rowClass}
                       dataColumnNames={['ID', 'Tipo de gasto', 'Orçado', 'Previsto', 'Orçado - previsto', 'Realizado', 'Orçado - realizado', items.mes1, items.mes2, items.mes3]}
                       dataColumns={['centrodecusto.id', 'centrodecusto.descricao', 'orcado', 'previsto', 'diferencaOrcadoPrevisto', 'realizado', 'diferencaOrcadoRealizado', 'estimadoMes1', 'estimadoMes2', 'estimadoMes3']}
                       dataTypes={['number', 'text', 'currency', 'currency', 'currency', 'currency', 'currency', 'currency', 'currency', 'currency']}
                       summarize={[null, null, 'orcado', 'previsto', 'diferencaOrcadoPrevisto', 'realizado', 'diferencaOrcadoRealizado', 'estimadoMes1', 'estimadoMes2', 'estimadoMes3']}/>
        </React.Fragment>
    )

}

export default React.memo(MonthlyBudgetAnalysis);