import React, {useCallback, useEffect, useState} from "react";
import {DocumentService} from "./document.service";
import {useDispatch} from "react-redux";
import {changePageTitle} from "../typescript/redux/actions";
import {Paper, Tab, Tabs} from "@mui/material";
import {DocumentPayPartial} from "./document-pay-partial";
import {DocumentPayTotal} from "./document-pay-total";

const classes = {
    documentPayTabBar: {
        marginBottom: '8px'
    }
};

const DocumentPayTabs = (props) => {

    const {id, onClose, onBack} = props;
    const [tabValue, setTabValue] = React.useState(0);
    const [document, setDocument] = useState(DocumentService.createDocument());
    const dispatch = useDispatch();

    const findOne = useCallback(async () => {
        const result = await DocumentService.findOne(id);
        if (!onClose) {
            if (result.parcela) {
                dispatch(changePageTitle("Documento " + id + " - Pagamento parcela " + result.parcela));
            } else {
                dispatch(changePageTitle("Documento " + id + " - Pagamento"));
            }
        }
        setDocument(result);
        if (result.saldo && result.saldo !== result.valorFinal) {
            setTabValue(1);
        }
    }, [dispatch, id, onClose]);

    useEffect(() => {
        findOne().then();
    }, [findOne]);

    const handleChangeTab = (event, newValue) => {
        setTabValue(newValue);
    };

    const renderTotal = () => {
        return tabValue === 0 && (
            <DocumentPayTotal document={document} back={onBack}/>
        )
    }

    const renderPartial = () => {
        return tabValue === 1 && (
            <DocumentPayPartial document={document} back={onBack}/>
        )
    }

    return (
        <React.Fragment>
            <Paper square sx={classes.documentPayTabBar}>
                <Tabs value={tabValue} indicatorColor="primary" textColor="primary" onChange={handleChangeTab}
                      variant={"scrollable"} scrollButtons={"auto"}>
                    <Tab label="Total" disabled={!!document.saldo && (document.saldo !== document.valorFinal)}/>
                    <Tab label="Parcial"/>
                </Tabs>
            </Paper>
            {renderTotal()}
            {renderPartial()}
        </React.Fragment>
    )
}

export default React.memo(DocumentPayTabs);