import React, {useCallback, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {CampaignType} from "./campaign-type";
import Datatable from "../../datatable/datatable";
import {CampaignTypeService} from "./campaign-type.service";
import {changePageTitle, setAlert} from "../../redux/actions";
import {ColorButtonEnum} from "../../util/color-button-enum";
import {ColorIconEnum} from "../../util/color-icon-enum";
import {Delete as DeleteIcon} from "@mui/icons-material";

const CampaignTypeList = React.memo(() => {

    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [items, setItems] = useState<CampaignType[]>([]);
    const [page, setPage] = useState<number | undefined>(0);
    const [size, setSize] = useState<number | undefined>(parseInt(process.env.REACT_APP_PAGE_SIZE || "0"));
    const [sort, setSort] = useState<string | undefined>('id,asc');
    const [filter, setFilter] = useState<string | undefined>('');

    const findAll = useCallback(async (page?: number, size?: number, sort?: string, filter?: string) => {
        const result = await CampaignTypeService.findAll(page, size, sort, filter);
        setItems(result.content);
        setPage(page);
        setSize(page);
        setSort(sort);
        setFilter(filter);
    }, []);

    useEffect(() => {
        dispatch(changePageTitle("Tipos de campanhas"));
        findAll(page, size, sort, filter).then();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const insertItem = () => {
        navigate('/campaigntype');
    }

    const editItem = (item: CampaignType) => {
        navigate('/campaigntype/' + item.id);
    }

    const deleteItem = (item: CampaignType) => {
        if (item.id) {
            CampaignTypeService.delete(item.id).then(() => {
                findAll(page, size, sort, filter).then();
            }).catch((error) => {
                dispatch(setAlert({
                    show: true,
                    message: 'Erro ao excluir registro - ' + error.response.data.message,
                    severity: 'error'
                }));
            })
        }
    }

    const headerOperations = [{color: () => ColorButtonEnum.PRIMARY, onClick: insertItem, label: () => 'Inserir'}];

    const rowOperations = [{
        color: () => ColorIconEnum.PRIMARY,
        onClick: deleteItem,
        label: () => 'Excluir',
        icon: () => <DeleteIcon/>
    }];

    return (
        <React.Fragment>
            <Datatable
                dataColumnNames={['ID', 'Descrição']}
                dataColumns={['id', 'description']}
                dataTypes={['number', 'text']}
                findAll={findAll}
                page={page}
                size={size}
                sort={sort}
                filter={filter}
                editData={editItem}
                data={items}
                totalData={items.length}
                headerOperations={headerOperations}
                rowOperations={rowOperations}
            />
        </React.Fragment>
    )

});

export default CampaignTypeList;