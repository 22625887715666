import React, {FormEvent, useEffect, useRef, useState} from "react";
import {Sale} from "../../../../sale/sale";
import {CloseOptionsResponse} from "../../../../sale/close-options-response";
import CustomRow from "../../../custom-row/custom-row";
import CustomTextField from "../../../custom-text-field/custom-text-field";
import CustomButtonGroup from "../../../custom-button-group/custom-button-group";
import CustomButton from "../../../custom-button/custom-button";
import {DateUtil} from "../../../util/2.0.0/date-util";
import {useDispatch} from "react-redux";
import BandeiraOption from "../../../bandeira-option/bandeira-option";
import {Brand} from "../../../brand/Brand";
import {SaleService} from "../../../../sale/sale.service";
import {setAlert} from "../../../redux/actions";
import {Document} from "../../../../document/document";
import Parcels from "../parcels";
import {NfeService} from "../../../../nfe/nfe.service";
import {FileUtil} from "../../../util/file-util";
import {decodeUint8Array} from "../../../util/string-util";
import {CloseSaleCartaoParams} from "../../../../sale/close-sale-cartao-params";

interface CloseSaleCartaoProps {
    sale: Sale;
    onClose: (success: boolean) => void;
    options: CloseOptionsResponse;
}

const CloseSaleCartao = React.memo(({sale, onClose, options}: CloseSaleCartaoProps) => {

    let saleId: number;
    if (sale.id) {
        saleId = sale.id;
    } else {
        throw new Error('Sale id required');
    }

    const dispatch = useDispatch();
    const submitButton = useRef<HTMLButtonElement>(null);
    const [date, setDate] = useState<string>(DateUtil.toJavaSqlDate(DateUtil.currentDate()));
    const [cardBrand, setCardBrand] = useState<Brand | null>(null);
    const [cardNumberOfParcels, setCardNumberOfParcels] = useState<number | string>("");
    const [documents, setDocuments] = useState<Document[]>([]);
    const [discountValue, setDiscountValue] = useState<number | string>("");
    const [discountPercentage, setDiscountPercentage] = useState<number | string>("");
    const [feeValue, setFeeValue] = useState<number | string>("");
    const [feePercentage, setFeePercentage] = useState<number | string>("");

    useEffect(() => {
        if (!cardBrand || !cardNumberOfParcels) {
            return;
        }
        const params = createParams();
        SaleService.closeSalePreviewCard(saleId, params).then(function (result) {
            const documentsGenerated: Document[] = [];
            documentsGenerated.push(result.data);
            setDocuments(documentsGenerated);
        }, (error) => {
            dispatch(setAlert({
                show: true,
                message: 'Erro ao consultar previsão de fechamento de compra - ' + error.response.data.message,
                severity: 'error'
            }));
        })
    }, [date, cardBrand, cardNumberOfParcels, discountValue, feeValue]); // eslint-disable-line react-hooks/exhaustive-deps

    const changeDate = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDate(event.target.value);
    }

    const changeCardNumberOfParcels = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCardNumberOfParcels(event.target.value);
    }

    const changeCardBrand = (bandeiraSelecionada: Brand | null) => {
        setCardBrand(bandeiraSelecionada);
    }

    const changeDiscountPercentage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDiscountPercentage(event.target.value);
        const valueToDiscount = Number(sale.valorTotal) * Number(event.target.value) / 100;
        setDiscountValue(valueToDiscount);
    }

    const changeDiscountValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDiscountValue(event.target.value);
        const percentageToDiscount = Number(event.target.value) / Number(sale.valorTotal) * 100;
        setDiscountPercentage(percentageToDiscount);
    }

    const changeFeePercentage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFeePercentage(event.target.value);
        const valueToFee = Number(sale.valorTotal) * Number(event.target.value) / 100;
        setFeeValue(valueToFee);
    }

    const changeFeeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFeeValue(event.target.value);
        const percentageToFee = Number(event.target.value) / Number(sale.valorTotal) * 100;
        setFeePercentage(percentageToFee);
    }

    const createParams = (): CloseSaleCartaoParams => {
        if (!cardBrand?.id) {
            throw new Error('Card brand id required');
        }
        if (!paymentIsToday(date)) {
            return {
                date: date,
                cardBrand: cardBrand.id,
                cardNumberOfParcels: Number(cardNumberOfParcels),
                discountValue: 0,
                feeValue: 0,
            };
        }
        return {
            date: date,
            cardBrand: cardBrand.id,
            cardNumberOfParcels: Number(cardNumberOfParcels),
            discountValue: Number(discountValue),
            feeValue: Number(feeValue),
        };
    }

    const geraNFeDeCartao = () => {
        NfeService.geraNFeDeCartao(saleId).then(function (result) {
            dispatch(setAlert({show: true, message: 'Venda fechada com sucesso', severity: 'success'}));
            FileUtil.openPdfInNewTab(result);
            onClose(true);
        }, (result) => {
            const error = decodeUint8Array(result);
            dispatch(setAlert({show: true, message: 'Erro ao gerar NFe - ' + error.message, severity: 'error'}));
            onClose(true);
        })
    }

    const gera = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        submitButton.current?.setAttribute("disabled", "disabled");
        const params = createParams();
        SaleService.closeSaleCard(saleId, params).then(function () {
            submitButton.current?.removeAttribute("disabled");
            dispatch(setAlert({show: true, message: 'Venda fechada com sucesso', severity: 'success'}));
            geraNFeDeCartao();
        }, (error) => {
            submitButton.current?.removeAttribute("disabled");
            dispatch(setAlert({
                show: true,
                message: 'Erro ao fechar compra - ' + error.response.data.message,
                severity: 'error'
            }));
            onClose(false);
        })
    }

    const cancela = () => {
        onClose(false);
    }

    const renderDocumentPreview = () => {
        return documents.length > 0 && (
            <Parcels documents={documents}/>
        )
    }

    const paymentIsToday = (saleDate: string) => {
        const today = DateUtil.toJavaSqlDate(DateUtil.currentDate());
        return saleDate === today;
    }

    const renderDiscountOrFees = () => {
        return paymentIsToday(date) && (
            <CustomRow>
                <CustomTextField fullWidth label="Desconto (%)" type="number" value={discountPercentage}
                                 onChange={changeDiscountPercentage} InputProps={{inputProps: {min: 0, step: "any"}}}/>
                <CustomTextField fullWidth label="Desconto (R$)" type="number" value={discountValue}
                                 onChange={changeDiscountValue} InputProps={{inputProps: {min: 0, step: "any"}}}/>
                <CustomTextField fullWidth label="Juros (%)" type="number" value={feePercentage}
                                 onChange={changeFeePercentage} InputProps={{inputProps: {min: 0, step: "any"}}}/>
                <CustomTextField fullWidth label="Juros (R$)" type="number" value={feeValue} onChange={changeFeeValue}
                                 InputProps={{inputProps: {min: 0, step: "any"}}}/>
            </CustomRow>
        )
    }

    return (
        <form onSubmit={gera}>
            <CustomTextField autoFocus fullWidth label="Data" type="date" value={date} required
                             onChange={changeDate}/>
            {renderDiscountOrFees()}
            <CustomRow>
                <BandeiraOption bandeiras={options.bandeiras} bandeiraSelecionada={cardBrand}
                                changeBandeira={changeCardBrand}/>
                <CustomTextField fullWidth label="Número de parcelas do cartão" type="number" required
                                 value={cardNumberOfParcels} onChange={changeCardNumberOfParcels}
                                 InputProps={{inputProps: {min: 1}}}/>
            </CustomRow>
            {renderDocumentPreview()}
            <CustomButtonGroup>
                <CustomButton ref={submitButton} color="primary" type="submit">Gerar</CustomButton>
                <CustomButton type="button" color="neutral" onClick={cancela}>Cancelar</CustomButton>
            </CustomButtonGroup>
        </form>

    )

});

export default CloseSaleCartao;
