import React from "react";
import {ComissionService} from "../comission/comission.service";
import {Typography} from "@mui/material";
import {NumberUtil} from "../typescript/util/number-util";
import CustomPanel from "../typescript/custom-panel/custom-panel";
import Datatable from "../typescript/datatable/datatable";
import {Commission} from "./commission";

interface ComissionProps {
    commission: Commission;
}

const CommissionDashboard = ({commission}: ComissionProps) => {

    return commission && (
        <CustomPanel title={"Metas do mês atual"}>
            <Typography variant={"subtitle1"} color={'red'}>
                Meta diária: {NumberUtil.currencyFormat(commission.masterDailyGoal)}
            </Typography>
            <Typography variant={"subtitle1"}>
                Comissão: {ComissionService.getGoalAccomplishedText(commission.goalAccomplished)} - {NumberUtil.currencyFormat(commission.comissionValue)} ({commission.comissionPercentual}%)
            </Typography>
            <Datatable
                dataColumnNames={['Semana', 'Primeiro dia', 'Último dia', 'Meta mínima', 'Meta básica', 'Meta master', 'Valor vendido']}
                dataColumns={['weekOfMonth', 'firstDay', 'lastDay', 'minimumGoal', 'basicGoal', 'masterGoal', 'valueAccomplished']}
                dataTypes={['number', 'number', 'number', 'currency', 'currency', 'currency', 'currency']}
                data={commission.goalsPerWeek} totalData={commission.goalsPerWeek.length}
                summarize={[null, null, null, 'minimumGoal', 'basicGoal', 'masterGoal', 'valueAccomplished']}/>
        </CustomPanel>
    )

}

export default React.memo(CommissionDashboard);
