import {useDispatch} from 'react-redux'
import {changePageTitle} from "../redux/actions";
import {useEffect} from "react";
import React from 'react';

export function Forbidden() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(changePageTitle("Forbidden"));
    });

    return <h2>Forbidden</h2>;
}