import React from "react";
import {Sale} from "../../../../../sale/sale";
import SaleListBodyTableContentRowEmpty from "./sale-list-body-table-content-row-empty";
import SaleListBodyTableContentRows from "./sale-list-body-table-content-rows";
import SaleListBodyTableContentRowBusy from "./sale-list-body-table-content-row-busy";

interface SaleListBodyTableContentProps {
    sales?: Sale[];
    findAll: () => void;
}

const SaleListBodyTableContent = React.memo(({sales, findAll}: SaleListBodyTableContentProps) => {

    if (!sales) {
        return <SaleListBodyTableContentRowBusy/>
    }

    if (sales.length === 0) {
        return <SaleListBodyTableContentRowEmpty/>
    }

    return <SaleListBodyTableContentRows findAll={findAll} sales={sales}/>

});

export default SaleListBodyTableContent;
