import {AppService} from "../typescript/app/app.service";

class LoginService {

    static API_URL = '/auth';

    static authenticate = (username: string, password: string) => {
        return AppService.post(this.API_URL + '/authenticate', {
            username: username,
            password: password
        }).then((response) => {
            AppService.saveAuthenticationData(response.data);
            return true;
        }).catch(() => {
            return false;
        })
    };

}

export {LoginService};