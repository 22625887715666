import React from "react";
import {Bar, BarChart, CartesianGrid, Cell, Label, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {blue, red} from "@mui/material/colors";
import {NumberUtil} from "../typescript/util/number-util";
import CustomPanel from "../typescript/custom-panel/custom-panel";

const BillingExpenseProgression = (props) => {
    const {progression} = props;

    const formatter = (value) => {
        if (value) {
            return NumberUtil.currencyFormat(value);
        }
        return null;
    }

    return (
        <CustomPanel title={"Progressão faturamento e despesa por mês"}>
            <ResponsiveContainer width={"100%"} height={300}>
                <BarChart data={progression} margin={{top: 10, right: 10, left: 20, bottom: 30}}>
                    <CartesianGrid/>
                    <XAxis dataKey="mes">
                        <Label value={"Mês"} position="bottom"/>
                    </XAxis>
                    <YAxis>
                        <Label value={"Valor"} position="left" angle={-90}/>
                    </YAxis>
                    <Legend verticalAlign="top"/>
                    <Tooltip formatter={formatter}/>
                    <Bar dataKey="receitaRealizada" name={"Receita realizada"} fill={blue["900"]}>
                        {progression.map((entry, index) => (
                            <Cell cursor="pointer" key={`cell-${index}`} fill={blue["900"]}/>
                        ))}
                    </Bar>
                    <Bar dataKey="despesaRealizada" name={"Despesa realizada"} fill={red["700"]}>
                        {progression.map((entry, index) => (
                            <Cell cursor="pointer" key={`cell-${index}`} fill={red["700"]}/>
                        ))}
                    </Bar>
                    <Bar dataKey="receitaEstimada" name={"Receita estimada"} fill={blue["100"]}>
                        {progression.map((entry, index) => (
                            <Cell cursor="pointer" key={`cell-${index}`} fill={blue["100"]}/>
                        ))}
                    </Bar>
                    <Bar dataKey="despesaEstimada" name={"Despesa estimada"} fill={red["100"]}>
                        {progression.map((entry, index) => (
                            <Cell cursor="pointer" key={`cell-${index}`} fill={red["100"]}/>
                        ))}
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </CustomPanel>
    )

}

export default React.memo(BillingExpenseProgression);