import React from "react";
import {CardMedia, Grid, IconButton, Typography} from "@mui/material";
import {
    AddShoppingCart,
    AttachMoney,
    CheckBox,
    List,
    Payment,
    Person,
    ShoppingCart,
    ShoppingCartCheckout
} from "@mui/icons-material";

const classes = {
    logo: {
        maxWidth: "400px",
        width: '100%',
        height: '300px',
        textAlign: 'center'
    },
    largeIcon: {
        fontSize: "100px"
    }
};

interface ShortcutsProps {
    checkoutOpened: boolean;
}

const Shortcuts = ({checkoutOpened}: ShortcutsProps) => {

    const renderAbrirCaixa = () => {
        return !checkoutOpened && (
            <Grid item md xs={6}>
                <IconButton color='primary' component="a" href={'/checkout/open'}>
                    <Grid container direction='column' alignItems={"center"} style={{textAlign: "center"}}>
                        <AttachMoney sx={classes.largeIcon}/>
                        <Typography>Abrir caixa</Typography>
                    </Grid>
                </IconButton>
            </Grid>
        )
    }

    const renderFecharCaixa = () => {
        return checkoutOpened && (
            <Grid item md xs={6}>
                <IconButton color='primary' component="a" href={'/checkout/close'}>
                    <Grid container direction='column' alignItems={"center"} style={{textAlign: "center"}}>
                        <AttachMoney sx={classes.largeIcon}/>
                        <Typography>Fechar caixa</Typography>
                    </Grid>
                </IconButton>
            </Grid>
        )
    }

    const renderDocuments = () => {
        return checkoutOpened && (
            <Grid item md xs={6}>
                <IconButton color='primary' component="a" href={'/documents'}>
                    <Grid container direction='column' alignItems={"center"} style={{textAlign: "center"}}>
                        <Payment sx={classes.largeIcon}/>
                        <Typography>Documentos</Typography>
                    </Grid>
                </IconButton>
            </Grid>
        )
    }

    const renderSales = () => {
        return checkoutOpened && (
            <Grid item md xs={6}>
                <IconButton color='primary' component="a" href={'/sales'}>
                    <Grid container direction='column' alignItems={"center"} style={{textAlign: "center"}}>
                        <ShoppingCart sx={classes.largeIcon}/>
                        <Typography>Vendas</Typography>
                    </Grid>
                </IconButton>
            </Grid>
        )
    }

    const renderSalesAdd = () => {
        return checkoutOpened && (
            <Grid item md xs={6}>
                <IconButton color='primary' component="a" href={'/sale'}>
                    <Grid container direction='column' alignItems={"center"} style={{textAlign: "center"}}>
                        <AddShoppingCart sx={classes.largeIcon}/>
                        <Typography>Nova venda</Typography>
                    </Grid>
                </IconButton>
            </Grid>
        )
    }

    const renderSalesAfter = () => {
        return checkoutOpened && (
            <Grid item md xs={6}>
                <IconButton color='primary' component="a" href={'/sales/after'}>
                    <Grid container direction='column' alignItems={"center"} style={{textAlign: "center"}}>
                        <ShoppingCartCheckout sx={classes.largeIcon}/>
                        <Typography>Pós vendas</Typography>
                    </Grid>
                </IconButton>
            </Grid>
        )
    }

    return (
        <Grid container spacing={1} alignItems={"center"} style={{textAlign: "center", padding: '20px'}}>
            <Grid item md xs={12} container direction="column" alignItems="center" justifyContent="center">
                <CardMedia sx={classes.logo} image="/copacubana.png"/>
            </Grid>

            <Grid item md xs={12}>
                <Grid container justifyContent="space-between" alignItems="center">
                    {renderAbrirCaixa()}
                    {renderFecharCaixa()}
                    <Grid item md xs={6}>
                        <IconButton color='primary' component="a" href={'/activities'}>
                            <Grid container direction='column' alignItems={"center"} style={{textAlign: "center"}}>
                                <CheckBox sx={classes.largeIcon}/>
                                <Typography>Atividades</Typography>
                            </Grid>
                        </IconButton>
                    </Grid>
                    <Grid item md xs={6}>
                        <IconButton color='primary' component="a" href={'/persons'}>
                            <Grid container direction='column' alignItems={"center"} style={{textAlign: "center"}}>
                                <Person sx={classes.largeIcon}/>
                                <Typography>Pessoas</Typography>
                            </Grid>
                        </IconButton>
                    </Grid>
                    <Grid item md xs={6}>
                        <IconButton color='primary' component="a" href={'/products'}>
                            <Grid container direction='column' alignItems={"center"} style={{textAlign: "center"}}>
                                <List sx={classes.largeIcon}/>
                                <Typography>Produtos</Typography>
                            </Grid>
                        </IconButton>
                    </Grid>
                </Grid>

                <Grid container justifyContent="space-between" alignItems="center">
                    {renderSalesAdd()}
                    {renderSales()}
                    {renderSalesAfter()}
                    {renderDocuments()}
                </Grid>

            </Grid>
        </Grid>
    )

}

export default React.memo(Shortcuts);