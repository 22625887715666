import React, {useState} from "react";
import {IconButton, Tooltip} from "@mui/material";
import {AttachMoney} from "@mui/icons-material";
import {Sale} from "../../../sale/sale";
import OrcamentoDialog from "./orcamento-dialog";

interface OrcamentoButtonProps {
    sale: Sale;
}

const OrcamentoButton = React.memo(({sale}: OrcamentoButtonProps) => {

    const [saleToOrcamento, setSaleToOrcamento] = useState<Sale | null>();

    const orcamentoItem = () => {
        setSaleToOrcamento(sale);
    }

    const closeOrcamentoDialog = () => {
        setSaleToOrcamento(null);
    }

    const renderOrcamentoDialog = () => {
        return saleToOrcamento && <OrcamentoDialog onClose={closeOrcamentoDialog} sale={saleToOrcamento}/>;
    }

    return (
        <React.Fragment>
            {renderOrcamentoDialog()}
            <Tooltip title={'Gerar orçamento'}>
                    <span>
                        <IconButton color={'primary'} onClick={orcamentoItem} disabled={!!sale.dataHoraFechamento}>
                            <AttachMoney/>
                        </IconButton>
                    </span>
            </Tooltip>
        </React.Fragment>
    )

});

export default OrcamentoButton;
