import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";

interface QuestionDialogProps {
    onClose: (close: boolean) => void;
    title: string;
    question: string;
}

export function QuestionDialog({onClose, title, question}: QuestionDialogProps) {

    const handleCloseYes = () => {
        onClose(true);
    };

    const handleCloseNo = () => {
        onClose(false);
    };

    return (
        <Dialog open={true} onClose={handleCloseNo} aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {question}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseYes} color="primary">Sim</Button>
                <Button onClick={handleCloseNo} color="primary">Não</Button>
            </DialogActions>
        </Dialog>
    );
}
