import React, {useCallback, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {UserService} from "./user.service";
import {Button, Card, CardMedia} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import CustomButton from "../typescript/custom-button/custom-button";
import CustomButtonGroup from "../typescript/custom-button-group/custom-button-group";
import {changePageTitle} from "../typescript/redux/actions";
import {FileUtil} from "../typescript/util/file-util";

export function UserAvatar() {

    let navigate = useNavigate();

    const {id} = useParams();

    const [avatar, setAvatar] = useState(null);
    const dispatch = useDispatch();

    const findAll = useCallback(async () => {
        const result = await UserService.getAvatar(id);
        setAvatar(result);
    }, [id]);

    useEffect(() => {
        dispatch(changePageTitle("Avatar do usuário " + id));
        findAll().then();
    }, [dispatch, findAll, id]);

    const goBack = () => {
        navigate('/users');
    }

    const deleteAvatar = async () => {
        await UserService.deleteAvatar(id);
        setAvatar(null);
    }

    const renderAvatar = () => {
        return avatar && (
            <Card sx={{maxWidth: "800px"}}>
                <CardMedia component="img" sx={{height: "600px"}} image={avatar}/>
            </Card>
        )
    }

    const renderDeleteAvatar = () => {
        return avatar && (
            <CustomButton color="primary" type="button" onClick={deleteAvatar}>Excluir avatar</CustomButton>
        )
    }

    const handleCapture = async ({target}) => {
        const image = target.files[0];
        await UserService.saveAvatar(id, image);
        const fileBase64 = await FileUtil.fileToBase64(image);
        setAvatar(fileBase64);
    };

    return (
        <React.Fragment>
            <CustomButtonGroup>
                <CustomButton type="button" onClick={goBack}>Voltar</CustomButton>
                {renderDeleteAvatar()}
                <Button color="primary" component="label">Selecionar avatar
                    <input type="file" hidden accept="image/png" onChange={handleCapture}/>
                </Button>
            </CustomButtonGroup>
            {renderAvatar()}
        </React.Fragment>
    );
}