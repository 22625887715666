import React, {useState} from "react";
import AppMenuList from "./app-menu-list";
import AppMenuBar from "./app-menu-bar";
import {useLocation} from 'react-router-dom';

const AppMenu = React.memo(() => {

    const [showDrawer, setShowDrawer] = useState(false);

    const location = useLocation();

    const renderAppMenuList = React.useCallback(() => {
        return (
            <AppMenuList showDrawer={showDrawer} setShowDrawer={setShowDrawer}/>
        )
    }, [showDrawer]);

    if (location.pathname === "/login") {
        return null;
    }

    return (
        <React.Fragment>
            <AppMenuBar setShowDrawer={setShowDrawer}/>
            {renderAppMenuList()}
        </React.Fragment>
    );
})

export default AppMenu;