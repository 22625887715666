import {blue, common, green, grey, indigo, lime, orange, pink, purple, red, teal, yellow} from "@mui/material/colors";

class GraphService {

    static getColors = () => {
        return [
            blue["100"],
            blue["400"],
            blue["900"],
            indigo["A100"],
            green["400"],
            green["A400"],
            green["900"],
            teal["300"],
            teal["900"],
            grey["400"],
            grey["600"],
            lime["400"],
            lime["900"],
            orange["400"],
            orange["900"],
            pink["500"],
            pink["900"],
            purple["400"],
            purple["900"],
            red["100"],
            red["500"],
            red["A700"],
            yellow["A100"],
            yellow["A200"],
            common.black,
        ];
    }
}

export {GraphService};