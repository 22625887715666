import React from "react";
import {FormControl, IconButton, Input, InputAdornment, InputLabel, Tooltip} from "@mui/material";
import {Search} from "@mui/icons-material";

const DatatableFilter = (props) => {

    const {filter, setFilter, doFilter, type} = props;

    const onChangeFilter = (event) => {
        setFilter(event.target.value);
    }

    const search = (event) => {
        if (event.keyCode === 13) {
            doFilter();
        }
    }

    return (
        <FormControl fullWidth variant={"standard"}>
            <InputLabel shrink>Pesquisar</InputLabel>
            <Input autoFocus value={filter} onChange={onChangeFilter} onKeyDown={search} type={type}
                   endAdornment={
                       <InputAdornment position="end">
                           <Tooltip title="Pesquisar">
                               <IconButton onClick={doFilter}>
                                   <Search/>
                               </IconButton>
                           </Tooltip>
                       </InputAdornment>
                   }
            />
        </FormControl>
    )

}

const areEqual = (prevProps, nextProps) => {
    return (prevProps.filter === nextProps.filter) && (prevProps.type === nextProps.type);
}

export default React.memo(DatatableFilter, areEqual);
