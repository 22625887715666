import React from "react";
import SaleListBodyTableContentRow from "./sale-list-body-table-content-row";
import {Sale} from "../../../../../sale/sale";

interface SaleListBodyTableContentRowsProps {
    sales: Sale[];
    findAll: () => void;
}

const SaleListBodyTableContentRows = React.memo(({sales, findAll}: SaleListBodyTableContentRowsProps) => {

    const items = sales.map((sale, index) => (
        <SaleListBodyTableContentRow key={index} sale={sale} findAll={findAll}/>
    ))

    return (
        <React.Fragment>
            {items}
        </React.Fragment>
    );

});

export default SaleListBodyTableContentRows;
