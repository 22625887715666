import React, {useCallback, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {UserService} from "./user.service";
import {changePageTitle, setAlert} from "../typescript/redux/actions";
import {Checkbox, FormControl, Input, InputLabel, ListItemText, MenuItem, Select} from "@mui/material";
import {
    ForeignKey,
    PersonDetail
} from "../index";
import CustomTextField from "../typescript/custom-text-field/custom-text-field";
import CustomButton from "../typescript/custom-button/custom-button";
import CustomButtonGroup from "../typescript/custom-button-group/custom-button-group";
import SelectOption from "../typescript/select-option/select-option";
import NumberInput from "../typescript/number-input/number-input";
import {PersonService} from "../person/person.service";

export function UserDetail({id, onClose, dialog}) {

    const [item, setItem] = useState(UserService.createUser());
    const dispatch = useDispatch();

    const findOne = useCallback(async () => {
        const result = await UserService.findOne(id);
        setItem(result);
    }, [id]);

    useEffect(() => {
        if (!dialog) {
            dispatch(changePageTitle(id ? "Usuário " + id : "Novo usuário"));
        }
        if (id) {
            findOne().then();
        }
    }, [dispatch, findOne, id, dialog]);

    const save = (event) => {
        event.preventDefault();
        event.stopPropagation();

        if (!id && (item.password.length < 5)) {
            dispatch(setAlert({show: true, message: 'A senha tem que ter pelo menos 5 caracteres', severity: 'error'}));
            return;
        }

        if (!id && (item.password !== item.password2)) {
            dispatch(setAlert({show: true, message: 'As senhas digitadas são diferentes', severity: 'error'}));
            return;
        }

        UserService.save(item).then((result) => {
            onClose(result.data);
        }).catch(() => {

        })
    }

    const cancel = () => {
        onClose();
    }

    const setUsername = (event) => {
        const usernameLowercase = event.target.value.toLowerCase();
        setItem({
            ...item,
            username: usernameLowercase
        })
    }

    const setAuthorities = (event) => {
        setItem({
            ...item,
            authorities: event.target.value
        })
    }

    const setActive = (event) => {
        setItem({
            ...item,
            enabled: event.target.value
        })
    }

    const setSalaryType = (event) => {
        if (event.target.value === "" || event.target.value === null) {
            setItem({
                ...item,
                salaryType: event.target.value,
                salary: ''
            })
        } else {
            setItem({
                ...item,
                salaryType: event.target.value
            })
        }
    }

    const setSalary = (event) => {
        setItem({
            ...item,
            salary: event.target.value
        })
    }

    const setPassword = (event) => {
        setItem({
            ...item,
            password: event.target.value
        })
    }

    const setPassword2 = (event) => {
        setItem({
            ...item,
            password2: event.target.value
        })
    }

    const setActivityStart = (event) => {
        setItem({
            ...item,
            activityStart: event.target.value
        })
    }

    const authorities = ['ADMIN', 'MASTER', 'USER']
    const authorities2 = [
        {name: 'ADMIN', description: 'Administrador'},
        {name: 'MASTER', description: 'Master'},
        {name: 'USER', description: 'Usuário'}
    ]

    const activeOptions = [{name: true, description: 'Sim'}, {name: false, description: 'Não'}];

    const salaryTypeOptions = [
        {name: 'HORISTA', description: 'Horista'},
        {name: 'MENSALISTA', description: 'Mensalista'}];

    const getRenderValue = (selected) => {
        let selectedString = [];
        selected.forEach(function (s) {
            const index = findWithAttr(authorities2, 'name', s);
            if (index !== -1) {
                selectedString.push(authorities2[index].description);
            }
        })
        return selectedString.join(', ');
    }

    const findWithAttr = (array, attr, value) => {
        for (let i = 0; i < array.length; i += 1) {
            if (array[i][attr] === value) {
                return i;
            }
        }
        return -1;
    }

    const getAuthorityByName = (name) => {
        const index = findWithAttr(authorities2, 'name', name);
        if (index !== -1) {
            return authorities2[index].description;
        }
    }

    const renderAuthorithies = () => {
        return id && (
            <FormControl required fullWidth variant={"standard"}>
                <InputLabel>Autoridades</InputLabel>
                <Select multiple value={item.authorities} onChange={setAuthorities} input={<Input/>}
                        renderValue={getRenderValue}>
                    {authorities.map((authority) => (
                        <MenuItem key={authority} value={authority}>
                            <Checkbox checked={item.authorities.indexOf(authority) > -1}/>
                            <ListItemText primary={getAuthorityByName(authority)}/>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    }

    const renderActive = () => {
        return id && (
            <SelectOption fullWidth required label="Ativo" value={item.enabled} onChange={setActive}
                          items={activeOptions}
                          itemValue='name' itemDescription='description'/>
        );
    }

    const renderPassword = () => {
        return !id && (
            <React.Fragment>
                <CustomTextField fullWidth required placeholder="Digite a sua senha" label="Senha" type="password"
                                 value={item.password} onChange={setPassword} autoCapitalize="none"/>
                <CustomTextField fullWidth required placeholder="Repita a sua senha" label="Repetição da Senha"
                                 type="password"
                                 value={item.password2} onChange={setPassword2} autoCapitalize="none"/>
            </React.Fragment>
        );
    }

    const onChangePessoa = async (id) => {
        setItem({
            ...item,
            person: {
                id: id
            }
        })
        if (id) {
            const person = await PersonService.findOne(id);
            if (person) {
                setItem({
                    ...item,
                    person: person
                })
            }
        }
    }

    const onAddPessoa = (person) => {
        if (person) {
            setItem({
                ...item,
                person: person
            })
        }
    }

    const findPessoas = (page, size, sort, filter) => {
        return PersonService.findAll(page, size, sort, filter);
    }

    return (
        <form onSubmit={save}>
            <CustomTextField fullWidth autoFocus required label="Usuário" value={item.username} onChange={setUsername}/>
            {renderAuthorithies()}
            {renderActive()}
            {renderPassword()}
            <CustomTextField fullWidth placeholder="Data em que iniciou as atividades na empresa"
                             label="Início das atividades" type="date" value={item.activityStart || ''}
                             onChange={setActivityStart} InputLabelProps={{shrink: true}}/>
            <SelectOption fullWidth label="Tipo de salário" value={item.salaryType || ''} onChange={setSalaryType}
                          items={salaryTypeOptions} itemValue='name' itemDescription='description'/>
            <NumberInput fullWidth label="Salário" placeholder="Digite o valor do salário de acordo com o tipo"
                         value={item.salary || ''} onChange={setSalary}/>
            <ForeignKey fullWidth label="Pessoa" value={item.person} fieldKey={'id'}
                        fieldDescription={'nome'} labelDescription='Nome' onChange={onChangePessoa}
                        onSelect={onAddPessoa} onAdd={onAddPessoa} findAll={findPessoas}
                        dataColumnNames={['ID', 'Nome']} dataColumns={['id', 'nome']}
                        dataTypes={['number', 'text']} addComponent={<PersonDetail/>}
            />
            <CustomButtonGroup>
                <CustomButton color="primary" type="submit">Salvar</CustomButton>
                <CustomButton type="button" color="neutral" onClick={cancel}>Cancelar</CustomButton>
            </CustomButtonGroup>
        </form>
    )
}