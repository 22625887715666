import React from "react";
import {
    Button,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip
} from "@mui/material";
import {useDispatch} from "react-redux";
import {AccessAlarm, PlaylistAddCheck, Print} from "@mui/icons-material";
import {AuthService} from "../typescript/auth/auth.service";
import {DateUtil} from "../typescript/util/date-util";
import {setAlert} from "../typescript/redux/actions";
import * as StringUtil from "../typescript/util/string-util";
import {FileUtil} from "../typescript/util/file-util";
import {NumberUtil} from "../typescript/util/number-util";
import SelectOption from "../typescript/select-option/select-option";
import CustomPanel from "../typescript/custom-panel/custom-panel";
import {RelatorioService} from "../typescript/relatorio/relatorio.service";
import {ComissionService} from "../comission/comission.service";

const classes = {
    tableHeader: {
        "& th": {
            fontWeight: 'bold'
        }
    }
};

const Comissions = (props) => {

    const {comissions, setComissions, setComissionYear, setComissionMonth, params} = props;
    const user = AuthService.getUserDetails();
    const years = DateUtil.startYears();
    const months = DateUtil.startMonths();
    const dispatch = useDispatch();

    const renderComissionsTableHeader = () => {
        return (
            <TableHead sx={classes.tableHeader}>
                <TableRow>
                    <TableCell>Usuário</TableCell>
                    <TableCell>Meta mínima</TableCell>
                    <TableCell>Meta básica</TableCell>
                    <TableCell>Meta master</TableCell>
                    <TableCell>Valor realizado</TableCell>
                    <TableCell>Comissão mensal</TableCell>
                    <TableCell>Comissão por produto</TableCell>
                    <TableCell/>
                </TableRow>
            </TableHead>
        );
    }

    const generateComission = (comission) => {
        ComissionService.generateComission(comission.user.id, comission.year, comission.month).then((result) => {
            let itemsToChange = [...comissions];
            for (let i = 0; i < itemsToChange.length; i++) {
                if (itemsToChange[i].user.id === comission.user.id && itemsToChange[i].year === comission.year && itemsToChange[i].month === comission.month) {
                    itemsToChange[i] = result.data;
                    break;
                }
            }
            setComissions(itemsToChange);
        }).catch((error) => {
            let message = 'Erro ao gerar comissão';
            if (error.response.data.message) {
                message += ' - ' + error.response.data.message;
            }
            dispatch(setAlert({show: true, message: message, severity: 'error'}));
        })
    }

    const printPonto = async (comission) => {
        RelatorioService.getPontoPorUsuario('PDF', comission.year, comission.month, comission.user.id).then(function (result) {
            FileUtil.openPdfInNewTab(result);
        }, (result) => {
            const error = StringUtil.decodeUint8Array(result);
            dispatch(setAlert({show: true, message: error.message, severity: 'error'}));
        })
    }

    const printValorRealizado = async (comission) => {
        RelatorioService.getValorRealizado('PDF', comission.user.id, comission.year, comission.month).then(function (result) {
            FileUtil.openPdfInNewTab(result);
        }, (result) => {
            const error = StringUtil.decodeUint8Array(result);
            dispatch(setAlert({show: true, message: error.message, severity: 'error'}));
        })
    }

    const renderGenerateComission = (comission) => {
        if (!comission.comissionGenerated && comission.comissionValue && comission.comissionValue > 0) {
            return (
                <Tooltip title="Gerar comissão mensal">
                    <Button color="primary" onClick={() => generateComission(comission)}>
                        <PlaylistAddCheck/>
                    </Button>
                </Tooltip>
            )
        }
    }

    const renderComissionsTableBodyNormal = (comission) => {
        if (comission.error) {
            return (
                <React.Fragment>
                    <TableCell colSpan={5}>
                        {comission.error}
                    </TableCell>
                    <TableCell colSpan={2}>
                        {NumberUtil.currencyFormat(comission.comissionProductGenerated)}
                    </TableCell>
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    <TableCell>
                        {NumberUtil.currencyFormat(comission.minimumGoal)}
                    </TableCell>
                    <TableCell>
                        {NumberUtil.currencyFormat(comission.basicGoal)}
                    </TableCell>
                    <TableCell>
                        {NumberUtil.currencyFormat(comission.masterGoal)}
                    </TableCell>
                    <TableCell>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            {NumberUtil.currencyFormat(comission.valueAccomplished)}
                            <Tooltip title="Documentos realizados">
                                <Button color="primary" onClick={() => printValorRealizado(comission)}>
                                    <Print/>
                                </Button>
                            </Tooltip>
                        </div>
                    </TableCell>
                    <TableCell>
                        {ComissionService.getGoalAccomplishedText(comission.goalAccomplished)} - {NumberUtil.currencyFormat(comission.comissionValue)} ({comission.comissionPercentual}%)
                    </TableCell>
                    <TableCell>
                        {NumberUtil.currencyFormat(comission.comissionProductGenerated)}
                    </TableCell>
                    <TableCell>
                        <Tooltip title="Imprimir ponto">
                            <Button color="primary" onClick={() => printPonto(comission)}>
                                <AccessAlarm/>
                            </Button>
                        </Tooltip>
                        {renderGenerateComission(comission)}
                    </TableCell>
                </React.Fragment>
            )
        }
    }

    const renderComissionsTableBody = () => {
        if (comissions && comissions.length > 0) {
            return (
                <TableBody>
                    {comissions.map((comission, index) => (
                        <TableRow key={index}>
                            <TableCell>
                                {comission.user.username}
                            </TableCell>
                            {renderComissionsTableBodyNormal(comission)}
                        </TableRow>
                    ))}
                </TableBody>
            );
        } else {
            return (
                <TableBody>
                    <TableRow>
                        <TableCell colSpan={99}>
                            Nenhum registro encontrado
                        </TableCell>
                    </TableRow>
                </TableBody>
            )
        }

    }

    return (user.authorities.includes('ADMIN') || user.authorities.includes('MASTER')) && (
        <Grid item md xs={12}>
            <CustomPanel title="Informações gerais sobre os usuários">
                <Grid container spacing={1}>
                    <Grid item md xs={12}>
                        <SelectOption fullWidth required label="Ano" value={params.year || ''}
                                      onChange={setComissionYear}
                                      items={years} itemValue='name' itemDescription='description'/>
                    </Grid>
                    <Grid item md xs={12}>
                        <SelectOption fullWidth required label="Mês" value={params.month || ''}
                                      onChange={setComissionMonth}
                                      items={months} itemValue='name' itemDescription='description'/>
                    </Grid>
                </Grid>
                <TableContainer component={Paper}>
                    <Table size={"small"}>
                        {renderComissionsTableHeader()}
                        {renderComissionsTableBody()}
                    </Table>
                </TableContainer>
            </CustomPanel>
        </Grid>
    );

}

export default React.memo(Comissions);
