import {Paper, styled} from "@mui/material";
import React from 'react';

const classes = {
    container: {
        padding: '16px',
        marginBottom: '16px'
    },
    title: {
        fontWeight: 'bold',
        fontSize: 'large',
        margin: '0 0 16px 0',
    }
};

interface CustomPanelProps {
    title: string;
    children: any | any[];
}

const CustomPanel = React.memo(({title, children}: CustomPanelProps) => {

    const Div = styled('div')(classes.title);

    const renderTitle = () => {
        return title && (
            <Div>
                {title}
            </Div>
        )
    }

    return (
        <Paper elevation={5} sx={classes.container}>
            {renderTitle()}
            {children}
        </Paper>
    )
});

export default CustomPanel;