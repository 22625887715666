import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import {Provider} from "react-redux";
import appRoutes from "./components/app/app-routes";
import {store} from "./components/typescript/redux/store";

const root = ReactDOM.createRoot(document.getElementById('root'));
const router = createBrowserRouter(appRoutes, {
    future: {
        v7_fetcherPersist: true,
        v7_relativeSplatPath: true,
        v7_normalizeFormMethod: true,
        v7_partialHydration: true,
        v7_skipActionErrorRevalidation: true
    }
});

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <RouterProvider router={router} future={{v7_startTransition: true}}/>
        </Provider>
    </React.StrictMode>
);