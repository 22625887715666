import React from 'react';
import {useNavigate, useParams} from "react-router-dom";
import CampaignTypeDetail from "./campaign-type-detail";

const CampaignTypeDetailPage = () => {

    const {id} = useParams();
    let navigate = useNavigate();

    const onClose = () => {
        navigate('/campaigntypes');
    }

    return (
        <CampaignTypeDetail id={id ? parseInt(id) : null} onClose={onClose}/>
    )

};

export default React.memo(CampaignTypeDetailPage);