import {Holiday} from "./holiday";
import {AppService} from "../app/app.service";

class HolidayService {

    static API_URL = "/api/holidays";

    static findAllByYear = (year: number): Promise<Holiday[]> => {
        return AppService.getAuthorized(this.API_URL, {
            year: year
        }).then((result) => {
            return result.data;
        }).catch(() => {
            return [];
        })
    };

    static findOne(id: number) {
        return AppService.getAuthorized(this.API_URL + "/" + id)
            .then((result) => {
                return result.data;
            }).catch(() => {
                return null;
            })
    }

    static save(item: Holiday) {
        if (item.id) {
            return AppService.postAuthorized(this.API_URL + "/" + item.id, item);
        } else {
            return AppService.postAuthorized(this.API_URL, item);
        }
    }

    static delete(id: number) {
        return AppService.deleteAuthorized(this.API_URL + "/" + id);
    }

    static createHoliday() {
        const holiday: Holiday = {
            description: '',
            date: ''
        };
        return holiday;
    }
}

export {HolidayService};
