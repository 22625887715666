import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Box, Collapse, IconButton, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {KeyboardArrowDown, KeyboardArrowUp} from "@mui/icons-material";
import {NumberUtil} from "../typescript/util/number-util";
import {CashFlowByPaymentType} from "./cash-flow-by-payment-type";
import {DateUtil} from "../typescript/util/2.0.0/date-util";

interface CashFlowRowProps {
    item: CashFlowByPaymentType;
}

const CashFlowRow = ({item}: CashFlowRowProps) => {
    const [open, setOpen] = useState(false);

    return (
        <React.Fragment>
            <TableRow>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUp/> : <KeyboardArrowDown/>}
                    </IconButton>
                    {item.type}
                </TableCell>
                <TableCell align="right">{NumberUtil.currencyFormat(item.totalInput)}</TableCell>
                <TableCell align="right">{NumberUtil.currencyFormat(item.totalOutput)}</TableCell>
            </TableRow>


            <TableRow>
                <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={8}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{margin: 1}}>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Vencimento</TableCell>
                                        <TableCell>Pagamento</TableCell>
                                        <TableCell>ID</TableCell>
                                        <TableCell>Pessoa</TableCell>
                                        <TableCell>Carteira</TableCell>
                                        <TableCell>Bandeira</TableCell>
                                        <TableCell align="right">Entrada</TableCell>
                                        <TableCell align="right">Saída</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {item.items.map((itemInner, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{DateUtil.javaSqlDateToDateToLocaleDateString(itemInner.vencimento)}</TableCell>
                                            <TableCell>{DateUtil.javaSqlDateToDateToLocaleDateString(itemInner.pagamento)}</TableCell>
                                            <TableCell>{itemInner.id}</TableCell>
                                            <TableCell>{itemInner.pessoa}</TableCell>
                                            <TableCell>{itemInner.carteira}</TableCell>
                                            <TableCell>{itemInner.bandeira}</TableCell>
                                            <TableCell align="right">{NumberUtil.currencyFormat(itemInner.entrada)}</TableCell>
                                            <TableCell align="right">{NumberUtil.currencyFormat(itemInner.saida)}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>


        </React.Fragment>
    )

}

CashFlowRow.propTypes = {
    item: PropTypes.object
};

export default React.memo(CashFlowRow);
