import React, {useState} from "react";
import {TableCell} from "@mui/material";
import {CampaignPerson} from "../campaign-person";
import {CampaignPersonService} from "../campaign-person.service";
import {setAlert} from "../../../redux/actions";
import {useDispatch} from "react-redux";
import CustomTextField from "../../../custom-text-field/custom-text-field";

const classes = {
    rowDanger: {
        backgroundColor: '#f2dedeb8'
    },
    rowAlert: {
        backgroundColor: '#fde7d7'
    },
};

interface ContactCellProps {
    campaignPerson: CampaignPerson;
    disabled?: boolean;
}

const ContactCell = ({campaignPerson, disabled}: ContactCellProps) => {

    const dispatch = useDispatch();
    const [item, setItem] = useState<CampaignPerson>(campaignPerson);

    const rowClassContacted = () => {
        return item.contact ? classes.rowAlert : classes.rowDanger;
    }

    const onChangeContact = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const newContact = event.target.value;
        setItem({
            ...campaignPerson,
            contact: newContact
        })
    }

    const saveContact = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newContact = event.target.value;
        if (newContact) {
            CampaignPersonService.updateContact(item.id, newContact).then(() => {
                dispatch(setAlert({show: true, message: 'Registro com sucesso', severity: 'success'}));
            }).catch((error) => {
                dispatch(setAlert({
                    show: true,
                    message: 'Erro ao salvar registro - ' + error.data.message,
                    severity: 'error'
                }));
            })
        }
    }

    return (
        <TableCell sx={rowClassContacted} align={"center"}>
            <CustomTextField type="date" value={item.contact || ""} required InputLabelProps={{shrink: true}}
                             onBlur={(event) => saveContact(event)} InputProps={{disableUnderline: true}}
                             onChange={(event) => onChangeContact(event)} disabled={disabled}/>
        </TableCell>
    )
}

export default React.memo(ContactCell);