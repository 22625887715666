import {AppService} from "../typescript/app/app.service";
import {Consulta} from "./consulta";

class ConsultaService {

    static API_URL = "/api/consultas/v2";

    static findAll = (dataInicial: string, dataFinal: string) => {
        return AppService.getAuthorized(this.API_URL, {
            dataInicial: dataInicial,
            dataFinal: dataFinal
        }).then((result) => {
            return result.data;
        }).catch(() => {
            return []
        })
    };

    static findOne(id: number) {
        return AppService.getAuthorized(this.API_URL + "/" + id).then((result) => {
            return result.data;
        }).catch(() => {
            return null;
        })
    }

    static save(item: Consulta) {
        let itemToSave = item;
        itemToSave.status1 = itemToSave.status1 ? itemToSave.status1 : null;
        itemToSave.status2 = itemToSave.status2 ? itemToSave.status2 : null;
        if (itemToSave.id) {
            return AppService.postAuthorized(this.API_URL + "/" + itemToSave.id, itemToSave);
        } else {
            return AppService.postAuthorized(this.API_URL, itemToSave);
        }
    }

    static updateStatus1(id: number, status1: string) {
        return AppService.postAuthorized(this.API_URL + "/" + id + "/status1", null, {status1: status1});
    }

    static updateStatus2(id: number, status2: string) {
        return AppService.postAuthorized(this.API_URL + "/" + id + "/status2", null, {status2: status2});
    }

    static delete(id: number) {
        return AppService.deleteAuthorized(this.API_URL + "/" + id);
    }

    static createConsulta() {
        const consulta: Consulta = {
            nome: '',
            fone1: '',
            fone2: '',
            medico: '',
            data: '',
            hora: '',
            status1: '',
            status2: '',
            observacao: ''
        }
        return consulta;
    }
}

export {ConsultaService};