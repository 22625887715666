import React from "react";
import {IconButton, TableCell, TableRow, Theme, Tooltip} from "@mui/material";
import DatatableTableBodyRowContent from "./datatable-table-body-row-content";
import {SxProps} from "@mui/system";
import {RowOperation} from "./row-operation";

interface DatatableTableBodyRowProps {
    item: string;
    dataColumns: string[];
    dataTypes: string[];
    dataValues?: ((item: any, dataColumn: string, columnIndex: number) => any)[];
    editData: () => void;
    cursorClass: SxProps<Theme>;
    rowOperations?: RowOperation[];
    rowClass?: (index: number) => SxProps<Theme>;
    rowIndex: number;
}

const DatatableTableBodyRow = React.memo(({
                                              item,
                                              dataColumns,
                                              dataTypes,
                                              dataValues,
                                              editData,
                                              cursorClass,
                                              rowOperations,
                                              rowClass,
                                              rowIndex
                                          }: DatatableTableBodyRowProps) => {

    const getDataType = (columnIndex: number) => {
        return (dataTypes ? dataTypes[columnIndex] : 'text');
    }

    const renderTableCellDataValue = (itemParam: any, dataColumn: string, columnIndex: number) => {
        if (dataValues) {
            const value = dataValues[columnIndex];
            return value(itemParam, dataColumn, columnIndex);
        }
    }

    const show = (operation: RowOperation, itemParam: any, rowIndexParam: number) => {
        if (operation.show === undefined) {
            return true;
        }
        return operation.show(itemParam, rowIndexParam);
    }

    const disabled = (operation: RowOperation, itemParam: any, rowIndexParam: number) => {
        if (operation.disabled) {
            return operation.disabled(itemParam, rowIndexParam);
        }
        return false;
    }

    const renderRowOperations = (itemParam: any, rowIndexParam: number) => {
        return rowOperations && (
            rowOperations.map((operation, keyIndex) => {
                return show(operation, itemParam, rowIndexParam) && (
                    <Tooltip key={keyIndex} title={operation.label(itemParam, rowIndexParam)}>
                    <span>
                        <IconButton color={operation.color(itemParam, rowIndexParam)}
                                    onClick={() => operation.onClick(itemParam, rowIndexParam)}
                                    disabled={disabled(operation, itemParam, rowIndexParam)}>
                            {operation.icon(itemParam, rowIndexParam)}
                        </IconButton>
                    </span>
                    </Tooltip>
                )
            })
        )
    }

    const renderTableRowOperations = (itemParam: string, rowIndexParam: number) => {
        return rowOperations && (
            <TableCell>
                {renderRowOperations(itemParam, rowIndexParam)}
            </TableCell>
        )
    }

    const applyRowClass = (rowIndexParam: number) => {
        return rowClass && (
            rowClass(rowIndexParam)
        );
    }

    return (
        <TableRow sx={applyRowClass(rowIndex)}>
            {dataColumns.map((dataColumn, columnIndex) => {
                if (dataValues && dataValues[columnIndex]) {
                    return (
                        <TableCell key={columnIndex} onClick={editData} sx={cursorClass}>
                            {renderTableCellDataValue(item, dataColumn, columnIndex)}
                        </TableCell>
                    )
                }
                return (
                    <DatatableTableBodyRowContent key={columnIndex} item={item} dataColumn={dataColumn}
                                                  type={getDataType(columnIndex)}
                                                  editData={editData} cursorClass={cursorClass}/>
                )

            })}
            {renderTableRowOperations(item, rowIndex)}
        </TableRow>
    )

})

export default DatatableTableBodyRow;