import {ButtonGroup} from "@mui/material";
import React from "react";

const classes = {
    customButtonGroupTop: {
        margin: '8px 0 8px 0'
    }
};

interface CustomButtonGroupProps {
    children: any | any[]
}

const CustomButtonGroup = React.memo(({children}: CustomButtonGroupProps) => {
    return (
        <ButtonGroup sx={classes.customButtonGroupTop} variant="contained">
            {children}
        </ButtonGroup>
    )
});

export default CustomButtonGroup;