import React, {useState} from "react";
import {Grid, IconButton, Tooltip} from "@mui/material";
import {Add, Search} from "@mui/icons-material";
import CustomTextField from "../typescript/custom-text-field/custom-text-field";
import {CustomDialog} from "../typescript/custom-dialog/custom-dialog";
import SearchDialog from "../typescript/search-dialog/search-dialog";
import {getTableCellContent} from "../typescript/util/string-util";

/**
 * @deprecated
 */
const ForeignKey = React.memo(props => {

    const [showSearchDialog, setShowSearchDialog] = useState(false);

    const [showAddDialog, setShowAddDialog] = useState(false);

    const getValueKey = () => {
        if (props.value) {
            return props.value[props.fieldKey] || '';
        } else {
            return ''
        }
    }
    const getValueDescription = () => {
        if (props.value) {
            const content = getTableCellContent(props.value, props.fieldDescription);
            return content || '';
        } else {
            return '';
        }
    }

    const openSearchDialog = () => {
        setShowSearchDialog(true);
    }

    const closeSearchDialog = () => {
        setShowSearchDialog(false);
    }

    const openAddDialog = () => {
        setShowAddDialog(true);
    }

    const closeAddDialog = (item) => {
        setShowAddDialog(false);
        props.onAdd(item);
    }

    const selectDataFromSearchDialog = (item, index) => {
        closeSearchDialog();
        props.onSelect(item, index);
    }

    const selectDataFromAddDialog = (item, index) => {
        closeAddDialog();
        props.onChange(item[props.fieldKey], index);
    }

    const renderSearchDialog = () => {
        return showSearchDialog && (
            <SearchDialog open={showSearchDialog} onClose={closeSearchDialog} findAll={props.findAll}
                          dataColumnNames={props.dataColumnNames} dataColumns={props.dataColumns}
                          dataTypes={props.dataTypes} label={props.label}
                          onSelectData={selectDataFromSearchDialog} initialSort={props.initialSort}/>
        )
    }

    const renderAddDialog = () => {
        return showAddDialog && (
            <CustomDialog open={showAddDialog} onClose={closeAddDialog} onSelectData={selectDataFromAddDialog}
                          title={props.label + ' - Inserir'}>{props.addComponent}</CustomDialog>
        )
    }

    const onChangeValue = (event) => {
        props.onChange(event.target.value);
    }

    function renderAddButton() {
        if (props.disabled) {
            return props.onAdd && (
                <IconButton color={"primary"} disabled onClick={() => {
                    openAddDialog()
                }}><Add/></IconButton>
            );
        } else {
            return props.onAdd && (
                <Tooltip title='Inserir'>
                    <IconButton color={"primary"} onClick={() => {
                        openAddDialog()
                    }}><Add/></IconButton>
                </Tooltip>
            );
        }
    }

    const renderSearchButton = () => {
        if (props.disabled) {
            return (
                <IconButton color={"primary"} disabled onClick={() => {
                    openSearchDialog()
                }}><Search/></IconButton>
            )
        } else {
            return (
                <Tooltip title='Pesquisar'>
                    <IconButton color={"primary"} onClick={() => {
                        openSearchDialog()
                    }}><Search/></IconButton>
                </Tooltip>
            )
        }
    }

    return (
        <React.Fragment>
            {renderSearchDialog()}
            {renderAddDialog()}
            <Grid container spacing={1}>
                <Grid item>
                    {renderAddButton()}
                    {renderSearchButton()}
                </Grid>
                <Grid item xs>
                    <Grid container spacing={1}>
                        <Grid item xs={6} md={3}>
                            <CustomTextField fullWidth={props.fullWidth} autoFocus={props.autoFocus}
                                             required={props.required}
                                             label={props.label} value={getValueKey()} disabled={props.disabled}
                                             onChange={onChangeValue}/>
                        </Grid>
                        <Grid item xs={6} md={9}>
                            <CustomTextField fullWidth={props.fullWidth} label={props.labelDescription}
                                             disabled={props.disabled}
                                             value={getValueDescription()}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    )

});

export default ForeignKey;
