import React, {RefObject} from 'react';
import {SaleListHeaderFilterEnum} from "./enum/sale-list-header-filter-enum";
import SaleListHeaderFilterEmission from "./sale-list-header-filter-emission";
import SaleListHeaderFilterSaleId from "./sale-list-header-filter-sale-id";
import SaleListHeaderFilterPersonName from "./sale-list-header-filter-person-name";

interface SaleListHeaderFilterItemsProps {
    saleFilter: SaleListHeaderFilterEnum;
    startDate: string | undefined;
    endDate: string | undefined;
    saleId: number | undefined;
    personName: string | undefined;
    startDateRef: RefObject<HTMLInputElement>;
    endDateRef: RefObject<HTMLInputElement>;
    saleIdRef: RefObject<HTMLInputElement>;
    personNameRef: RefObject<HTMLInputElement>;
}

const SaleListHeaderFilterItems = React.memo(({
                                             saleFilter,
                                             startDate,
                                             endDate,
                                             saleId,
                                             personName,
                                             startDateRef,
                                             endDateRef,
                                             saleIdRef,
                                             personNameRef
                                         }: SaleListHeaderFilterItemsProps) => {

    switch (saleFilter) {
        case SaleListHeaderFilterEnum.EMISSION:
            return <SaleListHeaderFilterEmission startDate={startDate} endDate={endDate} startDateRef={startDateRef} endDateRef={endDateRef}/>;
        case SaleListHeaderFilterEnum.SALE_ID:
            return <SaleListHeaderFilterSaleId saleId={saleId} ref={saleIdRef}/>;
        default:
            return <SaleListHeaderFilterPersonName personName={personName} ref={personNameRef}/>;
    }

});

export default SaleListHeaderFilterItems;
