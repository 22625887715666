import React, {useCallback, useEffect, useState} from "react";
import {changePageTitle} from "../typescript/redux/actions";
import {useDispatch} from "react-redux";
import {DocumentService} from "../document/document.service";
import {Grid, Typography} from "@mui/material";
import DocumentosEmAberto from "./documentos-em-aberto";
import DocumentosPagos from "./documentos-pagos";

const PaymentFlux = () => {
    const [dashboard, setDashboard] = useState(null);
    const dispatch = useDispatch();

    const findAll = useCallback(async () => {
        const result = await DocumentService.getPaymentFlux();
        setDashboard(result);
    }, []);

    useEffect(() => {
        dispatch(changePageTitle("Fluxo de pagamentos"));
        findAll().then();
    }, [dispatch, findAll]);

    return dashboard && (
        <React.Fragment>
            <Typography variant="h6" paragraph={true} align={"center"}>Inadimplentes</Typography>
            <Grid container spacing={2}>
                <DocumentosEmAberto documentos={dashboard.documentosInadimplentes.documentosAReceber}
                                    title={"À receber"} findAll={findAll}/>
                <DocumentosEmAberto documentos={dashboard.documentosInadimplentes.documentosAPagar} title={"À pagar"}
                                    findAll={findAll}/>
            </Grid>

            <Typography variant="h6" paragraph={true} align={"center"}>Vencendo hoje</Typography>
            <Grid container spacing={2}>
                <DocumentosEmAberto documentos={dashboard.documentosVencendoHoje.documentosAReceber} title={"À receber"}
                                    findAll={findAll}/>
                <DocumentosEmAberto documentos={dashboard.documentosVencendoHoje.documentosAPagar} title={"À pagar"}
                                    findAll={findAll}/>
            </Grid>

            <Typography variant="h6" paragraph={true} align={"center"}>Vencendo no restante da semana</Typography>
            <Grid container spacing={2}>
                <DocumentosEmAberto documentos={dashboard.documentosVencendoRestoDaSemana.documentosAReceber}
                                    title={"À receber"} findAll={findAll}/>
                <DocumentosEmAberto documentos={dashboard.documentosVencendoRestoDaSemana.documentosAPagar}
                                    title={"À pagar"} findAll={findAll}/>
            </Grid>

            <Typography variant="h6" paragraph={true} align={"center"}>Pagos na semana</Typography>
            <Grid container spacing={2}>
                <DocumentosPagos documentos={dashboard.documentosPagosNaSemana.documentosAReceber} title={"À receber"}
                                 findAll={findAll}/>
                <DocumentosPagos documentos={dashboard.documentosPagosNaSemana.documentosAPagar} title={"À pagar"}
                                 findAll={findAll}/>
            </Grid>
        </React.Fragment>
    )

}

export default React.memo(PaymentFlux);