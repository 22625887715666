import React, {useCallback, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {SaleService} from "../sale/sale.service";
import {Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography} from "@mui/material";
import CustomTextField from "../typescript/custom-text-field/custom-text-field";
import CustomRow from "../typescript/custom-row/custom-row";
import {changePageTitle} from "../typescript/redux/actions";
import {NumberUtil} from "../typescript/util/number-util";
import {DateUtil} from "../typescript/util/2.0.0/date-util";
import {ProfileResponse} from "../sale/profile-response";
import {Profile} from "../sale/profile";
import {SubProfile} from "../sale/sub-profile";

const SaleProfile = () => {
    const [startDate, setStartDate] = useState(DateUtil.firstDayOfCurrentMonthInJavaSqlDate());
    const [endDate, setEndDate] = useState(DateUtil.toJavaSqlDate(DateUtil.currentDate()));
    const [profile, setProfile] = useState<ProfileResponse | null>(null);
    const dispatch = useDispatch();

    const getProfile = useCallback(async () => {
        const result = await SaleService.getProfile(startDate, endDate);
        setProfile(result.data);
    }, [startDate, endDate]);

    useEffect(() => {
        dispatch(changePageTitle("Perfil das vendas"));
        getProfile().then();
    }, [dispatch, getProfile]);

    const onChangeStartDate = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStartDate(event.target.value)
    }

    const onChangeEndDate = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEndDate(event.target.value)
    }

    const renderSubPerfilDescricao = (perfil: Profile) => {
        return (
            <TableRow>
                {perfil.subPerfis.map((subPerfil: SubProfile, index: number) => (
                    <TableCell key={index}>
                        {subPerfil.descricao}
                    </TableCell>
                ))}
            </TableRow>
        )
    }

    const calculaPercentual = (quantidade: number, totalQuantidade: number) => {
        return NumberUtil.arredonda(quantidade / totalQuantidade * 100, 2).toLocaleString() + "%";
    }

    const renderSubPerfilQuantidade = (perfil: Profile) => {
        return (
            <TableRow>
                {perfil.subPerfis.map((subPerfil: SubProfile, index: number) => (
                    <TableCell key={index}>
                        {subPerfil.quantidade} ({calculaPercentual(subPerfil.quantidade, perfil.totalQuantidade)})
                    </TableCell>
                ))}
            </TableRow>
        )
    }

    const renderPerfil = (index: number, perfil: Profile) => {
        return (
            <TableRow key={index}>
                <TableCell>
                    {perfil.descricao} - Total: {perfil.totalQuantidade}
                    <TableContainer component={Paper}>
                        <Table>
                            <TableBody>
                                {renderSubPerfilDescricao(perfil)}
                                {renderSubPerfilQuantidade(perfil)}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </TableCell>
            </TableRow>
        )
    }

    return profile && (
        <React.Fragment>
            <CustomRow>
                <CustomTextField fullWidth required label="Data inicial" type="date" value={startDate || ''}
                                 InputLabelProps={{shrink: true}} onChange={onChangeStartDate}/>
                <CustomTextField fullWidth required label="Data final" type="date" value={endDate || ''}
                                 InputLabelProps={{shrink: true}} onChange={onChangeEndDate}/>
            </CustomRow>
            <Typography variant="h6" gutterBottom>
                Total de OS do período: {profile.totalOs}
            </Typography>
            <Typography variant="h6" gutterBottom>
                Média de idade das pessoas com OS no período: {profile.mediaDeIdade}
            </Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableBody>
                        {profile.perfis.map((perfil, index) => renderPerfil(index, perfil))}
                    </TableBody>
                </Table>
            </TableContainer>
        </React.Fragment>
    );
}

export default React.memo(SaleProfile);
