import React, {useCallback, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {UserService} from "./user.service";
import {changePageTitle} from "../typescript/redux/actions";
import {Datatable} from "../index";
import {Delete} from "@mui/icons-material";
import {useNavigate, useParams} from "react-router-dom";

export function WorkList() {

    let navigate = useNavigate();
    const {id} = useParams();
    const [pageParams, setPageParams] = useState(null);
    const dispatch = useDispatch();

    const findAll = useCallback(async (number, size, sort) => {
        const items = await UserService.findAllWorks(id, number, size, sort);
        setPageParams({
            number: number,
            size: size,
            sort: sort,
            items: items
        });
    }, [id]);

    useEffect(() => {
        dispatch(changePageTitle("Dia de trabalho do usuário " + id));
        findAll(0, parseInt(process.env.REACT_APP_PAGE_SIZE), "id,asc", '').then();
    }, [dispatch, findAll, id]);

    const editItem = (item) => {
        navigate('/user/' + id + '/work/' + item.id);
    }

    const insertItem = () => {
        navigate('/user/' + id + '/work');
    }

    const goBack = () => {
        navigate('/users');
    }

    const deleteItem = (item) => {
        UserService.deleteWork(id, item.id).then(() => {
            findAll(pageParams.number, pageParams.size, pageParams.sort).then();
        }).catch(() => {

        })
    }

    const headerOperations = [
        {onClick: goBack, label: () => 'Voltar'},
        {color: () => "primary", onClick: insertItem, label: () => 'Inserir'}
    ]

    const rowOperations = [
        {color: () => "primary", onClick: deleteItem, label: () => 'Excluir', icon: () => <Delete/>},
    ]

    return pageParams && (
        <Datatable
            dataColumnNames={['Dia da semana', '1° Turno - Início', '1° Turno - Fim', '2° Turno - Início', '2° Turno - Fim']}
            dataColumns={['dayOfWeek', 'firstRoundEnter', 'firstRoundExit', 'secondRoundEnter', 'secondRoundExit']}
            number={pageParams.number} size={pageParams.size} sort={pageParams.sort} filter={pageParams.filter}
            dataTypes={['dayOfWeek', 'time', 'time', 'time', 'time']} findAll={findAll} editData={editItem}
            data={pageParams.items.content} totalData={pageParams.items.totalElements}
            headerOperations={headerOperations} rowOperations={rowOperations}/>
    );
}