import {Breakpoint, Dialog, DialogContent, DialogTitle} from "@mui/material";
import React from "react";

interface CustomDialogProps {
    onClose?: (item: any) => void;
    children: any[] | any;
    title: string;
    open: boolean;
    maxWidth?: Breakpoint;
}

export function CustomDialog({onClose, children, title, open, maxWidth}: CustomDialogProps) {

    const childrenWithProps = React.Children.map(children, child => {
        if (React.isValidElement(child)) {
            const props = {onClose: onClose, dialog: true};
            return React.cloneElement(child, props);
        }
        return child;
    });

    const renderTitle = () => {
        return title && (
            <DialogTitle><strong>{title}</strong></DialogTitle>
        )
    }

    return (
        <Dialog open={open} fullWidth maxWidth={maxWidth ? maxWidth : "xl"}>
            {renderTitle()}
            <DialogContent>
                {childrenWithProps}
            </DialogContent>
        </Dialog>
    )
}
