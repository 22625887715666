import React, {FormEvent, useCallback, useEffect, useState} from "react";
import {State} from "../state/state";
import CustomTextField from "../custom-text-field/custom-text-field";
import CustomButtonGroup from "../custom-button-group/custom-button-group";
import CustomButton from "../custom-button/custom-button";
import {useDispatch} from "react-redux";
import {changePageTitle, setAlert} from "../redux/actions";
import {BrandTax} from "./brand-tax";
import {BrandService} from "../../brand/brand.service";

interface BrandTaxDetailProps {
    brand: number;
    tax?: number | null;
    onClose?: (state?: State) => void;
}

const BrandTaxDetail = ({brand, tax, onClose}: BrandTaxDetailProps) => {

    const [item, setItem] = useState<BrandTax>(BrandService.createTax());
    const dispatch = useDispatch();

    const findOne = useCallback(async (id: number) => {
        const result = await BrandService.findTax(brand, id);
        setItem(result);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (tax) {
            dispatch(changePageTitle("Taxa " + tax + " da bandeira " + brand));
            findOne(tax).then();
        } else {
            dispatch(changePageTitle("Nova taxa da bandeira " + brand));
        }
    }, [tax]); // eslint-disable-line react-hooks/exhaustive-deps

    const save = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        BrandService.saveTax(brand, item).then((result) => {
            if (onClose) {
                onClose(result.data);
            }
        }).catch((error) => {
            let message = 'Erro ao criar taxa';
            if (error.response.data.message) {
                message = (message + ' - ' + error.response.data.message);
            }
            dispatch(setAlert({show: true, message: message, severity: 'error'}));
        })
    }

    const onChangeParcelNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
        setItem({
            ...item,
            parcelNumber: event.target.value
        })
    }

    const onChangeTax = (event: React.ChangeEvent<HTMLInputElement>) => {
        setItem({
            ...item,
            tax: event.target.value
        })
    }

    const cancel = () => {
        if (onClose) {
            onClose();
        }
    }

    return (
        <form onSubmit={save}>
            <CustomTextField fullWidth autoFocus type="number" label="Número de parcelas" value={item.parcelNumber}
                             onChange={onChangeParcelNumber} required/>
            <CustomTextField fullWidth label="Taxa (%)" type="number" required value={item.tax} onChange={onChangeTax}
                             InputProps={{inputProps: {min: 0, step: "any"}}}/>
            <CustomButtonGroup>
                <CustomButton color="primary" type="submit">Salvar</CustomButton>
                <CustomButton type="button" color="neutral" onClick={cancel}>Cancelar</CustomButton>
            </CustomButtonGroup>
        </form>
    )

}
export default React.memo(BrandTaxDetail);