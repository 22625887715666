import React from "react";
import {TableCell, TableRow} from "@mui/material";

const SaleListBodyTableContentRowEmpty = React.memo(() => {

    return (
        <TableRow>
            <TableCell colSpan={99}>
                Nenhum registro encontrado
            </TableCell>
        </TableRow>
    )

});

export default SaleListBodyTableContentRowEmpty;
