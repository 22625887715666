import React, {useEffect} from "react";
import {changePageTitle} from "../typescript/redux/actions";
import {useDispatch} from "react-redux";
import {Grid} from "@mui/material";
import GraphSoldValue from "./graph-sold-value";
import GraphEntryWithinGiven from "./graph-entry-within-given";
import GraphTicketAvg from "./graph-ticket-avg";
import GraphSoldValueBySeller from "./graph-sold-value-by-seller";
import GraphSoldValueByParcelQuantity from "./graph-sold-value-by-parcel-quantity";
import GraphSoldValueByCategory from "./graph-sold-value-by-category";
import GraphSoldQuantityByCategory from "./graph-sold-quantity-by-category";
import GraphAbcCurve from "./graph-abc-curve";
import GraphProductConsumeByHour from "./graph-product-consume-by-hour";

const SaleOverview = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(changePageTitle("Visão geral de vendas fechadas por data de fechamento da venda"));
    }, [dispatch]);

    return (
        <React.Fragment>
            <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                    <GraphSoldValue/>
                </Grid>
                <Grid item md={6} xs={12}>
                    <GraphTicketAvg/>
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                    <GraphSoldValueBySeller/>
                </Grid>
                <Grid item md={6} xs={12}>
                    <GraphSoldValueByParcelQuantity/>
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                    <GraphSoldValueByCategory/>
                </Grid>
                <Grid item md={6} xs={12}>
                    <GraphSoldQuantityByCategory/>
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                    <GraphAbcCurve vendas={true} porProduto={true} title={"Curva ABC de vendas por produto"}
                                   name={"Vendas"} labelX={"Quantidade de produtos"} labelY={"% Vendas"}/>
                </Grid>
                <Grid item md={6} xs={12}>
                    <GraphAbcCurve vendas={true} porProduto={false} title={"Curva ABC de vendas por categoria"}
                                   name={"Vendas"} labelX={"Quantidade de categorias"} labelY={"% Vendas"}/>
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                    <GraphAbcCurve vendas={false} porProduto={true} title={"Curva ABC de lucro por produto"}
                                   name={"Lucro"} labelX={"Quantidade de produtos"} labelY={"% Lucro"}/>
                </Grid>
                <Grid item md={6} xs={12}>
                    <GraphAbcCurve vendas={false} porProduto={false} title={"Curva ABC de lucro por categoria"}
                                   name={"Lucro"} labelX={"Quantidade de categorias"} labelY={"% Lucro"}/>
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                    <GraphProductConsumeByHour/>
                </Grid>
                <Grid item md={6} xs={12}>
                    <GraphEntryWithinGiven/>
                </Grid>
            </Grid>

        </React.Fragment>
    );
}

export default React.memo(SaleOverview);