import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import SaleDetail from "./sale-detail";

const SaleDetailPage = React.memo(() => {

    const {id} = useParams();
    let navigate = useNavigate();

    const onClose = () => {
        navigate('/sales');
    }

    return (
        <SaleDetail id={id ? parseInt(id) : null} onClose={onClose}/>
    )
});

export default SaleDetailPage;
