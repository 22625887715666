import {SortParams} from "./sort-params";
import {DirectionEnum} from "./direction-enum";

export default class PageParamsUtil {

    static getSortType = (sortIndex: number, dataTypes: string[]) => {
        if (sortIndex === -1) {
            return 'text'
        } else {
            const type = dataTypes[sortIndex];
            switch (type) {
                case 'number':
                case 'integer':
                case 'decimal':
                    return 'number';
                case 'text' :
                case 'currency' :
                    return 'text';
                case 'date' :
                    return 'date';
                case 'time' :
                    return 'time';
                default:
                    return 'text';
            }
        }
    }

    static getSortParams = (sort?: string, dataColumns?: string[], dataTypes?: string[]) => {

        let sortParams = new SortParams("", DirectionEnum.ASC, -1, "");

        if (sort && dataColumns && dataTypes) {
            const parts = sort.split(',');
            sortParams.field = parts[0];
            sortParams.direction = (parts[1] === 'asc') ? DirectionEnum.ASC : DirectionEnum.DESC;
            sortParams.index = dataColumns.indexOf(sortParams.field);
            sortParams.type = this.getSortType(sortParams.index, dataTypes);
        }

        return sortParams;

    }

}