import React, {FormEvent, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {SaleService} from "../sale/sale.service";
import CustomTextField from "../typescript/custom-text-field/custom-text-field";
import CustomButton from "../typescript/custom-button/custom-button";
import CustomRow from "../typescript/custom-row/custom-row";
import {changePageTitle, setAlert} from "../typescript/redux/actions";
import {FileUtil} from "../typescript/util/file-util";
import * as StringUtil from "../typescript/util/string-util";
import {DateUtil} from "../typescript/util/2.0.0/date-util";

const LastSale = () => {
    const [startDate, setStartDate] = useState(DateUtil.firstDayOfCurrentMonthInJavaSqlDate());
    const [endDate, setEndDate] = useState(DateUtil.toJavaSqlDate(DateUtil.currentDate()));
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(changePageTitle("Última compra"));
    }, [dispatch]);

    const onChangeStartDate = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStartDate(event.target.value)
    }

    const onChangeEndDate = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEndDate(event.target.value)
    }

    const print = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        SaleService.ultimaCompra(startDate, endDate).then(function (result) {
            FileUtil.openPdfInNewTab(result);
        }, (result) => {
            const error = StringUtil.decodeUint8Array(result);
            dispatch(setAlert({show: true, message: error.message, severity: 'error'}));
        })
    }

    return (
        <form onSubmit={print}>
            <CustomRow>
                <CustomTextField fullWidth required label="Data inicial" type="date" value={startDate || ''}
                                 InputLabelProps={{shrink: true}} onChange={onChangeStartDate}/>
                <CustomTextField fullWidth required label="Data final" type="date" value={endDate || ''}
                                 InputLabelProps={{shrink: true}} onChange={onChangeEndDate}/>
                <CustomButton color="primary" type="submit">Imprimir</CustomButton>
            </CustomRow>
        </form>
    );
}

export default React.memo(LastSale);