import React, {FormEvent, useCallback, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {AuthService} from "../../auth/auth.service";
import {SaleService} from "../../../sale/sale.service";
import {setAlert} from "../../redux/actions";
import {UserService} from "../../../user/user.service";
import {LaboratoryService} from "../../../laboratory/laboratory.service";
import {PersonService} from "../../../person/person.service";
import {Delete as DeleteIcon} from "@mui/icons-material";
import {NumberUtil} from "../../util/number-util";
import {Typography} from "@mui/material";
import CustomRow from "../../custom-row/custom-row";
import CustomTextField from "../../custom-text-field/custom-text-field";
import SelectOption from "../../select-option/select-option";
import CustomButtonGroup from "../../custom-button-group/custom-button-group";
import CustomButton from "../../custom-button/custom-button";
import {Sale} from "../../../sale/sale";
import Datatable from "../../datatable/datatable";
import {ColorIconEnum} from "../../util/color-icon-enum";
import ItemToInsert from "./item-to-insert";
import {SaleItem} from "../../../sale/sale-item";
import SaleItemDialog from "./sale-item-dialog";
import ForeignKey from "../../foreign-key/foreign-key";
import {User} from "../../../user/user";
import {Laboratory} from "../../../laboratory/laboratory";
import {Person} from "../../../person/person";
import {Product} from "../../../product/product";
import {DateUtil} from "../../util/2.0.0/date-util";
import {SelectChangeEvent} from "@mui/material/Select/SelectInput";
import {PersonDetail} from "../../../person/person-detail";
import CloseSaleDialog from "../close/close-sale-dialog";
import PreencheDadosAcessoriosDialog from "../../preenche-dados-acessorios/preenche-dados-acessorios-dialog";
import {QuestionDialog} from "../../question-dialog/question-dialog";

interface SaleDetailProps {
    id?: number | null;
    onClose?: (sale?: Sale) => void;
}

interface SaleItemSelectedProps {
    index: number;
    saleItem: SaleItem | null;
}

const SaleDetail = React.memo(({id, onClose}: SaleDetailProps) => {

    const [item, setItem] = useState<Sale>(SaleService.createSale());
    const dispatch = useDispatch();
    const [saleItemSelected, setSaleItemSelected] = useState<SaleItemSelectedProps>({
        index: -1,
        saleItem: null
    });
    const isMaster = AuthService.userInRole("MASTER");
    const [saleToClose, setSaleToClose] = useState<Sale | null>();
    const [personToPreencheDadosAcessorios, setPersonToPreencheDadosAcessorios] = useState<Person | null>();
    const [question, setQuestion] = useState<string | null>(null);

    const findOne = useCallback(async (idParam: number) => {
        const result = await SaleService.findOne(idParam);
        setItem(result);
    }, []);

    useEffect(() => {
        if (id) {
            findOne(id).then();
        }
    }, [dispatch, findOne, id]);

    const hasLensOrFrames = () => {
        return item.itens.some(element => {
            return (element.servico?.categoria?.lente || element.servico?.categoria?.armacao);
        });
    }

    const hasBag = () => {
        return item.itens.some(element => {
            return (SaleService.CPCB_BASIC_ID === element.servico?.id);
        });
    }

    const save = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        if (!item?.itens || item.itens.length === 0) {
            dispatch(setAlert({show: true, message: 'Venda sem itens', severity: 'error'}));
            return;
        }
        if (hasLensOrFrames() && !hasBag()) {
            setQuestion("Deseja inserir item 6656 (CPCB BASIC) na venda?");
        } else {
            saveSale(false);
        }
    }

    const saveSale = (insertCpcbBasic: boolean) => {
        SaleService.save(item, insertCpcbBasic).then((result) => {
            if (!result.data.dataHoraFechamento) {
                setSaleToClose(result.data);
            } else {
                if (onClose) {
                    onClose(result.data);
                }
            }
        }).catch((error) => {
            let message = 'Erro ao salvar venda';
            if (error.response.data.message) {
                message = (message + ' - ' + error.response.data.message);
            }
            dispatch(setAlert({show: true, message: message, severity: 'error'}));
        })
    }

    const cancel = () => {
        if (onClose) {
            onClose();
        }
    }

    const onChangeVendedor = async (newId: number) => {
        setItem({
            ...item,
            itens: [],
            vendedor: {
                id: newId
            }
        })
        if (newId) {
            const vendedor = await UserService.findOne(newId);
            if (vendedor) {
                setItem({
                    ...item,
                    vendedor: vendedor
                })
            }
        }
    }

    const onChangeLaboratorio = async (newId: number) => {
        setItem({
            ...item,
            laboratorio: {
                id: newId
            }
        })
        if (newId) {
            const laboratorio = await LaboratoryService.findOne(newId);
            if (laboratorio) {
                setItem({
                    ...item,
                    laboratorio: laboratorio
                })
            }
        }
    }

    const onSelectVendedor = (vendedor: User | null) => {
        if (vendedor) {
            setItem({
                ...item,
                vendedor: vendedor
            })
        }
    }

    const onSelectLaboratorio = (laboratorio: Laboratory | null) => {
        if (laboratorio) {
            setItem({
                ...item,
                laboratorio: laboratorio
            })
        }
    }

    const onChangeCliente = async (newId: number) => {
        setItem({
            ...item,
            pessoa: {
                id: newId,
                parentes: []
            }
        })
        if (newId) {
            const cliente = await PersonService.findOneCliente(newId);
            if (cliente) {
                setItem({
                    ...item,
                    pessoa: cliente
                })
            }
        }
    }

    const onAddCliente = async (cliente: Person | null) => {
        if (cliente) {
            setItem({
                ...item,
                pessoa: cliente
            })
        }
    }

    const findClientes = (page?: number, size?: number, sort?: string, filter?: string) => {
        return PersonService.findAll(page, size, sort, filter, true);
    }

    const insertSaleItem = (saleItemNovo: SaleItem | null) => {
        if (!saleItemNovo?.servico) {
            throw new Error("Serviço required");
        }
        saleItemNovo.numeroItem = SaleService.getNumeroProximoItem(item.itens);
        let itemsToChange = [...item.itens];
        itemsToChange.push(saleItemNovo);
        const previsaoEntrega = calculaPrevisaoEntrega(saleItemNovo.servico);
        setItem({
            ...item,
            itens: itemsToChange,
            previsaoEntrega: previsaoEntrega ? previsaoEntrega : ''
        });
    }

    const updateSaleItem = (saleItemEditado: SaleItem) => {
        let itemsToChange = [...item.itens];
        itemsToChange[saleItemSelected.index] = saleItemEditado;
        setItem({
            ...item,
            itens: itemsToChange
        });
    }

    const calculaPrevisaoEntrega = (servicoNovo: Product) => {
        if (servicoNovo.prazoEntrega) {
            let novaPrevisaoEntrega = DateUtil.addDays(new Date(), Number(servicoNovo.prazoEntrega));
            if (novaPrevisaoEntrega.getDay() === 0) {
                novaPrevisaoEntrega = DateUtil.addDays(novaPrevisaoEntrega, 1);
            } else if (novaPrevisaoEntrega.getDay() === 6) {
                novaPrevisaoEntrega = DateUtil.addDays(novaPrevisaoEntrega, 2);
            }
            if (!item.previsaoEntrega || DateUtil.javaSqlDateToDate(item.previsaoEntrega) < novaPrevisaoEntrega) {
                return DateUtil.toJavaSqlDate(novaPrevisaoEntrega);
            }
        }
        return item.previsaoEntrega;
    }

    const deleteItem = (itemToDelete: SaleItem, index: number) => {
        let itemsToChange = [...item.itens];
        itemsToChange.splice(index, 1);
        setItem({
            ...item,
            itens: itemsToChange
        });
    }

    const editItem = (itemSelected: SaleItem, index: number) => {
        setSaleItemSelected({
            index: index,
            saleItem: itemSelected
        });
    }

    const rowOperations = [{
        color: () => ColorIconEnum.PRIMARY,
        onClick: deleteItem,
        label: () => 'Excluir',
        icon: () => <DeleteIcon/>,
        disabled: () => {
            return !!item.dataHoraFechamento
        }
    },]

    const valorTotalVenda = () => {
        const valor = item.itens.reduce((valorInicial, itemDaVenda) => valorInicial + ((itemDaVenda.quantidade || 0) * (itemDaVenda.valorUnitario || 0)), 0)
        return NumberUtil.currencyFormat(valor);
    }

    const onCloseSaleItem = (saleItemEditado: SaleItem | null) => {
        if (saleItemEditado) {
            updateSaleItem(saleItemEditado);
        }
        setSaleItemSelected({
            index: -1,
            saleItem: null
        });
    }

    const renderItemToInsert = () => {
        return !item?.dataHoraFechamento && (
            <ItemToInsert insertItem={insertSaleItem} warrantyOperation={'GARANTIA' === item.operation}/>
        )
    }

    const setPrevisaoEntrega = (event: React.ChangeEvent<HTMLInputElement>) => {
        setItem({
            ...item,
            previsaoEntrega: event.target.value
        })
    }

    const changeDoctor = (event: React.ChangeEvent<HTMLInputElement>) => {
        setItem({
            ...item,
            doctor: event.target.value
        })
    }

    const changeCrm = (event: React.ChangeEvent<HTMLInputElement>) => {
        setItem({
            ...item,
            crm: event.target.value
        })
    }

    const changeOperation = (event: SelectChangeEvent) => {
        setItem({
            ...item,
            operation: event.target.value
        })
    }

    const operationOptions = [
        {name: 'VENDA', description: 'Venda'},
        {name: 'GARANTIA', description: 'Garantia'},
        {name: 'TROCA', description: 'Troca'},
        {name: 'DEVOLUCAO', description: 'Devolução'},
        {name: 'BRINDE', description: 'Brinde'},
        {name: 'BONIFICACAO', description: 'Bonificação'},
        {name: 'VOUCHER', description: 'Voucher'}
    ];

    function convertDate() {
        if (item.createdDate) {
            return DateUtil.javaLocalDateTimeToDate(item.createdDate).toLocaleString();
        }
        return "";
    }

    const findPerson = async (personId: number) => {
        const person = await PersonService.findOne(personId);
        const accessoryDataFilled = (person.influenciacompras.length > 0 &&
            person.decidecompras.length > 0 && person.conheceulojas.length > 0);
        if (accessoryDataFilled) {
            if (onClose) {
                onClose(item);
            }
        } else {
            setPersonToPreencheDadosAcessorios(person);
        }
    }

    const closeCloseSaleDialog = (success: boolean) => {
        setSaleToClose(null);
        if (success && item.pessoa?.id) {
            findPerson(item.pessoa?.id).then();
        } else {
            if (onClose) {
                onClose(item);
            }
        }
    }

    const closeQuestionDialog = (response: boolean) => {
        setQuestion(null);
        saveSale(response);
    }

    const renderCloseSaleDialog = () => {
        return saleToClose && <CloseSaleDialog onClose={closeCloseSaleDialog} sale={saleToClose}/>;
    }

    const renderQuestionDialog = () => {
        return question && <QuestionDialog onClose={closeQuestionDialog} question={question} title={"Inserir Bag"}/>;
    }

    const closePreencheDadosAcessoriosDialog = () => {
        setPersonToPreencheDadosAcessorios(null);
        if (onClose) {
            onClose(item);
        }
    }

    const renderPreencheDadosAcessorios = () => {
        return personToPreencheDadosAcessorios &&
            <PreencheDadosAcessoriosDialog onClose={closePreencheDadosAcessoriosDialog}
                                           person={personToPreencheDadosAcessorios}/>;
    }

    return item && (
        <React.Fragment>
            {renderQuestionDialog()}
            {renderCloseSaleDialog()}
            {renderPreencheDadosAcessorios()}
            {saleItemSelected.saleItem &&
                <SaleItemDialog open={true} saleItem={saleItemSelected.saleItem} onClose={onCloseSaleItem}
                                saleItemClosed={!!item.dataHoraFechamento} warrantyOperation={'GARANTIA' === item.operation}/>}
            <form onSubmit={save}>
                <CustomRow>
                    <ForeignKey fullWidth autoFocus required label="Vendedor" value={item.vendedor} fieldKey={'id'}
                                fieldDescription={'username'} labelDescription='Usuário'
                                dataColumnNames={['ID', 'Usuário']} disabled={!isMaster}
                                dataColumns={['id', 'username']} dataTypes={['number', 'text']}
                                onChange={onChangeVendedor} findAll={UserService.findAll} onSelect={onSelectVendedor}/>
                    <ForeignKey fullWidth required label="Cliente" value={item.pessoa} fieldKey={'id'}
                                disabled={!!item.dataHoraFechamento} fieldDescription={'nome'} labelDescription='Nome'
                                onChange={onChangeCliente} onSelect={onAddCliente} onAdd={onAddCliente}
                                findAll={findClientes} dataColumnNames={['ID', 'Nome']} dataColumns={['id', 'nome']}
                                addComponent={<PersonDetail cliente={true}/>} dataTypes={['number', 'text']}/>
                    <CustomTextField fullWidth label="Emissão" type="text" value={convertDate()}
                                     InputLabelProps={{shrink: true}} disabled/>
                </CustomRow>
                <CustomRow>
                    <ForeignKey fullWidth label="Laboratório" value={item.laboratorio} fieldKey={'id'}
                                fieldDescription={'description'} labelDescription='Descrição'
                                onChange={onChangeLaboratorio} onSelect={onSelectLaboratorio}
                                findAll={LaboratoryService.findAll} required={SaleService.hasLens(item)}
                                dataColumnNames={['ID', 'Descrição']} dataColumns={['id', 'description']}
                                dataTypes={['number', 'text']}/>
                    <CustomTextField fullWidth label="Previsão de entrega" type="date"
                                     value={item.previsaoEntrega || ''} InputLabelProps={{shrink: true}}
                                     onChange={setPrevisaoEntrega} disabled={!!item.dataHoraFechamento}/>
                </CustomRow>
                <CustomRow>
                    <CustomTextField fullWidth label="Médico" type="text" value={item.doctor || ''}
                                     onChange={changeDoctor} inputProps={{maxLength: 50}}/>
                    <CustomTextField fullWidth label="CRM" type="text" value={item.crm || ''} onChange={changeCrm} inputProps={{maxLength: 10}}/>
                    <SelectOption fullWidth label="Operação" value={item.operation} onChange={changeOperation} required
                                  items={operationOptions} itemValue='name' itemDescription='description' disabled={!!item.dataHoraFechamento}/>
                </CustomRow>
                {renderItemToInsert()}
                <Datatable data={item.itens} totalData={item.itens.length}
                           dataColumnNames={['Item', 'Produto', 'Quantidade', 'Valor unitário (R$)', 'Valor total (R$)', 'Observação', 'Depende']}
                           dataColumns={['numeroItem', 'servico.descricao', 'quantidade', 'valorUnitario', 'valorTotal', 'observacao', 'depende']}
                           dataTypes={['number', 'text', 'number', 'currency', 'currency', 'text', 'number']}
                           editData={editItem} rowOperations={rowOperations}/>

                <Typography variant={"h6"} gutterBottom>
                    Valor total da venda: {valorTotalVenda()}
                </Typography>
                <CustomButtonGroup>
                    <CustomButton color="primary" type="submit">Salvar</CustomButton>
                    <CustomButton type="button" color="neutral" onClick={cancel}>Cancelar</CustomButton>
                </CustomButtonGroup>
            </form>
        </React.Fragment>
    )

});

export default SaleDetail;
