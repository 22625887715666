import React, {FormEvent, useEffect, useState} from "react";
import {Dialog, DialogContent, DialogTitle} from "@mui/material";
import {Sale} from "../../../sale/sale";
import CustomButtonGroup from "../../custom-button-group/custom-button-group";
import CustomButton from "../../custom-button/custom-button";
import {FileUtil} from "../../util/file-util";
import * as StringUtil from "../../util/string-util";
import {setAlert} from "../../redux/actions";
import {useDispatch} from "react-redux";
import ForeignKey from "../../foreign-key/foreign-key";
import CustomTextField from "../../custom-text-field/custom-text-field";
import {ParameterService} from "../../../parameter/parameter.service";
import {FiscalmodelService} from "../../../fiscalmodel/fiscalmodel.service";
import {NfeService} from "../../../nfe/nfe.service";
import {GeraNfeParam} from "./gera-nfe-param";
import {FiscalModel} from "../../../fiscalmodel/fiscal-model";

interface GeraNfeDialogProps {
    onClose: (success: boolean) => void;
    sale: Sale
}

const GeraNfeDialog = React.memo(({sale, onClose}: GeraNfeDialogProps) => {

    let saleId: number;
    if (sale.id) {
        saleId = sale.id;
    } else {
        throw new Error('Sale id required');
    }
    const dispatch = useDispatch();

    const [clientePadrao, setClientePadrao] = useState(null);
    const [params, setParams] = useState<GeraNfeParam>({
        fiscalModel: null,
        cnpjCpf: '',
        nfeReferenciada: ''
    });

    useEffect(() => {
        const fetClientePadrao = async () => {
            const result = await ParameterService.getClientePadrao();
            setClientePadrao(result);
        }
        fetClientePadrao().then();
    }, []);

    const onChangeFiscalModel = async (id: number) => {
        setParams({
            ...params,
            fiscalModel: {
                id: id
            },
            nfeReferenciada: ''
        })
        if (id) {
            const fiscalModel = await FiscalmodelService.findOne(id);
            onSelectFiscalModel(fiscalModel);
        }
    }

    const onSelectFiscalModel = (fiscalModel: FiscalModel) => {
        if (fiscalModel) {
            setParams({
                ...params,
                fiscalModel: fiscalModel,
                nfeReferenciada: ''
            })
        }
    }

    const geraNfe = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        if (!params.fiscalModel?.id) return;
        NfeService.geraNFe(saleId, params.fiscalModel?.id, params.cnpjCpf, params.nfeReferenciada).then(function (result) {
            FileUtil.openPdfInNewTab(result);
            onClose(true);
        }, (result) => {
            const error = StringUtil.decodeUint8Array(result);
            dispatch(setAlert({show: true, message: error.message, severity: 'error'}));
        })
    }

    const onChangeCnpjCpf = (event: React.ChangeEvent<HTMLInputElement>) => {
        setParams({
            ...params,
            cnpjCpf: event.target.value
        })
    }

    const onChangeNfeReferenciada = (event: React.ChangeEvent<HTMLInputElement>) => {
        setParams({
            ...params,
            nfeReferenciada: event.target.value
        })
    }

    const renderCnpjCpf = () => {
        return sale.pessoa?.id === clientePadrao && (
            <CustomTextField fullWidth required label="CNPJ/CPF" value={params.cnpjCpf} onChange={onChangeCnpjCpf}/>
        )
    }

    const renderNfeReferenciada = () => {
        return params.fiscalModel?.finalidade === "2" && (
            <CustomTextField fullWidth required label="Nfe referenciada" value={params.nfeReferenciada}
                             onChange={onChangeNfeReferenciada}/>
        )
    }

    const cancel = () => {
        onClose(false);
    }

    const onCloseDialog = (event: Object, reason: string) => {
        if (reason !== 'backdropClick') {
            onClose(false);
        }
    }

    return (
        <Dialog fullWidth onClose={onCloseDialog} open={true}>
            <DialogTitle>Gerar nota fiscal</DialogTitle>
            <DialogContent>
                <form onSubmit={geraNfe}>
                    <ForeignKey fullWidth required autoFocus label="Modelo fiscal" value={params.fiscalModel}
                                fieldKey={'id'} fieldDescription={'descricao'} labelDescription='Descrição'
                                onChange={onChangeFiscalModel} onSelect={onSelectFiscalModel}
                                findAll={FiscalmodelService.findAll}
                                dataColumnNames={['ID', 'Descrição']} dataColumns={['id', 'descricao']}
                                dataTypes={['number', 'text']}/>
                    {renderCnpjCpf()}
                    {renderNfeReferenciada()}
                    <CustomButtonGroup>
                        <CustomButton color="primary" type="submit">Gerar</CustomButton>
                        <CustomButton type="button" color="neutral" onClick={cancel}>Cancelar</CustomButton>
                    </CustomButtonGroup>
                </form>
            </DialogContent>
        </Dialog>
    )

});

export default GeraNfeDialog;
