import React from "react";
import {Alert, Snackbar} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {setAlert} from "../typescript/redux/actions";
import {RootState} from "../typescript/redux/store";

const AppAlert = React.memo(() => {

    const alert = useSelector((store: RootState) => store.pageState.alert);
    const dispatch = useDispatch();

    const closeAlert = () => {
        dispatch(setAlert({
            ...alert,
            show: false
        }));
    }

    return alert.show && (
        <Snackbar anchorOrigin={{vertical: "top", horizontal: "right"}} open={alert.show}
                  autoHideDuration={5000} onClose={closeAlert}>
            <Alert variant="filled" onClose={closeAlert} severity={alert.severity}>
                <div dangerouslySetInnerHTML={{ __html: alert.message }} />
            </Alert>
        </Snackbar>
    )
})

export default AppAlert;
