import React from "react";
import {Area, AreaChart, CartesianGrid, Label, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import {CustomChartProps} from "../util/custom-chart-props";

const CustomAreaChart = ({data, formatter, dataKey, name, dataKeyXAxis, labelX, labelY}: CustomChartProps) => {
    return (
        <ResponsiveContainer width={"100%"} height={300}>
            <AreaChart data={data} margin={{top: 10, right: 10, left: 20, bottom: 20}}>
                <CartesianGrid/>
                <XAxis dataKey={dataKeyXAxis}>
                    <Label value={labelX} position="bottom"/>
                </XAxis>
                <YAxis>
                    <Label value={labelY} position="left" angle={-90}/>
                </YAxis>
                <Tooltip formatter={formatter}/>
                <Area type="monotone" name={name} dataKey={dataKey}/>
            </AreaChart>
        </ResponsiveContainer>
    );
}

export default React.memo(CustomAreaChart);