import React from 'react';
import {Checkbox, FormControl, Input, InputLabel, ListItemText, MenuItem, Select} from "@mui/material";
import {SelectChangeEvent} from "@mui/material/Select/SelectInput";

interface SelectDataOptionProps {
    value: any;
    valueIndex: string;
    valueDescription: string;
    items: any[];
    label: string;
    required: boolean;
    autoFocus?: boolean;
    onChange: (event: SelectChangeEvent<any>) => void;
    fullWidth: boolean;
    disabled?: boolean;
}

const SelectDataOption = React.memo(({
                                         value,
                                         valueIndex,
                                         valueDescription,
                                         items,
                                         label,
                                         required,
                                         autoFocus,
                                         onChange,
                                         fullWidth,
                                         disabled,
                                     }: SelectDataOptionProps) => {

    const renderDescription = (valueIndexSelected: any) => {
        for (let i = 0; i < items.length; i++) {
            if (items[i][valueIndex] === valueIndexSelected) {
                return items[i][valueDescription]
            }
        }
    }

    const renderLabel = () => {
        return label && (
            <InputLabel>{label}</InputLabel>
        )
    }

    const getValue = () => {
        return value ? value[valueIndex] : '';
    }

    const getChecked = (item: any) => {
        return value ? value[valueIndex] === item[valueIndex] : false;
    }

    return (
        <FormControl required={required} fullWidth={fullWidth} disabled={disabled} variant={"standard"}>
            {renderLabel()}
            <Select autoFocus={autoFocus}
                    value={getValue()}
                    onChange={onChange}
                    input={<Input/>}
                    renderValue={renderDescription}>
                {items.map((item, index) => (
                    <MenuItem key={index} value={item[valueIndex]}>
                        <Checkbox checked={getChecked(item)}/>
                        <ListItemText primary={item[valueDescription]}/>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )

})

export default SelectDataOption;