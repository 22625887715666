import React from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {ComissionProductDetail} from "./comission-product-detail";

const ComissionProductDetailPage = React.memo(() => {

    const {id} = useParams();
    let navigate = useNavigate();

    const onClose = () => {
        navigate('/comissionproducts');
    }

    return (
        <ComissionProductDetail id={id} onClose={onClose}/>
    )

});

export default ComissionProductDetailPage;