import React, {FormEvent, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import CustomTextField from "../typescript/custom-text-field/custom-text-field";
import CustomButton from "../typescript/custom-button/custom-button";
import CustomButtonGroup from "../typescript/custom-button-group/custom-button-group";
import {changePageTitle, setAlert} from "../typescript/redux/actions";
import SelectOption from "../typescript/select-option/select-option";
import {UserService} from "../user/user.service";
import {ActivityService} from "./activity.service";
import {ActivityRound} from "./activity-round";
import {ActivityRepetition} from "./activity-repetition";
import {CreateActivityRequest} from "./create-activity-request";
import {User} from "../user/user";
import ForeignKey from "../typescript/foreign-key/foreign-key";

export function ActivityInsert() {

    let navigate = useNavigate();
    const dispatch = useDispatch();

    const [description, setDescription] = useState('');
    const [user, setUser] = useState<User | null>(null);
    const [date, setDate] = useState('');
    const [round, setRound] = useState('');
    const [repetition, setRepetition] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    useEffect(() => {
        dispatch(changePageTitle("Inserir atividades"));
    }, [dispatch]);

    const back = () => {
        navigate('/activities');
    }

    const create = (event: FormEvent<HTMLFormElement>) => {
        if (!user) {
            throw new Error('User required');
        }
        event.preventDefault();
        event.stopPropagation();

        const indexOfRoundEnum = Object.values(ActivityRound).indexOf(round as unknown as ActivityRound);
        const roundEnum = Object.values(ActivityRound)[indexOfRoundEnum];

        const indexOfRepetitionEnum = Object.values(ActivityRepetition).indexOf(repetition as unknown as ActivityRepetition);
        const repetitionEnum = Object.values(ActivityRepetition)[indexOfRepetitionEnum];

        const request: CreateActivityRequest = {
            description: description,
            user: user,
            date: date,
            round: roundEnum,
            repetition: repetitionEnum,
            startDate: startDate,
            endDate: endDate
        }
        ActivityService.create(request).then(() => {
            back();
        }).catch((error) => {
            let message = 'Erro ao criar atividade';
            if (error.response.data.message) {
                message = (message + ' - ' + error.response.data.message);
            }
            dispatch(setAlert({show: true, message: message, severity: 'error'}));
        })
    }

    const onChangeUsuario = async (id: number) => {
        setUser({id: id});
        if (id) {
            const user = await UserService.findOne(id);
            if (user) {
                setUser(user)
            }
        }
    }

    const onAddUsuario = (user: User) => {
        if (user) {
            setUser(user);
        }
    }

    const roundOptions = [
        {name: 'MORNING', description: 'Manhã'},
        {name: 'AFTERNOON', description: 'Tarde'}];

    const repetitionOptions = [
        {name: 'NONE', description: 'Não repetir'},
        {name: 'DAY', description: 'Repetir diariamente'},
        {name: 'WEEK', description: 'Repetir semanalmente'}];

    const renderRepetitionOptions = () => {
        return ['DAY', 'WEEK'].includes(repetition) && (
            <React.Fragment>
                <CustomTextField fullWidth required label="Iniciar repetições em" type="date" value={startDate}
                                 InputLabelProps={{shrink: true}}
                                 onChange={(event) => setStartDate(event.target.value)}/>
                <CustomTextField fullWidth required label="Terminar repetições em" type="date" value={endDate}
                                 InputLabelProps={{shrink: true}} onChange={(event) => setEndDate(event.target.value)}/>
            </React.Fragment>
        )
    }

    return (
        <form onSubmit={create}>
            <CustomTextField fullWidth autoFocus required label="Descrição" value={description}
                             onChange={(event) => setDescription(event.target.value)}/>
            <ForeignKey fullWidth required label="Usuário" value={user} fieldKey={'id'} fieldDescription={'username'}
                        labelDescription='Usuário' onChange={onChangeUsuario} onSelect={onAddUsuario}
                        findAll={UserService.findAll} dataColumnNames={['ID', 'Usuário']}
                        dataColumns={['id', 'username']} dataTypes={['number', 'text']}/>
            <CustomTextField fullWidth required label="Data" type="date" value={date} InputLabelProps={{shrink: true}}
                             onChange={(event) => setDate(event.target.value)}/>
            <SelectOption fullWidth required label="Turno" value={round}
                          onChange={(event) => setRound(event.target.value)} items={roundOptions} itemValue='name'
                          itemDescription='description'/>
            <SelectOption fullWidth required label="Repetições" value={repetition}
                          onChange={(event) => setRepetition(event.target.value)} items={repetitionOptions}
                          itemValue='name' itemDescription='description'/>
            {renderRepetitionOptions()}
            <CustomButtonGroup>
                <CustomButton color="primary" type="submit">Salvar</CustomButton>
                <CustomButton type="button" color="neutral" onClick={back}>Cancelar</CustomButton>
            </CustomButtonGroup>
        </form>
    )
}
