import React, {useState} from "react";
import {Avatar, IconButton} from "@mui/material";
import AppMenuUserList from "./app-menu-user-list";
import {AuthService} from "../typescript/auth/auth.service";

const AppMenuUser = () => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const avatar = AuthService.getAvatar();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <React.Fragment>
            <IconButton onClick={handleClick} aria-haspopup="true" color="inherit">
                <Avatar alt="Remy Sharp" src={avatar}/>
            </IconButton>
            <AppMenuUserList anchorEl={anchorEl} onClose={handleClose}/>
        </React.Fragment>
    )
}

export default React.memo(AppMenuUser);