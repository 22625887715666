import React from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {ProfissaoDetail} from "./profissao-detail";

const ProfissaoDetailPage = React.memo(() => {

    const {id} = useParams();
    let navigate = useNavigate();

    const onClose = () => {
        navigate('/profissaos');
    }

    return (
        <ProfissaoDetail id={id} onClose={onClose}/>
    )

});

export default ProfissaoDetailPage;