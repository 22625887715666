import React from 'react';
import {Grid, Typography} from "@mui/material";
import {NumberUtil} from "../typescript/util/number-util";

const GoalAnalysisPanel = (props) => {
    const {goal} = props;
    return (
        <React.Fragment>
            <Grid container spacing={1}>
                <Grid item md xs={12}>
                    <Typography variant="subtitle1" gutterBottom>
                        Meta mínima do mês: {NumberUtil.currencyFormat(goal.minimumGoalMonth)}
                    </Typography>
                </Grid>
                <Grid item md xs={12}>
                    <Typography variant="subtitle1" gutterBottom>
                        Vendido do mês: {NumberUtil.currencyFormat(goal.soldedMonth)}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item md xs={12}>
                    <Typography variant="subtitle1" gutterBottom>
                        Percentual da meta mínima do mês: {goal.percentuaMinimumGoalMonth.toFixed(0)}%
                    </Typography>
                </Grid>
                <Grid item md xs={12}>
                    <Typography variant="subtitle1" gutterBottom>
                        Média diária restante: {NumberUtil.currencyFormat(goal.remainingDailyGoal)}
                    </Typography>
                </Grid>
            </Grid>
        </React.Fragment>
    )

}

export default React.memo(GoalAnalysisPanel);