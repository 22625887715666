import {useDispatch} from 'react-redux';
import React from "react";
import AppMenu from "./app-menu";
import AppLoader from "./app-loader";
import AppAlert from "./app-alert";
import {ThemeProvider} from '@mui/material/styles';
import {Outlet} from "react-router-dom";
import {axiosInstance} from "../typescript/axios/axios.configuration";
import {setAlert, showLoader} from "../typescript/redux/actions";
import {AppService} from "../typescript/app/app.service";
import theme from "../typescript/theme/theme";

export function App() {

    const dispatch = useDispatch();

    axiosInstance.interceptors.request.use((config) => {
        dispatch(showLoader(true));
        return config
    }, (error) => {
        return Promise.reject(error);
    });

    axiosInstance.interceptors.response.use((response) => {
        dispatch(showLoader(false));
        return response;
    }, (error) => {
        dispatch(showLoader(false));
        if (error.message === 'Network Error') {
            dispatch(setAlert({show: true, message: error.message, severity: 'error'}));
        }
        if (error.response.status === 403) {
            error.response.data = {
                message: 'Sem permissão'
            }
            dispatch(setAlert({show: true, message: error.response.data.message, severity: 'error'}));
        }
        if (error.response.status === 401) {
            AppService.logout();
            error.response.data = {
                message: 'Não autorizado'
            }
            dispatch(setAlert({show: true, message: error.response.data.message, severity: 'error'}));
        }
        if (error.response.status === 500) {
            dispatch(setAlert({show: true, message: error.response.data.message, severity: 'error'}));
        }
        return Promise.reject(error);
    });

    return (
        <ThemeProvider theme={theme}>
            <AppAlert/>
            <AppLoader/>
            <AppMenu/>
            <Outlet/>
        </ThemeProvider>
    );

}
