import React, {useCallback, useEffect, useState} from "react";
import {
    Datatable,
    ForeignKey
} from "../index";
import {useDispatch} from "react-redux";
import {SaleService} from "../sale/sale.service";
import {Typography} from "@mui/material";
import CustomTextField from "../typescript/custom-text-field/custom-text-field";
import CustomRow from "../typescript/custom-row/custom-row";
import {changePageTitle} from "../typescript/redux/actions";
import {DateUtil} from "../typescript/util/date-util";
import {NumberUtil} from "../typescript/util/number-util";
import {UserService} from "../user/user.service";

const SaleAnalysis = () => {
    const [startDate, setStartDate] = useState(DateUtil.firstDayOfCurrentMonthInJavaSqlDate());
    const [endDate, setEndDate] = useState(DateUtil.toJavaSqlDate(DateUtil.currentDate()));
    const [seller, setSeller] = useState(null);
    const [analise, setAnalise] = useState(null);
    const [startHour, setStartHour] = useState('12:00:00');
    const [endHour, setEndHour] = useState('12:00:00');
    const dispatch = useDispatch();

    const getAnalise = useCallback(async () => {
        const result = await SaleService.getAnalise(startDate, startHour, endDate, endHour, seller ? seller.id : '');
        setAnalise(result.data);
    }, [startDate, startHour, endDate, endHour, seller]);

    useEffect(() => {
        dispatch(changePageTitle("Análise das vendas fechadas por data de fechamento da venda"));
        getAnalise().then();
    }, [dispatch, getAnalise]);

    const onChangeStartDate = (event) => {
        setStartDate(event.target.value)
    }

    const onChangeStartHour = (event) => {
        setStartHour(event.target.value)
    }

    const onChangeEndDate = (event) => {
        setEndDate(event.target.value)
    }

    const onChangeEndHour = (event) => {
        setEndHour(event.target.value)
    }

    const onChangeSeller = async (id) => {
        setSeller({id: id});
        if (id) {
            const sellerSelected = await UserService.findOne(id);
            if (sellerSelected) {
                setSeller(sellerSelected);
            }
        }
    }

    const onSelectSeller = (sellerSelected) => {
        if (sellerSelected) {
            setSeller(sellerSelected);
        }
    }

    const renderVendasPorCategoria = () => {
        return (
            <Datatable data={analise.vendasPorCategoriaEVendedor.itens}
                       totalData={analise.vendasPorCategoriaEVendedor.itens.length}
                       dataColumnNames={['Categoria', 'Quantidade', 'Valor bruto da venda fechada', 'Acréscimo no DOC', 'Desconto no DOC', 'Valor final do DOC', 'Média', 'Percentual']}
                       dataColumns={['categoria.descricao', 'quantidade', 'valorBruto', 'acrescimo', 'desconto', 'valorFinal', 'media', 'percentual']}
                       dataTypes={['text', 'number', 'currency', 'currency', 'currency', 'currency', 'currency', 'percentual']}
                       summarize={[null, 'quantidade', 'valorBruto', 'acrescimo', 'desconto', 'valorFinal', null, 'percentual']}/>
        )
    }

    const renderVendasPorQuantidade = () => {
        return (
            <Datatable data={analise.vendasPorQuantidadeDeParcelas.itens}
                       totalData={analise.vendasPorQuantidadeDeParcelas.itens.length}
                       dataColumnNames={['Parcelas das vendas', 'Valor das vendas', 'Percentual']}
                       dataColumns={['parcelas', 'valor', 'percentual']}
                       dataTypes={['number', 'currency', 'percentual']}
                       summarize={[null, 'valor', 'percentual']}/>
        )
    }

    return analise && (
        <React.Fragment>
            <CustomRow>
                <ForeignKey fullWidth autoFocus label="ID" value={seller} fieldKey={'id'}
                            fieldDescription={'username'} labelDescription='Vendedor'
                            dataColumnNames={['ID', 'Usuário']}
                            dataColumns={['id', 'username']} dataTypes={['number', 'text']} onChange={onChangeSeller}
                            findAll={UserService.findAll} onSelect={onSelectSeller}/>
                <CustomTextField fullWidth required label="Data inicial" type="date" value={startDate || ''}
                                 InputLabelProps={{shrink: true}} onChange={onChangeStartDate}/>
                <CustomTextField fullWidth required label="Hora inicial" type={"time"} step="1" value={startHour || ''}
                                 InputProps={{inputProps: {step: 1}}} InputLabelProps={{shrink: true}}
                                 onChange={onChangeStartHour}/>
                <CustomTextField fullWidth required label="Data final" type="date" value={endDate || ''}
                                 InputLabelProps={{shrink: true}} onChange={onChangeEndDate}/>
                <CustomTextField fullWidth required label="Hora inicial" type={"time"} step="1" value={endHour || ''}
                                 InputProps={{inputProps: {step: 1}}} InputLabelProps={{shrink: true}}
                                 onChange={onChangeEndHour}/>
            </CustomRow>
            {renderVendasPorCategoria()}
            {renderVendasPorQuantidade()}
            <Typography variant="subtitle1" gutterBottom>
                Inadimplência do período: {NumberUtil.currencyFormat(analise.inadimplenciaPorVendedorEVencimento)}
            </Typography>
        </React.Fragment>
    );
}

export default React.memo(SaleAnalysis);