import {useDispatch} from 'react-redux'
import React, {useCallback, useEffect, useState} from "react";
import {HomeService} from "./home.service";
import Activities from "./activities";
import Shortcuts from "./shortcuts";
import Aniversarios from "./aniversarios";
import {changePageTitle, setAlert} from "../typescript/redux/actions";
import CommissionDashboard from "./commission-dashboard";
import {Dashboard} from "./dashboard";

const Home = React.memo(() => {

    const dispatch = useDispatch();
    const [dashboard, setDashboard] = useState<Dashboard>();

    const getDashboard = useCallback(async () => {
        const result = await HomeService.getDashboard().catch((error) => {
            dispatch(setAlert({show: true, message: error.data.message, severity: 'error'}));
            return null;
        })
        if (result) {
            setDashboard(result);
        }
    }, [dispatch]);

    useEffect(() => {
        dispatch(changePageTitle("Home"));
        getDashboard().then();
    }, [dispatch, getDashboard]);

    if (dashboard) {
        return (
            <React.Fragment>
                <Shortcuts checkoutOpened={dashboard.checkoutOpened}/>
                <CommissionDashboard commission={dashboard.comission}/>
                <Activities activities={dashboard.activities}/>
                <Aniversarios aniversarios={dashboard.aniversarios}/>
            </React.Fragment>
        )
    }

    return null;
})

export default Home;