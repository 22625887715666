import React, {useCallback, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {changePageTitle, setAlert} from "../typescript/redux/actions";
import {Delete as DeleteIcon} from "@mui/icons-material";
import {Datatable} from "../index";
import {ProductService} from "./product.service";
import {useNavigate} from "react-router-dom";

export function ProductList() {

    let navigate = useNavigate();
    const [pageParams, setPageParams] = useState(null);
    const dispatch = useDispatch();

    const findAll = useCallback(async (number, size, sort, filter) => {
        const items = await ProductService.findAll(number, size, sort, filter);
        setPageParams({
            number: number,
            size: size,
            sort: sort,
            filter: filter,
            items: items
        });
    }, []);

    useEffect(() => {
        dispatch(changePageTitle("Produtos"));
        findAll(0, parseInt(process.env.REACT_APP_PAGE_SIZE), "id,asc", '').then();
    }, [dispatch, findAll]);

    const editItem = (item) => {
        navigate('/product/' + item.id);
    }

    const insertItem = () => {
        navigate('/product');
    }

    const updateValues = () => {
        navigate('/products/updatevalues');
    }

    const deleteItem = (item) => {
        ProductService.delete(item.id).then(() => {
            findAll(pageParams.number, pageParams.size, pageParams.sort, pageParams.filter).then();
        }).catch((error) => {
            let message = 'Erro ao excluir produto';
            if (error.response.data.message) {
                message += ' - ' + error.response.data.message;
            }
            dispatch(setAlert({show: true, message: message, severity: 'error'}));
        })
    }

    const headerOperations = [
        {color: () => "primary", onClick: insertItem, label: () => 'Inserir'},
        {color: () => "primary", onClick: updateValues, label: () => 'Atualiza valores por categoria'}
    ]

    const rowOperations = [
        {color: () => "primary", onClick: deleteItem, label: () => 'Excluir', icon: () => <DeleteIcon/>},
    ]

    return pageParams && (
        <Datatable
            dataColumnNames={['ID', 'Descrição', 'Ativo', 'Categoria', 'Estoque mínimo', 'Estoque ideal', 'Estoque atual', 'Atual - mínimo', 'Controla estoque']}
            dataColumns={['id', 'descricao', 'active', 'category.descricao', 'stock.minimum', 'stock.ideal', 'stock.current', 'stock.currentMinusMinimum', 'stock.control']}
            number={pageParams.number} size={pageParams.size} sort={pageParams.sort} filter={pageParams.filter}
            dataTypes={['number', 'text', 'boolean', 'text', 'number', 'number', 'number', 'number', 'boolean']}
            findAll={findAll} editData={editItem}
            data={pageParams.items.content} totalData={pageParams.items.totalElements}
            headerOperations={headerOperations} rowOperations={rowOperations}/>
    );
}