import {AppService} from "../typescript/app/app.service";

class HomeService {

    static API_URL = "/api/home";

    static getDashboard() {
        return AppService.getAuthorized(this.API_URL + "/dashboard").then((result) => {
            return result.data;
        }).catch(() => {
            return null;
        })
    }

}

export {HomeService};