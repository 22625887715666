import React, {useCallback, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {AttachMoney, Cancel, Check, Delete as DeleteIcon, Edit, Print, Search} from "@mui/icons-material";
import {
    Datatable
} from "../index";
import {Grid, IconButton, Tooltip} from "@mui/material";
import {ReserveService} from "./reserve.service";
import {useNavigate} from "react-router-dom";
import CustomTextField from "../typescript/custom-text-field/custom-text-field";
import {changePageTitle, setAlert} from "../typescript/redux/actions";
import {DateUtil} from "../typescript/util/date-util";
import {FileUtil} from "../typescript/util/file-util";
import * as StringUtil from "../typescript/util/string-util";
import ButtonSelect from "../typescript/button-select/button-select";
import {RelatorioService} from "../typescript/relatorio/relatorio.service";

const ReserveList = () => {

    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [startDate, setStartDate] = useState(DateUtil.firstDayOfCurrentMonthInJavaSqlDate());
    const [endDate, setEndDate] = useState(DateUtil.lastDayOfCurrentMonthInJavaSqlDate());
    const [items, setItems] = useState(null);

    const findAll = useCallback(async () => {
        if (startDate && endDate) {
            const result = await ReserveService.findAll(startDate, endDate);
            setItems(result);
        }
    }, [startDate, endDate]);

    useEffect(() => {
        dispatch(changePageTitle("Reservas"));
        findAll().then();
    }, [dispatch, findAll]);

    const editItem = (item) => {
        navigate('/reserve/' + item.id);
    }

    const insertItem = () => {
        navigate('/reserve');
    }

    const search = (event) => {
        event.preventDefault();
        event.stopPropagation();
        findAll().then();
    }

    const deleteItem = (item) => {
        ReserveService.delete(item.id).then(() => {
            findAll().then();
        }).catch(() => {
            dispatch(setAlert({show: true, message: 'Erro ao excluir reserva', severity: 'error'}));
        })
    }

    const headerOperations = [
        {color: () => "primary", onClick: insertItem, label: () => 'Inserir'}
    ]

    const print = (item) => {
        RelatorioService.imprimeReserva(item.id).then(function (result) {
            FileUtil.openPdfInNewTab(result);
        }, (result) => {
            const error = StringUtil.decodeUint8Array(result);
            dispatch(setAlert({show: true, message: error.message, severity: 'error'}));
        })
    }

    const rowOperations = [
        {color: () => "primary", onClick: deleteItem, label: () => 'Excluir', icon: () => <DeleteIcon/>},
        {color: () => "primary", onClick: print, label: () => 'Imprimir', icon: () => <Print/>}
    ]

    const valuesStatus1 = [
        {id: null, description: 'Neutro', icon: <Edit/>, color: "default"},
        {id: 'CONFIRMADO', description: 'Confirmado', icon: <Check/>, color: "primary"},
        {id: 'DESISTENCIA', description: 'Desistência', icon: <Cancel/>, color: "error"}
    ]

    const valuesStatus2 = [
        {id: null, description: 'Neutro', icon: <Edit/>, color: "default"},
        {id: 'COMPROU', description: 'Comprou', icon: <Check/>, color: "primary"},
        {id: 'FEZ_ORCAMENTO', description: 'Fez orçamento', icon: <AttachMoney/>, color: "primary"},
        {id: 'FEZ_EM_OUTRO_LUGAR', description: 'Fez em outro lugar', icon: <Cancel/>, color: "error"},
        {id: 'NAO_PRECISOU', description: 'Não precisou', icon: <Cancel/>, color: "error"},
        {id: 'ACHOU_CARO', description: 'Achou caro', icon: <Cancel/>, color: "error"}
    ]

    const onChangeStatus1 = async (status1, item) => {
        const result = await ReserveService.updateStatus1(item.id, status1);
        let itemsToChange = [...items];
        for (let itemToChange of itemsToChange) {
            if (itemToChange.id === item.id) {
                itemToChange.status1 = result.data.status1;
                break;
            }
        }
        setItems(itemsToChange);
    }

    const onChangeStatus2 = async (status2, item) => {
        const result = await ReserveService.updateStatus2(item.id, status2);
        let itemsToChange = [...items];
        for (let itemToChange of itemsToChange) {
            if (itemToChange.id === item.id) {
                itemToChange.status2 = result.data.status2;
                break;
            }
        }
        setItems(itemsToChange);
    }

    const renderDescriptionStatus1 = (id) => {
        const itemSelected = valuesStatus1.filter(item => item.id === id);
        if (itemSelected[0]) {
            return itemSelected[0].description;
        }
    }

    const renderDescriptionStatus2 = (id) => {
        const itemSelected = valuesStatus2.filter(item => item.id === id);
        if (itemSelected[0]) {
            return itemSelected[0].description;
        }
    }

    const renderStatus1 = (item, dataColumn) => {
        return (
            <ButtonSelect items={valuesStatus1} id={item[dataColumn]}
                          onChange={(status1) => onChangeStatus1(status1, item)}
                          title={renderDescriptionStatus1(item[dataColumn])}/>
        )
    }

    const renderStatus2 = (item, dataColumn) => {
        return (
            <ButtonSelect items={valuesStatus2} id={item[dataColumn]}
                          onChange={(status2) => onChangeStatus2(status2, item)}
                          title={renderDescriptionStatus2(item[dataColumn])}/>
        )
    }

    const changeStartDate = (event) => {
        setStartDate(event.target.value);
    }

    const changeEndDate = (event) => {
        setEndDate(event.target.value);
    }

    return items && (
        <React.Fragment>
            <form onSubmit={search}>
                <Grid container spacing={1}>
                    <Grid item md>
                        <Grid container spacing={1}>
                            <Grid item md xs={12}>
                                <CustomTextField fullWidth autoFocus required label="Data inicial" type="date"
                                                 value={startDate || ''} InputLabelProps={{shrink: true}}
                                                 onChange={changeStartDate}/>
                            </Grid>
                            <Grid item md xs={12}>
                                <CustomTextField fullWidth required label="Data final" type="date" value={endDate || ''}
                                                 InputLabelProps={{shrink: true}} onChange={changeEndDate}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Tooltip title="Pesquisar">
                            <IconButton type={"submit"}>
                                <Search/>
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </form>
            <Datatable
                dataColumnNames={['ID', 'Pessoa', 'Produto', 'Fone 1', 'Fone 2', 'Data', 'Hora', 'Status 1', 'Status 2']}
                dataColumns={['id', 'name', 'product', 'phone1', 'phone2', 'date', 'hour', 'status1', 'status2']}
                dataTypes={['number', 'text', 'text', 'text', 'text', 'date', 'hour', 'enum', 'enum']} findAll={findAll}
                editData={editItem} data={items} totalData={items.length}
                dataValues={[null, null, null, null, null, null, null, renderStatus1, renderStatus2]}
                headerOperations={headerOperations} rowOperations={rowOperations}/>
        </React.Fragment>
    );
}

export default React.memo(ReserveList);