import React from 'react';
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {SaleItem} from "../../../sale/sale-item";
import CustomTextField from "../../custom-text-field/custom-text-field";
import CustomRow from "../../custom-row/custom-row";

interface LensDetailProps {
    servicoParaInserir: SaleItem;
    setServicoParaInserir: (saleItem: SaleItem) => void;
}

const LensDetail = ({servicoParaInserir, setServicoParaInserir}: LensDetailProps) => {

    const onChangeODEsferico = (event: React.ChangeEvent<HTMLInputElement>) => {
        setServicoParaInserir({
            ...servicoParaInserir,
            odEsferico: event.target.value
        });
    }

    const onChangeODCilindrico = (event: React.ChangeEvent<HTMLInputElement>) => {
        setServicoParaInserir({
            ...servicoParaInserir,
            odCilindrico: event.target.value
        });
    }

    const onChangeODEixo = (event: React.ChangeEvent<HTMLInputElement>) => {
        setServicoParaInserir({
            ...servicoParaInserir,
            odEixo: event.target.value
        });
    }

    const onChangeODAdicao = (event: React.ChangeEvent<HTMLInputElement>) => {
        setServicoParaInserir({
            ...servicoParaInserir,
            odAdicao: event.target.value
        });
    }

    const onChangeODDnp = (event: React.ChangeEvent<HTMLInputElement>) => {
        setServicoParaInserir({
            ...servicoParaInserir,
            odDnp: event.target.value
        });
    }

    const onChangeODAltura = (event: React.ChangeEvent<HTMLInputElement>) => {
        setServicoParaInserir({
            ...servicoParaInserir,
            odAltura: event.target.value
        });
    }

    const onChangeOEEsferico = (event: React.ChangeEvent<HTMLInputElement>) => {
        setServicoParaInserir({
            ...servicoParaInserir,
            oeEsferico: event.target.value
        });
    }

    const onChangeOECilindrico = (event: React.ChangeEvent<HTMLInputElement>) => {
        setServicoParaInserir({
            ...servicoParaInserir,
            oeCilindrico: event.target.value
        });
    }

    const onChangeOEEixo = (event: React.ChangeEvent<HTMLInputElement>) => {
        setServicoParaInserir({
            ...servicoParaInserir,
            oeEixo: event.target.value
        });
    }

    const onChangeOEAdicao = (event: React.ChangeEvent<HTMLInputElement>) => {
        setServicoParaInserir({
            ...servicoParaInserir,
            oeAdicao: event.target.value
        });
    }

    const onChangeOEDnp = (event: React.ChangeEvent<HTMLInputElement>) => {
        setServicoParaInserir({
            ...servicoParaInserir,
            oeDnp: event.target.value
        });
    }

    const onChangeOEAltura = (event: React.ChangeEvent<HTMLInputElement>) => {
        setServicoParaInserir({
            ...servicoParaInserir,
            oeAltura: event.target.value
        });
    }

    const changePantoscopicAngle = (event: React.ChangeEvent<HTMLInputElement>) => {
        setServicoParaInserir({
            ...servicoParaInserir,
            pantoscopicAngle: event.target.value
        });
    }

    const changeCurvatureAngle = (event: React.ChangeEvent<HTMLInputElement>) => {
        setServicoParaInserir({
            ...servicoParaInserir,
            curvatureAngle: event.target.value
        });
    }

    const changeCroRightEye = (event: React.ChangeEvent<HTMLInputElement>) => {
        setServicoParaInserir({
            ...servicoParaInserir,
            croRightEye: event.target.value
        });
    }

    const changeCroLeftEye = (event: React.ChangeEvent<HTMLInputElement>) => {
        setServicoParaInserir({
            ...servicoParaInserir,
            croLeftEye: event.target.value
        });
    }

    const changeReadingDistance = (event: React.ChangeEvent<HTMLInputElement>) => {
        setServicoParaInserir({
            ...servicoParaInserir,
            readingDistance: event.target.value
        });
    }

    const changeDominantEye = (event: React.ChangeEvent<HTMLInputElement>) => {
        setServicoParaInserir({
            ...servicoParaInserir,
            dominantEye: event.target.value
        });
    }

    const changeCoefficientHeadEye = (event: React.ChangeEvent<HTMLInputElement>) => {
        setServicoParaInserir({
            ...servicoParaInserir,
            coefficientHeadEye: event.target.value
        });
    }

    const renderHeader = () => {
        return (
            <TableHead>
                <TableRow>
                    <TableCell/>
                    <TableCell>Esférico</TableCell>
                    <TableCell>Cilìndrico</TableCell>
                    <TableCell>Eixo</TableCell>
                    <TableCell>Adição</TableCell>
                    <TableCell>DNP</TableCell>
                    <TableCell>Altura</TableCell>
                </TableRow>
            </TableHead>
        )
    }

    const renderBody = () => {
        return (
            <TableBody>
                <TableRow>
                    <TableCell>OD</TableCell>
                    <TableCell>
                        <CustomTextField fullWidth value={servicoParaInserir.odEsferico} type={"number"}
                                         InputProps={{disableUnderline: true, inputProps: {step: "any"}}} onChange={onChangeODEsferico}/>
                    </TableCell>
                    <TableCell>
                        <CustomTextField fullWidth value={servicoParaInserir.odCilindrico} type={"number"}
                                         InputProps={{disableUnderline: true, inputProps: {step: "any"}}} onChange={onChangeODCilindrico}/>
                    </TableCell>
                    <TableCell>
                        <CustomTextField fullWidth value={servicoParaInserir.odEixo} type={"number"}
                                         InputProps={{disableUnderline: true, inputProps: {step: "any"}}} onChange={onChangeODEixo}/>
                    </TableCell>
                    <TableCell>
                        <CustomTextField fullWidth value={servicoParaInserir.odAdicao} type={"number"}
                                         InputProps={{disableUnderline: true, inputProps: {step: "any"}}} onChange={onChangeODAdicao}/>
                    </TableCell>
                    <TableCell>
                        <CustomTextField fullWidth value={servicoParaInserir.odDnp} type={"number"}
                                         InputProps={{disableUnderline: true, inputProps: {step: "any"}}} onChange={onChangeODDnp}/>
                    </TableCell>
                    <TableCell>
                        <CustomTextField fullWidth value={servicoParaInserir.odAltura} type={"number"}
                                         InputProps={{disableUnderline: true, inputProps: {step: "any"}}} onChange={onChangeODAltura}/>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>OE</TableCell>
                    <TableCell>
                        <CustomTextField fullWidth value={servicoParaInserir.oeEsferico} type={"number"}
                                         InputProps={{disableUnderline: true, inputProps: {step: "any"}}} onChange={onChangeOEEsferico}/>
                    </TableCell>
                    <TableCell>
                        <CustomTextField fullWidth value={servicoParaInserir.oeCilindrico} type={"number"}
                                         InputProps={{disableUnderline: true, inputProps: {step: "any"}}} onChange={onChangeOECilindrico}/>
                    </TableCell>
                    <TableCell>
                        <CustomTextField fullWidth value={servicoParaInserir.oeEixo} type={"number"}
                                         InputProps={{disableUnderline: true, inputProps: {step: "any"}}} onChange={onChangeOEEixo}/>
                    </TableCell>
                    <TableCell>
                        <CustomTextField fullWidth value={servicoParaInserir.oeAdicao} type={"number"}
                                         InputProps={{disableUnderline: true, inputProps: {step: "any"}}} onChange={onChangeOEAdicao}/>
                    </TableCell>
                    <TableCell>
                        <CustomTextField fullWidth value={servicoParaInserir.oeDnp} type={"number"}
                                         InputProps={{disableUnderline: true, inputProps: {step: "any"}}} onChange={onChangeOEDnp}/>
                    </TableCell>
                    <TableCell>
                        <CustomTextField fullWidth value={servicoParaInserir.oeAltura} type={"number"}
                                         InputProps={{disableUnderline: true, inputProps: {step: "any"}}} onChange={onChangeOEAltura}/>
                    </TableCell>
                </TableRow>
            </TableBody>
        )
    }

    return (
        <React.Fragment>
            <TableContainer component={Paper}>
                <Table size={"small"} stickyHeader>
                    {renderHeader()}
                    {renderBody()}
                </Table>
            </TableContainer>
            <CustomRow>
                <CustomTextField fullWidth label="Ângulo pantoscópico" type="text"
                                 value={servicoParaInserir.pantoscopicAngle || ''} onChange={changePantoscopicAngle}
                                 inputProps={{maxLength: 5}}/>
                <CustomTextField fullWidth label="Ângulo curvatura" type="text"
                                 value={servicoParaInserir.curvatureAngle || ''} onChange={changeCurvatureAngle}
                                 inputProps={{maxLength: 5}}/>
                <CustomTextField fullWidth label="CRO OD" type="text" value={servicoParaInserir.croRightEye || ''}
                                 onChange={changeCroRightEye} inputProps={{maxLength: 5}}/>
                <CustomTextField fullWidth label="CRO OE" type="text" value={servicoParaInserir.croLeftEye || ''}
                                 onChange={changeCroLeftEye} inputProps={{maxLength: 5}}/>
                <CustomTextField fullWidth label="Distância de leitura" type="text"
                                 value={servicoParaInserir.readingDistance || ''} onChange={changeReadingDistance}
                                 inputProps={{maxLength: 5}}/>
                <CustomTextField fullWidth label="Olho dominante" type="text" value={servicoParaInserir.dominantEye || ''}
                                 onChange={changeDominantEye} inputProps={{maxLength: 5}}/>
                <CustomTextField fullWidth label="Coeficiente cabeça/olho" type="text"
                                 value={servicoParaInserir.coefficientHeadEye || ''} onChange={changeCoefficientHeadEye}
                                 inputProps={{maxLength: 5}}/>
            </CustomRow>
        </React.Fragment>
    )

}

export default React.memo(LensDetail);
