import React from "react";
import {styled, Typography} from "@mui/material";
import {green, lightGreen, orange, red, yellow} from "@mui/material/colors";
import * as PropTypes from "prop-types";
import CustomRow from "../typescript/custom-row/custom-row";
import CustomPanel from "../typescript/custom-panel/custom-panel";
import {NumberUtil} from "../typescript/util/number-util";
import {Activity} from "./activity";

const classes = {
    indice20: {
        color: red["700"]
    },
    indice40: {
        color: orange["700"]
    },
    indice60: {
        color: yellow["700"]
    },
    indice80: {
        color: lightGreen["700"]
    },
    indice100: {
        color: green["700"]
    }
};

function Div2() {
    return null;
}

Div2.propTypes = {
    sx: PropTypes.any,
    children: PropTypes.node
};

interface ActivitiesProps {
    activities: Activity;
}

interface DivProps {
    indice: number
}

const Activities = ({activities}: ActivitiesProps) => {

    const calculaIndice = (indice: number) => {
        if (indice < 20) {
            return classes.indice20;
        } else if (indice < 40) {
            return classes.indice40;
        } else if (indice < 60) {
            return classes.indice60;
        } else if (indice < 80) {
            return classes.indice80;
        } else {
            return classes.indice100;
        }
    }

    const Div = styled('div')((propsDiv: DivProps) => (calculaIndice(propsDiv.indice)));

    return (
        <CustomRow>
            <CustomPanel title={"Atividades hoje"}>
                <Div indice={activities.indiceHoje}>
                    <Typography variant={"subtitle1"}>
                        Total: {activities.totalAtividadesHoje}
                    </Typography>
                    <Typography variant={"subtitle1"}>
                        Realizadas: {activities.totalAtividadesRealizadasHoje}
                    </Typography>
                    <Typography variant={"subtitle1"}>
                        Não realizadas {activities.totalAtividadesNaoRealizadasHoje}
                    </Typography>
                    <Typography variant={"subtitle1"}>
                        Índice: {NumberUtil.percentualFormat(activities.indiceHoje)}
                    </Typography>
                </Div>
            </CustomPanel>
            <CustomPanel title={"Atividades no mês atual"}>
                <Div indice={activities.indiceMes}>
                    <Typography variant={"subtitle1"}>
                        Total: {activities.totalAtividadesMes}
                    </Typography>
                    <Typography variant={"subtitle1"}>
                        Realizadas: {activities.totalAtividadesRealizadasMes}
                    </Typography>
                    <Typography variant={"subtitle1"}>
                        Não realizadas {activities.totalAtividadesNaoRealizadasMes}
                    </Typography>
                    <Typography variant={"subtitle1"}>
                        Índice: {NumberUtil.percentualFormat(activities.indiceMes)}
                    </Typography>
                </Div>
            </CustomPanel>
        </CustomRow>
    )

}

export default React.memo(Activities);