import React from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {FiscalmodelDetail} from "./fiscalmodel-detail";

const FiscalModelDetailPage = React.memo(() => {

    const {id} = useParams();
    let navigate = useNavigate();

    const onClose = () => {
        navigate('/fiscalmodels');
    }

    return (
        <FiscalmodelDetail id={id} onClose={onClose}/>
    )

});

export default FiscalModelDetailPage;