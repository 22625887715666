import React, {useCallback, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {changePageTitle, setAlert} from "../typescript/redux/actions";
import {BrandService} from "./brand.service";
import {AttachMoney, Delete as DeleteIcon} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import {PageParams} from "../typescript/util/page-params";
import {Brand} from "../typescript/brand/Brand";
import Datatable from "../typescript/datatable/datatable";
import {ColorButtonEnum} from "../typescript/util/color-button-enum";
import {ColorIconEnum} from "../typescript/util/color-icon-enum";
import {BrandTax} from "../typescript/brand-tax/brand-tax";

const BrandList = () => {

    let navigate = useNavigate();
    const [pageParams, setPageParams] = useState<PageParams>({
        number: 0,
        size: parseInt(process.env.REACT_APP_PAGE_SIZE || "0"),
        sort: "id,asc",
        filter: "",
        items: [],
        totalElements: 0
    });
    const dispatch = useDispatch();

    const findAll = useCallback(async (number?: number, size?: number, sort?: string, filter?: string) => {
        const result = await BrandService.findAll(number, size, sort, filter);
        setPageParams({
            number: number,
            size: size,
            sort: sort,
            filter: filter,
            items: result.content,
            totalElements: result.totalElements
        });
    }, []);

    useEffect(() => {
        dispatch(changePageTitle("Bandeiras"));
        findAll(pageParams.number, pageParams.size, pageParams.sort, pageParams.filter).then();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const editItem = (item: Brand) => {
        navigate('/brand/' + item.id);
    }

    const insertItem = () => {
        navigate('/brand');
    }

    const deleteItem = (item: Brand) => {
        if (item.id === undefined) {
            return;
        }
        BrandService.delete(item.id).then(() => {
            findAll(pageParams.number, pageParams.size, pageParams.sort, pageParams.filter).then();
        }).catch((error) => {
            dispatch(setAlert({
                show: true,
                message: 'Erro ao excluir registro - ' + error.message,
                severity: 'error'
            }));
        })
    }

    const vacation = async (item: BrandTax) => {
        navigate('/brand/' + item.id + '/taxes');
    }

    const headerOperations = [
        {color: () => ColorButtonEnum.PRIMARY, onClick: insertItem, label: () => 'Inserir'}
    ]

    const rowOperations = [
        {color: () => ColorIconEnum.PRIMARY, onClick: deleteItem, label: () => 'Excluir', icon: () => <DeleteIcon/>},
        {color: () => ColorIconEnum.PRIMARY, onClick: vacation, label: () => 'Taxas', icon: () => <AttachMoney/>},
    ]

    return pageParams && (
        <Datatable dataColumnNames={['ID', 'Descrição']} dataColumns={['id', 'descricao']}
                   page={pageParams.number} size={pageParams.size} sort={pageParams.sort} filter={pageParams.filter}
                   dataTypes={['number', 'text']} findAll={findAll} editData={editItem}
                   data={pageParams.items} totalData={pageParams.totalElements}
                   headerOperations={headerOperations} rowOperations={rowOperations}/>
    );
}

export default React.memo(BrandList);