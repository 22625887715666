import React from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {CityDetail} from "./city-detail";

const CityDetailPage = React.memo(() => {

    const {id} = useParams();
    let navigate = useNavigate();

    const onClose = () => {
        navigate('/cities');
    }

    return (
        <CityDetail id={id ? parseInt(id) : null} onClose={onClose}/>
    )

});

export default CityDetailPage;