import {AppService} from "../app/app.service";

class RelatorioService {

    static API_URL = "/api/relatorios";

    static orcamento = (os: number, condicoesPagamentoDinheiro: string, condicoesPagamentoBoleto: string, condicoesPagamentoCheque: string, condicoesPagamentoCartao: string) => {
        const params = {
            os: os,
            condicoesDePagamentoDinheiro: condicoesPagamentoDinheiro,
            condicoesDePagamentoBoleto: condicoesPagamentoBoleto,
            condicoesDePagamentoCheque: condicoesPagamentoCheque,
            condicoesDePagamentoCartao: condicoesPagamentoCartao
        }
        return AppService.getAuthorized(this.API_URL + '/orcamento', params, "arraybuffer");
    }

    static getAniversariantes(mes: number, dia: number | '') {
        if (dia) {
            return AppService.getAuthorized(this.API_URL + "/aniversariantesPorDia", {
                mes: mes,
                dia: dia
            }, "arraybuffer");
        }
        return AppService.getAuthorized(this.API_URL + "/aniversariantesPorMes", {
            mes: mes
        }, "arraybuffer");
    }

    static getNotasEmitidas(dataInicial: string, dataFinal: string) {
        return AppService.getAuthorized(this.API_URL + "/notas", {
            dataInicial: dataInicial,
            dataFinal: dataFinal
        }, "arraybuffer");
    }

    static imprimeConsulta(id: number) {
        return AppService.getAuthorized(this.API_URL + '/consulta', {
            consulta: id
        }, "arraybuffer");
    }

    static imprimeReserva(id: number) {
        return AppService.getAuthorized(this.API_URL + '/reserve', {
            reserve: id
        }, "arraybuffer");
    }

    static getCurvaAbc(dataInicial: string, dataFinal: string, porProduto: boolean, vendas: boolean) {
        return AppService.getAuthorized(this.API_URL + "/curvaABC", {
            dataInicial: dataInicial,
            dataFinal: dataFinal,
            porProduto: porProduto,
            vendas: vendas
        }, "arraybuffer");
    }

    static getVendas(params: any) {
        return AppService.getAuthorized(this.API_URL + "/vendas", params, "arraybuffer");
    }

    static getDreCategoria(type: string) {
        return AppService.getAuthorized(this.API_URL + "/category", {
            type: type
        }, "arraybuffer");
    }

    static getDreFornecedor(type: string) {
        return AppService.getAuthorized(this.API_URL + "/fornecedor", {
            type: type
        }, "arraybuffer");
    }

    static getDreProduto(type: string) {
        return AppService.getAuthorized(this.API_URL + "/product", {
            type: type
        }, "arraybuffer");
    }

    static getDreRecebidos(type: string, dataInicial: string, dataFinal: string) {
        return AppService.getAuthorized(this.API_URL + "/recebidos", {
            type: type,
            dataInicial: dataInicial,
            dataFinal: dataFinal
        }, "arraybuffer");
    }

    static getDrePagos(type: string, dataInicial: string, dataFinal: string) {
        return AppService.getAuthorized(this.API_URL + "/pagos", {
            type: type,
            dataInicial: dataInicial,
            dataFinal: dataFinal
        }, "arraybuffer");
    }

    static getDreReceitas(type: string, dataInicial: string, dataFinal: string) {
        return AppService.getAuthorized(this.API_URL + "/receitas", {
            type: type,
            dataInicial: dataInicial,
            dataFinal: dataFinal
        }, "arraybuffer");
    }

    static getDreDespesas(type: string, dataInicial: string, dataFinal: string) {
        return AppService.getAuthorized(this.API_URL + "/despesas", {
            type: type,
            dataInicial: dataInicial,
            dataFinal: dataFinal
        }, "arraybuffer");
    }

    static getPonto(type: string, year: number, month: number) {
        return AppService.getAuthorized(this.API_URL + "/ponto", {
            type: type,
            year: year,
            month: month
        }, "arraybuffer");
    }

    static getPontoPorUsuario(type: string, year: number, month: number, userId: number) {
        return AppService.getAuthorized(this.API_URL + "/ponto/" + userId, {
            type: type,
            year: year,
            month: month
        }, "arraybuffer");
    }

    static getValorRealizado(type: string, user: number, year: number, month: number) {
        return AppService.getAuthorized(this.API_URL + "/realizado", {
            type: type,
            user: user,
            year: year,
            month: month
        }, "arraybuffer");
    }
}

export {RelatorioService};