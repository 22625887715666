import React from 'react';
import {Checkbox, FormControl, Input, InputLabel, ListItemText, MenuItem, Select} from "@mui/material";
import {SelectChangeEvent} from "@mui/material/Select/SelectInput";

interface SelectOptionProps {
    value: any;
    itemValue: string;
    items: any[];
    itemDescription: string;
    label: string;
    required?: boolean;
    fullWidth: boolean;
    onChange: (event: SelectChangeEvent<any[] | any>) => void;
    disabled?: boolean;
    autoFocus?: boolean;
    multiple?: boolean;
}

const SelectOption = React.memo(({
                                     multiple,
                                     value,
                                     itemValue,
                                     items,
                                     itemDescription,
                                     label,
                                     required,
                                     fullWidth,
                                     disabled,
                                     autoFocus,
                                     onChange
                                 }: SelectOptionProps) => {

    const getItemValues = () => {
        if (multiple) {
            let lista: any = [];
            value.forEach(function (item: any) {
                lista.push(item[itemValue]);
            })
            return lista;
        } else {
            return value === '' || value === null ? '' : value;
        }
    }

    const renderDescriptions = (selected: any) => {
        if (multiple) {
            let selectedString: any[] = [];
            items.forEach(function (item: any) {
                if (selected.indexOf(item[itemValue]) > -1) {
                    selectedString.push(item[itemDescription]);
                }
            })
            return selectedString.join(', ');
        } else {
            let selectedString: any[] = [];
            items.forEach(function (item) {
                if (selected === item[itemValue]) {
                    selectedString.push(item[itemDescription]);
                }
            })
            return selectedString.join(', ');
        }
    }

    const verifyChecked = (item: any) => {
        if (multiple) {
            return getItemValues().indexOf(item[itemValue]) > -1;
        } else {
            return getItemValues() === item[itemValue];
        }
    }

    const renderLabel = () => {
        return label && (
            <InputLabel>{label}</InputLabel>
        )
    }

    return (
        <FormControl required={required} fullWidth={fullWidth} disabled={disabled} variant="standard">
            {renderLabel()}
            <Select autoFocus={autoFocus}
                    multiple={multiple}
                    value={getItemValues()}
                    onChange={onChange}
                    input={<Input/>}
                    renderValue={renderDescriptions}>
                {items.map((item, index) => (
                    <MenuItem key={index} value={item[itemValue]}>
                        <Checkbox checked={verifyChecked(item)}/>
                        <ListItemText primary={item[itemDescription]}/>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )

});

export default SelectOption;
