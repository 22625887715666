import React, {useCallback, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {changePageTitle, setAlert} from "../typescript/redux/actions";
import {ForeignKey} from "../index";
import {NeighborhoodService} from "./neighborhood.service";
import CustomTextField from "../typescript/custom-text-field/custom-text-field";
import CustomButton from "../typescript/custom-button/custom-button";
import CustomButtonGroup from "../typescript/custom-button-group/custom-button-group";
import {CityDetail} from "../typescript/city/city-detail";
import {CityService} from "../typescript/city/city.service";

export function NeighborhoodDetail({id, onClose, dialog}) {

    const [item, setItem] = useState(NeighborhoodService.createNeighborhood());
    const dispatch = useDispatch();

    const findOne = useCallback(async () => {
        const result = await NeighborhoodService.findOne(id);
        setItem(result);
    }, [id]);

    useEffect(() => {
        if (!dialog) {
            dispatch(changePageTitle(id ? "Bairro " + id : "Novo bairro"));
        }
        if (id) {
            findOne().then();
        }
    }, [dispatch, findOne, id, dialog]);

    const save = (event) => {
        event.preventDefault();
        event.stopPropagation();
        NeighborhoodService.save(item).then((result) => {
            onClose(result.data);
        }).catch((error) => {
            dispatch(setAlert({
                show: true,
                message: 'Erro ao salvar bairro - ' + error.data.message,
                severity: 'error'
            }));
        })
    }

    const cancel = () => {
        onClose();
    }

    const setDescricao = (event) => {
        setItem({
            ...item,
            descricao: event.target.value
        })
    }

    const onChangeCidade = async (newId) => {
        setItem({
            ...item,
            cidade: {
                id: newId
            }
        })
        if (newId) {
            const cidade = await CityService.findOne(newId);
            if (cidade) {
                setItem({
                    ...item,
                    cidade: cidade
                })
            }
        }
    }

    const onAddCidade = (cidade) => {
        if (cidade) {
            setItem({
                ...item,
                cidade: cidade
            })
        }
    }

    const findCities = (page, size, sort, filter) => {
        return CityService.findAll(page, size, sort, filter);
    }

    return (
        <form onSubmit={save}>
            <CustomTextField fullWidth autoFocus required label="Descrição" value={item.descricao}
                             onChange={setDescricao}/>
            <ForeignKey fullWidth required label="Cidade" value={item.cidade} fieldKey={'id'}
                        fieldDescription={'descricao'} labelDescription='Descrição' onChange={onChangeCidade}
                        onSelect={onAddCidade} onAdd={onAddCidade} findAll={findCities}
                        dataColumnNames={['ID', 'Descrição']} dataColumns={['id', 'descricao']}
                        dataTypes={['number', 'text']} addComponent={<CityDetail/>}
            />
            <CustomButtonGroup>
                <CustomButton color="primary" type="submit">Salvar</CustomButton>
                <CustomButton type="button" color="neutral" onClick={cancel}>Cancelar</CustomButton>
            </CustomButtonGroup>
        </form>
    )
}