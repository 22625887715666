import React from "react";
import {useSelector} from "react-redux";
import AppMenuUser from "./app-menu-user";
import {AppBar, IconButton, Toolbar, Tooltip, Typography} from "@mui/material";
import {Home, Menu} from "@mui/icons-material";
import theme from "../typescript/theme/theme";
import {RootState} from "../typescript/redux/store";

interface AppMenuBarProps {
    setShowDrawer: (show: boolean) => void;
}

const AppMenuBar = ({setShowDrawer}: AppMenuBarProps) => {

    const pageTitle = useSelector((store: RootState) => store.pageState.title);

    return (
        <AppBar position={"static"}>
            <Toolbar>
                <IconButton color="inherit" onClick={() => {
                    setShowDrawer(true)
                }}>
                    <Menu/>
                </IconButton>
                <Typography variant="h6" sx={theme.menu.bar.title}>
                    {pageTitle}
                </Typography>
                <Tooltip title='Home'>
                    <IconButton aria-controls="menu-appbar" aria-haspopup="true" color="inherit" component="a" href="/">
                        <Home/>
                    </IconButton>
                </Tooltip>
                <AppMenuUser/>
            </Toolbar>
        </AppBar>
    )
}

export default React.memo(AppMenuBar);