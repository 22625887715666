import React, {FormEvent, useEffect, useRef, useState} from "react";
import {Sale} from "../../../../sale/sale";
import {CloseOptionsResponse} from "../../../../sale/close-options-response";
import {useDispatch} from "react-redux";
import {SaleService} from "../../../../sale/sale.service";
import {setAlert} from "../../../redux/actions";
import CustomTextField from "../../../custom-text-field/custom-text-field";
import {CloseSaleAPrazoParams, FirstPaymentParams, ParcelsParams} from "../../../../sale/close-sale-a-prazo-params";
import {DateUtil} from "../../../util/2.0.0/date-util";
import {PaymentType} from "../../../../payment-type/payment-type";
import {Brand} from "../../../brand/Brand";
import {Document} from "../../../../document/document";
import CustomRow from "../../../custom-row/custom-row";
import TipoDePagamentoOption from "../../../tipo-de-pagamento-option/tipo-de-pagamento-option";
import BandeiraOption from "../../../bandeira-option/bandeira-option";
import CustomButtonGroup from "../../../custom-button-group/custom-button-group";
import CustomButton from "../../../custom-button/custom-button";
import Parcels from "../parcels";
import CloseSaleAPrazoEntrada from "./close-sale-a-prazo-entrada";
import {FormControl, FormControlLabel, Radio, RadioGroup} from "@mui/material";
import {Entrada} from "./entrada";

interface CloseSaleAPrazoProps {
    sale: Sale;
    onClose: (success: boolean) => void;
    options: CloseOptionsResponse;
}

const CloseSaleAPrazo = React.memo(({sale, onClose, options}: CloseSaleAPrazoProps) => {

    let saleId: number;
    if (sale.id) {
        saleId = sale.id;
    } else {
        throw new Error('Sale id required');
    }

    const dispatch = useDispatch();
    const submitButton = useRef<HTMLButtonElement>(null);
    const [numberOfParcels, setNumberOfParcels] = useState<number>(1);
    const [firstDueDate, setFirstDueDate] = useState<string>(DateUtil.toJavaSqlDate(DateUtil.addMonths(DateUtil.currentDate(), 1)));
    const [discountValue, setDiscountValue] = useState<number | string>("");
    const [discountPercentage, setDiscountPercentage] = useState<number | string>("");
    const [feeValue, setFeeValue] = useState<number | string>("");
    const [feePercentage, setFeePercentage] = useState<number | string>("");
    const [installmentOption, setInstallmentOption] = useState<PaymentType | null>(null);
    const [cardBrand, setCardBrand] = useState<Brand | null>(null);
    const [cardNumberOfParcels, setCardNumberOfParcels] = useState<number | string>("");
    const [comEntrada, setComEntrada] = useState<boolean>(true);
    const [entrada, setEntrada] = useState<Entrada>(SaleService.createEntrada());
    const [documents, setDocuments] = useState<Document[]>([]);

    const cancela = () => {
        onClose(false);
    }

    const createParams = (): CloseSaleAPrazoParams => {
        if (!installmentOption?.id) {
            throw new Error('InstallmentOption id required');
        }
        const parcels: ParcelsParams = {
            firstDueDate: firstDueDate,
            discountValue: Number(discountValue),
            feeValue: Number(feeValue),
            installmentOption: installmentOption.id,
            cardBrand: cardBrand?.id,
            cardNumberOfParcels: Number(cardNumberOfParcels),
            numberOfParcels: numberOfParcels
        }
        let firstPayment: FirstPaymentParams | null = null;
        if (comEntrada) {
            if (!entrada.installmentOption?.id) {
                throw new Error('InstallmentOption id required');
            }
            firstPayment = {
                date: entrada.date,
                value: Number(entrada.value),
                installmentOption: entrada.installmentOption.id,
                cardBrand: entrada.cardBrand?.id,
                cardNumberOfParcels: Number(entrada.cardNumberOfParcels),
            }
        }
        return {
            parcels: parcels,
            firstPayment: firstPayment
        };
    }

    useEffect(() => {
        if (!installmentOption) {
            return;
        }
        if (!installmentOption.id) {
            return;
        }
        if ((installmentOption.id === SaleService.PAGAMENTO_CARTAO.id) && (!cardBrand || !cardNumberOfParcels)) {
            return;
        }
        if (comEntrada) {
            if (!entrada.installmentOption) {
                return;
            }
            if (!entrada.installmentOption.id) {
                return;
            }
            if ((entrada.installmentOption.id === SaleService.PAGAMENTO_CARTAO.id) && (!entrada.cardBrand || !entrada.cardNumberOfParcels)) {
                return;
            }
        }
        const params = createParams();
        SaleService.closeSalePreviewParcel(saleId, params).then(function (result) {
            setDocuments(result.data);
        }, (error) => {
            dispatch(setAlert({
                show: true,
                message: 'Erro ao consultar previsão de fechamento de compra - ' + error.response.data.message,
                severity: 'error'
            }));
        })
    }, [installmentOption, firstDueDate, discountValue, feeValue, cardBrand, cardNumberOfParcels, numberOfParcels, comEntrada, entrada]); // eslint-disable-line react-hooks/exhaustive-deps

    const gera = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        submitButton.current?.setAttribute("disabled", "disabled");
        const params = createParams();
        SaleService.closeSaleParcel(saleId, params).then(function () {
            submitButton.current?.removeAttribute("disabled");
            dispatch(setAlert({show: true, message: 'Venda fechada com sucesso', severity: 'success'}));
            onClose(true);
        }, (error) => {
            submitButton.current?.removeAttribute("disabled");
            dispatch(setAlert({
                show: true,
                message: 'Erro ao fechar compra - ' + error.response.data.message,
                severity: 'error'
            }));
            onClose(false);
        })
    }

    const changeNumberOfParcels = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNumberOfParcels(Number(event.target.value));
    }

    const changeFirstDueDate = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFirstDueDate(event.target.value);
    }

    const changeDiscountPercentage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDiscountPercentage(event.target.value);
        const valueToDiscount = Number(sale.valorTotal) * Number(event.target.value) / 100;
        setDiscountValue(valueToDiscount);
    }

    const changeDiscountValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDiscountValue(event.target.value);
        const percentageToDiscount = Number(event.target.value) / Number(sale.valorTotal) * 100;
        setDiscountPercentage(percentageToDiscount);
    }

    const changeFeePercentage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFeePercentage(event.target.value);
        const valueToFee = Number(sale.valorTotal) * Number(event.target.value) / 100;
        setFeeValue(valueToFee);
    }

    const changeFeeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFeeValue(event.target.value);
        const percentageToFee = Number(event.target.value) / Number(sale.valorTotal) * 100;
        setFeePercentage(percentageToFee);
    }

    const changeCardBrand = (bandeiraSelecionada: Brand | null) => {
        setCardBrand(bandeiraSelecionada);
    }

    const changeCardNumberOfParcels = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCardNumberOfParcels(event.target.value);
    }

    const changeInstallmentOption = (tipoDePagamentoSelecionado: PaymentType | null) => {
        setInstallmentOption(tipoDePagamentoSelecionado);
        if (tipoDePagamentoSelecionado?.id !== SaleService.PAGAMENTO_CARTAO.id) {
            setCardBrand(null);
            setCardNumberOfParcels("");
        }
    }

    const changeComEntrada = (event: React.ChangeEvent<HTMLInputElement>) => {
        setComEntrada(event.target.value === 'true');
    }

    const renderEntrada = () => {
        return comEntrada && (
            <CloseSaleAPrazoEntrada entrada={entrada} setEntrada={setEntrada} options={options} saleId={saleId}/>
        )
    }
    const renderBrand = () => {
        return installmentOption && installmentOption.id === SaleService.PAGAMENTO_CARTAO.id && (
            <BandeiraOption bandeiras={options.bandeiras} bandeiraSelecionada={cardBrand}
                            changeBandeira={changeCardBrand}/>
        )
    }

    const renderCardNumberOfParcels = () => {
        return installmentOption && installmentOption.id === SaleService.PAGAMENTO_CARTAO.id && (
            <CustomTextField fullWidth label="Número de parcelas do cartão" type="number" required
                             value={cardNumberOfParcels} onChange={changeCardNumberOfParcels}
                             InputProps={{inputProps: {min: 1}}}/>
        )
    }

    const renderDocumentPreview = () => {
        return documents.length > 0 && (
            <Parcels documents={documents}/>
        )
    }

    return (
        <form onSubmit={gera}>
            <FormControl component="fieldset" required>
                <RadioGroup row value={comEntrada} onChange={changeComEntrada}>
                    <FormControlLabel value={true} control={<Radio/>} label="Com entrada"/>
                    <FormControlLabel value={false} control={<Radio/>} label="Sem entrada"/>
                </RadioGroup>
            </FormControl>

            {renderEntrada()}
            <CustomRow>
                <CustomTextField fullWidth label="Nº parcelas" type="number" value={numberOfParcels} required
                                 onChange={changeNumberOfParcels} InputProps={{inputProps: {min: 1}}}/>
                <CustomTextField fullWidth label="Primeiro vencimento" type="date" value={firstDueDate} required
                                 onChange={changeFirstDueDate}/>
                <CustomTextField fullWidth label="Desconto (%)" type="number" value={discountPercentage}
                                 onChange={changeDiscountPercentage} InputProps={{inputProps: {min: 0, step: "any"}}}/>
                <CustomTextField fullWidth label="Desconto (R$)" type="number" value={discountValue}
                                 onChange={changeDiscountValue} InputProps={{inputProps: {min: 0, step: "any"}}}/>
                <CustomTextField fullWidth label="Juros (%)" type="number" value={feePercentage}
                                 onChange={changeFeePercentage} InputProps={{inputProps: {min: 0, step: "any"}}}/>
                <CustomTextField fullWidth label="Juros (R$)" type="number" value={feeValue} onChange={changeFeeValue}
                                 InputProps={{inputProps: {min: 0, step: "any"}}}/>
            </CustomRow>
            <CustomRow>
                <TipoDePagamentoOption tiposDePagamento={options.tiposDePagamento}
                                       tipoDePagamentoSelecionado={installmentOption}
                                       changeTipoDePagamento={changeInstallmentOption}/>
                {renderBrand()}
                {renderCardNumberOfParcels()}
            </CustomRow>
            {renderDocumentPreview()}
            <CustomButtonGroup>
                <CustomButton ref={submitButton} color="primary" type="submit">Gerar</CustomButton>
                <CustomButton type="button" color="neutral" onClick={cancela}>Cancelar</CustomButton>
            </CustomButtonGroup>
        </form>
    )

});

export default CloseSaleAPrazo;
