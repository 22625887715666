import React from "react";
import CustomButton from "../typescript/custom-button/custom-button";
import CustomButtonGroup from "../typescript/custom-button-group/custom-button-group";

const DatatableHeader = (props) => {

    const {headerOperations} = props;

    const renderHeaderOperations = () => {
        return headerOperations.map((headerOperation, index) => {
            return (
                <CustomButton key={index} variant="outlined" type="button"
                              color={headerOperation.color ? headerOperation.color() : 'inherit'}
                              onClick={() => headerOperation.onClick()}>
                    {headerOperation.label()}
                </CustomButton>
            )
        })
    }

    return (
        <CustomButtonGroup>
            {renderHeaderOperations()}
        </CustomButtonGroup>
    )

}

export default React.memo(DatatableHeader);
