import React, {useCallback, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {UserService} from "./user.service";
import {changePageTitle, setAlert} from "../typescript/redux/actions";
import CustomTextField from "../typescript/custom-text-field/custom-text-field";
import CustomButton from "../typescript/custom-button/custom-button";
import CustomButtonGroup from "../typescript/custom-button-group/custom-button-group";

export function VacationDetail({id, onClose, vacation}) {

    const [item, setItem] = useState({
        start: '',
        end: '',
        observation: ''
    });
    const dispatch = useDispatch();

    const findOne = useCallback(async () => {
        const result = await UserService.findOneVacation(id, vacation);
        setItem(result);
    }, [id, vacation]);

    useEffect(() => {
        dispatch(changePageTitle(vacation ?
            "Férias do usuário " + id + " - Férias " + vacation :
            "Férias do usuário " + id + " - Novas férias"));
        if (vacation) {
            findOne().then();
        }
    }, [dispatch, findOne, id, vacation]);

    const save = (event) => {
        event.preventDefault();

        UserService.saveVacation(id, item).then((result) => {
            onClose(result.data);
        }).catch((error) => {
            dispatch(setAlert({
                show: true,
                message: 'Erro ao salvar férias - ' + error.data.message,
                severity: 'error'
            }));
        })
    }

    const setStart = (event) => {
        setItem({
            ...item,
            start: event.target.value
        })
    }

    const setEnd = (event) => {
        setItem({
            ...item,
            end: event.target.value
        })
    }

    const setObservation = (event) => {
        setItem({
            ...item,
            observation: event.target.value
        })
    }

    const cancel = () => {
        onClose();
    }

    return (
        <form onSubmit={save}>
            <CustomTextField autoFocus fullWidth label="Início" type="date" value={item.start || ''} onChange={setStart}
                             InputLabelProps={{shrink: true}}/>
            <CustomTextField fullWidth label="Fim" type="date" value={item.end || ''} onChange={setEnd}
                             InputLabelProps={{shrink: true}}/>
            <CustomTextField fullWidth label="Observação" type="text" value={item.observation}
                             onChange={setObservation}/>
            <CustomButtonGroup>
                <CustomButton color="primary" type="submit">Salvar</CustomButton>
                <CustomButton type="button" color="neutral" onClick={cancel}>Cancelar</CustomButton>
            </CustomButtonGroup>
        </form>
    )
}