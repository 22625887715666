import {Card, CardMedia, Grid, List} from "@mui/material";
import React, {FormEvent, useCallback, useEffect, useState} from "react";
import {PontoService} from "./ponto.service";
import CustomTextField from "../typescript/custom-text-field/custom-text-field";
import CustomButton from "../typescript/custom-button/custom-button";
import CustomButtonGroup from "../typescript/custom-button-group/custom-button-group";
import {CustomDialog} from "../typescript/custom-dialog/custom-dialog";
import {AuthService} from "../typescript/auth/auth.service";
import {UserService} from "../user/user.service";
import {DateUtil} from "../typescript/util/2.0.0/date-util";
import {RoundMessageModal} from "./round-message-modal";
import {PointDto} from "./point-dto";
import {useNavigate} from "react-router-dom";
import {setAlert} from "../typescript/redux/actions";
import {useDispatch} from "react-redux";

const classes = {
    pontoCelebrationImg: {
        height: '360px'
    },
    pontoMuralCard: {
        maxWidth: '800px'
    },
    pontoMuralImg: {
        height: '600px'
    }
};

export function PontoDetail() {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    let busy = false;

    const [currentDate] = useState(DateUtil.currentDate());
    const [roundMessage, setRoundMessage] = useState<RoundMessageModal>({
        message: null,
        show: false
    });

    const [ponto, setPonto] = useState<PointDto>({
        firstRoundEnter: '',
        firstRoundExit: '',
        secondRoundEnter: '',
        secondRoundExit: '',
        thirdRoundEnter: '',
        thirdRoundExit: '',
        goalAccomplished: ''
    });

    const [showCelebrationMessage, setShowCelebrationMessage] = useState(false);

    const getPonto = useCallback(async () => {
        const result = await PontoService.get(currentDate);
        if (result) {
            setPonto(result);
        }
    }, [currentDate]);

    useEffect(() => {
        getPonto().then();
    }, [getPonto]);

    const username = () => {
        const userDetails = AuthService.getUserDetails();
        if (userDetails) {
            return userDetails.username;
        }
    }

    const closeCelebrationMessage = () => {
        setShowCelebrationMessage(false);
        if (secondRoundExitRegistered(ponto)) {
            getMuralImage();
        }
    }

    const getMuralImage = () => {
        UserService.getFinalRoundMessage().then((result) => {
            setRoundMessage({
                message: result.data,
                show: !!result
            });
        }).catch((error) => {
            navigate('/activities');
            let message = 'Erro ao obter mural';
            if (error.response.data.message) {
                message = (message + ' - ' + error.response.data.message);
            }
            dispatch(setAlert({show: true, message: message, severity: 'error'}));
        })
    }

    const closeMuralImage = () => {
        setRoundMessage({
            message: null,
            show: false
        });
        navigate('/activities');
    }

    const renderDialogMuralImage = () => {
        function renderMuralDialogImage() {
            return roundMessage?.message?.mural && (
                <Card sx={classes.pontoMuralCard}>
                    <CardMedia component="img" sx={classes.pontoMuralImg} image={roundMessage?.message.mural}/>
                </Card>
            );
        }

        return roundMessage.show && (
            <CustomDialog title={"Parabéns!"} open={roundMessage.show} maxWidth="md">
                <List>
                    <h2>Parabéns por mais um dia de conquistas na CPCB!</h2>
                    <h3>Total de atividades no dia: {roundMessage?.message?.totalDayActivities}</h3>
                    <h3>Total de atividades
                        realizadas: {roundMessage?.message?.totalDayActivitiesAccomplished} ({Math.round((roundMessage?.message?.percentualActivitiesAccomplished || 0) * 100)} %)</h3>
                    {renderMuralDialogImage()}
                    <CustomButtonGroup>
                        <CustomButton color="primary" type="button" onClick={closeMuralImage}>Ok</CustomButton>
                    </CustomButtonGroup>
                </List>
            </CustomDialog>
        )
    }

    const renderMessage1 = () => {
        let goalAccomplished;
        switch (ponto.goalAccomplished) {
            case 'MINIMUM':
                goalAccomplished = 'MÍNIMA';
                break;
            case 'BASIC':
                goalAccomplished = 'BÁSICA';
                break;
            default:
                goalAccomplished = 'MASTER';
                break;
        }
        return (
            <p>Você atingiu a meta <strong>{goalAccomplished}</strong>!</p>
        );
    }

    const renderMessage2 = () => {
        let nextGoal;
        switch (ponto.goalAccomplished) {
            case 'MINIMUM':
                nextGoal = 'BÁSICA';
                break;
            case 'BASIC':
                nextGoal = 'MASTER';
                break;
            default:
                nextGoal = '';
                break;
        }
        return nextGoal && (
            <p>Rumo a meta <strong>{nextGoal}</strong>!</p>
        );
    }

    const renderCelebrationMessage = () => {
        return (
            <React.Fragment>
                {renderMessage1()}
                {renderMessage2()}
            </React.Fragment>
        )
    }

    const renderDialogCelebrationMessage = () => {
        return showCelebrationMessage && (
            <CustomDialog title="Parabéns!" open={showCelebrationMessage} maxWidth="xs">
                <List>
                    {renderCelebrationMessage()}
                    <Card>
                        <CardMedia sx={classes.pontoCelebrationImg} image="/celebration.gif"/>
                    </Card>
                    <CustomButtonGroup>
                        <CustomButton color="primary" type="button" onClick={closeCelebrationMessage}>Ok</CustomButton>
                    </CustomButtonGroup>
                </List>
            </CustomDialog>
        )
    }

    const register = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        if (busy) {
            return;
        }
        busy = true;
        setTimeout(function () {
            const date = DateUtil.currentDate()
            const time = DateUtil.currentDate().toLocaleTimeString();
            PontoService.register(date, time).then((result) => {
                busy = false;
                const data = result.data;
                if (data) {
                    setPonto(data);
                    if (data.goalAccomplished && data.goalAccomplished !== 'NONE') {
                        setShowCelebrationMessage(true);
                    } else {
                        if (secondRoundExitRegistered(data)) {
                            getMuralImage();
                        }
                    }
                }
            }).catch((error) => {
                let message = 'Erro ao registrar ponto';
                if (error.response.data.message) {
                    message = (message + ' - ' + error.response.data.message);
                }
                dispatch(setAlert({show: true, message: message, severity: 'error'}));
            })
        }, 1000);
    }

    const allRegistered = () => {
        return !!ponto.firstRoundEnter && !!ponto.firstRoundExit
            && !!ponto.secondRoundEnter && !!ponto.secondRoundExit
            && !!ponto.thirdRoundEnter && !!ponto.thirdRoundExit;
    }

    const secondRoundExitRegistered = (pontoReturned: PointDto) => {
        return pontoReturned.firstRoundEnter && pontoReturned.firstRoundExit
            && pontoReturned.secondRoundEnter && pontoReturned.secondRoundExit
            && !pontoReturned.thirdRoundEnter && !pontoReturned.thirdRoundExit;
    }

    return (
        <form onSubmit={register}>

            {renderDialogCelebrationMessage()}
            {renderDialogMuralImage()}

            <h2>{username()} - Data: {currentDate.toLocaleDateString()}</h2>

            <Grid container spacing={1}>
                <Grid item md xs={12}>
                    <CustomTextField fullWidth disabled label="Primeiro Turno Entrada"
                                     value={ponto.firstRoundEnter || ''}
                                     InputLabelProps={{shrink: true}}/>
                </Grid>
                <Grid item md xs={12}>
                    <CustomTextField fullWidth disabled label="Primeiro Turno Saída" value={ponto.firstRoundExit || ''}
                                     InputLabelProps={{shrink: true}}/>
                </Grid>
            </Grid>

            <Grid container spacing={1}>
                <Grid item md xs={12}>
                    <CustomTextField fullWidth disabled label="Segundo Turno Entrada"
                                     value={ponto.secondRoundEnter || ''}
                                     InputLabelProps={{shrink: true}}/>
                </Grid>
                <Grid item md xs={12}>
                    <CustomTextField fullWidth disabled label="Segundo Turno Saída" value={ponto.secondRoundExit || ''}
                                     InputLabelProps={{shrink: true}}/>
                </Grid>
            </Grid>

            <Grid container spacing={1}>
                <Grid item md xs={12}>
                    <CustomTextField fullWidth disabled label="Terceiro Turno Entrada"
                                     value={ponto.thirdRoundEnter || ''}
                                     InputLabelProps={{shrink: true}}/>
                </Grid>
                <Grid item md xs={12}>
                    <CustomTextField fullWidth disabled label="Terceiro Turno Saída" value={ponto.thirdRoundExit || ''}
                                     InputLabelProps={{shrink: true}}/>
                </Grid>
            </Grid>
            <CustomButtonGroup>
                <CustomButton color="primary" type="submit" disabled={allRegistered()}>Registrar</CustomButton>
            </CustomButtonGroup>
        </form>
    )
}
