import React, {useCallback, useEffect, useState} from "react";
import {changePageTitle} from "../typescript/redux/actions";
import {useDispatch} from "react-redux";
import {DocumentService} from "../document/document.service";
import {Datatable} from "../index";
import CustomTextField from "../typescript/custom-text-field/custom-text-field";
import CustomRow from "../typescript/custom-row/custom-row";
import {DateUtil} from "../typescript/util/date-util";

const ExpenseAnalysis = () => {
    const [expenses, setExpenses] = useState(null);
    const [dataInicial, setDataInicial] = useState(DateUtil.firstDayOfCurrentMonthInJavaSqlDate());
    const [dataFinal, setDataFinal] = useState(DateUtil.lastDayOfCurrentMonthInJavaSqlDate());
    const dispatch = useDispatch();

    const findAll = useCallback(async () => {
        if (dataInicial && dataFinal) {
            const result = await DocumentService.getExpensesByPaymentType(dataInicial, dataFinal);
            setExpenses(result);
        }
    }, [dataInicial, dataFinal]);

    useEffect(() => {
        dispatch(changePageTitle("Análise de despesas"));
        findAll().then();
    }, [dispatch, findAll]);

    const changeDataInicial = (event) => {
        setDataInicial(event.target.value);
    }

    const changeDataFinal = (event) => {
        setDataFinal(event.target.value);
    }

    return expenses && (
        <React.Fragment>
            <CustomRow>
                <CustomTextField fullWidth autoFocus required label="Data inicial" type="date" value={dataInicial}
                                 InputLabelProps={{shrink: true}} onChange={changeDataInicial}/>
                <CustomTextField fullWidth required label="Data final" type="date" value={dataFinal}
                                 InputLabelProps={{shrink: true}} onChange={changeDataFinal}/>
            </CustomRow>
            <Datatable data={expenses} totalData={expenses.length}
                       dataColumnNames={['Tipo de pagamento', 'Valor', 'Percentual']}
                       dataColumns={['tipoDePagamento.descricao', 'valor', 'percentual']}
                       dataTypes={['text', 'currency', 'percentual']} summarize={[null, 'valor', 'percentual']}/>
        </React.Fragment>
    )

}

export default React.memo(ExpenseAnalysis);