import React, {useCallback, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {
    ForeignKey
} from "../index";
import {PaymentTypeService} from "./payment-type.service";
import CustomTextField from "../typescript/custom-text-field/custom-text-field";
import CustomButton from "../typescript/custom-button/custom-button";
import CustomButtonGroup from "../typescript/custom-button-group/custom-button-group";
import {changePageTitle, setAlert} from "../typescript/redux/actions";
import {WalletService} from "../typescript/wallet/wallet.service";
import {WalletDetail} from "../typescript/wallet/wallet-detail";

export function PaymentTypeDetail({id, onClose, dialog}) {

    const [item, setItem] = useState(PaymentTypeService.createPaymentType());
    const dispatch = useDispatch();

    const findOne = useCallback(async () => {
        const result = await PaymentTypeService.findOne(id);
        setItem(result);
    }, [id]);

    useEffect(() => {
        if (!dialog) {
            dispatch(changePageTitle(id ? "Tipo de pagamento " + id : "Novo tipo de pagamento"));
        }
        if (id) {
            findOne().then();
        }
    }, [dispatch, findOne, id, dialog]);

    const save = (event) => {
        event.preventDefault();
        event.stopPropagation();
        PaymentTypeService.save(item).then((result) => {
            onClose(result.data);
        }).catch((error) => {
            let message = 'Erro ao salvar tipo de pagamento';
            if (error.response.data.message) {
                message = (message + ' - ' + error.response.data.message);
            }
            dispatch(setAlert({show: true, message: message, severity: 'error'}));
        })
    }

    const cancel = () => {
        onClose();
    }

    const setDescricao = (event) => {
        setItem({
            ...item,
            descricao: event.target.value
        })
    }

    const onChangeCarteira = async (id) => {
        setItem({
            ...item,
            carteira: {
                id: id
            }
        })
        if (id) {
            const carteira = await WalletService.findOne(id);
            if (carteira) {
                setItem({
                    ...item,
                    carteira: carteira
                })
            }
        }
    }

    const onAddCarteira = (carteira) => {
        if (carteira) {
            setItem({
                ...item,
                carteira: carteira
            })
        }
    }

    const findCarteiras = (page, size, sort, filter) => {
        return WalletService.findAll(page, size, sort, filter);
    }

    return (
        <form onSubmit={save}>
            <CustomTextField fullWidth autoFocus required label="Descrição" value={item.descricao}
                             onChange={setDescricao}/>
            <ForeignKey fullWidth required label="Conta" value={item.carteira} fieldKey={'id'}
                        fieldDescription={'descricao'} labelDescription='Descrição' onChange={onChangeCarteira}
                        onSelect={onAddCarteira} onAdd={onAddCarteira} findAll={findCarteiras}
                        dataColumnNames={['ID', 'Descrição']} dataColumns={['id', 'descricao']}
                        dataTypes={['number', 'text']} addComponent={<WalletDetail/>}/>
            <CustomButtonGroup>
                <CustomButton color="primary" type="submit">Salvar</CustomButton>
                <CustomButton type="button" color="neutral" onClick={cancel}>Cancelar</CustomButton>
            </CustomButtonGroup>
        </form>
    )
}
