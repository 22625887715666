import React from "react";
import {Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {DateUtil} from "../typescript/util/date-util";
import CustomPanel from "../typescript/custom-panel/custom-panel";
import {ParenteAniversario} from "./parente-aniversario";

const classes = {
    tableHeader: {
        "& th": {
            fontWeight: 'bold'
        }
    }
};

interface AniversariosProps {
    aniversarios: ParenteAniversario[];
}

const Aniversarios = ({aniversarios}: AniversariosProps) => {

    const renderAniversarioTableHeader = () => {
        return (
            <TableHead sx={classes.tableHeader}>
                <TableRow>
                    <TableCell>Pessoa</TableCell>
                    <TableCell>Telefone da pessoa</TableCell>
                    <TableCell>Nome parente</TableCell>
                    <TableCell>Grau de parentesco</TableCell>
                    <TableCell>Data de nascimento</TableCell>
                </TableRow>
            </TableHead>
        );
    }

    const getDate = (content: string) => {
        const date = DateUtil.javaSqlDateToDate(content);
        return date?.toLocaleDateString();
    }

    const renderAniversarioTableBody = () => {
        if (aniversarios && aniversarios.length > 0) {
            return (
                <TableBody>
                    {aniversarios.map((aniversario, index) => (
                        <TableRow key={index}>
                            <TableCell>
                                {aniversario.pessoa}
                            </TableCell>
                            <TableCell>
                                {aniversario.telefone}
                            </TableCell>
                            <TableCell>
                                {aniversario.nome}
                            </TableCell>
                            <TableCell>
                                {aniversario.grau}
                            </TableCell>
                            <TableCell>
                                {getDate(aniversario.dataNascimento)}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            );
        } else {
            return (
                <TableBody>
                    <TableRow>
                        <TableCell colSpan={99}>
                            Nenhum registro encontrado
                        </TableCell>
                    </TableRow>
                </TableBody>
            )
        }
    }

    return (
        <Grid item md xs={12}>
            <CustomPanel title="Aniversários nos próximos 30 dias">
                <TableContainer component={Paper}>
                    <Table size={"small"}>
                        {renderAniversarioTableHeader()}
                        {renderAniversarioTableBody()}
                    </Table>
                </TableContainer>
            </CustomPanel>
        </Grid>
    );

}

export default React.memo(Aniversarios);