import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {changePageTitle} from "../../redux/actions";
import CampaignGenerateByValue from "./campaign-generate-by-value";
import {Paper, Tab, Tabs} from "@mui/material";
import CampaignGenerateByBirthday from "./campaign-generate-by-birthday";
import CampaignGenerateByRest from "./campaign-generate-by-rest";

const classes = {
    tabBar: {
        marginBottom: '8px'
    }
};

const CampaignGenerate = () => {

    const dispatch = useDispatch();
    const [tabValue, setTabValue] = React.useState(0);

    useEffect(() => {
        dispatch(changePageTitle("Nova campanha"));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const renderGenerateByValue = () => {
        return tabValue === 0 && <CampaignGenerateByValue/>;
    }

    const renderGenerateByBirthday = () => {
        return tabValue === 1 && <CampaignGenerateByBirthday/>;
    }

    const renderGenerateByRest = () => {
        return tabValue === 2 && <CampaignGenerateByRest/>;
    }

    return (
        <React.Fragment>
            <Paper square sx={classes.tabBar}>
                <Tabs value={tabValue} indicatorColor="primary" textColor="primary" onChange={handleChangeTab}
                      variant={"scrollable"} scrollButtons={"auto"}>
                    <Tab label="Por valor"/>
                    <Tab label="Por aniversário"/>
                    <Tab label="Pessoas sem campanha em determinado período"/>
                </Tabs>
            </Paper>
            {renderGenerateByValue()}
            {renderGenerateByBirthday()}
            {renderGenerateByRest()}
        </React.Fragment>
    )

}

export default React.memo(CampaignGenerate);
