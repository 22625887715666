import React, {useCallback, useEffect, useState} from "react";
import {
    ForeignKey
} from "../index";
import {Bar, CartesianGrid, ComposedChart, Label, Line, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {blue, common, green, pink, red, yellow} from "@mui/material/colors";
import CustomTextField from "../typescript/custom-text-field/custom-text-field";
import CustomRow from "../typescript/custom-row/custom-row";
import {DateUtil} from "../typescript/util/date-util";
import {NumberUtil} from "../typescript/util/number-util";
import CustomPanel from "../typescript/custom-panel/custom-panel";
import {CategoryService} from "../category/category.service";
import {SaleService} from "../sale/sale.service";

const InstagramRoi = () => {
    const [startDate, setStartDate] = useState(DateUtil.firstDayOfCurrentMonthInJavaSqlDate());
    const [endDate, setEndDate] = useState(DateUtil.toJavaSqlDate(DateUtil.currentDate()));
    const [graph, setGraph] = useState(null);
    const [category, setCategory] = useState(null);

    const DOCUMENTOS_RECEBIDOS = 'Documentos recebidos';
    const DOCUMENTOS_RECEBIDOS_ESTIMADOS = 'Documentos recebidos estimados (Média D-7)';
    const VENDAS_FECHADAS = 'Vendas fechadas';
    const VENDAS_FECHADAS_ESTIMADAS = 'Vendas fechadas estimadas (Média D-7)';

    const getGraph = useCallback(async () => {
        const result = await SaleService.graficoRoiInstagram(startDate, endDate, category);
        setGraph(result.data);
    }, [startDate, endDate, category]);

    useEffect(() => {
        getGraph().then();
    }, [getGraph]);

    const formatter = (value) => {
        return DateUtil.javaSqlDateToDateToLocaleDateString(value);
    }

    const formatterValue = (value, label) => {
        if (label === DOCUMENTOS_RECEBIDOS || label === DOCUMENTOS_RECEBIDOS_ESTIMADOS || label === VENDAS_FECHADAS || label === VENDAS_FECHADAS_ESTIMADAS) {
            return NumberUtil.currencyFormat(value);
        }
        return value;
    }

    const onChangeStartDate = (event) => {
        setStartDate(event.target.value)
    }

    const onChangeEndDate = (event) => {
        setEndDate(event.target.value)
    }

    const onChangeCategory = async (id) => {
        setCategory({
            id: id
        });
        if (id) {
            const newCategory = await CategoryService.findOne(id);
            onSelectCategory(newCategory);
        }
    }

    const onSelectCategory = (newCategory) => {
        if (newCategory) {
            setCategory(newCategory);
        }
    }

    return graph && (
        <CustomPanel title={"ROI Instagram - Estimativa baseada em 7 dias anteriores"}>
            <CustomRow>
                <CustomTextField fullWidth required label="Data inicial" type="date" value={startDate || ''}
                                 InputLabelProps={{shrink: true}} onChange={onChangeStartDate}/>
                <CustomTextField fullWidth required label="Data final" type="date" value={endDate || ''}
                                 InputLabelProps={{shrink: true}} onChange={onChangeEndDate}/>
                <ForeignKey fullWidth label="Categoria" value={category} fieldKey={'id'}
                            fieldDescription={'descricao'} labelDescription='Descrição' onChange={onChangeCategory}
                            onSelect={onSelectCategory} findAll={CategoryService.findAll}
                            dataColumnNames={['ID', 'Descrição']} dataColumns={['id', 'descricao']}
                            dataTypes={['number', 'text']}/>
            </CustomRow>
            <ResponsiveContainer width={"100%"} height={300}>
                <ComposedChart data={graph} margin={{top: 10, right: 10, left: 20, bottom: 30}}>
                    <CartesianGrid/>
                    <XAxis dataKey="date" tickFormatter={formatter}>
                        <Label value={"Data"} position="bottom"/>
                    </XAxis>
                    <YAxis>
                        <Label value={"Valor"} position="left" angle={-90}/>
                    </YAxis>
                    <Tooltip labelFormatter={formatter} formatter={formatterValue}/>
                    <Bar type="monotone" dataKey="stories" name={"Stories"} fill={pink["100"]}/>
                    <Bar type="monotone" dataKey="reels" name={"Reels"} fill={red["700"]}/>
                    <Bar type="monotone" dataKey="feed" name={"Feed"} fill={yellow["700"]}/>
                    <Bar type="monotone" dataKey="status" name={"Status"} fill={common.black}/>
                    <Line type="monotone" dataKey="received" name={DOCUMENTOS_RECEBIDOS} stroke={green["700"]}
                          strokeWidth={5}/>
                    <Line type="monotone" dataKey="sales" name={VENDAS_FECHADAS} stroke={blue["700"]} strokeWidth={5}/>
                    <Line type="monotone" dataKey="receivedEstimated" name={DOCUMENTOS_RECEBIDOS_ESTIMADOS}
                          stroke={green["700"]} strokeWidth={5} strokeDasharray="5 5"/>
                    <Line type="monotone" dataKey="salesEstimated" name={VENDAS_FECHADAS_ESTIMADAS} stroke={blue["700"]}
                          strokeWidth={5} strokeDasharray="5 5"/>
                </ComposedChart>
            </ResponsiveContainer>
        </CustomPanel>
    );
}

export default React.memo(InstagramRoi);