import React from "react";
import {Bar, BarChart, CartesianGrid, Cell, Label, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {blue, red} from "@mui/material/colors";
import {NumberUtil} from "../typescript/util/number-util";
import CustomPanel from "../typescript/custom-panel/custom-panel";

const IncomeExpense = (props) => {
    const {incomeExpenses} = props;

    const formatter = (value) => {
        return NumberUtil.currencyFormat(value);
    }

    return (
        <CustomPanel title={"Receita e despesa na semana"}>
            <ResponsiveContainer width={"100%"} height={300}>
                <BarChart data={incomeExpenses} margin={{top: 10, right: 10, left: 20, bottom: 30}}>
                    <CartesianGrid/>
                    <XAxis dataKey="diaDaSemana">
                        <Label value={"Dia da semana"} position="bottom"/>
                    </XAxis>
                    <YAxis>
                        <Label value={"Valor"} position="left" angle={-90}/>
                    </YAxis>
                    <Tooltip formatter={formatter}/>
                    <Bar dataKey="receita" name={"Receita"}>
                        {incomeExpenses.map((entry, index) => (
                            <Cell cursor="pointer" key={`cell-${index}`} fill={blue["700"]}/>
                        ))}
                    </Bar>
                    <Bar dataKey="despesa" name={"Despesa"}>
                        {incomeExpenses.map((entry, index) => (
                            <Cell cursor="pointer" key={`cell-${index}`} fill={red["700"]}/>
                        ))}
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </CustomPanel>
    )

}

export default React.memo(IncomeExpense);