import {createTheme} from '@mui/material/styles';

const theme = createTheme({
    palette: {
        neutral: {
            main: '#5a6065',
            contrastText: '#ffffff',
            light: '#A7A8A9',
            dark: '#2a2d2f'
        },
    },
    menu: {
        bar: {
            title: {
                flexGrow: 1
            },
        },
        submenu: {
            paddingLeft: '56px'
        }
    },
    chips: {
        margin: '10px'
    },
    loginContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#1976d2',
        height: '100%'
    },
    loginCard: {
        width: '345px',
        height: '360px'
    },
    loginMedia: {
        height: '180px'
    },
    loginButtonBar: {
        justifyContent: 'center'
    },
    tableContainer: {
        marginBottom: '10px'
    },
    tableRowInactive: {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
        "& td": {
            color: 'rgb(205,205,205)'
        }
    },
    tableEdit: {
        cursor: 'pointer'
    }
});

declare module '@mui/material/styles' {
    interface Palette {
        neutral: Palette['primary'];
    }

    // allow configuration using `createTheme`
    interface PaletteOptions {
        neutral?: PaletteOptions['primary'];
    }
}

// Update the Button's color prop options
declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        neutral: true;
    }
}

// Update the Button's color prop options
declare module '@mui/material/Chip' {
    interface ChipPropsColorOverrides {
        neutral: true;
    }
}

export default theme;
