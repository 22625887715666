import React from "react";
import {TableFooter, TablePagination, TableRow} from "@mui/material";

interface DatatableTableFooterProps {
    page: number;
    setPage: (number: number) => void;
    size: number;
    setSize: (size: number) => void;
    totalData: number;
}

const DatatableTableFooter = React.memo(({totalData, setPage, setSize, page, size}: DatatableTableFooterProps) => {

    const onPageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
        setPage(page);
    }

    const onRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const rows = parseInt(event.target.value, 10);
        setSize(rows);
    }

    return (
        <TableFooter>
            <TableRow>
                <TablePagination rowsPerPageOptions={[5, 10, 20, 25, 50, 100]} count={totalData}
                                 onPageChange={onPageChange} page={page}
                                 rowsPerPage={size} onRowsPerPageChange={onRowsPerPageChange}
                                 labelRowsPerPage="Itens por página"/>
            </TableRow>
        </TableFooter>
    )

})

export default DatatableTableFooter;