import React from "react";
import {DocumentPayTabs} from "../index";
import {useLocation, useNavigate, useParams} from "react-router-dom";

const DocumentPaySaleAfter = (props) => {

    let navigate = useNavigate();
    const location = useLocation();

    const {document, sale} = useParams();
    const {onClose} = props;

    const back = (success) => {
        const parts = location.pathname.split('/');
        const payable = (parts[4] === 'payables');
        if (onClose) {
            onClose(success)
        } else {
            if (payable) {
                navigate('/sales/after/' + sale + '/payables');
            } else {
                navigate('/sales/after/' + sale + '/receivables');
            }
        }
    }
    return (
        <DocumentPayTabs id={document} onClose={onClose} onBack={back}/>
    )
}

export default React.memo(DocumentPaySaleAfter);