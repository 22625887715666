import React, {useState} from 'react';
import CustomTextField from "../../../custom-text-field/custom-text-field";

interface SaleListHeaderFilterPersonNameProps {
    personName: string | undefined;
}

const SaleListHeaderFilterPersonName = React.memo(React.forwardRef<HTMLInputElement, SaleListHeaderFilterPersonNameProps>(({personName}, ref) => {

    const [internalPersonName, setInternalPersonName] = useState<string | undefined>(personName);

    const onChangePersonName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInternalPersonName(event.target.value);
    }

    return (
        <CustomTextField fullWidth autoFocus required label="Nome do cliente" type="text"
                         value={internalPersonName || ''} onChange={onChangePersonName} ref={ref}/>
    )

}));

export default SaleListHeaderFilterPersonName;
