import React, {FormEvent, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {setAlert} from "../../redux/actions";
import {CampaignService} from "./campaign.service";
import CustomButtonGroup from "../../custom-button-group/custom-button-group";
import CustomButton from "../../custom-button/custom-button";
import {useNavigate} from "react-router-dom";
import CampaignDetail from "./campaign-detail";
import {Campaign} from "./campaign";
import CustomTextField from "../../custom-text-field/custom-text-field";
import CustomRow from "../../custom-row/custom-row";
import {GenerateCampaignRequestByRest} from "./generate-campaign-request-by-rest";

const CampaignGenerateByRest = () => {

    const dispatch = useDispatch();
    let navigate = useNavigate();

    const [request, setRequest] = useState<GenerateCampaignRequestByRest>(CampaignService.createCampaignRequestByRest());

    useEffect(() => {
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const generate = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        CampaignService.generateByRest(request).then(() => {
            navigate('/campaigns');
        }).catch((error) => {
            dispatch(setAlert({
                show: true,
                message: 'Erro ao salvar registro - ' + error.response.data.message,
                severity: 'error'
            }));
        })
    }

    const cancel = () => {
        navigate('/campaigns');
    }

    const setCampaign = (campaign: Campaign) => {
        setRequest({
            ...request,
            campaign: campaign
        })
    }

    const onChangeStart = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRequest({
            ...request,
            start: event.target.value
        })
    }

    const onChangeEnd = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRequest({
            ...request,
            end: event.target.value
        })
    }

    const onChangeCreatedFrom = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRequest({
            ...request,
            createdFrom: event.target.value
        })
    }

    return (
        <form onSubmit={generate}>

            <CustomRow>
                <CustomTextField fullWidth autoFocus required label="Data inicial" value={request.start} type="date"
                                 onChange={onChangeStart} InputLabelProps={{shrink: true}}/>
                <CustomTextField fullWidth required label="Data final" value={request.end} type="date"
                                 onChange={onChangeEnd} InputLabelProps={{shrink: true}}/>
                <CustomTextField fullWidth required label="Cadastros criados a partir de" value={request.createdFrom}
                                 type="date" onChange={onChangeCreatedFrom} InputLabelProps={{shrink: true}}/>
            </CustomRow>

            <CampaignDetail item={request.campaign} setItem={setCampaign}/>

            <CustomButtonGroup>
                <CustomButton color="primary" type="submit">Gerar</CustomButton>
                <CustomButton type="button" color="neutral" onClick={cancel}>Cancelar</CustomButton>
            </CustomButtonGroup>
        </form>
    )

}

export default React.memo(CampaignGenerateByRest);
