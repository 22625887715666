import React from "react";
import {Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {ForeignKey} from "../index";
import CustomPanel from "../typescript/custom-panel/custom-panel";
import {CategoryService} from "../category/category.service";

const classes = {
    tableHeader: {
        "& th": {
            fontWeight: 'bold'
        }
    }
};

const ProductsWithoutValue = (props) => {

    const {productsWithoutValue, params, setParams} = props;

    const renderHeader = () => {
        return (
            <TableHead sx={classes.tableHeader}>
                <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Descrição</TableCell>
                </TableRow>
            </TableHead>
        );
    }

    const renderBody = () => {
        if (productsWithoutValue && productsWithoutValue.length > 0) {
            return (
                <TableBody>
                    {productsWithoutValue.map((product, index) => (
                        <TableRow key={index}>
                            <TableCell>{product.id}</TableCell>
                            <TableCell>{product.descricao}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            );
        } else {
            return (
                <TableBody>
                    <TableRow>
                        <TableCell colSpan={99}>
                            Nenhum registro encontrado
                        </TableCell>
                    </TableRow>
                </TableBody>
            )
        }
    }

    const onChangeCategoria = async (id) => {
        setParams({
            ...params,
            category: {
                id: id
            }
        })
        if (id) {
            const category = await CategoryService.findOne(id);
            onSelectCategoria(category);
        }
    }

    const onSelectCategoria = (category) => {
        if (category) {
            setParams({
                ...params,
                category: category
            })
        }
    }

    return (
        <Grid item md xs={12}>
            <CustomPanel title="Produtos ativos e sem valor nesta data">
                <ForeignKey fullWidth label="Categoria" value={params.category} fieldKey={'id'}
                            fieldDescription={'descricao'} labelDescription='Descrição' onChange={onChangeCategoria}
                            onSelect={onSelectCategoria} findAll={CategoryService.findAll}
                            dataColumnNames={['ID', 'Descrição']} dataColumns={['id', 'descricao']}
                            dataTypes={['number', 'text']}/>
                <TableContainer component={Paper}>
                    <Table size={"small"}>
                        {renderHeader()}
                        {renderBody()}
                    </Table>
                </TableContainer>
            </CustomPanel>
        </Grid>
    );

}

export default React.memo(ProductsWithoutValue);