import React from "react";
import {Grid, Typography} from "@mui/material";
import {Datatable} from "../index";
import {DocumentService} from "../document/document.service";
import {useDispatch} from "react-redux";
import {MoneyOff, Print} from "@mui/icons-material";
import {setAlert} from "../typescript/redux/actions";
import {NumberUtil} from "../typescript/util/number-util";
import {FileUtil} from "../typescript/util/file-util";
import CustomPanel from "../typescript/custom-panel/custom-panel";

const DocumentosPagos = (props) => {

    const {documentos, title, findAll} = props;
    const dispatch = useDispatch();

    const getTotal = () => {
        const valorTotal = documentos.reduce((valorInicial, item) => valorInicial + item.valorFinal, 0)
        return NumberUtil.currencyFormat(valorTotal);
    }

    const cancelarPagamento = async (item) => {
        DocumentService.cancelPayment(item.id).then(() => {
            dispatch(setAlert({show: true, message: 'Pagamento cancelado com sucesso', severity: 'success'}));
            findAll();
        }).catch((error) => {
            let message = 'Erro ao cancelar pagamento';
            if (error.response.data.message) {
                message += ' - ' + error.response.data.message;
            }
            dispatch(setAlert({show: true, message: message, severity: 'error'}));
        })
    }

    const recibo = async (item) => {
        DocumentService.recibo58x135(item.id).then(function (result) {
            FileUtil.openPdfInNewTab(result);
        });
    }

    const rowOperations = [
        {
            color: () => "primary",
            onClick: cancelarPagamento,
            label: () => 'Cancelar pagamento',
            icon: () => <MoneyOff/>
        },
        {color: () => "primary", onClick: recibo, label: () => 'Recibo', icon: () => <Print/>},
    ]

    return (
        <React.Fragment>
            <Grid item md xs={12}>
                <CustomPanel title={title}>
                    <Datatable data={documentos} totalData={documentos.length} maxHeight={440}
                               dataColumnNames={['Pessoa', 'Vencimento', 'Pagamento', 'Valor pago']}
                               dataColumns={['pessoa.nome', 'vencimento', 'pagamento', 'valorFinal']}
                               dataTypes={['text', 'date', 'date', 'currency']} rowOperations={rowOperations}/>
                </CustomPanel>
                <Typography variant="subtitle1" align={"center"}>Total: {getTotal()}</Typography>
            </Grid>
        </React.Fragment>
    );

}

export default React.memo(DocumentosPagos);