import React, {useEffect, useState} from "react";
import {
    ForeignKey
} from "../index";
import {useDispatch} from "react-redux";
import {PontoService} from "./ponto.service";
import {
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip
} from "@mui/material";
import {Cancel, Edit, Save} from "@mui/icons-material";
import CustomTextField from "../typescript/custom-text-field/custom-text-field";
import CustomButton from "../typescript/custom-button/custom-button";
import {changePageTitle, setAlert} from "../typescript/redux/actions";
import {DateUtil} from "../typescript/util/date-util";
import SelectOption from "../typescript/select-option/select-option";
import {UserService} from "../user/user.service";

const classes = {
    datatableTableHeader: {
        "& th": {
            fontWeight: 'bold'
        }
    }
};

const PontoManagement = () => {
    const [items, setItems] = useState(null);
    const [ano, setAno] = useState(DateUtil.currentYear());
    const [mes, setMes] = useState(DateUtil.currentMonth());
    const anoOptions = DateUtil.startYears();
    const mesOptions = DateUtil.startMonths();
    const [user, setUser] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(changePageTitle("Manutenção de ponto"));
    }, [dispatch]);

    const search = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        PontoService.findAll(ano, mes, user.id).then((result) => {
            setItems(result.data);
        }).catch((error) => {
            dispatch(setAlert({show: true, message: error.data.message, severity: 'error'}));
            setItems([]);
        })
    }

    const onChangeUsuario = async (id) => {
        setUser({id: id});
        if (id) {
            const user = await UserService.findOne(id);
            onSelectUsuario(user);
        }
    }

    const onSelectUsuario = (user) => {
        if (user) {
            setUser(user);
        }
    }

    const renderTableHeader = () => {
        return (
            <TableHead sx={classes.datatableTableHeader}>
                <TableRow>
                    <TableCell>Data</TableCell>
                    <TableCell>1º turno - entrada</TableCell>
                    <TableCell>1º turno - saída</TableCell>
                    <TableCell>2º turno - entrada</TableCell>
                    <TableCell>2º turno - saída</TableCell>
                    <TableCell>3º turno - entrada</TableCell>
                    <TableCell>3º turno - saída</TableCell>
                    <TableCell/>
                </TableRow>
            </TableHead>
        )
    }

    const editItem = (item, index) => {
        let itemsToChange = [...items];
        itemsToChange[index].editando = true;
        setItems(itemsToChange);
    }

    const saveItem = (item, index) => {
        PontoService.saveOrUpdate(item).then((result) => {
            dispatch(setAlert({show: true, message: 'Horário salvo com sucesso', severity: 'success'}));
            let itemsToChange = [...items];
            itemsToChange[index] = result.data;
            setItems(itemsToChange);
        }).catch((error) => {
            dispatch(setAlert({
                show: true,
                message: 'Erro ao salvar horário - ' + error.data.message,
                severity: 'error'
            }));
            let itemsToChange = [...items];
            itemsToChange[index].editando = false;
            setItems(itemsToChange);
        })

    }

    const cancelEdit = (item, index) => {
        let itemsToChange = [...items];
        itemsToChange[index].editando = false;
        setItems(itemsToChange);
    }

    const renderItemNotEditando = (item, index) => {
        return !item.editando && (
            <React.Fragment>
                <TableCell>{item.firstRoundEnter}</TableCell>
                <TableCell>{item.firstRoundExit}</TableCell>
                <TableCell>{item.secondRoundEnter}</TableCell>
                <TableCell>{item.secondRoundExit}</TableCell>
                <TableCell>{item.thirdRoundEnter}</TableCell>
                <TableCell>{item.thirdRoundExit}</TableCell>
                <TableCell>
                    <Tooltip title={'Editar'}>
                        <IconButton color={'primary'} onClick={() => editItem(item, index)}>
                            <Edit/>
                        </IconButton>
                    </Tooltip>
                </TableCell>
            </React.Fragment>
        )
    }

    const setRound = (event, index, valueToChange) => {
        const newValue = event.target.value;
        let itemsToChange = [...items];
        itemsToChange[index][valueToChange] = newValue;
        setItems(itemsToChange);
    }

    const renderItemEditando = (item, index) => {
        return !!item.editando && (
            <React.Fragment>
                <TableCell>
                    <CustomTextField fullWidth label="1° Turno - Entrada" type="time" value={item.firstRoundEnter || ''}
                                     onChange={(event) => setRound(event, index, 'firstRoundEnter')}
                                     InputLabelProps={{shrink: true}}/>
                </TableCell>
                <TableCell>
                    <CustomTextField fullWidth label="1° Turno - Saída" type="time" value={item.firstRoundExit || ''}
                                     onChange={(event) => setRound(event, index, 'firstRoundExit')}
                                     InputLabelProps={{shrink: true}}/>
                </TableCell>
                <TableCell>
                    <CustomTextField fullWidth label="2° Turno - Entrada" type="time"
                                     value={item.secondRoundEnter || ''}
                                     onChange={(event) => setRound(event, index, 'secondRoundEnter')}
                                     InputLabelProps={{shrink: true}}/>
                </TableCell>
                <TableCell>
                    <CustomTextField fullWidth label="2° Turno - Saída" type="time" value={item.secondRoundExit || ''}
                                     onChange={(event) => setRound(event, index, 'secondRoundExit')}
                                     InputLabelProps={{shrink: true}}/>
                </TableCell>
                <TableCell>
                    <CustomTextField fullWidth label="3° Turno - Entrada" type="time" value={item.thirdRoundEnter || ''}
                                     onChange={(event) => setRound(event, index, 'thirdRoundEnter')}
                                     InputLabelProps={{shrink: true}}/>
                </TableCell>
                <TableCell>
                    <CustomTextField fullWidth label="3° Turno - Saída" type="time" value={item.thirdRoundExit || ''}
                                     onChange={(event) => setRound(event, index, 'thirdRoundExit')}
                                     InputLabelProps={{shrink: true}}/>
                </TableCell>
                <TableCell>
                    <Tooltip title={'Salvar'}>
                        <IconButton color={'primary'} onClick={() => saveItem(item, index)}>
                            <Save/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={'Cancelar'}>
                        <IconButton color={'default'} onClick={() => cancelEdit(item, index)}>
                            <Cancel/>
                        </IconButton>
                    </Tooltip>
                </TableCell>
            </React.Fragment>
        )
    }

    const renderTableBody = () => {
        return (
            <TableBody>
                {items.map((item, index) => {
                    return (
                        <TableRow key={index}>
                            <TableCell>{DateUtil.javaSqlDateToDateToLocaleDateString(item.date)}</TableCell>
                            {renderItemNotEditando(item, index)}
                            {renderItemEditando(item, index)}
                        </TableRow>
                    )
                })}
            </TableBody>
        )
    }

    const renderTable = () => {
        return items && (
            <TableContainer component={Paper}>
                <Table size={"small"} stickyHeader>
                    {renderTableHeader()}
                    {renderTableBody()}
                </Table>
            </TableContainer>
        )
    }

    return (
        <React.Fragment>
            <form onSubmit={search}>
                <Grid container spacing={1}>
                    <Grid item md>
                        <Grid container spacing={1}>
                            <Grid item md xs={12}>
                                <SelectOption fullWidth required label="Ano" value={ano}
                                              onChange={(event) => setAno(event.target.value)} items={anoOptions}
                                              itemValue='name'
                                              itemDescription='description'/>
                            </Grid>
                            <Grid item md xs={12}>
                                <SelectOption fullWidth required label="Mês" value={mes}
                                              onChange={(event) => setMes(event.target.value)} items={mesOptions}
                                              itemValue='name'
                                              itemDescription='description'/>
                            </Grid>
                            <Grid item md xs={12}>
                                <ForeignKey fullWidth required label="Usuário" value={user} fieldKey={'id'}
                                            fieldDescription={'username'} labelDescription='Usuário'
                                            onChange={onChangeUsuario}
                                            onSelect={onSelectUsuario} findAll={UserService.findAll}
                                            dataColumnNames={['ID', 'Usuário']} dataColumns={['id', 'username']}
                                            dataTypes={['number', 'text']}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <CustomButton color="primary" type="submit">Pesquisar</CustomButton>
                    </Grid>
                </Grid>
            </form>
            {renderTable()}
        </React.Fragment>
    )

}

export default React.memo(PontoManagement);