import React from "react";
import {Paper, Table, TableContainer, Theme} from "@mui/material";
import DatatableTableHeader from "./datatable-table-header";
import DatatableTableBody from "./datatable-table-body";
import DatatableTableFooter from "./datatable-table-footer";
import DatatableTableSummary from "./datatable-table-summary";
import PageParamsUtil from "../util/page-params-util";
import {RowOperation} from "./row-operation";
import {SxProps} from "@mui/system";

const classes = {
    tableContainer: {
        marginBottom: '10px'
    }
};

interface DatatableTableProps {
    dataColumnNames: string[];
    dataColumns: string[];
    setSort: (newSortField: string, changedSortField: boolean) => void;
    dataTypes: string[];
    totalData: number;
    setPage: (newPage: number) => void;
    setSize: (newSize: number) => void;
    data: any[];
    dataValues?: ((item: any, dataColumn: string, columnIndex: number) => any)[];
    editData?: (item: any, index: number) => void;
    rowOperations?: RowOperation[];
    page: number
    rowClass?: (index: number) => SxProps<Theme>;
    size: number;
    sort?: string;
    sortColumns?: string[];
    summarize?: (string | null)[];
    maxHeight?: number;
}

const DatatableTable = React.memo(({
                                       dataColumnNames,
                                       dataColumns,
                                       setSort,
                                       dataTypes,
                                       totalData,
                                       setPage,
                                       setSize,
                                       data,
                                       dataValues,
                                       editData,
                                       rowOperations,
                                       page,
                                       rowClass,
                                       size,
                                       sort,
                                       sortColumns,
                                       summarize,
                                       maxHeight
                                   }: DatatableTableProps) => {

    const sortParams = PageParamsUtil.getSortParams(sort, dataColumns, dataTypes);

    const setSortBy = (newSortIndex: number) => {
        let newSortField = dataColumns[newSortIndex];
        let newSortDirection = 'asc';
        let changedSortField = true;
        if (newSortIndex === sortParams.index) {
            newSortDirection = (sortParams.direction === 'asc' ? 'desc' : 'asc');
            changedSortField = false;
        }
        setSort(newSortField + ',' + newSortDirection, changedSortField);
    }

    const renderFooter = () => {
        return (totalData !== data.length) &&
            <DatatableTableFooter totalData={totalData} setPage={setPage} setSize={setSize}
                                  page={page} size={size}/>;
    }

    const renderSummarize = () => {
        return summarize && (
            <DatatableTableSummary summarize={summarize} data={data} dataTypes={dataTypes}/>
        )
    }

    const cssClass = () => {
        if (maxHeight) {
            return {
                maxHeight: 440
            };
        }
    }

    return (
        <TableContainer component={Paper} style={cssClass()} sx={classes.tableContainer}>
            <Table size={"small"} stickyHeader>
                <DatatableTableHeader dataColumnNames={dataColumnNames} sortIndex={sortParams.index}
                                      sortColumns={sortColumns}
                                      sortDirection={sortParams.direction} setSortBy={setSortBy}
                                      hasRowOperations={!!rowOperations}/>
                <DatatableTableBody data={data} dataColumns={dataColumns} dataTypes={dataTypes} rowClass={rowClass}
                                    dataValues={dataValues} editData={editData} rowOperations={rowOperations}/>
                {renderSummarize()}
                {renderFooter()}
            </Table>
        </TableContainer>
    )

})

export default DatatableTable;