export class DateUtil {

    static toJavaSqlDate(date: Date): string {
        const ano = date.getFullYear();
        const mes = ("0" + (date.getMonth() + 1)).slice(-2);
        const dia = ("0" + date.getDate()).slice(-2);
        return ano + "-" + mes + '-' + dia;
    }

    static currentDayOfMonth() {
        return new Date().getDate();
    }

    static currentDate(): Date {
        return new Date();
    }

    static calculaData(diaVencimento: number, monthsToAdd: number): Date {
        let dataParcela = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0);

        if ((typeof diaVencimento != 'undefined') && (diaVencimento != null)) {
            dataParcela.setDate(diaVencimento);
        }

        const mesTemp = dataParcela.getMonth();
        dataParcela.setMonth(dataParcela.getMonth() + 1 + monthsToAdd);
        if (dataParcela.getMonth() > mesTemp + monthsToAdd + 1) {
            dataParcela.setDate(1 - 1);
        }

        return dataParcela;
    }

    static javaSqlDateToDate(date: string) {
        const dateString = date.split("-");
        return new Date(parseInt(dateString[0]), parseInt(dateString[1]) - 1, parseInt(dateString[2]));
    }

    static firstDayOfCurrentMonth() {
        const date = new Date();
        return new Date(date.getFullYear(), date.getMonth(), 1);
    }

    static currentYear() {
        const date = new Date();
        return date.getFullYear();
    }

    static firstDayOfCurrentMonthInJavaSqlDate() {
        const date = this.firstDayOfCurrentMonth();
        return this.toJavaSqlDate(date);
    }

    static lastDayOfCurrentMonth() {
        const date = new Date();
        return new Date(date.getFullYear(), date.getMonth() + 1, 0);
    }

    static lastDayOfCurrentMonthInJavaSqlDate() {
        const date = this.lastDayOfCurrentMonth();
        return this.toJavaSqlDate(date);
    }

    static javaSqlDateToDateToLocaleDateString(javaSqlDate: string) {
        const date = DateUtil.javaSqlDateToDate(javaSqlDate);
        return date.toLocaleDateString();
    }

    static fromZonedDateTime(zonedDateTime: string) {
        return new Date(zonedDateTime).toLocaleString()
    }

    static addDays(date: Date, days: number) {
        let tomorrow = new Date();
        tomorrow.setDate(date.getDate() + days);
        return tomorrow;
    }

    static addMonths(date: Date, monthsToAdd: number) {
        let today = new Date();
        today.setMonth(date.getMonth() + monthsToAdd);
        return today;
    }

    static javaLocalDateTimeToDate(date: string) {
        const parts = date.split("T");
        const dateParts = parts[0].split("-");
        const timeParts = parts[1].split(":");
        return new Date(parseInt(dateParts[0]), parseInt(dateParts[1]) - 1, parseInt(dateParts[2]), parseInt(timeParts[0]), parseInt(timeParts[1]), parseInt(timeParts[2]));
    }

    static toJavaSqlTime(date: Date) {
        return ("0" + date.getHours()).slice(-2) + ":" + ("0" + date.getMinutes()).slice(-2) + ':' + ("0" + date.getSeconds()).slice(-2);
    }

    static toJavaLocalDateTime(date: Date) {
        const data = this.toJavaSqlDate(date);
        const hora = this.toJavaSqlTime(date);
        return data + 'T' + hora;
    }

    static currentMonth() {
        const date = new Date();
        return date.getMonth() + 1;
    }

    static mesPorExtenso = (mes: number) => {
        switch (mes) {
            case 1:
                return 'Janeiro';
            case 2:
                return 'Fevereiro';
            case 3:
                return 'Março';
            case 4:
                return 'Abril';
            case 5:
                return 'Maio';
            case 6:
                return 'Junho';
            case 7:
                return 'Julho';
            case 8:
                return 'Agosto';
            case 9:
                return 'Setembro';
            case 10:
                return 'Outubro';
            case 11:
                return 'Novembro';
            case 12:
                return 'Dezembro';
            default:
                return null;
        }
    }

    static startYears = () => {
        let yearsToSelect = [];
        for (let i = 2015; i < 2030; i++) {
            yearsToSelect.push({name: i, description: i})
        }
        return yearsToSelect;
    }

    static startMonths = () => {
        let monthsToSelect = [];
        for (let i = 1; i < 13; i++) {
            monthsToSelect.push({name: i, description: this.mesPorExtenso(i)})
        }
        return monthsToSelect;
    }
}
