import {DirectionEnum} from "./direction-enum";

export class SortParams {
    field: string;
    direction: DirectionEnum;
    index: number;
    type: string;

    constructor(field: string, direction: DirectionEnum, index: number, type: string) {
        this.field = field;
        this.direction = direction;
        this.index = index;
        this.type = type;
    }
}