import React, {useCallback, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {changePageTitle, setAlert} from "../typescript/redux/actions";
import {Datatable} from "../index";
import {PersonService} from "./person.service";
import {Assignment, Delete as DeleteIcon, ListAlt, Payment, ShoppingCart} from "@mui/icons-material";
import {Checkbox, FormControlLabel} from "@mui/material";
import {useNavigate} from "react-router-dom";
import StarIcon from '@mui/icons-material/Star';

export function PersonList() {

    let navigate = useNavigate();
    const [pageParams, setPageParams] = useState(null);
    const dispatch = useDispatch();
    const [filtro, setFiltro] = useState({
        cliente: true,
        fornecedor: false
    });

    const findAll = useCallback(async (number, size, sort, filter) => {
        const filtroCliente = (filtro.cliente ? true : null);
        const filtroFornecedor = (filtro.fornecedor ? true : null);
        const items = await PersonService.findAll(number, size, sort, filter, filtroCliente, filtroFornecedor);
        setPageParams({
            number: number,
            size: size,
            sort: sort,
            filter: filter,
            items: items
        });
    }, [filtro]);

    useEffect(() => {
        dispatch(changePageTitle("Pessoas"));
        findAll(0, parseInt(process.env.REACT_APP_PAGE_SIZE), "nome,asc", '').then();
    }, [dispatch, findAll, filtro]);

    const editItem = (item) => {
        navigate('/person/' + item.id);
    }

    const insertItem = () => {
        navigate('/person');
    }

    const deleteItem = (item) => {
        PersonService.delete(item.id).then(() => {
            findAll(pageParams.number, pageParams.size, pageParams.sort, pageParams.filter).then();
        }).catch((error) => {
            dispatch(setAlert({
                show: true,
                message: 'Erro ao excluir pessoa - ' + error.data.message,
                severity: 'error'
            }));
        })
    }

    const setCliente = (event) => {
        setFiltro({
            ...filtro,
            cliente: event.target.checked,
        })
    }

    const setFornecedor = (event) => {
        setFiltro({
            ...filtro,
            fornecedor: event.target.checked,
        })
    }

    const headerOperations = [
        {color: () => "primary", onClick: insertItem, label: () => 'Inserir'}
    ]

    const goToDadosPrincipais = async (item) => {
        navigate('/person/' + item.id + '/0');
    }

    const goToDadosAcessorios = async (item) => {
        navigate('/person/' + item.id + '/1');
    }

    const goToCompras = async (item) => {
        navigate('/person/' + item.id + '/2');
    }

    const goToDocumentos = async (item) => {
        navigate('/person/' + item.id + '/documents');
    }

    const goToCampaigns = async (item) => {
        navigate('/person/' + item.id + '/campaigns');
    }

    const rowOperations = [
        {color: () => "primary", onClick: deleteItem, label: () => 'Excluir', icon: () => <DeleteIcon/>},
        {
            color: () => "primary",
            onClick: goToDadosPrincipais,
            label: () => 'Dados principais',
            icon: () => <Assignment/>
        },
        {
            color: () => "primary",
            onClick: goToDadosAcessorios,
            label: () => 'Dados acessórios',
            icon: () => <ListAlt/>
        },
        {color: () => "primary", onClick: goToCompras, label: () => 'Compras', icon: () => <ShoppingCart/>},
        {color: () => "primary", onClick: goToDocumentos, label: () => 'Documentos', icon: () => <Payment/>},
        {color: () => "primary", onClick: goToCampaigns, label: () => 'Campanhas', icon: () => <StarIcon/>},
    ]

    const filters = () => {
        return (
            <React.Fragment>
                <FormControlLabel control={<Checkbox checked={filtro.cliente} onChange={setCliente}/>}
                                  label="Clientes"/>
                <FormControlLabel control={<Checkbox checked={filtro.fornecedor} onChange={setFornecedor}/>}
                                  label="Fornecedores"/>
            </React.Fragment>
        )
    }

    return pageParams && (
        <React.Fragment>
            {filters()}
            <Datatable dataColumnNames={['ID', 'Nome', 'Telefone 1']} dataColumns={['id', 'nome', 'fone1']}
                       number={pageParams.number} size={pageParams.size} sort={pageParams.sort}
                       filter={pageParams.filter}
                       dataTypes={['number', 'text', 'text']} findAll={findAll} editData={editItem}
                       data={pageParams.items.content} totalData={pageParams.items.totalElements}
                       headerOperations={headerOperations} rowOperations={rowOperations}/>
        </React.Fragment>
    );
}