import React from "react";
import {TableCell, TableSortLabel} from "@mui/material";

const DatatableTableHeaderSort = (props) => {

    const {onClick, active, direction, item} = props;

    return (
        <TableCell>
            <TableSortLabel active={active} direction={direction} onClick={onClick}>
                {item}
            </TableSortLabel>
        </TableCell>
    )

}

export default React.memo(DatatableTableHeaderSort);