import React, {useCallback, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {changePageTitle} from "../typescript/redux/actions";
import {Datatable} from "../index";
import {DocumentService} from "./document.service";
import {useNavigate} from "react-router-dom";

export function DocumentDeleteLogList() {

    let navigate = useNavigate();
    const [pageParams, setPageParams] = useState(null);
    const dispatch = useDispatch();

    const findAll = useCallback(async (number, size, sort, filter) => {
        const items = await DocumentService.findAllDeleteLogs(number, size, sort, filter);
        setPageParams({
            number: number,
            size: size,
            sort: sort,
            filter: filter,
            items: items
        });
    }, []);

    useEffect(() => {
        dispatch(changePageTitle("Logs de exclusões nos documentos"));
        findAll(0, parseInt(process.env.REACT_APP_PAGE_SIZE), "date,desc", '').then();
    }, [dispatch, findAll]);

    const goBack = () => {
        navigate('/documents');
    }

    const headerOperations = [
        {color: () => "primary", onClick: goBack, label: () => 'Voltar'},
    ]

    return pageParams && (
        <Datatable data={pageParams.items.content} totalData={pageParams.items.totalElements}
                   dataColumnNames={['Data', 'Documento', 'Usuário', 'Pessoa', 'Valor']}
                   dataColumns={['date', 'documentId', 'username', 'person', 'valor']}
                   dataTypes={['date', 'number', 'string', 'string', 'decimal']}
                   number={pageParams.number} size={pageParams.size} sort={pageParams.sort} filter={pageParams.filter}
                   findAll={findAll} headerOperations={headerOperations}/>
    );
}