import React, {useCallback, useEffect, useState} from "react";
import {changePageTitle} from "../typescript/redux/actions";
import {DocumentService} from "./document.service";
import {useDispatch} from "react-redux";
import Datatable from "../typescript/datatable/datatable";
import {PageParams} from "../typescript/util/page-params";
import {useNavigate} from "react-router-dom";
import {ColorButtonEnum} from "../typescript/util/color-button-enum";

export function DocumentBaixaLogList() {

    let navigate = useNavigate();
    const [pageParams, setPageParams] = useState<PageParams>({
        number: 0,
        size: parseInt(process.env.REACT_APP_PAGE_SIZE || "0"),
        sort: "id,asc",
        filter: "",
        items: [],
        totalElements: 0
    });
    const dispatch = useDispatch();

    const findAll = useCallback(async (number?: number, size?: number, sort?: string, filter?: string) => {
        const result = await DocumentService.findAllBaixaLogs(number, size, sort, filter);
        setPageParams({
            number: number,
            size: size,
            sort: sort,
            filter: filter,
            items: result.content,
            totalElements: result.totalElements
        });
    }, []);

    useEffect(() => {
        dispatch(changePageTitle("Logs de baixas nos documentos"));
        findAll(0, parseInt(process.env.REACT_APP_PAGE_SIZE || "0"), "id,desc", '').then();
    }, [dispatch, findAll]);

    const goBack = () => {
        navigate('/documents');
    }

    const headerOperations = [
        {color: () => ColorButtonEnum.PRIMARY, onClick: goBack, label: () => 'Voltar'},
    ]

    return pageParams && (
        <Datatable dataColumnNames={[ 'ID', 'Descrição', 'Documento', 'Pessoa', 'Usuário', 'Valor', 'Data']}
                   dataColumns={['id', 'description', 'documentId', 'person', 'username', 'value', 'date']}
                   dataTypes={['number', 'text', 'number', 'string', 'string', 'currency', 'zonedatetime']}
                   page={pageParams.number} size={pageParams.size} sort={pageParams.sort} filter={pageParams.filter}
                   findAll={findAll} data={pageParams.items} totalData={pageParams.totalElements}
                   headerOperations={headerOperations}/>
    );
}