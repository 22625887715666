import React from 'react';
import {FormControl, FormGroup, FormLabel, Grid, Switch, Typography} from "@mui/material";

interface CustomSwitchProps {
    title: string;
    on: string;
    off: string;
    checked: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CustomSwitch = ({title, on, off, checked, onChange}: CustomSwitchProps) => {

    return (
        <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">{title}</FormLabel>
            <FormGroup>
                <Typography component="div">
                    <Grid component="label" container alignItems="center" spacing={1}>
                        <Grid item>{off}</Grid>
                        <Grid item>
                            <Switch color="primary" checked={checked} onChange={onChange}/>
                        </Grid>
                        <Grid item>{on}</Grid>
                    </Grid>
                </Typography>
            </FormGroup>
        </FormControl>
    )

}


export default React.memo(CustomSwitch);