import {Typography} from "@mui/material";
import {
    ForeignKey,
} from "../index";
import React, {useEffect, useState} from "react";
import {DocumentService} from "./document.service";
import {useDispatch} from "react-redux";
import CustomTextField from "../typescript/custom-text-field/custom-text-field";
import CustomButton from "../typescript/custom-button/custom-button";
import CustomButtonGroup from "../typescript/custom-button-group/custom-button-group";
import CustomRow from "../typescript/custom-row/custom-row";
import {DateUtil} from "../typescript/util/date-util";
import {setAlert} from "../typescript/redux/actions";
import {FileUtil} from "../typescript/util/file-util";
import {NumberUtil} from "../typescript/util/number-util";
import {WalletService} from "../typescript/wallet/wallet.service";
import {BrandService} from "../brand/brand.service";
import {PaymentTypeService} from "../payment-type/payment-type.service";
import {SaleService} from "../sale/sale.service";

export function DocumentPayPartial(props) {

    const [document, setDocumento] = useState(DocumentService.createDocument());

    const [item, setItem] = useState({
        dataPagamento: DateUtil.toJavaSqlDate(DateUtil.currentDate()),
        tipoDePagamento: PaymentTypeService.createPaymentType(),
        conta: WalletService.createWallet(),
        bandeira: BrandService.createBrand(),
        valor: '',
        parcelas: ''
    })
    const dispatch = useDispatch();

    useEffect(() => {
        const documentFromProps = props.document;

        let tipoDePagamento = PaymentTypeService.createPaymentType();
        let conta = WalletService.createWallet();
        if (documentFromProps.tipopagamento) {
            tipoDePagamento = documentFromProps.tipopagamento;
            if (documentFromProps.tipopagamento.id === SaleService.PAGAMENTO_CARTAO.id && documentFromProps.bandeira && documentFromProps.bandeira.carteira) {
                conta = documentFromProps.bandeira.carteira;
            } else if (documentFromProps.tipopagamento.carteira) {
                conta = documentFromProps.tipopagamento.carteira;
            }
        }

        setDocumento(documentFromProps);
        setItem({
            dataPagamento: DateUtil.toJavaSqlDate(DateUtil.currentDate()),
            tipoDePagamento: tipoDePagamento,
            conta: conta,
            valor: documentFromProps.saldo
        })

    }, [setDocumento, props.document]);

    const setDataPagamento = (event) => {
        setItem({
            ...item,
            dataPagamento: event.target.value
        })
    }

    const setValor = (event) => {
        setItem({
            ...item,
            valor: event.target.value
        })
    }

    const setParcelas = (event) => {
        setItem({
            ...item,
            parcelas: event.target.value
        })
    }

    const onChangeTipoDePagamento = async (id) => {
        setItem({
            ...item,
            tipoDePagamento: {
                id: id
            },
            conta: WalletService.createWallet(),
            bandeira: BrandService.createBrand()
        })
        if (id) {
            const tipoDePagamento = await PaymentTypeService.findOne(id);
            if (tipoDePagamento) {
                let conta = WalletService.createWallet();
                if (tipoDePagamento.carteira) {
                    conta = tipoDePagamento.carteira;
                }
                setItem({
                    ...item,
                    tipoDePagamento: tipoDePagamento,
                    conta: conta,
                    bandeira: BrandService.createBrand()
                })
            }
        }
    }

    const onSelectTipoDePagamento = (tipoDePagamento) => {
        let conta = WalletService.createWallet();
        if (tipoDePagamento.conta) {
            conta = tipoDePagamento.conta;
        }
        if (tipoDePagamento) {
            setItem({
                ...item,
                tipoDePagamento: tipoDePagamento,
                conta: conta,
                bandeira: BrandService.createBrand()
            })
        }
    }

    const findTipoDePagamentos = (page, size, sort, filter) => {
        return PaymentTypeService.findAll(page, size, sort, filter);
    }

    const onChangeConta = async (id) => {
        setItem({
            ...item,
            conta: {
                id: id
            }
        })
        if (id) {
            const conta = await WalletService.findOne(id);
            if (conta) {
                setItem({
                    ...item,
                    conta: conta
                })
            }
        }
    }

    const onSelectConta = (conta) => {
        if (conta) {
            setItem({
                ...item,
                conta: conta
            })
        }
    }

    const findContas = (page, size, sort, filter) => {
        return WalletService.findAll(page, size, sort, filter);
    }

    const onChangeBandeira = async (id) => {
        setItem({
            ...item,
            bandeira: {
                id: id
            }
        })
        if (id) {
            const bandeira = await BrandService.findOne(id);
            if (bandeira) {
                setItem({
                    ...item,
                    bandeira: bandeira
                })
            }
        }
    }

    const onSelectBandeira = (bandeira) => {
        if (bandeira) {
            setItem({
                ...item,
                bandeira: bandeira
            })
        }
    }

    const findBandeiras = (page, size, sort, filter) => {
        return BrandService.findAll(page, size, sort, filter);
    }

    const renderBandeiraEParcelas = () => {
        return PaymentTypeService.isCartao(item.tipoDePagamento) && (
            <CustomRow>
                <ForeignKey fullWidth required label="Bandeira" value={item.bandeira} fieldKey={'id'}
                            fieldDescription={'descricao'} labelDescription='Descrição' onChange={onChangeBandeira}
                            onSelect={onSelectBandeira} findAll={findBandeiras}
                            dataColumnNames={['ID', 'Descrição']} dataColumns={['id', 'descricao']}
                            dataTypes={['number', 'text']}/>
                <CustomTextField fullWidth required label="Parcelas" type="number" value={item.parcelas}
                                 onChange={setParcelas}/>
            </CustomRow>
        )
    }

    const payPartial = (event) => {
        event.preventDefault();
        event.stopPropagation();
        DocumentService.payPartial(props.document.id, item).then((result) => {
            DocumentService.reciboPagamentoParcial(result.data.id).then(function (result) {
                if (!props.document.aPagar) {
                    FileUtil.openPdfInNewTab(result);
                }
            });
            dispatch(setAlert({show: true, message: 'Documento pago com sucesso', severity: 'success'}));
            props.back(true);
        }).catch(() => {

        })
    }

    const renderPayments = () => {
        return props.document.movimentacoes.length > 0 && (
            <React.Fragment>
                <Typography variant="subtitle1" gutterBottom>
                    Pagamentos:
                </Typography>
                {props.document.movimentacoes.map((movimentacao, index) => {
                    return (
                        <Typography key={index} variant="subtitle2" gutterBottom>
                            {DateUtil.javaSqlDateToDate(movimentacao.data).toLocaleDateString()} - {NumberUtil.currencyFormat(movimentacao.valor)}
                        </Typography>
                    )
                })}
            </React.Fragment>
        )
    }

    const back = () => {
        props.back();
    }

    return (
        <form onSubmit={payPartial}>
            <Typography variant="subtitle1" gutterBottom>
                Valor final do documento: {NumberUtil.currencyFormat(document.valorFinal)}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
                Saldo: {NumberUtil.currencyFormat(document.saldo)}
            </Typography>
            {renderPayments()}
            <Typography variant="subtitle1" gutterBottom>
                Valor recebido: {NumberUtil.currencyFormat(document.valorFinal - document.saldo)}
            </Typography>
            <CustomRow>
                <CustomTextField fullWidth autoFocus required label="Valor à receber (R$)" type="number"
                                 value={item.valor}
                                 onChange={setValor}/>
                <CustomTextField fullWidth required label="Data de pagamento" type="date" value={item.dataPagamento}
                                 onChange={setDataPagamento} InputLabelProps={{shrink: true}}/>
            </CustomRow>
            <CustomRow>
                <ForeignKey fullWidth required label="Tipo de pagamento" value={item.tipoDePagamento} fieldKey={'id'}
                            fieldDescription={'descricao'} labelDescription='Descrição'
                            onChange={onChangeTipoDePagamento}
                            onSelect={onSelectTipoDePagamento} findAll={findTipoDePagamentos}
                            dataColumnNames={['ID', 'Descrição']}
                            dataColumns={['id', 'descricao']} dataTypes={['number', 'text']}/>
                <ForeignKey fullWidth required label="Conta" value={item.conta} fieldKey={'id'}
                            fieldDescription={'descricao'} labelDescription='Descrição' onChange={onChangeConta}
                            onSelect={onSelectConta} findAll={findContas}
                            dataColumnNames={['ID', 'Descrição']} dataColumns={['id', 'descricao']}
                            dataTypes={['number', 'text']}/>
            </CustomRow>
            {renderBandeiraEParcelas()}
            <Typography variant="subtitle1" gutterBottom>
                Saldo restante: {NumberUtil.currencyFormat(document.saldo - item.valor)}
            </Typography>
            <CustomButtonGroup>
                <CustomButton color="primary" type="submit"
                              disabled={(document.saldo - item.valor) < 0}>Pagar</CustomButton>
                <CustomButton type="button" color="neutral" onClick={back}>Cancelar</CustomButton>
            </CustomButtonGroup>
        </form>
    )
}