import React from "react";
import {Paper, Table, TableContainer} from "@mui/material";
import DatatableTableHeader from "./datatable-table-header";
import DatatableTableBody from "./datatable-table-body";
import DatatableTableFooter from "./datatable-table-footer";
import DatatableTableSummary from "./datatable-table-summary";
import PageParamsUtil from "../typescript/util/page-params-util";

const classes = {
    tableContainer: {
        marginBottom: '10px'
    }
};

const DatatableTable = React.memo(({
                                       dataColumnNames,
                                       dataColumns,
                                       setSort,
                                       dataTypes,
                                       totalData,
                                       setNumber,
                                       setSize,
                                       data,
                                       dataValues,
                                       editData,
                                       rowOperations,
                                       number,
                                       rowClass,
                                       size,
                                       sort,
                                       sortColumns,
                                       summarize,
                                       maxHeight
                                   }) => {

    const sortParams = PageParamsUtil.getSortParams(sort, dataColumns, dataTypes);

    const setSortBy = (newSortIndex) => {
        let newSortField = dataColumns[newSortIndex];
        let newSortDirection = 'asc';
        let changedSortField = true;
        if (newSortIndex === sortParams.index) {
            newSortDirection = (sortParams.direction === 'asc' ? 'desc' : 'asc');
            changedSortField = false;
        }
        setSort(newSortField + ',' + newSortDirection, changedSortField);
    }

    const renderFooter = () => {
        return (totalData !== data.length) &&
            <DatatableTableFooter totalData={totalData} setNumber={setNumber} setSize={setSize}
                                  number={number} size={size}/>;
    }

    const renderSummarize = () => {
        return summarize && (
            <DatatableTableSummary summarize={summarize} data={data} dataTypes={dataTypes}/>
        )
    }

    const cssClass = () => {
        if (maxHeight) {
            return {
                maxHeight: 440
            };
        }
    }

    return (
        <TableContainer component={Paper} style={cssClass()} sx={classes.tableContainer}>
            <Table size={"small"} stickyHeader>
                <DatatableTableHeader dataColumnNames={dataColumnNames} sortIndex={sortParams.index}
                                      sortColumns={sortColumns}
                                      sortDirection={sortParams.direction} setSortBy={setSortBy}
                                      hasRowOperations={!!rowOperations}/>
                <DatatableTableBody data={data} dataColumns={dataColumns} dataTypes={dataTypes} rowClass={rowClass}
                                    dataValues={dataValues} editData={editData} rowOperations={rowOperations}/>
                {renderSummarize()}
                {renderFooter()}
            </Table>
        </TableContainer>
    )

})

export default DatatableTable;