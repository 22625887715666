import React, {FormEvent, useEffect, useState} from "react";
import {LoginService} from "./login.service";
import {Card, CardActions, CardContent, CardMedia, Grid} from "@mui/material";
import {useNavigate} from "react-router-dom";
import CustomTextField from "../typescript/custom-text-field/custom-text-field";
import CustomButton from "../typescript/custom-button/custom-button";
import {AppService} from "../typescript/app/app.service";
import {UserService} from "../user/user.service";
import theme from "../typescript/theme/theme";

export function Login() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    let navigate = useNavigate();

    useEffect(() => {
        AppService.logout();
    }, []);

    const authenticate = async function (event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        let loginSuccessfull = await LoginService.authenticate(username, password);
        if (loginSuccessfull) {
            let getUserDetailsSuccessfull = await UserService.getMe();
            if (getUserDetailsSuccessfull) {
                await UserService.getAvatarOfCurrentUser();
                let from = AppService.getFrom();
                navigate(from);
            }
        }
    }

    return (
        <Grid sx={theme.loginContainer}>
            <form onSubmit={authenticate}>
                <Card sx={theme.loginCard}>
                    <CardMedia sx={theme.loginMedia} image="/copacubana.png"/>
                    <CardContent>
                        <CustomTextField fullWidth autoFocus required placeholder="Digite o seu usuário" label="Usuário"
                                         value={username} onChange={e => setUsername(e.target.value)}/>
                        <CustomTextField fullWidth required placeholder="Digite a sua senha" label="Senha"
                                         type="password"
                                         value={password} onChange={e => setPassword(e.target.value)}/>
                    </CardContent>
                    <CardActions sx={theme.loginButtonBar}>
                        <CustomButton color="primary" type="submit">Login</CustomButton>
                    </CardActions>
                </Card>
            </form>
        </Grid>
    );

}