import React, {useCallback, useEffect, useState} from "react";
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import CustomTextField from "../typescript/custom-text-field/custom-text-field";
import CustomRow from "../typescript/custom-row/custom-row";
import {DateUtil} from "../typescript/util/date-util";
import {NumberUtil} from "../typescript/util/number-util";
import CustomPanel from "../typescript/custom-panel/custom-panel";
import {SaleService} from "../sale/sale.service";

const classes = {
    datatableTableHeader: {
        "& th": {
            fontWeight: 'bold'
        }
    }
};

const VendasPorTempo = () => {
    const [startDate, setStartDate] = useState(DateUtil.firstDayOfCurrentMonthInJavaSqlDate());
    const [endDate, setEndDate] = useState(DateUtil.toJavaSqlDate(DateUtil.currentDate()));
    const [data, setData] = useState(null);

    const getData = useCallback(async () => {
        if (startDate && endDate) {
            const result = await SaleService.getSalesPerTime(startDate, endDate);
            setData(result.data);
        }
    }, [startDate, endDate]);

    useEffect(() => {
        getData().then();
    }, [getData]);

    const onChangeStartDate = (event) => {
        setStartDate(event.target.value)
    }

    const onChangeEndDate = (event) => {
        setEndDate(event.target.value)
    }

    return data && (
        <CustomPanel title={"Vendas por tempo"}>
            <CustomRow>
                <CustomTextField fullWidth required label="Data inicial" type="date" value={startDate || ''}
                                 InputLabelProps={{shrink: true}} onChange={onChangeStartDate}/>
                <CustomTextField fullWidth required label="Data final" type="date" value={endDate || ''}
                                 InputLabelProps={{shrink: true}} onChange={onChangeEndDate}/>
            </CustomRow>
            <TableContainer component={Paper}>
                <Table size={"small"} stickyHeader>
                    <TableHead sx={classes.datatableTableHeader}>
                        <TableRow>
                            <TableCell>Tipo de vendas</TableCell>
                            <TableCell>Quantidade de vendas</TableCell>
                            <TableCell>Valor total de vendas</TableCell>
                            <TableCell>Valor investido</TableCell>
                            <TableCell>Média investido/quantidade</TableCell>
                            <TableCell>Média investido/valor</TableCell>
                            <TableCell>Quantidade pessoas que conheceu loja pelo Instagram</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>Antigas</TableCell>
                            <TableCell>{data.vendasAntigas.quantidadeDeVendas}</TableCell>
                            <TableCell>{NumberUtil.currencyFormat(data.vendasAntigas.valorTotalDeVendas)}</TableCell>
                            <TableCell>{NumberUtil.currencyFormat(data.vendasAntigas.valorInvestido)}</TableCell>
                            <TableCell>{NumberUtil.currencyFormat(data.vendasAntigas.mediaValorInvestidoPorQuantidadeDeVendas)}</TableCell>
                            <TableCell>{data.vendasAntigas.mediaValorInvestidoPorValorTotalDeVendas}</TableCell>
                            <TableCell>{data.vendasAntigas.quantidadePessoasConheceuLojaInstagram}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Novas</TableCell>
                            <TableCell>{data.vendasNovas.quantidadeDeVendas}</TableCell>
                            <TableCell>{NumberUtil.currencyFormat(data.vendasNovas.valorTotalDeVendas)}</TableCell>
                            <TableCell>{NumberUtil.currencyFormat(data.vendasNovas.valorInvestido)}</TableCell>
                            <TableCell>{NumberUtil.currencyFormat(data.vendasNovas.mediaValorInvestidoPorQuantidadeDeVendas)}</TableCell>
                            <TableCell>{data.vendasNovas.mediaValorInvestidoPorValorTotalDeVendas}</TableCell>
                            <TableCell>{data.vendasNovas.quantidadePessoasConheceuLojaInstagram}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </CustomPanel>
    );
}

export default React.memo(VendasPorTempo);