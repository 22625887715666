import React from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {ConheceulojaDetail} from "./conheceuloja-detail";

const ConheceuLojaDetailPage = React.memo(() => {

    const {id} = useParams();
    let navigate = useNavigate();

    const onClose = () => {
        navigate('/conheceulojas');
    }

    return (
        <ConheceulojaDetail id={id} onClose={onClose}/>
    )

});

export default ConheceuLojaDetailPage;