import {COMPONENT_CONSTRUCTED, SET_ALERT, SHOW_LOADER} from './actionTypes';
import {Alert} from "../alert/alert";

const initialState = {
    title: "",
    alert: new Alert(false, "", "success"),
    showLoader: false
};

export const pageReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case COMPONENT_CONSTRUCTED:
            return {
                ...state,
                title: action.title
            };
        case SET_ALERT:
            return {
                ...state,
                alert: action.alert
            };
        case SHOW_LOADER:
            return {
                ...state,
                showLoader: action.showLoader
            };
        default:
            return state;
    }
};