import React, {useCallback, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {changePageTitle} from "../typescript/redux/actions";
import {Grid} from "@mui/material";
import {ForeignKey, PersonDetail} from "../index";
import {ConsultaService} from "./consulta.service";
import CustomTextField from "../typescript/custom-text-field/custom-text-field";
import CustomButton from "../typescript/custom-button/custom-button";
import CustomButtonGroup from "../typescript/custom-button-group/custom-button-group";
import {PersonService} from "../person/person.service";

export function ConsultaDetail({id, onClose, dialog}) {

    const [item, setItem] = useState(ConsultaService.createConsulta());
    const dispatch = useDispatch();

    const findOne = useCallback(async () => {
        const result = await ConsultaService.findOne(id);
        setItem(result);
    }, [id]);

    useEffect(() => {
        if (!dialog) {
            dispatch(changePageTitle(id ? "Consulta " + id : "Nova consulta"));
        }
        if (id) {
            findOne().then();
        }
    }, [dispatch, findOne, id, dialog]);

    const save = (event) => {
        event.preventDefault();
        event.stopPropagation();
        ConsultaService.save(item).then((result) => {
            onClose(result.data);
        }).catch(() => {

        })
    }

    const cancel = () => {
        onClose();
    }

    const setMedico = (event) => {
        setItem({
            ...item,
            medico: event.target.value
        })
    }

    const setData = (event) => {
        setItem({
            ...item,
            data: event.target.value
        })
    }

    const setHora = (event) => {
        setItem({
            ...item,
            hora: event.target.value
        })
    }

    const setObservacao = (event) => {
        setItem({
            ...item,
            observacao: event.target.value
        })
    }

    const onChangePessoa = async (id) => {
        setItem({
            ...item,
            pessoa: {
                id: id
            }
        })
        if (id) {
            const pessoa = await PersonService.findOne(id);
            if (pessoa) {
                setItem({
                    ...item,
                    pessoa: pessoa
                })
            }
        }
    }

    const onAddPessoa = (pessoa) => {
        if (pessoa) {
            setItem({
                ...item,
                pessoa: pessoa
            })
        }
    }

    const findPessoas = (page, size, sort, filter) => {
        return PersonService.findAll(page, size, sort, filter);
    }

    return (
        <form onSubmit={save}>
            <ForeignKey fullWidth autoFocus required label="Pessoa" value={item.pessoa} fieldKey={'id'}
                        fieldDescription={'nome'} labelDescription='Nome' onChange={onChangePessoa}
                        onSelect={onAddPessoa} onAdd={onAddPessoa} findAll={findPessoas}
                        dataColumnNames={['ID', 'Nome']} dataColumns={['id', 'nome']}
                        dataTypes={['number', 'text']} addComponent={<PersonDetail/>}/>
            <Grid container spacing={1}>
                <Grid item md xs={12}>
                    <CustomTextField fullWidth required label="Data" type="date" value={item.data || ''}
                                     InputLabelProps={{shrink: true}} onChange={setData}/>
                </Grid>
                <Grid item md xs={12}>
                    <CustomTextField fullWidth required label="Hora" type="time" value={item.hora || ''}
                                     InputLabelProps={{shrink: true}} onChange={setHora}/>
                </Grid>
            </Grid>
            <CustomTextField fullWidth required label="Dr." value={item.medico} onChange={setMedico}/>
            <CustomTextField fullWidth label="Observação" value={item.observacao ? item.observacao : ''}
                             onChange={setObservacao}/>
            <CustomButtonGroup>
                <CustomButton color="primary" type="submit">Salvar</CustomButton>
                <CustomButton type="button" color="neutral" onClick={cancel}>Cancelar</CustomButton>
            </CustomButtonGroup>
        </form>
    )
}