import React, {useState} from "react";
import {Checkbox, TableCell} from "@mui/material";
import {CampaignPerson} from "../campaign-person";
import {CampaignPersonService} from "../campaign-person.service";
import {setAlert} from "../../../redux/actions";
import {useDispatch} from "react-redux";

const classes = {
    rowDanger: {
        backgroundColor: '#f2dedeb8'
    },
    rowSuccess: {
        backgroundColor: '#dff0d8c9'
    },
};

interface BoughtCellProps {
    campaignPerson: CampaignPerson;
    disabled?: boolean;
}

const BoughtCell = ({campaignPerson, disabled}: BoughtCellProps) => {

    const dispatch = useDispatch();
    const [item, setItem] = useState<CampaignPerson>(campaignPerson);

    const rowClassBought = () => {
        return item.bought ? classes.rowSuccess : classes.rowDanger;
    }

    const onChangeBought = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newBought = event.target.checked;
        CampaignPersonService.updateBought(item.id, newBought).then(() => {
            setItem({
                ...campaignPerson,
                bought: newBought
            })
            dispatch(setAlert({show: true, message: 'Registro com sucesso', severity: 'success'}));
        }).catch((error) => {
            dispatch(setAlert({
                show: true,
                message: 'Erro ao salvar registro - ' + error.data.message,
                severity: 'error'
            }));
        })
    }

    return (
        <TableCell sx={rowClassBought} align={"center"}>
            <Checkbox checked={item.bought} onChange={(event) => onChangeBought(event)} disabled={disabled}/>
        </TableCell>
    )
}

export default React.memo(BoughtCell);