import {TextField} from "@mui/material";
import React from "react";
import {TextFieldProps} from "@mui/material/TextField/TextField";

const CustomTextField = React.memo(React.forwardRef<HTMLInputElement, TextFieldProps>((props, ref) => {
    return (
        <TextField variant="standard" {...props} ref={ref}/>
    )
}));

export default CustomTextField;
