import React, {useCallback, useEffect, useState} from "react";
import CustomButton from "../../custom-button/custom-button";
import {useNavigate, useParams} from "react-router-dom";
import CustomButtonGroup from "../../custom-button-group/custom-button-group";
import {CampaignService} from "../campaign/campaign.service";
import CampaignDetail from "../campaign/campaign-detail";
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import BoughtCell from "./component/bought-cell";
import AskedCell from "./component/asked-cell";
import InterestedCell from "./component/interested-cell";
import ReturnedCell from "./component/returned-cell";
import ContactCell from "./component/contact-cell";
import {Campaign} from "../campaign/campaign";
import {CampaignPerson} from "./campaign-person";
import {DateUtil} from "../../util/2.0.0/date-util";

const CampaignPersonList = () => {

    const {id} = useParams();
    let navigate = useNavigate();
    const [campaign, setCampaign] = useState<Campaign>();
    const [persons, setPersons] = useState<CampaignPerson[]>([]);

    const findOne = useCallback(async (id: number) => {
        const result = await CampaignService.findPersons(id);
        setCampaign(result.campaign);
        setPersons(result.persons);
    }, []);

    useEffect(() => {
        findOne(Number(id)).then();
    }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

    const back = () => {
        navigate('/campaigns');
    }

    const renderCampaign = () => {
        return campaign && <CampaignDetail item={campaign}/>;
    }

    const sortBy = (sort: string) => {
        switch (sort) {
            case "birthday": {
                let itemsToChange = [...persons];
                itemsToChange?.sort((n1, n2) => {
                    if (DateUtil.javaSqlDateToDate(n1.person.dataNascimento).getDate() > DateUtil.javaSqlDateToDate(n2.person.dataNascimento).getDate()) {
                        return 1;
                    }
                    if (DateUtil.javaSqlDateToDate(n1.person.dataNascimento).getDate() < DateUtil.javaSqlDateToDate(n2.person.dataNascimento).getDate()) {
                        return -1;
                    }
                    return 0;
                });
                setPersons(itemsToChange);
                break;
            }
            default: {
                let itemsToChange = [...persons];
                itemsToChange?.sort((n1, n2) => {
                    if (n1.person.nome > n2.person.nome) {
                        return 1;
                    }
                    if (n1.person.nome < n2.person.nome) {
                        return -1;
                    }
                    return 0;
                });
                setPersons(itemsToChange);
                break;
            }
        }
    }

    const renderPersons = () => {
        return persons && (
            <TableContainer component={Paper} sx={{marginBottom: '10px'}}>
                <Table size={"small"} stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell onClick={() => sortBy("name")}>Pessoa</TableCell>
                            <TableCell onClick={() => sortBy("birthday")}>Data de nascimento</TableCell>
                            <TableCell>Telefone</TableCell>
                            <TableCell>Faixa</TableCell>
                            <TableCell align={"center"}>Data contato</TableCell>
                            <TableCell align={"center"}>Retornou</TableCell>
                            <TableCell align={"center"}>Demonstrou interesse</TableCell>
                            <TableCell align={"center"}>Pediu produtos</TableCell>
                            <TableCell align={"center"}>Comprou</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {persons.map((campaignPerson, index) => (
                            <TableRow key={index}>
                                <TableCell>{campaignPerson.person.nome}</TableCell>
                                <TableCell>{campaignPerson.person.dataNascimento ? DateUtil.javaSqlDateToDateToLocaleDateString(campaignPerson.person.dataNascimento) : null}</TableCell>
                                <TableCell>{campaignPerson.person.fone1}</TableCell>
                                <TableCell>{CampaignService.range(campaignPerson.min, campaignPerson.max)}</TableCell>
                                <ContactCell campaignPerson={campaignPerson}/>
                                <ReturnedCell campaignPerson={campaignPerson}/>
                                <InterestedCell campaignPerson={campaignPerson}/>
                                <AskedCell campaignPerson={campaignPerson}/>
                                <BoughtCell campaignPerson={campaignPerson}/>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    return (
        <React.Fragment>
            <CustomButtonGroup>
                <CustomButton type="button" color="neutral" onClick={back}>Voltar</CustomButton>
            </CustomButtonGroup>
            {renderCampaign()}
            {renderPersons()}
        </React.Fragment>
    )

}

export default React.memo(CampaignPersonList);
