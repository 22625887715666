import {useDispatch} from 'react-redux';
import {Datatable} from "../index";
import React, {useCallback, useEffect, useState} from "react";
import {
    AccountCircle,
    AttachMoney,
    Cancel,
    CheckCircle, EmojiEvents,
    FitnessCenter,
    FlightTakeoff,
    VpnKey
} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import {changePageTitle} from "../typescript/redux/actions";
import {UserService} from "./user.service";

const classes = {
    userInactive: {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
        "& td": {
            color: 'rgb(205,205,205)'
        }
    }
};

export function UserList() {

    let navigate = useNavigate();
    const [pageParams, setPageParams] = useState(null);
    const dispatch = useDispatch();

    const findAll = useCallback(async (number, size, sort, filter) => {
        const items = await UserService.findAll(number, size, sort, filter);
        setPageParams({
            number: number,
            size: size,
            sort: sort,
            filter: filter,
            items: items
        });
    }, []);

    useEffect(() => {
        dispatch(changePageTitle("Usuários"));
        findAll(0, parseInt(process.env.REACT_APP_PAGE_SIZE), "id,asc", '').then();
    }, [dispatch, findAll]);

    const editItem = (item) => {
        navigate('/user/' + item.id);
    }

    const insertItem = () => {
        navigate('/user');
    }

    const rowClass = (index) => {
        return pageParams.items.content[index].enabled ? null : classes.userInactive;
    }

    const toogleActivate = async (item) => {
        item.enabled ? await UserService.inactivate(item.id) : await UserService.activate(item.id);
        await findAll(pageParams.number, pageParams.size, pageParams.sort, pageParams.filter);
    }

    const changePassword = async (item) => {
        navigate('/user/' + item.id + '/changepassword');
    }

    const salaryHistory = async (item) => {
        navigate('/user/' + item.id + '/salaryhistory');
    }

    const buttonActivateLabel = (item) => {
        return item.enabled ? 'Desativar' : 'Ativar';
    }

    const buttonActivateIcon = (item) => {
        return item.enabled ? <Cancel/> : <CheckCircle/>;
    }

    const buttonActivateColor = (item) => {
        return item.enabled ? "error" : "primary";
    }

    const vacation = async (item) => {
        navigate('/user/' + item.id + '/vacations');
    }

    const work = async (item) => {
        navigate('/user/' + item.id + '/works');
    }

    const mural = async (item) => {
        navigate('/user/' + item.id + '/mural');
    }

    const avatar = async (item) => {
        navigate('/user/' + item.id + '/avatar');
    }

    const rowOperations = [
        {color: buttonActivateColor, onClick: toogleActivate, label: buttonActivateLabel, icon: buttonActivateIcon},
        {color: () => "primary", onClick: changePassword, label: () => 'Alterar senha', icon: () => <VpnKey/>},
        {
            color: () => "primary",
            onClick: salaryHistory,
            label: () => 'Histórico de salários',
            icon: () => <AttachMoney/>
        },
        {color: () => "primary", onClick: vacation, label: () => 'Férias', icon: () => <FlightTakeoff/>},
        {color: () => "primary", onClick: work, label: () => 'Dias de trabalho', icon: () => <FitnessCenter/>},
        {color: () => "primary", onClick: avatar, label: () => 'Avatar', icon: () => <AccountCircle/>},
        {color: () => "primary", onClick: mural, label: () => 'Mural dos sonhos', icon: () => <EmojiEvents/>}
    ]

    const headerOperations = [
        {color: () => "primary", onClick: insertItem, label: () => 'Inserir'}
    ]

    return pageParams && (
        <Datatable dataColumnNames={['ID', 'Usuário', 'Ativo']} dataColumns={['id', 'username', 'enabled']}
                   rowClass={rowClass}
                   number={pageParams.number} size={pageParams.size} sort={pageParams.sort} filter={pageParams.filter}
                   dataTypes={['number', 'text', 'boolean']} findAll={findAll} editData={editItem}
                   data={pageParams.items.content} totalData={pageParams.items.totalElements}
                   headerOperations={headerOperations} rowOperations={rowOperations}/>
    );
}