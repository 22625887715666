import React, {useCallback, useEffect, useState} from "react";
import {Datatable} from "../index";
import {useDispatch} from "react-redux";
import {Typography} from "@mui/material";
import {useParams} from "react-router-dom";
import CustomTextField from "../typescript/custom-text-field/custom-text-field";
import CustomRow from "../typescript/custom-row/custom-row";
import {changePageTitle} from "../typescript/redux/actions";
import {NumberUtil} from "../typescript/util/number-util";
import {WalletService} from "../typescript/wallet/wallet.service";
import CustomPanel from "../typescript/custom-panel/custom-panel";

const WalletOverview = () => {

    const {id} = useParams();
    const [dataInicial, setDataInicial] = useState(null);
    const [dataFinal, setDataFinal] = useState(null);
    const [wallet, setWallet] = useState(null);
    const dispatch = useDispatch();

    const findOne = useCallback(async (dataInicialParam, dataFinalParam) => {
        const result = await WalletService.getCarteiraOverview(id, dataInicialParam, dataFinalParam);
        setWallet(result.data);
        setDataInicial(result.data.dataInicial);
        setDataFinal(result.data.dataFinal);
    }, [id]);

    useEffect(() => {
        dispatch(changePageTitle("Conta " + id));
        findOne(null, null).then();
    }, [dispatch, findOne, id]);

    const changeDataInicial = (event) => {
        findOne(event.target.value, dataFinal).then();
    }

    const changeDataFinal = (event) => {
        findOne(dataInicial, event.target.value).then();
    }

    return wallet && (
        <CustomPanel title={"Detalhe de conta " + wallet.descricao}>
            <CustomRow>
                <CustomTextField fullWidth autoFocus label="Data inicial" type="date" value={dataInicial || ''}
                                 InputLabelProps={{shrink: true}} onChange={changeDataInicial}/>
                <CustomTextField fullWidth autoFocus label="Data final" type="date" value={dataFinal || ''}
                                 InputLabelProps={{shrink: true}} onChange={changeDataFinal}/>
            </CustomRow>
            <Typography variant="h6" gutterBottom align={"right"}>
                Saldo inicial: {NumberUtil.currencyFormat(wallet.saldoInicial)}
            </Typography>
            <Datatable dataColumnNames={['Data', 'Descrição', 'Pessoa', 'Tipo de pagamento', 'Valor']}
                       dataColumns={['data', 'descricao', 'pessoa.nome', 'tipoDePagamento.descricao', 'valor']}
                       dataTypes={['date', 'text', 'text', 'text', 'currency']}
                       data={wallet.movimentos} totalData={wallet.movimentos.length}/>
            <Typography variant="h6" gutterBottom align={"right"}>
                Saldo final: {NumberUtil.currencyFormat(wallet.saldoFinal)}
            </Typography>
        </CustomPanel>
    )

}

export default React.memo(WalletOverview);