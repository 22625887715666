import {AppService} from "../typescript/app/app.service";
import {PageResponse} from "../typescript/util/page-response";
import {UserGoal} from "./user-goal";
import {UserService} from "../user/user.service";
import {User} from "../user/user";

class UserGoalService {

    static API_URL = "/api/metausuarios/v2";

    static findAll = (page?: number, size?: number, sort?: string, filter?: string): Promise<PageResponse<UserGoal>> => {
        return AppService.getAuthorized(this.API_URL, {
            page: page,
            size: size,
            sort: sort,
            id: sort?.startsWith('id') ? filter : '',
            vendedor: sort?.startsWith('vendedor') ? filter : '',
            ano: sort?.startsWith('ano') ? filter : '',
            mes: sort?.startsWith('mes') ? filter : ''
        }).then((result) => {
            return result.data;
        }).catch(() => {
            return {
                content: [],
                totalElements: 0
            };
        })
    };

    static findOne(id: number) {
        return AppService.getAuthorized(this.API_URL + "/" + id).then((result) => {
            return result.data;
        }).catch(() => {
            return null;
        })
    }

    static save(item: UserGoal) {
        if (item.id) {
            return AppService.postAuthorized(this.API_URL + "/" + item.id, item);
        } else {
            return AppService.postAuthorized(this.API_URL, item);
        }
    }

    static delete(id: number) {
        return AppService.deleteAuthorized(this.API_URL + "/" + id);
    }

    static createUserGoal() {
        const userGoal: UserGoal = {
            ano: '',
            mes: '',
            metaMinima: '',
            metaBasica: '',
            metaMaster: '',
            usuario: UserService.createUser()
        }
        return userGoal;
    }

    static async analysis(user: User) {
        return AppService.getAuthorized(this.API_URL + "/analise", {
            user: user
        });
    }
}

export {UserGoalService};
