import React, {RefObject, useCallback, useEffect, useState} from "react";
import {DateUtil} from "../../util/2.0.0/date-util";
import {SaleListHeaderFilterEnum} from "./header/enum/sale-list-header-filter-enum";
import {changePageTitle} from "../../redux/actions";
import {useDispatch} from "react-redux";
import {SaleService} from "../../../sale/sale.service";
import SaleListHeader from "./header/sale-list-header";
import {SaleListHeaderFilterClosedEnum} from "./header/enum/sale-list-header-filter-closed-enum";
import {SaleListHeaderFilterReceivedEnum} from "./header/enum/sale-list-header-filter-received-enum";
import {SaleListResponse} from "../../../sale/sale-list-response";
import SaleListBody from "./body/sale-list-body";

const SaleList = React.memo(() => {
    const dispatch = useDispatch();

    const [saleFilter] = useState<SaleListHeaderFilterEnum>(SaleListHeaderFilterEnum.EMISSION);

    const [startDate] = useState<string | undefined>(DateUtil.firstDayOfCurrentMonthInJavaSqlDate());
    const [endDate] = useState<string | undefined>(DateUtil.lastDayOfCurrentMonthInJavaSqlDate());
    const [saleId] = useState<number | undefined>();
    const [personName] = useState<string | undefined>();

    const [saleClosed] = useState<SaleListHeaderFilterClosedEnum>(SaleListHeaderFilterClosedEnum.BOTH);
    const [saleReceived] = useState<SaleListHeaderFilterReceivedEnum>(SaleListHeaderFilterReceivedEnum.BOTH);

    const [sales, setSales] = useState<SaleListResponse | null>(null);

    const startDateRef = React.useRef<HTMLInputElement>(null);
    const endDateRef = React.useRef<HTMLInputElement>(null);
    const saleIdRef = React.useRef<HTMLInputElement>(null);
    const personNameRef = React.useRef<HTMLInputElement>(null);
    const saleClosedRef = React.useRef<HTMLInputElement>(null);
    const saleReceivedRef = React.useRef<HTMLInputElement>(null);

    const getSaleClosedNumber = (saleClosedParam: RefObject<HTMLInputElement>) => {
        const firstChild = saleClosedParam.current?.children[0].firstChild as HTMLInputElement;
        if (firstChild.classList.contains('Mui-checked')) {
            return 0;
        }
        const secondChild = saleClosedParam.current?.children[1].firstChild as HTMLInputElement;
        if (secondChild.classList.contains('Mui-checked')) {
            return 1;
        }
        return 2;
    }

    const getSaleReceivedNumber = (saleReceivedParam: RefObject<HTMLInputElement>) => {
        const firstChild = saleReceivedParam.current?.children[0].firstChild as HTMLInputElement;
        if (firstChild.classList.contains('Mui-checked')) {
            return 0;
        }
        const secondChild = saleReceivedParam.current?.children[1].firstChild as HTMLInputElement;
        if (secondChild.classList.contains('Mui-checked')) {
            return 1;
        }
        return 2;
    }

    const findAll = useCallback(async () => {
        setSales(null);
        const startDateChild: HTMLInputElement = (startDateRef.current?.lastChild?.firstChild as HTMLInputElement);
        const endDateChild: HTMLInputElement = (endDateRef.current?.lastChild?.firstChild as HTMLInputElement);
        const saleIdChild: HTMLInputElement = (saleIdRef.current?.lastChild?.firstChild as HTMLInputElement);
        const personNameChild: HTMLInputElement = (personNameRef.current?.lastChild?.firstChild as HTMLInputElement);

        const saleClosedNumber = getSaleClosedNumber(saleClosedRef);
        const saleClosedBoolean = SaleService.saleFilterClosedEnumToBoolean(saleClosedNumber);

        const saleReceivedNumber = getSaleReceivedNumber(saleReceivedRef);
        const saleReceivedBoolean = SaleService.saleFilterReceivedEnumToBoolean(saleReceivedNumber);

        const result = await SaleService.findAll(startDateChild?.value, endDateChild?.value, saleClosedBoolean, saleReceivedBoolean,
            saleIdChild ? Number(saleIdChild?.value) : undefined, personNameChild?.value);
        setSales(result);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        dispatch(changePageTitle("Vendas"));
        findAll().then();
    }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <React.Fragment>
            <SaleListHeader saleFilter={saleFilter} startDate={startDate} endDate={endDate} saleId={saleId}
                            personName={personName} findAll={findAll} startDateRef={startDateRef}
                            endDateRef={endDateRef} saleIdRef={saleIdRef} personNameRef={personNameRef}
                            saleClosed={saleClosed} saleClosedRef={saleClosedRef}
                            saleReceived={saleReceived} saleReceivedRef={saleReceivedRef}/>
            <SaleListBody sales={sales} findAll={findAll}/>
        </React.Fragment>
    )
});

export default SaleList;
