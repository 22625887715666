import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {FormControl, FormControlLabel, FormLabel, Radio, RadioGroup} from "@mui/material";
import CustomTextField from "../typescript/custom-text-field/custom-text-field";
import CustomButton from "../typescript/custom-button/custom-button";
import CustomButtonGroup from "../typescript/custom-button-group/custom-button-group";
import CustomRow from "../typescript/custom-row/custom-row";
import {changePageTitle, setAlert} from "../typescript/redux/actions";
import {DateUtil} from "../typescript/util/date-util";
import {FileUtil} from "../typescript/util/file-util";
import * as StringUtil from "../typescript/util/string-util";
import {RelatorioService} from "../typescript/relatorio/relatorio.service";

const CurvaABC = () => {
    const curr = new Date();
    const [startDate, setStartDate] = useState(DateUtil.toJavaSqlDate(new Date(curr.getFullYear() - 1, curr.getMonth(), 1)));
    const [endDate, setEndDate] = useState(DateUtil.toJavaSqlDate(new Date(curr.getFullYear(), curr.getMonth(), 0)));
    const [vendas, setVendas] = useState(true);
    const [produtos, setProdutos] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(changePageTitle("Curva ABC"));
    }, [dispatch]);

    const print = (event) => {
        event.preventDefault();
        event.stopPropagation();
        RelatorioService.getCurvaAbc(startDate, endDate, produtos, vendas).then(function (result) {
            FileUtil.openPdfInNewTab(result);
        }, (result) => {
            const error = StringUtil.decodeUint8Array(result);
            dispatch(setAlert({show: true, message: error.message, severity: 'error'}));
        })
    }

    const onChangeVendas = (event) => {
        const value = event.target.value;
        setVendas(value === 'true');
    }

    const onChangeProdutos = (event) => {
        const value = event.target.value;
        setProdutos(value === 'true');
    }

    const onChangeStartDate = (event) => {
        setStartDate(event.target.value)
    }

    const onChangeEndDate = (event) => {
        setEndDate(event.target.value)
    }

    return (
        <form onSubmit={print}>
            <CustomRow>
                <FormControl component="fieldset" required>
                    <FormLabel component="legend">Tipo</FormLabel>
                    <RadioGroup value={vendas} onChange={onChangeVendas}>
                        <FormControlLabel value={true} control={<Radio/>} label="De vendas"/>
                        <FormControlLabel value={false} control={<Radio/>} label="De lucro"/>
                    </RadioGroup>
                </FormControl>

                <FormControl component="fieldset" required>
                    <FormLabel component="legend">Classificação</FormLabel>
                    <RadioGroup value={produtos} onChange={onChangeProdutos}>
                        <FormControlLabel value={true} control={<Radio/>} label="Por produto"/>
                        <FormControlLabel value={false} control={<Radio/>} label="Por categoria"/>
                    </RadioGroup>
                </FormControl>

                <CustomTextField fullWidth required label="Data inicial" type="date" value={startDate || ''}
                                 InputLabelProps={{shrink: true}} onChange={onChangeStartDate}/>

                <CustomTextField fullWidth required label="Data final" type="date" value={endDate || ''}
                                 InputLabelProps={{shrink: true}} onChange={onChangeEndDate}/>
            </CustomRow>

            <CustomButtonGroup>
                <CustomButton color="primary" type="submit">Imprimir</CustomButton>
            </CustomButtonGroup>
        </form>
    );
}

export default React.memo(CurvaABC);