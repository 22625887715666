import {PersonService} from "../person/person.service";
import {Parente} from "./parente";

class ParenteService {

    static API_URL = "/api/parentes";

    static createParente() {
        const parente: Parente = {
            pessoa: PersonService.createPerson(false, false),
            nome: '',
            grau: '',
            telefone: '',
            dataNascimento: ''
        }
        return parente;
    }
}

export {ParenteService};