import React, {useState} from 'react';
import {FormControl, FormControlLabel, Radio, RadioGroup} from "@mui/material";
import {SaleListHeaderFilterClosedEnum} from "./enum/sale-list-header-filter-closed-enum";

interface SaleListHeaderFilterClosedProps {
    saleClosed: SaleListHeaderFilterClosedEnum;
}

const SaleListHeaderFilterClosed = React.memo(React.forwardRef<HTMLInputElement, SaleListHeaderFilterClosedProps>(({saleClosed}, ref) => {

        const [saleClosedInternal, setSaleClosedInternal] = useState<SaleListHeaderFilterClosedEnum>(saleClosed);

        const onChangeClosed = (event: React.ChangeEvent<HTMLInputElement>) => {
            setSaleClosedInternal(Number(event.target.value));
        }

        return (
            <FormControl component="fieldset" required>
                <RadioGroup row value={saleClosedInternal} onChange={onChangeClosed} ref={ref}>
                    <FormControlLabel value={SaleListHeaderFilterClosedEnum.CLOSED} control={<Radio/>} label="Fechada"/>
                    <FormControlLabel value={SaleListHeaderFilterClosedEnum.OPENED} control={<Radio/>} label="Aberta"/>
                    <FormControlLabel value={SaleListHeaderFilterClosedEnum.BOTH} control={<Radio/>} label="Ambas"/>
                </RadioGroup>
            </FormControl>
        )

    }
));

export default SaleListHeaderFilterClosed;
