import {useDispatch} from "react-redux";
import React, {useCallback, useEffect, useState} from "react";
import {changePageTitle} from "../typescript/redux/actions";
import {UserService} from "./user.service";
import {Datatable} from "../index";
import {useNavigate, useParams} from "react-router-dom";
import CustomButton from "../typescript/custom-button/custom-button";

export function SalaryHistoryList() {

    const {id} = useParams();
    let navigate = useNavigate();

    const [items, setItems] = useState([]);

    const dispatch = useDispatch();

    const findAll = useCallback(async () => {
        const result = await UserService.findSalaryHistory(id);
        setItems(result);
    }, [id]);

    useEffect(() => {
        dispatch(changePageTitle("Histórico de salários do usuário " + id));
        findAll().then();
    }, [dispatch, findAll, id]);

    const back = () => {
        navigate('/users');
    }

    return (
        <React.Fragment>
            <Datatable data={items} totalData={items.length}
                       dataColumnNames={['Data/Hora', 'Tipo de salário', 'Valor']}
                       dataColumns={['date', 'salaryType', 'salary']}
                       dataTypes={['datetime', 'enum', 'number']}
            />
            <CustomButton type="button" onClick={back}>Voltar</CustomButton>
        </React.Fragment>
    )
}