import {Grid} from "@mui/material";
import React from "react";

interface CustomRowProps {
    children: any | any[]
}

const CustomRow = React.memo(({children}: CustomRowProps) => {

    const childrenWithProps = React.Children.map(children, child => {
        return child && (
            <Grid item md xs={12}>
                {child}
            </Grid>
        )
    });

    return (
        <Grid container spacing={1}>
            {childrenWithProps}
        </Grid>
    );
});

export default CustomRow;