import {configureStore, PreloadedState} from "@reduxjs/toolkit";
import {rootReducer} from "./rootReducer";

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    })
})
export function setupStore(preloadedState?: PreloadedState<RootState>) {
    return store
}
export type RootState = ReturnType<typeof store.getState>
export type AppStore = ReturnType<typeof setupStore>