import React, {FormEvent, useCallback, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {WalletService} from "./wallet.service";
import CustomTextField from "../custom-text-field/custom-text-field";
import CustomButton from "../custom-button/custom-button";
import CustomButtonGroup from "../custom-button-group/custom-button-group";
import {changePageTitle, setAlert} from "../redux/actions";
import {State} from "../state/state";

interface WalletDetailProps {
    id?: number | null;
    dialog?: boolean;
    onClose?: (state?: State) => void;
}

export function WalletDetail({id, dialog, onClose}: WalletDetailProps) {

    const [item, setItem] = useState(WalletService.createWallet());
    const dispatch = useDispatch();

    const findOne = useCallback(async (id: number) => {
        const result = await WalletService.findOne(id);
        setItem(result);
    }, []);

    useEffect(() => {
        if (!dialog) {
            dispatch(changePageTitle(id ? "Carteira " + id : "Nova carteira"));
        }
        if (id) {
            findOne(id).then();
        }
    }, [id, dialog]); // eslint-disable-line react-hooks/exhaustive-deps

    const save = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        WalletService.save(item).then((result) => {
            if (onClose) {
                onClose(result.data);
            }
        }).catch((error) => {
            dispatch(setAlert({
                show: true,
                message: 'Erro ao salvar registro - ' + error.message,
                severity: 'error'
            }));
        })
    }

    const cancel = () => {
        if (onClose) {
            onClose();
        }
    }

    const setDescricao = (event: React.ChangeEvent<HTMLInputElement>) => {
        setItem({
            ...item,
            descricao: event.target.value
        })
    }

    return (
        <form onSubmit={save}>
            <CustomTextField fullWidth autoFocus required label="Descrição" value={item.descricao}
                             onChange={setDescricao}/>
            <CustomButtonGroup>
                <CustomButton color="primary" type="submit">Salvar</CustomButton>
                <CustomButton type="button" color="neutral" onClick={cancel}>Cancelar</CustomButton>
            </CustomButtonGroup>
        </form>
    )
}