import React, {FormEvent, useCallback, useEffect, useState} from "react";
import {CampaignContactType} from "./campaign-contact-type";
import {useDispatch} from "react-redux";
import {changePageTitle, setAlert} from "../../redux/actions";
import {CampaignContactTypeService} from "./campaign-contact-type.service";
import CustomTextField from "../../custom-text-field/custom-text-field";
import CustomButtonGroup from "../../custom-button-group/custom-button-group";
import CustomButton from "../../custom-button/custom-button";

interface CampaignContactTypeDetailProps {
    id?: number | null;
    dialog?: boolean;
    onClose?: (campaignContactType?: CampaignContactType) => void;
}

const CampaignContactTypeDetail = ({id, dialog, onClose}: CampaignContactTypeDetailProps) => {

    const dispatch = useDispatch();

    const [item, setItem] = useState(CampaignContactTypeService.createCampaignContactType());

    const findOne = useCallback(async (id: number) => {
        const result = await CampaignContactTypeService.findOne(id);
        setItem(result);
    }, []);

    useEffect(() => {
        if (!dialog) {
            dispatch(changePageTitle(id ? "Tipo de contato de campanha " + id : "Novo tipo de contato de campanha"));
        }
        if (id) {
            findOne(id).then();
        }
    }, [id, dialog]); // eslint-disable-line react-hooks/exhaustive-deps

    const save = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        CampaignContactTypeService.save(item).then((result) => {
            if (onClose) {
                onClose(result.data);
            }
        }).catch((error) => {
            dispatch(setAlert({
                show: true,
                message: 'Erro ao salvar registro - ' + error.response.data.message,
                severity: 'error'
            }));
        })
    }

    const cancel = () => {
        if (onClose) {
            onClose();
        }
    }

    const setDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
        setItem({
            ...item,
            description: event.target.value
        })
    }

    return (
        <form onSubmit={save}>
            <CustomTextField fullWidth autoFocus required label="Descrição" value={item.description}
                             onChange={setDescription}/>
            <CustomButtonGroup>
                <CustomButton color="primary" type="submit">Salvar</CustomButton>
                <CustomButton type="button" color="neutral" onClick={cancel}>Cancelar</CustomButton>
            </CustomButtonGroup>
        </form>
    )

}

export default React.memo(CampaignContactTypeDetail);