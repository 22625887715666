import {AppService} from "../typescript/app/app.service";
import {PageResponse} from "../typescript/util/page-response";
import {Category} from "./category";
import {CategoryGroupService} from "../category-group/category-group.service";
import {AxiosResponse} from "axios";

class CategoryService {

    static API_URL = "/api/categorias/v2";

    static findAll = (page?: number, size?: number, sort?: string, filter?: string): Promise<PageResponse<Category>> => {
        return AppService.getAuthorized(this.API_URL, {
            page: page,
            size: size,
            sort: sort,
            id: sort?.startsWith('id') ? filter : '',
            descricao: sort?.startsWith('descricao') ? filter : ''
        }).then((result) => {
            return result.data;
        }).catch(() => {
            return {
                content: [],
                totalElements: 0
            };
        })
    };

    static findOne = async (id: number): Promise<Category> => {
        return AppService.getAuthorized(this.API_URL + "/" + id).then((result: AxiosResponse<Category>) => {
            return result.data;
        });
    }

    static save(item: Category) {
        if (item.id) {
            return AppService.postAuthorized(this.API_URL + "/" + item.id, item);
        } else {
            return AppService.postAuthorized(this.API_URL, item);
        }
    }

    static delete(id: number) {
        return AppService.deleteAuthorized(this.API_URL + "/" + id);
    }

    static getSaleCategories = async (): Promise<Category[] | null> => {
        return AppService.getAuthorized(this.API_URL + "/sale").then((result: AxiosResponse<Category[]>) => {
            return result.data;
        }).catch(() => {
            return null;
        })
    }

    static createCategory() {
        const category: Category = {
            descricao: '',
            group: CategoryGroupService.createCategoryGroup(),
            geraContaAPagar: false,
            lente: false,
            armacao: false,
            venda: false,
            estoqueMinimo: '',
            materialRequired: false,
            sizeRequired: false,
            frameSizeRequired: false,
            lensColorRequired: false,
            ncm: ''
        }
        return category;
    }
}

export {CategoryService};