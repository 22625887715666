import React, {useState} from "react";
import {Checkbox, TableCell} from "@mui/material";
import {CampaignPerson} from "../campaign-person";
import {CampaignPersonService} from "../campaign-person.service";
import {setAlert} from "../../../redux/actions";
import {useDispatch} from "react-redux";

const classes = {
    rowDanger: {
        backgroundColor: '#f2dedeb8'
    },
    rowAlert: {
        backgroundColor: '#fde7d7'
    },
};

interface ReturnedCellProps {
    campaignPerson: CampaignPerson;
    disabled?: boolean;
}

const ReturnedCell = ({campaignPerson, disabled}: ReturnedCellProps) => {

    const dispatch = useDispatch();
    const [item, setItem] = useState<CampaignPerson>(campaignPerson);

    const rowClassReturned = () => {
        return item.returned ? classes.rowAlert : classes.rowDanger;
    }

    const onChangeReturned = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newReturned = event.target.checked;
        CampaignPersonService.updateReturned(item.id, newReturned).then(() => {
            setItem({
                ...campaignPerson,
                returned: newReturned
            })
            dispatch(setAlert({show: true, message: 'Registro com sucesso', severity: 'success'}));
        }).catch((error) => {
            dispatch(setAlert({
                show: true,
                message: 'Erro ao salvar registro - ' + error.data.message,
                severity: 'error'
            }));
        })
    }

    return (
        <TableCell sx={rowClassReturned} align={"center"}>
            <Checkbox checked={item.returned} onChange={(event) => onChangeReturned(event)} disabled={disabled}/>
        </TableCell>
    )
}

export default React.memo(ReturnedCell);