import {AxiosRequestConfig, ResponseType} from "axios";
import {axiosInstance} from "../axios/axios.configuration";
import {AuthService} from "../auth/auth.service";
import {AuthenticationData} from "../auth/authentication-data";

class AppService {

    static FROM = "/";

    static post = (url: string, data: any, config?: AxiosRequestConfig) => {
        return axiosInstance
            .post(url, data, config);
    };

    static getAuthorized = (url: string, params?: any, responseType?: ResponseType) => {
        const headers = {
            Authorization: 'Bearer ' + AuthService.getToken()
        };
        if (responseType) {
            return axiosInstance
                .get(url, {headers: headers, params: params, responseType: responseType});
        } else {
            return axiosInstance
                .get(url, {headers: headers, params: params});
        }

    }

    static deleteAuthorized = (url: string) => {
        const headers = {
            Authorization: 'Bearer ' + AuthService.getToken()
        };
        return axiosInstance
            .delete(url, {headers: headers});
    }

    static postAuthorized = (url: string, data?: any, params?: any, responseType?: ResponseType) => {
        const headers = {
            Authorization: 'Bearer ' + AuthService.getToken()
        };
        if (responseType) {
            return axiosInstance
                .post(url, data, {headers: headers, params: params, responseType: responseType});
        } else {
            return axiosInstance
                .post(url, data, {headers: headers, params: params});
        }
    }

    static postMultipartAuthorized = (url: string, data?: any, params?: any, responseType?: ResponseType) => {
        const headers = {
            'Authorization': 'Bearer ' + AuthService.getToken(),
            'Content-Type': 'multipart/form-data'
        };
        if (responseType) {
            return axiosInstance
                .post(url, data, {headers: headers, params: params, responseType: responseType});
        } else {
            return axiosInstance
                .post(url, data, {headers: headers, params: params});
        }
    }

    static saveAuthenticationData = (authenticationData: AuthenticationData) => {
        AuthService.saveAuthenticationData(authenticationData);
    }

    static logout = () => {
        AuthService.clearStorage();
    }

    static setFrom = (from: string) => {
        this.FROM = from;
    }

    static getFrom = () => {
        return this.FROM;
    }

}

export {AppService};
