import {AppService} from "../app/app.service";

interface FinancialVisionParam {
    comission: {
        year: any;
        month: any;
    };
    productWithoutValue: {
        category: {
            id: number;
            descricao: string;
        };
    };
}

class DashboardService {

    static API_URL = "/api/dashboard";

    static getFinancialVision(params: FinancialVisionParam) {
        const request = {
            comission: {
                year: params.comission.year,
                month: params.comission.month,
            },
            productWithoutValue: {
                category: params.productWithoutValue.category ? params.productWithoutValue.category.id : ''
            }
        }
        return AppService.postAuthorized(this.API_URL + '/financialVision', request).then((result) => {
            return result.data;
        }).catch(() => {
            return null;
        })
    }

}

export {DashboardService};