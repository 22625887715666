import React, {useCallback, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {changePageTitle, setAlert} from "../redux/actions";
import {BrandTax} from "./brand-tax";
import {BrandService} from "../../brand/brand.service";
import Datatable from "../datatable/datatable";
import {Delete as DeleteIcon} from "@mui/icons-material";
import {ColorButtonEnum} from "../util/color-button-enum";
import {ColorIconEnum} from "../util/color-icon-enum";

const BrandTaxList = React.memo(() => {

    const dispatch = useDispatch();
    let navigate = useNavigate();
    const {brand} = useParams();
    if (!brand) {
        throw new Error('Brand required');
    }
    const [taxes, setTaxes] = useState<BrandTax[]>([]);

    const findAll = useCallback(async () => {
        const result = await BrandService.getAllTaxesByBrand(parseInt(brand));
        setTaxes(result);
    }, [brand]);

    useEffect(() => {
        dispatch(changePageTitle("Taxas da bandeira " + brand));
        findAll().then();
    }, [brand]); // eslint-disable-line react-hooks/exhaustive-deps

    const goBack = () => {
        navigate('/brands');
    }

    const insertItem = () => {
        navigate('/brand/' + brand + '/tax');
    }

    const deleteItem = (item: BrandTax) => {
        if (!brand) {
            throw new Error('Brand required');
        }
        if (!item.id) {
            throw new Error('Brand required');
        }
        BrandService.deleteTax(Number(brand), item.id).then(() => {
            findAll().then();
        }).catch((error) => {
            dispatch(setAlert({show: true, message: 'Erro ao excluir registro - ' + error.message, severity: 'error'}));
        })
    }

    const headerOperations = [
        {color: () => ColorButtonEnum.INHERIT, onClick: goBack, label: () => 'Voltar'},
        {color: () => ColorButtonEnum.PRIMARY, onClick: insertItem, label: () => 'Inserir'}
    ]

    const rowOperations = [
        {color: () => ColorIconEnum.PRIMARY, onClick: deleteItem, label: () => 'Excluir', icon: () => <DeleteIcon/>}
    ]

    return (
        <Datatable dataColumnNames={['Número de parcelas', 'Taxa (%)']} dataColumns={['parcelNumber', 'tax']}
                   dataTypes={['number', 'decimal']} findAll={findAll} data={taxes}
                   totalData={taxes.length} headerOperations={headerOperations} rowOperations={rowOperations}/>
    );

});

export default BrandTaxList;