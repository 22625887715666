import React from "react";
import {Bar, BarChart, CartesianGrid, Cell, Label, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import {GraphService} from "../../sale-overview/graph.service";
import {CustomChartProps} from "../util/custom-chart-props";

const CustomBarChart = ({data, formatter, dataKey, name, dataKeyXAxis, labelX, labelY}: CustomChartProps) => {
    const COLORS = GraphService.getColors();
    return (
        <ResponsiveContainer width={"100%"} height={300}>
            <BarChart data={data} margin={{top: 10, right: 10, left: 20, bottom: 30}}>
                <CartesianGrid/>
                <XAxis dataKey={dataKeyXAxis}>
                    <Label value={labelX} position="bottom"/>
                </XAxis>
                <YAxis>
                    <Label value={labelY} position="left" angle={-90}/>
                </YAxis>
                <Tooltip formatter={formatter}/>
                <Bar name={name} dataKey={dataKey}>
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>
                    ))}
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    );
}

export default React.memo(CustomBarChart);