import React, {useState} from 'react';
import CustomTextField from "../../../custom-text-field/custom-text-field";

interface SaleListHeaderFilterSaleIdProps {
    saleId: number | undefined;
}

const SaleListHeaderFilterSaleId = React.memo(React.forwardRef<HTMLInputElement, SaleListHeaderFilterSaleIdProps>(({saleId}, ref) => {

    const [internalSaleId, setInternalSaleId] = useState<number | undefined>(saleId);

    const onChangeSaleId = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newSaleId = event.target.value ? Number(event.target.value) : undefined;
        setInternalSaleId(newSaleId);
    }

    return (
        <CustomTextField fullWidth autoFocus required label="ID da OS" type="number" value={internalSaleId || ''}
                         onChange={onChangeSaleId} ref={ref}/>
    )

}));

export default SaleListHeaderFilterSaleId;
