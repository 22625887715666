import React from "react";
import {Collapse, List, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import {ExpandLess, ExpandMore, List as ListIcon} from "@mui/icons-material";
import theme from "../typescript/theme/theme";
import {Submenu} from "./submenu";

interface MenuProps {
    handleClick: () => void;
    open: boolean;
    submenus: Submenu[];
    title: string;
}

const Menu = ({handleClick, open, submenus, title}: MenuProps) => {
    return (
        <React.Fragment>
            <ListItemButton onClick={handleClick}>
                <ListItemIcon><ListIcon/></ListItemIcon>
                <ListItemText primary={title}/>
                {open ? <ExpandLess/> : <ExpandMore/>}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List disablePadding>
                    {submenus.map((submenu, index) => (
                        <ListItemButton key={index} component="a" href={submenu.href} onClick={submenu.onClick}>
                            <ListItemText primary={submenu.title} sx={theme.menu.submenu}/>
                        </ListItemButton>
                    ))}
                </List>
            </Collapse>
        </React.Fragment>
    )

}

export default React.memo(Menu);