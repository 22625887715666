import {DateUtil} from "./date-util";
import {NumberUtil} from "./number-util";
import {AxiosError} from "axios";

const capitalizeFirst = (text: string) => {
    return text.charAt(0).toUpperCase() + text.slice(1)
}

const contentFormatted = (content: string, type: string) => {
    switch (type) {
        case 'date':
            return content ? DateUtil.javaSqlDateToDateToLocaleDateString(content) : null;
        case 'datetime':
            if (content) {
                const date = DateUtil.javaLocalDateTimeToDate(content);
                if (date) {
                    return date.toLocaleString();
                }
            }
            break;
        case 'zonedatetime':
            return content ? new Date(content).toLocaleString() : null;
        case 'boolean':
            return content ? 'Sim' : 'Não';
        case 'dayOfWeek':
            return content ? DateUtil.toLocalDayOfWeek(content) : null;
        case 'currency':
            return content ? NumberUtil.currencyFormat(parseFloat(content)) : null;
        case 'decimal':
            return content ? NumberUtil.decimalFormat(parseFloat(content)) : null;
        case 'number':
            return (content === null || content === undefined) ? null : content.toString();
        case 'percentual':
            return content ? NumberUtil.percentualFormat(parseFloat(content)) : null;
        default:
            return content ? content.toString() : null;
    }
}

const decodeUint8Array = (value: AxiosError<number>) => {
    if (value && value.response && value.response.data) {
        const enc = new TextDecoder("utf-8");
        const arr = new Uint8Array(value.response.data);
        return JSON.parse(enc.decode(arr));
    }
};

const beforeDot = (string: string): string => {
    const indexOfDot = string.indexOf('.');
    if (indexOfDot === -1) {
        return ""
    }
    return string.slice(0, string.indexOf('.'));
}

const afterDot = (string: string) => {
    return string.slice(string.indexOf('.') + 1);
}

const getTableCellContent = (externalItem: any, internalItem: string): string => {
    const initialPart = beforeDot(internalItem);
    const finalPart = afterDot(internalItem);
    if (!initialPart) {
        return externalItem[finalPart];
    }
    if (finalPart && externalItem[initialPart]) {
        return getTableCellContent(externalItem[initialPart], finalPart);
    }
    return "";
}

export {capitalizeFirst, decodeUint8Array, afterDot, beforeDot, getTableCellContent, contentFormatted}