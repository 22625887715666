import React from "react";
import {Chip, Grid} from "@mui/material";
import {MonetizationOn} from "@mui/icons-material";
import {NumberUtil} from "../../../util/number-util";
import theme from "../../../theme/theme";

interface SaleListBodyTotalProps {
    valorTotal: number;
    valorTotalFechada: number;
    quantidadeTotalAberta: number;
}

const SaleListBodyTotal = React.memo(({valorTotal, valorTotalFechada, quantidadeTotalAberta}: SaleListBodyTotalProps) => {

    return (
        <Grid container spacing={1} justifyContent={"center"}>
            <Chip color={"neutral"} icon={<MonetizationOn/>} sx={theme.chips}
                  label={'Total de vendas: ' + NumberUtil.currencyFormat(valorTotal)}/>
            <Chip color={"neutral"} icon={<MonetizationOn/>} sx={theme.chips}
                  label={'Total de vendas fechadas: ' + NumberUtil.currencyFormat(valorTotalFechada)}/>
            <Chip color={"neutral"} icon={<MonetizationOn/>} sx={theme.chips}
                  label={'Quantidade total de vendas em aberto: ' + quantidadeTotalAberta}/>
        </Grid>
    )

});

export default SaleListBodyTotal;
