import React from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {PersonDetail} from "./person-detail";

const PersonDetailPage = React.memo(() => {

    const {id, tab} = useParams();
    let navigate = useNavigate();

    const onClose = () => {
        navigate('/persons');
    }

    return (
        <PersonDetail id={id ? parseInt(id) : undefined} tab={tab ? parseInt(tab) : undefined} onClose={onClose}/>
    )

});

export default PersonDetailPage;
