import React from 'react';
import {useNavigate, useParams} from "react-router-dom";
import BrandTaxDetail from "./brand-tax-detail";

const BrandTaxDetailPage = React.memo(() => {

    const {brand, tax} = useParams();
    let navigate = useNavigate();
    if (!brand) {
        throw new Error('Brand required');
    }

    const onClose = () => {
        navigate('/brand/' + brand + '/taxes');
    }

    return (
        <BrandTaxDetail brand={Number(brand)} tax={tax ? parseInt(tax) : null} onClose={onClose}/>
    )

});

export default BrandTaxDetailPage;
