import {AppService} from "../typescript/app/app.service";

class NfeService {

    static API_URL = "/api/nfes/v2";

    static geraNFe(saleId: number, modeloFiscalId: number, cpfCnpj: string, nfeReferenciada: string) {
        return AppService.getAuthorized(this.API_URL, {
            sale: saleId ? saleId : null,
            fiscalModel: modeloFiscalId ? modeloFiscalId : null,
            cpfCnpj: cpfCnpj ? cpfCnpj : null,
            nfeReferenciada: nfeReferenciada ? nfeReferenciada : null
        }, "arraybuffer");
    }

    static geraNFeDeCartao(saleId: number) {
        return AppService.getAuthorized(this.API_URL + "/cartao", {sale: saleId}, "arraybuffer");
    }

    static imprimeDanfe(nota: number) {
        return AppService.getAuthorized(this.API_URL + "/" + nota + "/danfe", null, "arraybuffer");
    }

    static cancelaNFe(nota: number, justificativa: string) {
        return AppService.postAuthorized(this.API_URL + "/" + nota + "/cancel", {
            justificativa: justificativa ? justificativa : null
        });
    }

    static atualizaCadeiaCertificadora() {
        return AppService.getAuthorized(this.API_URL + "/atualizaCadeiaCertificadora");
    }

    static uploadCertificate(certificate: File) {
        let bodyFormData = new FormData();
        bodyFormData.append('file', certificate);
        return AppService.postMultipartAuthorized(this.API_URL + "/certificate", bodyFormData);
    }

    static getDownloadCertificate() {
        return AppService.getAuthorized(this.API_URL + "/certificate", null, "arraybuffer");
    }
}

export {NfeService};