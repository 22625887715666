import React, {useCallback, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {changePageTitle} from "../typescript/redux/actions";
import {Grid} from "@mui/material";
import {ForeignKey} from "../index";
import {ComissionService} from "./comission.service";
import CustomTextField from "../typescript/custom-text-field/custom-text-field";
import CustomButton from "../typescript/custom-button/custom-button";
import CustomButtonGroup from "../typescript/custom-button-group/custom-button-group";
import {UserService} from "../user/user.service";

export function ComissionDetail({id, onClose, dialog}) {

    const [item, setItem] = useState(ComissionService.createComission());
    const dispatch = useDispatch();

    const findOne = useCallback(async () => {
        const result = await ComissionService.findOne(id);
        setItem(result);
    }, [id]);

    useEffect(() => {
        if (!dialog) {
            dispatch(changePageTitle(id ? "Comissão mensal " + id : "Nova comissão mensal"));
        }
        if (id) {
            findOne().then();
        }
    }, [dispatch, findOne, id, dialog]);

    const save = (event) => {
        event.preventDefault();
        event.stopPropagation();
        ComissionService.save(item).then((result) => {
            onClose(result.data);
        }).catch(() => {

        })
    }

    const cancel = () => {
        onClose();
    }

    const setMinimumGoalPercentual = (event) => {
        setItem({
            ...item,
            minimumGoalPercentual: event.target.value
        })
    }

    const setBasicGoalPercentual = (event) => {
        setItem({
            ...item,
            basicGoalPercentual: event.target.value
        })
    }

    const setMasterGoalPercentual = (event) => {
        setItem({
            ...item,
            masterGoalPercentual: event.target.value
        })
    }

    const onChangeUsuario = async (id) => {
        setItem({
            ...item,
            user: {
                id: id
            }
        })
        if (id) {
            const user = await UserService.findOne(id);
            if (user) {
                setItem({
                    ...item,
                    user: user
                })
            }
        }
    }

    const onAddUsuario = (user) => {
        if (user) {
            setItem({
                ...item,
                user: user
            })
        }
    }

    const findUsuarios = (page, size, sort, filter) => {
        return UserService.findAll(page, size, sort, filter);
    }

    return (
        <form onSubmit={save}>
            <ForeignKey fullWidth autoFocus required label="Usuário" value={item.user} fieldKey={'id'}
                        fieldDescription={'username'} labelDescription='Usuário' onChange={onChangeUsuario}
                        onSelect={onAddUsuario} findAll={findUsuarios}
                        dataColumnNames={['ID', 'Usuário']} dataColumns={['id', 'username']}
                        dataTypes={['number', 'text']}/>
            <Grid container spacing={1}>
                <Grid item md xs={12}>
                    <CustomTextField type="number" fullWidth required label="% Meta mínima"
                                     value={item.minimumGoalPercentual | ''}
                                     onChange={setMinimumGoalPercentual}/>
                </Grid>
                <Grid item md xs={12}>
                    <CustomTextField type="number" fullWidth required label="% Meta básica"
                                     value={item.basicGoalPercentual | ''}
                                     onChange={setBasicGoalPercentual}/>
                </Grid>
                <Grid item md xs={12}>
                    <CustomTextField type="number" fullWidth required label="% Meta master"
                                     value={item.masterGoalPercentual | ''}
                                     onChange={setMasterGoalPercentual}/>
                </Grid>
            </Grid>
            <CustomButtonGroup>
                <CustomButton color="primary" type="submit">Salvar</CustomButton>
                <CustomButton type="button" color="neutral" onClick={cancel}>Cancelar</CustomButton>
            </CustomButtonGroup>
        </form>
    )
}