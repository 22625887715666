import {ResponseFile} from "./response-file";

export class FileUtil {

    static fileToBase64 = (file: Blob) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    static getFileNameFromHttpResponse = (httpResponse: ResponseFile) => {
        const contentDispositionHeader = httpResponse.headers['content-disposition'];
        const result = contentDispositionHeader.split(';')[1].trim().split('=')[1];
        return result.replace(/"/g, '');
    }

    static getContentTypeFromHttpResponse = (httpResponse: ResponseFile) => {
        return httpResponse.headers['content-type'];
    }

    static saveAs = (httpResponse: ResponseFile) => {
        const fileName = this.getFileNameFromHttpResponse(httpResponse);
        const contentType = this.getContentTypeFromHttpResponse(httpResponse);

        const blob = new Blob([httpResponse.data], {type: contentType});
        const url = window.URL.createObjectURL(blob);
        const doc = document.createElement("a");

        doc.href = url;
        doc.download = fileName;
        doc.click();
        window.URL.revokeObjectURL(url);
    };

    static openPdfInNewTab = (httpResponse: ResponseFile) => {
        const popup = window.open('', '_blank');
        if (popup && popup.document) {
            popup.document.write('Loading...');
            const file = new Blob([httpResponse.data], {type: 'application/pdf'});
            popup.location.href = window.URL.createObjectURL(file);
        }
    };

}