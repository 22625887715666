import {Dialog, DialogContent, DialogTitle} from "@mui/material";
import React, {useEffect, useState} from "react";
import Datatable from "../datatable/datatable";
import {PageResponse} from "../util/page-response";
import {PageParams} from "../util/page-params";

interface SearchDialogProps {
    initialSort?: string;
    findAll: (number?: number, size?: number, sort?: string, filter?: string) => Promise<PageResponse<any>>;
    onSelectData: (item: any, index: number) => void;
    onClose: () => void;
    open: boolean;
    label: string;
    dataColumnNames: string[];
    dataColumns: string[];
    dataTypes: string[];
}

const SearchDialog = React.memo(({
                                     initialSort,
                                     findAll,
                                     onSelectData,
                                     onClose,
                                     open,
                                     label,
                                     dataColumnNames,
                                     dataColumns,
                                     dataTypes
                                 }: SearchDialogProps) => {
    const [pageParams, setPageParams] = useState<PageParams>({
        number: 0,
        size: parseInt(process.env.REACT_APP_PAGE_SIZE || "0"),
        sort: initialSort ? initialSort : "id,asc",
        filter: '',
        items: [],
        totalElements: 0,
    });

    useEffect(() => {
        findAllInternal(pageParams.number, pageParams.size, pageParams.sort, pageParams.filter).then();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const findAllInternal = async (number?: number, size?: number, sort?: string, filter?: string) => {
        const result = await findAll(number, size, sort, filter);
        setPageParams({
            number: number,
            size: size,
            sort: sort,
            filter: filter,
            items: result.content,
            totalElements: result.totalElements
        })
    };

    const changePageParams = (number?: number, size?: number, sort?: string, filter?: string) => {
        findAllInternal(number, size, sort, filter).then();
    }

    return (
        <Dialog maxWidth={"lg"} onClose={onClose} open={open} fullWidth>
            <DialogTitle>{label} - Selecione</DialogTitle>
            <DialogContent>
                <Datatable dataColumnNames={dataColumnNames} dataColumns={dataColumns}
                           page={pageParams.number} size={pageParams.size} sort={pageParams.sort}
                           filter={pageParams.filter} dataTypes={dataTypes} findAll={changePageParams}
                           editData={onSelectData} data={pageParams.items} totalData={pageParams.totalElements}/>
            </DialogContent>
        </Dialog>
    )
});

export default SearchDialog;