import React, {FormEvent, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Radio, RadioGroup} from "@mui/material";
import CustomTextField from "../typescript/custom-text-field/custom-text-field";
import CustomButton from "../typescript/custom-button/custom-button";
import CustomButtonGroup from "../typescript/custom-button-group/custom-button-group";
import CustomRow from "../typescript/custom-row/custom-row";
import {changePageTitle, setAlert} from "../typescript/redux/actions";
import {FileUtil} from "../typescript/util/file-util";
import * as StringUtil from "../typescript/util/string-util";
import {RelatorioService} from "../typescript/relatorio/relatorio.service";
import {DateUtil} from "../typescript/util/2.0.0/date-util";

const DreReport = () => {
    const curr = new Date();
    const [startDate, setStartDate] = useState(DateUtil.toJavaSqlDate(new Date(curr.getFullYear() - 1, curr.getMonth(), 1)));
    const [endDate, setEndDate] = useState(DateUtil.toJavaSqlDate(new Date(curr.getFullYear(), curr.getMonth(), 0)));
    const [xlsx, setXlsx] = useState(true);
    const [categoria, setCategoria] = useState(true);
    const [fornecedor, setFornecedor] = useState(true);
    const [produto, setProduto] = useState(true);
    const [recebidos, setRecebidos] = useState(true);
    const [pagos, setPagos] = useState(true);
    const [receitas, setReceitas] = useState(true);
    const [despesas, setDespesas] = useState(true);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(changePageTitle("Relatórios para DRE"));
    }, [dispatch]);

    const print = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        if (categoria) {
            RelatorioService.getDreCategoria(xlsx ? 'XLSX' : 'PDF').then(function (result) {
                FileUtil.saveAs(result);
            }, (result) => {
                const error = StringUtil.decodeUint8Array(result);
                dispatch(setAlert({show: true, message: error.message, severity: 'error'}));
            })
        }

        if (fornecedor) {
            RelatorioService.getDreFornecedor(xlsx ? 'XLSX' : 'PDF').then(function (result) {
                FileUtil.saveAs(result);
            }, (result) => {
                const error = StringUtil.decodeUint8Array(result);
                dispatch(setAlert({show: true, message: error.message, severity: 'error'}));
            })
        }

        if (produto) {
            RelatorioService.getDreProduto(xlsx ? 'XLSX' : 'PDF').then(function (result) {
                FileUtil.saveAs(result);
            }, (result) => {
                const error = StringUtil.decodeUint8Array(result);
                dispatch(setAlert({show: true, message: error.message, severity: 'error'}));
            })
        }

        if (recebidos) {
            RelatorioService.getDreRecebidos(xlsx ? 'XLSX' : 'PDF', startDate, endDate).then(function (result) {
                FileUtil.saveAs(result);
            }, (result) => {
                const error = StringUtil.decodeUint8Array(result);
                dispatch(setAlert({show: true, message: error.message, severity: 'error'}));
            })
        }

        if (pagos) {
            RelatorioService.getDrePagos(xlsx ? 'XLSX' : 'PDF', startDate, endDate).then(function (result) {
                FileUtil.saveAs(result);
            }, (result) => {
                const error = StringUtil.decodeUint8Array(result);
                dispatch(setAlert({show: true, message: error.message, severity: 'error'}));
            })
        }

        if (receitas) {
            RelatorioService.getDreReceitas(xlsx ? 'XLSX' : 'PDF', startDate, endDate).then(function (result) {
                FileUtil.saveAs(result);
            }, (result) => {
                const error = StringUtil.decodeUint8Array(result);
                dispatch(setAlert({show: true, message: error.message, severity: 'error'}));
            })
        }

        if (despesas) {
            RelatorioService.getDreDespesas(xlsx ? 'XLSX' : 'PDF', startDate, endDate).then(function (result) {
                FileUtil.saveAs(result);
            }, (result) => {
                const error = StringUtil.decodeUint8Array(result);
                dispatch(setAlert({show: true, message: error.message, severity: 'error'}));
            })
        }

    }

    const onChangeXlsx = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setXlsx(value === 'true');
    }

    const onChangeCategoria = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCategoria(event.target.checked);
    }

    const onChangeDespesas = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDespesas(event.target.checked);
    }

    const onChangeReceitas = (event: React.ChangeEvent<HTMLInputElement>) => {
        setReceitas(event.target.checked);
    }

    const onChangePagos = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPagos(event.target.checked);
    }

    const onChangeRecebidos = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRecebidos(event.target.checked);
    }

    const onChangeFornecedor = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFornecedor(event.target.checked);
    }

    const onChangeProduto = (event: React.ChangeEvent<HTMLInputElement>) => {
        setProduto(event.target.checked);
    }

    const onChangeStartDate = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStartDate(event.target.value)
    }

    const onChangeEndDate = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEndDate(event.target.value)
    }

    return (
        <form onSubmit={print}>
            <CustomRow>
                <CustomTextField fullWidth required label="Data inicial" type="date" value={startDate || ''}
                                 InputLabelProps={{shrink: true}} onChange={onChangeStartDate}/>
                <CustomTextField fullWidth required label="Data final" type="date" value={endDate || ''}
                                 InputLabelProps={{shrink: true}} onChange={onChangeEndDate}/>
                <FormControl component="fieldset" required>
                    <FormLabel component="legend">Tipo</FormLabel>
                    <RadioGroup value={xlsx} onChange={onChangeXlsx}>
                        <FormControlLabel value={true} control={<Radio/>} label="XLSX"/>
                        <FormControlLabel value={false} control={<Radio/>} label="PDF"/>
                    </RadioGroup>
                </FormControl>
            </CustomRow>

            <CustomRow>
                <FormControl component="fieldset">
                    <FormLabel component="legend">Relatórios</FormLabel>
                    <FormGroup row>
                        <FormControlLabel control={<Checkbox checked={categoria} onChange={onChangeCategoria}/>}
                                          label="Categoria"/>
                        <FormControlLabel control={<Checkbox checked={fornecedor} onChange={onChangeFornecedor}/>}
                                          label="Fornecedor"/>
                        <FormControlLabel control={<Checkbox checked={produto} onChange={onChangeProduto}/>}
                                          label="Produto"/>
                        <FormControlLabel control={<Checkbox checked={recebidos} onChange={onChangeRecebidos}/>}
                                          label="Recebidos"/>
                        <FormControlLabel control={<Checkbox checked={pagos} onChange={onChangePagos}/>} label="Pagos"/>
                        <FormControlLabel control={<Checkbox checked={receitas} onChange={onChangeReceitas}/>}
                                          label="Receitas"/>
                        <FormControlLabel control={<Checkbox checked={despesas} onChange={onChangeDespesas}/>}
                                          label="Despesas"/>
                    </FormGroup>
                </FormControl>
            </CustomRow>

            <CustomButtonGroup>
                <CustomButton color="primary" type="submit">Imprimir</CustomButton>
            </CustomButtonGroup>
        </form>
    );
}

export default React.memo(DreReport);
