import React from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {ConsultaDetail} from "./consulta-detail";

const ConsultaDetailPage = React.memo(() => {

    const {id} = useParams();
    let navigate = useNavigate();

    const onClose = () => {
        navigate('/consultas');
    }

    return (
        <ConsultaDetail id={id} onClose={onClose}/>
    )

});

export default ConsultaDetailPage;