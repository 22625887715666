import React from "react";
import {TableCell, TableHead, TableRow} from "@mui/material";
import DatatableTableHeaderSort from "./datatable-table-header-sort";

const classes = {
    datatableTableHeader: {
        "& th": {
            fontWeight: 'bold'
        }
    }
};

const DatatableTableHeader = React.memo(({dataColumnNames, sortIndex, sortDirection, setSortBy, hasRowOperations, sortColumns}) => {

    const getActive = (index) => {
        return (sortIndex === index);
    }

    const inSortColumns = (item) => {
        if (sortColumns) {
            return sortColumns.includes(item);
        } else {
            return true;
        }
    }

    return (
        <TableHead sx={classes.datatableTableHeader}>
            <TableRow>
                {dataColumnNames.map((item, index) => (
                    (sortIndex === -1 || !inSortColumns(item)) ?
                        <TableCell key={index}>{item}</TableCell>
                        :
                        <DatatableTableHeaderSort key={index} item={item} active={getActive(index)}
                                                  direction={sortDirection} onClick={() => setSortBy(index)}/>
                ))}
                {hasRowOperations ? <TableCell/> : null}
            </TableRow>
        </TableHead>
    )

})

export default DatatableTableHeader;