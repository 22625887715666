import {AppService} from "../typescript/app/app.service";
import {Instagram} from "./instagram";

class InstagramService {

    static API_URL = "/api/instagrams";

    static findAll = (startDate: string, endDate: string) => {
        return AppService.getAuthorized(this.API_URL, {
            startDate: startDate,
            endDate: endDate
        }).then((result) => {
            return result.data;
        }).catch(() => {
            return []
        })
    };

    static findOne(id: number) {
        return AppService.getAuthorized(this.API_URL + "/" + id).then((result) => {
            return result.data;
        }).catch(() => {
            return null;
        })
    }

    static save(item: Instagram) {
        if (item.id) {
            return AppService.postAuthorized(this.API_URL + "/" + item.id, item);
        } else {
            return AppService.postAuthorized(this.API_URL, item);
        }
    }

    static delete(id: number) {
        return AppService.deleteAuthorized(this.API_URL + "/" + id);
    }

    static createInstagram() {
        const instagram: Instagram = {
            date: '',
            stories: '',
            reels: '',
            feed: '',
            status: '',
            sponsored: '',
            investment: ''
        }
        return instagram;
    }
}

export {InstagramService};