import React from "react";
import {TableCell, Theme} from "@mui/material";
import {SxProps} from "@mui/system";
import {contentFormatted, getTableCellContent} from "../util/string-util";

interface DatatableTableBodyRowContentProps {
    item: string;
    dataColumn: string;
    type: string;
    editData: () => void;
    cursorClass: SxProps<Theme>;
}

const DatatableTableBodyRowContent = React.memo(({
                                                     item,
                                                     dataColumn,
                                                     type,
                                                     editData,
                                                     cursorClass
                                                 }: DatatableTableBodyRowContentProps) => {

    const contentFormattedInternal = () => {
        const content = getTableCellContent(item, dataColumn);
        return contentFormatted(content, type);
    }

    return (
        <TableCell sx={cursorClass} onClick={editData}>
            {contentFormattedInternal()}
        </TableCell>
    )

})

export default DatatableTableBodyRowContent;