import {AppService} from "../typescript/app/app.service";
import {PageResponse} from "../typescript/util/page-response";
import {DecideCompra} from "./decide-compra";

class DecidecompraService {

    static API_URL = "/api/decidecompras/v2";

    static findAll = (page?: number, size?: number, sort?: string, filter?: string): Promise<PageResponse<DecideCompra>> => {
        return AppService.getAuthorized(this.API_URL, {
            page: page,
            size: size,
            sort: sort,
            id: sort?.startsWith('id') ? filter : '',
            descricao: sort?.startsWith('descricao') ? filter : ''
        }).then((result) => {
            return result.data;
        }).catch(() => {
            return {
                content: [],
                totalElements: 0
            };
        })
    };

    static findOne(id: number) {
        return AppService.getAuthorized(this.API_URL + "/" + id).then((result) => {
            return result.data;
        }).catch(() => {
            return null;
        })
    }

    static save(item: DecideCompra) {
        if (item.id) {
            return AppService.postAuthorized(this.API_URL + "/" + item.id, item);
        } else {
            return AppService.postAuthorized(this.API_URL, item);
        }
    }

    static delete(id: number) {
        return AppService.deleteAuthorized(this.API_URL + "/" + id);
    }

    static createDecidecompra() {
        const decideCompra: DecideCompra = {
            descricao: ''
        }
        return decideCompra;
    }
}

export {DecidecompraService};