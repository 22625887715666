import React from "react";
import SaleListBodyTotal from "./sale-list-body-total";
import SaleListBodyTable from "./table/sale-list-body-table";
import SaleListBodyStatus2 from "./sale-list-body-status2";
import {SaleListResponse} from "../../../../sale/sale-list-response";

interface SaleListBodyProps {
    sales: SaleListResponse | null;
    findAll: () => void;
}

const areEqual = (prevProps: SaleListBodyProps, nextProps: SaleListBodyProps) => {
    return JSON.stringify(prevProps.sales) === JSON.stringify(nextProps.sales);
}

const SaleListBody = React.memo(({sales, findAll}: SaleListBodyProps) => {

    return (
        <React.Fragment>
            <SaleListBodyTotal quantidadeTotalAberta={sales?.quantidadeTotalAberta || 0}
                               valorTotal={sales?.valorTotal || 0} valorTotalFechada={sales?.valorTotalFechada || 0}/>
            <SaleListBodyTable sales={sales?.vendas} findAll={findAll}/>
            <SaleListBodyStatus2 comprou={sales?.comprou || 0} fezOrcamento={sales?.fezOrcamento || 0}
                                 fezEmOutroLugar={sales?.fezEmOutroLugar || 0} naoPrecisou={sales?.naoPrecisou || 0}
                                 achouCaro={sales?.achouCaro || 0}/>
        </React.Fragment>
    )

}, areEqual);

export default SaleListBody;
