/**
 * @deprecated
 */
export class DateUtil {

    static toJavaSqlDate(date: Date) {
        if (date) {
            const ano = date.getFullYear();
            const mes = ("0" + (date.getMonth() + 1)).slice(-2);
            const dia = ("0" + date.getDate()).slice(-2);
            return ano + "-" + mes + '-' + dia;
        }
        return null;
    }

    static toJavaSqlTime(date: Date) {
        if (date) {
            return ("0" + date.getHours()).slice(-2) + ":" + ("0" + date.getMinutes()).slice(-2) + ':' + ("0" + date.getSeconds()).slice(-2);
        }
        return null;
    }

    static daysBetween(startDate: Date, endDate: Date) {
        return Math.round((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24));
    }

    static toJavaLocalDateTime(date: Date) {
        if (date) {
            const data = this.toJavaSqlDate(date);
            const hora = this.toJavaSqlTime(date);
            return data + 'T' + hora;
        }
        return null;
    }

    static javaSqlDateToDate(date: string) {
        if (date) {
            const dateString = date.split("-");
            return new Date(parseInt(dateString[0]), parseInt(dateString[1]) - 1, parseInt(dateString[2]));
        }
        return null;
    }

    static currentDayOfMonth() {
        return new Date().getDate();
    }

    static javaLocalDateTimeToDate(date: string) {
        if (date) {
            const parts = date.split("T");
            const dateParts = parts[0].split("-");
            const timeParts = parts[1].split(":");
            return new Date(parseInt(dateParts[0]), parseInt(dateParts[1]) - 1, parseInt(dateParts[2]), parseInt(timeParts[0]), parseInt(timeParts[1]), parseInt(timeParts[2]));
        }
        return null;
    }

    static javaSqlDateToDateToLocaleDateString(javaSqlDate: string) {
        const date = DateUtil.javaSqlDateToDate(javaSqlDate);
        if (date) {
            return date.toLocaleDateString();
        }
    }

    static toLocalDayOfWeek(dayOfWeek: string) {
        switch (dayOfWeek) {
            case 'SUNDAY':
                return 'Domingo';
            case 'MONDAY':
                return 'Segunda-feira';
            case 'TUESDAY':
                return 'Terça-feira';
            case 'WEDNESDAY':
                return 'Quarta-feira';
            case 'THURSDAY':
                return 'Quinta-feira';
            case 'FRIDAY':
                return 'Sexta-feira';
            case 'SATURDAY':
                return 'Sábado';
            default:
                return null;
        }
    }

    static firstDayOfCurrentMonth() {
        const date = new Date();
        return new Date(date.getFullYear(), date.getMonth(), 1);
    }

    static firstDayOfCurrentMonthInJavaSqlDate() {
        const date = this.firstDayOfCurrentMonth();
        return this.toJavaSqlDate(date);
    }

    static lastDayOfCurrentMonth() {
        const date = new Date();
        return new Date(date.getFullYear(), date.getMonth() + 1, 0);
    }

    static lastDayOfCurrentMonthInJavaSqlDate() {
        const date = this.lastDayOfCurrentMonth();
        return this.toJavaSqlDate(date);
    }

    static currentYear() {
        const date = new Date();
        return date.getFullYear();
    }

    static currentMonth() {
        const date = new Date();
        return date.getMonth() + 1;
    }

    static currentDate() {
        return new Date();
    }

    static currentDateAtStartOfDay() {
        return new Date(this.toJavaSqlDate(new Date()) + ' 00:00:00');
    }

    static addYear(date: Date, yearsToAdd: number) {
        date.setDate(date.getDate() + (yearsToAdd * 364));
        return date;
    }

    static startYears = () => {
        let yearsToSelect = [];
        for (let i = 2015; i < 2030; i++) {
            yearsToSelect.push({name: i, description: i})
        }
        return yearsToSelect;
    }

    static startMonths = () => {
        let monthsToSelect = [];
        for (let i = 1; i < 13; i++) {
            monthsToSelect.push({name: i, description: this.mesPorExtenso(i)})
        }
        return monthsToSelect;
    }

    static mesPorExtenso = (mes: number) => {
        switch (mes) {
            case 1:
                return 'Janeiro';
            case 2:
                return 'Fevereiro';
            case 3:
                return 'Março';
            case 4:
                return 'Abril';
            case 5:
                return 'Maio';
            case 6:
                return 'Junho';
            case 7:
                return 'Julho';
            case 8:
                return 'Agosto';
            case 9:
                return 'Setembro';
            case 10:
                return 'Outubro';
            case 11:
                return 'Novembro';
            case 12:
                return 'Dezembro';
            default:
                return null;
        }
    }

    static calculaData(diaVencimento: number, monthsToAdd: number) : Date {
        let dataParcela = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0);

        if ((typeof diaVencimento != 'undefined') && (diaVencimento != null)) {
            dataParcela.setDate(diaVencimento);
        }

        const mesTemp = dataParcela.getMonth();
        dataParcela.setMonth(dataParcela.getMonth() + 1 + monthsToAdd);
        if (dataParcela.getMonth() > mesTemp + monthsToAdd + 1) {
            dataParcela.setDate(1 - 1);
        }

        return dataParcela;
    }

    static getAllMonthsEnum() {
        return [
            {name: 1, description: 'Janeiro'},
            {name: 2, description: 'Fevereiro'},
            {name: 3, description: 'Março'},
            {name: 4, description: 'Abril'},
            {name: 5, description: 'Maio'},
            {name: 6, description: 'Junho'},
            {name: 7, description: 'Julho'},
            {name: 8, description: 'Agosto'},
            {name: 9, description: 'Setembro'},
            {name: 10, description: 'Outubro'},
            {name: 11, description: 'Novembro'},
            {name: 12, description: 'Dezembro'}
        ];
    }

    static getAllDaysEnum() {
        let days = [{name: 0, description: 'Todos'}];
        for (let i = 1; i <= 31; i++) {
            days.push({name: i, description: i.toString()});
        }
        return days;
    }

    static addDays(date: Date, days: number) {
        let tomorrow = new Date();
        tomorrow.setDate(date.getDate() + days);
        return tomorrow;
    }
}
