import {AuthenticationData} from "./authentication-data";
import {User} from "../../user/user";

export class AuthService {

    static TOKEN = 'TOKEN';
    static EXPIRES = 'EXPIRES';
    static USER_DETAILS = 'USER_DETAILS';
    static AVATAR = 'AVATAR';

    static getToken = () => {
        const token = localStorage.getItem(this.TOKEN);
        if (token) {
            return token;
        }
    };

    static getExpires = () => {
        const expires = localStorage.getItem(this.EXPIRES);
        if (expires) {
            return parseInt(expires);
        }
    };

    static saveAuthenticationData = (authenticationData: AuthenticationData) => {
        localStorage.setItem(this.TOKEN, authenticationData.token);
        localStorage.setItem(this.EXPIRES, authenticationData.expires.toString());
    };

    static clearStorage = () => {
        localStorage.clear();
    };

    static isAuthenticated = () => {
        const token = this.getToken();
        const expires = this.getExpires();
        return token && expires && expires > new Date().getTime();
    };

    static saveUserDetails(userDetails: User) {
        localStorage.setItem(this.USER_DETAILS, JSON.stringify(userDetails));
    }

    static saveAvatar(avatar: string) {
        localStorage.setItem(this.AVATAR, avatar);
    }

    static getAvatar = () => {
        const avatar = localStorage.getItem(this.AVATAR);
        if (avatar) {
            return avatar;
        }
    };

    static getUsername = () => {
        const userDetails = this.getUserDetails();
        if (userDetails) {
            return userDetails.username;
        }
    };

    static getUserDetails = (): User | null => {
        const userDetails = localStorage.getItem(this.USER_DETAILS);
        if (userDetails) {
            return JSON.parse(userDetails) as User;
        }
        return null;
    };

    static userInRole(role: string) {
        const userDetails = this.getUserDetails();
        if (userDetails) {
            return userDetails.authorities?.includes(role);
        }
    }
}