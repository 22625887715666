import React, {useEffect, useState} from "react";
import {Dialog, DialogContent, DialogTitle, Paper, Tab, Tabs} from "@mui/material";
import {NumberUtil} from "../../util/number-util";
import {Sale} from "../../../sale/sale";
import CloseSaleAVista from "./avista/close-sale-a-vista";
import {SaleService} from "../../../sale/sale.service";
import {CloseOptionsResponse} from "../../../sale/close-options-response";
import CloseSaleAPrazo from "./aprazo/close-sale-a-prazo";
import CloseSaleCartao from "./cartao/close-sale-cartao";

interface CloseSaleDialogProps {
    onClose: (success: boolean) => void;
    sale: Sale
}

const CloseSaleDialog = React.memo(({sale, onClose}: CloseSaleDialogProps) => {

    const [tabValue, setTabValue] = useState(0);
    const [options, setOptions] = useState<CloseOptionsResponse>();

    useEffect(() => {
        const fetchOptions = async () => {
            const optionsDefault = await SaleService.getCloseOptions(false);
            setOptions(optionsDefault);
        }
        fetchOptions().then();
    }, []);

    const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const renderAVista = () => {
        return options && tabValue === 0 && (
            <CloseSaleAVista sale={sale} onClose={onClose} options={options}/>
        )
    }

    const renderAPrazo = () => {
        return options && tabValue === 1 && (
            <CloseSaleAPrazo sale={sale} onClose={onClose} options={options}/>
        )
    }

    const renderCartao = () => {
        return options && tabValue === 2 && (
            <CloseSaleCartao sale={sale} onClose={onClose} options={options}/>
        )
    }

    const onCloseDialog = (event: Object, reason: string) => {
        if (reason !== 'backdropClick') {
            onClose(false);
        }
    }

    return (
        <Dialog fullWidth maxWidth={"xl"} onClose={onCloseDialog} open={true}>
            <DialogTitle>Fechar venda {sale.id} - Total da
                venda: {NumberUtil.currencyFormat(sale.valorTotal || 0)}</DialogTitle>
            <DialogContent>
                <Paper square>
                    <Tabs value={tabValue} indicatorColor="primary" textColor="primary" onChange={handleChangeTab}
                          variant={"scrollable"} scrollButtons={"auto"}>
                        <Tab label="À vista"/>
                        <Tab label="À prazo"/>
                        <Tab label="Cartão"/>
                    </Tabs>
                </Paper>
                {renderAVista()}
                {renderAPrazo()}
                {renderCartao()}
            </DialogContent>
        </Dialog>
    )

});

export default CloseSaleDialog;
