import React from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {StateDetail} from "./state-detail";

const StateDetailPage = React.memo(() => {

    const {id} = useParams();
    let navigate = useNavigate();

    const onClose = () => {
        navigate('/states');
    }

    return (
        <StateDetail id={id ? parseInt(id) : null} onClose={onClose}/>
    )

});

export default StateDetailPage;