import {PageResponse} from "../../util/page-response";
import {AppService} from "../../app/app.service";
import {CampaignType} from "./campaign-type";

class CampaignTypeService {

    static API_URL = "/api/campaigntypes";

    static findAll = (page?: number, size?: number, sort?: string, filter?: string): Promise<PageResponse<CampaignType>> => {
        return AppService.getAuthorized(this.API_URL, {
            page: page,
            size: size,
            sort: sort,
            id: sort?.startsWith('id') ? filter : '',
            description: sort?.startsWith('description') ? filter : ''
        }).then((result) => {
            return result.data;
        }).catch(() => {
            return {
                content: [],
                totalElements: 0
            };
        })
    };

    static findOne(id: number) {
        return AppService.getAuthorized(this.API_URL + "/" + id)
            .then((result) => {
                return result.data;
            }).catch(() => {
                return null;
            })
    }

    static save(item: CampaignType) {
        if (item.id) {
            return AppService.postAuthorized(this.API_URL + "/" + item.id, item);
        } else {
            return AppService.postAuthorized(this.API_URL, item);
        }
    }

    static delete(id: number) {
        return AppService.deleteAuthorized(this.API_URL + "/" + id);
    }

    static createCampaignType = () => {
        const campaignType: CampaignType = {
            description: ''
        }
        return campaignType;
    }

}

export {CampaignTypeService}