import React from "react";
import {Document} from "../../../document/document";
import {Typography} from "@mui/material";
import Datatable from "../../datatable/datatable";

interface ParcelsProps {
    documents: Document[]
}

const Parcels = React.memo(({documents}: ParcelsProps) => {

    return (
        <React.Fragment>
            <Typography gutterBottom variant="subtitle1" component="div">
                Parcelas geradas:
            </Typography>
            <Datatable dataColumnNames={['Vencimento', 'Valor bruto', 'Desconto', 'Juros', 'Valor líquido']}
                       dataColumns={['dataVencimento', 'valor', 'valorDesconto', 'valorJuros', 'valorFinal']}
                       dataTypes={['date', 'currency', 'currency', 'currency', 'currency']}
                       summarize={[null, 'valor', 'valorDesconto', 'valorJuros', 'valorFinal']}
                       data={documents} totalData={documents.length}/>
        </React.Fragment>
    )

});

export default Parcels;
