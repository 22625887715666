import {COMPONENT_CONSTRUCTED, SET_ALERT, SHOW_LOADER} from './actionTypes';
import {Alert} from "../alert/alert";

const changePageTitle = (title: string) => ({
    type: COMPONENT_CONSTRUCTED,
    title: title
});

const setAlert = (alert: Alert) => ({
    type: SET_ALERT,
    alert: alert
});

const showLoader = (showLoader: boolean) => ({
    type: SHOW_LOADER,
    showLoader: showLoader
});

export {changePageTitle, setAlert, showLoader}