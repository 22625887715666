import React from "react";
import {Button} from "@mui/material";
import {useDispatch} from "react-redux";
import CustomButtonGroup from "../typescript/custom-button-group/custom-button-group";
import {setAlert} from "../typescript/redux/actions";
import {FileUtil} from "../typescript/util/file-util";
import * as StringUtil from "../typescript/util/string-util";
import {NfeService} from "../nfe/nfe.service";

const DigitalCertificate = () => {
    const dispatch = useDispatch();

    const handleCapture = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const certificate = event.target.files[0];
            NfeService.uploadCertificate(certificate).then(() => {
                dispatch(setAlert({
                    show: true,
                    message: 'Certificado salvo com sucesso',
                    severity: 'success'
                }));
            }, (error) => {
                dispatch(setAlert({
                    show: true,
                    message: 'Erro ao salvar certificado - ' + error.data.message,
                    severity: 'error'
                }));
            })
        }
    };

    const downloadCertificate = () => {
        NfeService.getDownloadCertificate().then(function (result) {
            FileUtil.saveAs(result);
        }, (result) => {
            const error = StringUtil.decodeUint8Array(result);
            dispatch(setAlert({show: true, message: error.message, severity: 'error'}));
        })
    }

    return (
        <CustomButtonGroup>
            <Button color="primary" component="label">Upload de certificado
                <input type="file" hidden accept=".pfx" onChange={handleCapture}/>
            </Button>
            <Button onClick={downloadCertificate} variant="contained" color="primary" component="label">Baixar
                certificado</Button>
        </CustomButtonGroup>
    );
}

export default React.memo(DigitalCertificate);