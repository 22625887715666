import React, {RefObject} from 'react';
import CustomRow from "../../../custom-row/custom-row";
import SaleListHeaderFilterEmissionStartDate from "./sale-list-header-filter-emission-start-date";
import SaleListHeaderFilterEmissionEndDate from "./sale-list-header-filter-emission-end-date";

interface SaleListHeaderFilterEmissionProps {
    startDate: string | undefined;
    endDate: string | undefined;
    startDateRef: RefObject<HTMLInputElement>;
    endDateRef: RefObject<HTMLInputElement>;
}

const SaleListHeaderFilterEmission = React.memo(({
                                                     startDate,
                                                     endDate,
                                                     startDateRef,
                                                     endDateRef
                                                 }: SaleListHeaderFilterEmissionProps) => {

        return (
            <CustomRow>
                <SaleListHeaderFilterEmissionStartDate startDate={startDate} ref={startDateRef}/>
                <SaleListHeaderFilterEmissionEndDate endDate={endDate} ref={endDateRef}/>
            </CustomRow>
        )

    }
);

export default SaleListHeaderFilterEmission;
