import React from "react";
import CustomTextField from "../custom-text-field/custom-text-field";

interface NumberInputProps {
    fullWidth: boolean;
    autoFocus?: boolean;
    label: string;
    required?: boolean
    value: string;
    onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    placeholder?: string;
}

const NumberInput = React.memo(({fullWidth, autoFocus, label, required, value, onChange, placeholder}: NumberInputProps) => {
    return (
        <CustomTextField fullWidth={fullWidth} autoFocus={autoFocus} label={label} required={required} value={value}
                         onChange={onChange} type="number" placeholder={placeholder} InputProps={{inputProps: {min: 0, step: "any"}}}/>
    )
});

export default NumberInput;