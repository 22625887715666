import React, {FormEvent, useCallback, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {changePageTitle, setAlert} from "../redux/actions";
import {CityService} from "./city.service";
import CustomTextField from "../custom-text-field/custom-text-field";
import CustomButton from "../custom-button/custom-button";
import CustomButtonGroup from "../custom-button-group/custom-button-group";
import ForeignKey from "../foreign-key/foreign-key";
import {City} from "./city";
import {StateService} from "../state/state.service";
import {State} from "../state/state";
import {StateDetail} from "../state/state-detail";

interface CityDetailProps {
    id?: number | null;
    dialog?: boolean;
    onClose?: (city?: City) => void;
}

export function CityDetail({id, dialog, onClose}: CityDetailProps) {

    const [item, setItem] = useState(CityService.createCity());
    const dispatch = useDispatch();

    const findOne = useCallback(async (id: number) => {
        const result = await CityService.findOne(id);
        setItem(result);
    }, []);

    useEffect(() => {
        if (!dialog) {
            dispatch(changePageTitle(id ? "Cidade " + id : "Nova cidade"));
        }
        if (id) {
            findOne(id).then();
        }
    }, [id, dialog]); // eslint-disable-line react-hooks/exhaustive-deps

    const save = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        CityService.save(item).then((result) => {
            if (onClose) {
                onClose(result.data);
            }
        }).catch((error) => {
            dispatch(setAlert({
                show: true,
                message: 'Erro ao salvar registro - ' + error.data.message,
                severity: 'error'
            }));
        })
    }

    const cancel = () => {
        if (onClose) {
            onClose();
        }
    }

    const setDescricao = (event: React.ChangeEvent<HTMLInputElement>) => {
        setItem({
            ...item,
            descricao: event.target.value
        })
    }

    const onChangeEstado = async (id: number) => {
        setItem({
            ...item,
            estado: {
                id: id
            }
        })
        if (id) {
            const estado = await StateService.findOne(id);
            if (estado) {
                setItem({
                    ...item,
                    estado: estado
                })
            }
        }
    }

    const onAddEstado = (estado?: State) => {
        if (estado) {
            setItem({
                ...item,
                estado: estado
            })
        }
    }

    const setCodigoIbge = (event: React.ChangeEvent<HTMLInputElement>) => {
        setItem({
            ...item,
            codigoIbge: event.target.value
        })
    }

    return (
        <form onSubmit={save}>
            <CustomTextField fullWidth autoFocus required label="Descrição" value={item.descricao}
                             onChange={setDescricao}/>
            <ForeignKey fullWidth required label="Estado" value={item.estado} fieldKey={'id'}
                        fieldDescription={'descricao'} labelDescription='Descrição' onChange={onChangeEstado}
                        onSelect={onAddEstado} onAdd={onAddEstado} findAll={StateService.findAll}
                        dataColumnNames={['ID', 'Descrição']} dataColumns={['id', 'descricao']}
                        dataTypes={['number', 'text']} addComponent={<StateDetail/>}
            />
            <CustomTextField fullWidth label="IBGE" value={item.codigoIbge || ''} onChange={setCodigoIbge} required/>
            <CustomButtonGroup>
                <CustomButton color="primary" type="submit">Salvar</CustomButton>
                <CustomButton type="button" color="neutral" onClick={cancel}>Cancelar</CustomButton>
            </CustomButtonGroup>
        </form>
    )

}
