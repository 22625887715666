import React, {useState} from "react";
import {UserService} from "./user.service";
import {useDispatch} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import CustomTextField from "../typescript/custom-text-field/custom-text-field";
import CustomButton from "../typescript/custom-button/custom-button";
import CustomButtonGroup from "../typescript/custom-button-group/custom-button-group";
import {setAlert} from "../typescript/redux/actions";

export function ChangePassword() {

    const {id} = useParams();
    let navigate = useNavigate();
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordRepeat, setNewPasswordRepeat] = useState('');

    const dispatch = useDispatch();

    const save = (event) => {
        event.preventDefault();
        event.stopPropagation();

        if (newPassword.length < 5) {
            dispatch(setAlert({show: true, message: 'A senha tem que ter pelo menos 5 caracteres', severity: 'error'}));
            return;
        }

        if (newPassword !== newPasswordRepeat) {
            dispatch(setAlert({show: true, message: 'As senhas digitadas são diferentes', severity: 'error'}));
            return;
        }

        UserService.changePassword(id, currentPassword, newPassword).then(() => {
            navigate('/users');
        }).catch(() => {

        })
    }

    const cancel = () => {
        navigate('/users');
    }

    return (
        <form onSubmit={save}>
            <CustomTextField autoFocus fullWidth required placeholder="Digite a sua senha atual" label="Senha atual"
                             type="password"
                             value={currentPassword} onChange={e => setCurrentPassword(e.target.value)}/>
            <CustomTextField fullWidth required placeholder="Digite a sua nova senha" label="Nova senha" type="password"
                             value={newPassword} onChange={e => setNewPassword(e.target.value)}/>
            <CustomTextField fullWidth required placeholder="Repita a sua nova senha" label="Repetição da Nova senha"
                             type="password"
                             value={newPasswordRepeat} onChange={e => setNewPasswordRepeat(e.target.value)}/>
            <CustomButtonGroup>
                <CustomButton color="primary" type="submit">Salvar</CustomButton>
                <CustomButton type="button" color="neutral" onClick={cancel}>Cancelar</CustomButton>
            </CustomButtonGroup>
        </form>
    )
}