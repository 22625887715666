import React from "react";
import CustomButton from "../custom-button/custom-button";
import CustomButtonGroup from "../custom-button-group/custom-button-group";
import {HeaderOperation} from "./header-operation";

interface DatatableHeaderProps {
    headerOperations: HeaderOperation[]
}

const DatatableHeader = ({headerOperations}: DatatableHeaderProps) => {

    const renderHeaderOperations = () => {
        return headerOperations.map((headerOperation, index) => {
            return (
                <CustomButton key={index} variant="outlined" type="button"
                              color={headerOperation.color ? headerOperation.color() : undefined}
                              onClick={() => headerOperation.onClick()}>
                    {headerOperation.label()}
                </CustomButton>
            )
        })
    }

    return (
        <CustomButtonGroup>
            {renderHeaderOperations()}
        </CustomButtonGroup>
    )

}

export default React.memo(DatatableHeader);
