import React, {FormEvent, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip
} from "@mui/material";
import {Search} from "@mui/icons-material";
import {DocumentService} from "../document/document.service";
import CustomTextField from "../typescript/custom-text-field/custom-text-field";
import {changePageTitle} from "../typescript/redux/actions";
import {PaymentTypeService} from "../payment-type/payment-type.service";
import {DateUtil} from "../typescript/util/2.0.0/date-util";
import {PaymentType} from "../payment-type/payment-type";
import {CashFlow} from "./cash-flow";
import ForeignKey from "../typescript/foreign-key/foreign-key";
import {NumberUtil} from "../typescript/util/number-util";
import CashFlowRow from "./cash-flow-row";

const CashFlowList = () => {
    const dispatch = useDispatch();

    const [cashFlow, setCashFlow] = useState<CashFlow>();
    const [dataInicial, setDataInicial] = useState(DateUtil.toJavaSqlDate(DateUtil.currentDate()));
    const [dataFinal, setDataFinal] = useState(DateUtil.toJavaSqlDate(DateUtil.currentDate()));
    const [tipoDePagamento, setTipoDePagamento] = useState<PaymentType>();

    const findAll = async () => {
        let tipoPagamentoParam
        if (tipoDePagamento) {
            tipoPagamentoParam = tipoDePagamento.id;
        }
        const result = await DocumentService.getCashFlow(dataInicial, dataFinal, tipoPagamentoParam);
        setCashFlow(result);
    };

    useEffect(() => {
        dispatch(changePageTitle("Fluxo de caixa"));
    }, [dispatch]);

    const changeDataInicial = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDataInicial(event.target.value);
    }

    const changeDataFinal = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDataFinal(event.target.value);
    }

    const search = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        findAll().then();
    }

    const onChangeTipoDePagamento = async (id: number) => {
        setTipoDePagamento({
            id: id
        });
        if (id) {
            const tipoDePagamento = await PaymentTypeService.findOne(id);
            onSelectTipoDePagamento(tipoDePagamento)
        }
    }

    const onSelectTipoDePagamento = (tipoDePagamento: PaymentType) => {
        if (tipoDePagamento) {
            setTipoDePagamento(tipoDePagamento)
        }
    }

    const renderTable = () => {
        return cashFlow && (
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Tipo</TableCell>
                            <TableCell align="right">Total entrada</TableCell>
                            <TableCell align="right">Total saída</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {cashFlow.items.map((item, index) => (
                            <CashFlowRow key={index} item={item}/>
                        ))}
                    </TableBody>
                    <TableHead>
                        <TableRow>
                            <TableCell>Total</TableCell>
                            <TableCell align="right">{NumberUtil.currencyFormat(cashFlow.totalInput)}</TableCell>
                            <TableCell align="right">{NumberUtil.currencyFormat(cashFlow.totalOutput)}</TableCell>
                        </TableRow>
                    </TableHead>
                </Table>
            </TableContainer>
        )
    }

    return (
        <form onSubmit={search}>
            <Grid container spacing={1}>
                <Grid item md>
                    <Grid container spacing={1}>
                        <Grid item md xs={12}>
                            <CustomTextField fullWidth autoFocus required label="Data inicial" type="date"
                                             value={dataInicial || ''} InputLabelProps={{shrink: true}}
                                             onChange={changeDataInicial}/>
                        </Grid>
                        <Grid item md xs={12}>
                            <CustomTextField fullWidth required label="Data final" type="date" value={dataFinal || ''}
                                             InputLabelProps={{shrink: true}} onChange={changeDataFinal}/>
                        </Grid>
                        <Grid item md xs={12}>
                            <ForeignKey fullWidth label="ID" value={tipoDePagamento} fieldKey={'id'}
                                        fieldDescription={'descricao'} labelDescription='Tipo de pagamento'
                                        onChange={onChangeTipoDePagamento} onSelect={onSelectTipoDePagamento}
                                        findAll={PaymentTypeService.findAll} dataColumnNames={['ID', 'Descrição']}
                                        dataColumns={['id', 'descricao']} dataTypes={['number', 'text']}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Tooltip title="Pesquisar">
                        <IconButton type={"submit"}>
                            <Search/>
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
            {renderTable()}
        </form>
    )

}

export default React.memo(CashFlowList);
