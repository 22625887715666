import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {Typography} from "@mui/material";
import GoalAnalysisPanel from "./goal-analysis-panel";
import {ForeignKey} from "../index";
import {UserGoalService} from "../user-goal/user-goal.service";
import {AuthService} from "../typescript/auth/auth.service";
import {changePageTitle} from "../typescript/redux/actions";
import {DateUtil} from "../typescript/util/date-util";
import CustomPanel from "../typescript/custom-panel/custom-panel";
import {UserService} from "../user/user.service";

const GoalAnalysis = () => {
    const dispatch = useDispatch();
    const currentDate = DateUtil.currentDate();
    const [seller, setSeller] = useState(AuthService.getUserDetails());
    const [goal, setGoal] = useState(null);

    const getGoal = useCallback(async () => {
        const result = await UserGoalService.analysis(seller.id);
        setGoal(result.data);
    }, [seller.id]);

    useEffect(() => {
        dispatch(changePageTitle("Análise de metas"));
        getGoal().then();
    }, [dispatch, getGoal]);

    const onChangeSeller = async (id) => {
        setSeller({id: id});
        if (id) {
            const sellerSelected = await UserService.findOne(id);
            if (sellerSelected) {
                setSeller(sellerSelected);
            }
        }
    }

    const onSelectSeller = (sellerSelected) => {
        if (sellerSelected) {
            setSeller(sellerSelected);
        }
    }

    return goal && (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                Data: {currentDate.toLocaleDateString()}
            </Typography>
            <Typography variant="h6" gutterBottom>
                Dias úteis restantes até o final do mês: {goal.weekDaysRemaining}
            </Typography>
            <CustomPanel title={"Por vendedor"}>
                <ForeignKey fullWidth autoFocus required label="Vendedor" value={seller} fieldKey={'id'}
                            fieldDescription={'username'} labelDescription='Usuário' dataColumnNames={['ID', 'Usuário']}
                            dataColumns={['id', 'username']} dataTypes={['number', 'text']} onChange={onChangeSeller}
                            findAll={UserService.findAll} onSelect={onSelectSeller}/>

                <GoalAnalysisPanel goal={goal.userGoalAnalysisUser}/>
            </CustomPanel>
            <CustomPanel title={"Geral"}>
                <GoalAnalysisPanel goal={goal.userGoalAnalysisGeneral}/>
            </CustomPanel>
        </React.Fragment>
    )

}

export default React.memo(GoalAnalysis);
