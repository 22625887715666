import React, {FormEvent, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import CustomTextField from "../typescript/custom-text-field/custom-text-field";
import CustomButton from "../typescript/custom-button/custom-button";
import CustomButtonGroup from "../typescript/custom-button-group/custom-button-group";
import CustomRow from "../typescript/custom-row/custom-row";
import {AuthService} from "../typescript/auth/auth.service";
import {changePageTitle, setAlert} from "../typescript/redux/actions";
import {ProductService} from "../product/product.service";
import {Product} from "../product/product";
import ForeignKey from "../typescript/foreign-key/foreign-key";

const StockMovement = () => {
    const dispatch = useDispatch();
    const [product, setProduct] = useState<Product | null>(null);
    const [quantity, setQuantity] = useState('');

    useEffect(() => {
        dispatch(changePageTitle("Movimentar produto"));
    }, [dispatch]);

    const onChangeProduct = async (id: number) => {
        setProduct({id: id});
        if (id) {
            const productFound = await ProductService.findOne(id, true);
            if (productFound) {
                setProduct(productFound);
            }
        }
    }

    const onSelectProduct = (productSelected: Product) => {
        if (productSelected) {
            setProduct(productSelected);
        }
    }

    const findProducts = (page?: number, size?: number, sort?: string, filter?: string) => {
        return ProductService.findAllToSale(page, size, sort, filter, true);
    }

    const addMovement = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        if (!product?.id) {
            throw new Error('Product id required');
        }
        ProductService.stockMovement(product.id, Number(quantity)).then(() => {
            setProduct(null);
            setQuantity('');
            dispatch(setAlert({show: true, message: 'Estoque movimentado com sucesso', severity: 'success'}));
        }, (error) => {
            dispatch(setAlert({
                show: true,
                message: 'Erro ao movimentar estoque - ' + error.data.message,
                severity: 'error'
            }));
        })
    }

    const onChangeQuantity = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newQuantity = event.target.value;
        setQuantity(newQuantity);
    }

    const getInputPropsQuantity = () => {
        if (!AuthService.userInRole("MASTER")) {
            return {inputProps: {min: 1}};
        }
    }

    const renderProductCurrentStock = () => {
        return product && (
            <CustomTextField type="number" fullWidth label="Estoque atual" value={product.estoqueAtual || '0'}
                             disabled/>
        )
    }

    return (
        <form onSubmit={addMovement}>
            <CustomRow>
                <ForeignKey autoFocus fullWidth label="Produto" value={product} fieldKey={'id'}
                            initialSort={'descricao,asc'} fieldDescription={'descricao'} labelDescription='Descrição'
                            dataColumnNames={['ID', 'Descrição', 'Categoria', 'Controla estoque', 'Estoque atual']}
                            dataColumns={['id', 'descricao', 'categoria.descricao', 'controlaEstoque', 'estoqueAtual']}
                            dataTypes={['number', 'text', 'text', 'boolean', 'integer']} onChange={onChangeProduct}
                            onSelect={onSelectProduct} findAll={findProducts}/>
                {renderProductCurrentStock()}
            </CustomRow>
            <CustomTextField type="number" fullWidth label="Quantidade" value={quantity}
                             onChange={onChangeQuantity} InputProps={getInputPropsQuantity()}/>
            <CustomButtonGroup>
                <CustomButton color="primary" type="submit">Movimentar</CustomButton>
            </CustomButtonGroup>
        </form>
    );
}

export default React.memo(StockMovement);
