import React, {useCallback, useEffect, useState} from "react";
import {SaleService} from "../sale/sale.service";
import CustomTextField from "../typescript/custom-text-field/custom-text-field";
import CustomRow from "../typescript/custom-row/custom-row";
import {DateUtil} from "../typescript/util/date-util";
import {NumberUtil} from "../typescript/util/number-util";
import CustomPanel from "../typescript/custom-panel/custom-panel";
import CustomBarChart from "../typescript/custom-bar-chart/custom-bar-chart";

const GraphSoldValue = () => {
    const curr = new Date();
    const [startDate, setStartDate] = useState(DateUtil.toJavaSqlDate(new Date(curr.getFullYear() - 1, curr.getMonth(), 1)));
    const [endDate, setEndDate] = useState(DateUtil.toJavaSqlDate(new Date(curr.getFullYear(), curr.getMonth(), 0)));
    const [graph, setGraph] = useState(null);

    const getGraph = useCallback(async () => {
        const result = await SaleService.graficoValorVendido(startDate, endDate);
        setGraph(result.data);
    }, [startDate, endDate]);

    useEffect(() => {
        getGraph().then();
    }, [getGraph]);

    const formatter = (value) => {
        return NumberUtil.currencyFormat(value);
    }

    const onChangeStartDate = (event) => {
        setStartDate(event.target.value)
    }

    const onChangeEndDate = (event) => {
        setEndDate(event.target.value)
    }

    return graph && (
        <CustomPanel title={"Valor vendido por mês"}>
            <CustomBarChart data={graph} dataKeyXAxis={"name"} formatter={formatter} name={"Valor vendido"} dataKey={"value"} labelX={"Mês"} labelY={"Valor"}/>
            <CustomRow>
                <CustomTextField fullWidth required label="Data inicial" type="date" value={startDate || ''}
                                 InputLabelProps={{shrink: true}} onChange={onChangeStartDate}/>
                <CustomTextField fullWidth required label="Data final" type="date" value={endDate || ''}
                                 InputLabelProps={{shrink: true}} onChange={onChangeEndDate}/>
            </CustomRow>
        </CustomPanel>
    );
}

export default React.memo(GraphSoldValue);