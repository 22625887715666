import React, {useCallback, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {Checkbox, FormControl, FormControlLabel} from "@mui/material";
import {changePageTitle} from "../typescript/redux/actions";
import {FiscalmodelService} from "./fiscalmodel.service";
import CustomTextField from "../typescript/custom-text-field/custom-text-field";
import CustomButton from "../typescript/custom-button/custom-button";
import CustomButtonGroup from "../typescript/custom-button-group/custom-button-group";
import CustomRow from "../typescript/custom-row/custom-row";

export function FiscalmodelDetail({id, onClose, dialog}) {

    const [item, setItem] = useState(FiscalmodelService.createFiscalmodel());
    const dispatch = useDispatch();

    const findOne = useCallback(async () => {
        const result = await FiscalmodelService.findOne(id);
        setItem(result);
    }, [id]);

    useEffect(() => {
        if (!dialog) {
            dispatch(changePageTitle(id ? "Modelo fiscal " + id : "Novo modelo fiscal"));
        }
        if (id) {
            findOne().then();
        }
    }, [dispatch, findOne, id, dialog]);

    const save = (event) => {
        event.preventDefault();
        event.stopPropagation();
        FiscalmodelService.save(item).then((result) => {
            onClose(result.data);
        }).catch(() => {

        })
    }

    const cancel = () => {
        onClose();
    }

    const setDescricao = (event) => {
        setItem({
            ...item,
            descricao: event.target.value
        })
    }

    const setFinalidade = (event) => {
        setItem({
            ...item,
            finalidade: event.target.value
        })
    }

    const setSemPagamento = (event) => {
        setItem({
            ...item,
            semPagamento: event.target.checked
        })
    }

    const setCfop = (event) => {
        setItem({
            ...item,
            cfop: event.target.value
        })
    }

    const setNaturezaOperacao = (event) => {
        setItem({
            ...item,
            naturezaOperacao: event.target.value
        })
    }

    const setModelo = (event) => {
        setItem({
            ...item,
            modelo: event.target.value
        })
    }

    const setCstIcmsCsosn = (event) => {
        setItem({
            ...item,
            cstOuSosnIcms: event.target.value
        })
    }

    const setCstIpi = (event) => {
        setItem({
            ...item,
            cstIpi: event.target.value
        })
    }

    const setCstPis = (event) => {
        setItem({
            ...item,
            cstPis: event.target.value
        })
    }

    const setCstCofins = (event) => {
        setItem({
            ...item,
            cstCofins: event.target.value
        })
    }

    const setEnqIpi = (event) => {
        setItem({
            ...item,
            enqIpi: event.target.value
        })
    }

    const setPercentualTributos = (event) => {
        setItem({
            ...item,
            percentualTributos: event.target.value
        })
    }

    const setInformacoesComplementares = (event) => {
        setItem({
            ...item,
            informacoesComplementares: event.target.value
        })
    }

    return (
        <form onSubmit={save}>
            <CustomRow>
                <CustomTextField fullWidth autoFocus required label="Descrição" value={item.descricao}
                                 onChange={setDescricao}/>
                <CustomTextField fullWidth required label="Finalidade" value={item.finalidade}
                                 onChange={setFinalidade}/>
                <CustomTextField fullWidth required label="CFOP" value={item.cfop} onChange={setCfop}/>

                <FormControl fullWidth>
                    <FormControlLabel control={<Checkbox checked={item.semPagamento} onChange={setSemPagamento}/>}
                                      label="Sem pagamento"/>
                </FormControl>
            </CustomRow>
            <CustomRow>
                <CustomTextField fullWidth required label="Natureza da operação" value={item.naturezaOperacao}
                                 onChange={setNaturezaOperacao}/>
                <CustomTextField fullWidth required label="Modelo" value={item.modelo} onChange={setModelo}/>
            </CustomRow>
            <CustomRow>
                <CustomTextField fullWidth required label="ICMS/CSOSN" value={item.cstOuSosnIcms}
                                 onChange={setCstIcmsCsosn}/>
                <CustomTextField fullWidth required label="IPI" value={item.cstIpi} onChange={setCstIpi}/>
                <CustomTextField fullWidth required label="PIS" value={item.cstPis} onChange={setCstPis}/>
                <CustomTextField fullWidth required label="COFINS" value={item.cstCofins} onChange={setCstCofins}/>
            </CustomRow>
            <CustomRow>
                <CustomTextField fullWidth required label="Enquadramento IPI" value={item.enqIpi} onChange={setEnqIpi}/>
                <CustomTextField fullWidth required label="Percentual aproximado de tributos"
                                 value={item.percentualTributos}
                                 onChange={setPercentualTributos}/>
            </CustomRow>
            <CustomTextField fullWidth label="Informações complementares" value={item.informacoesComplementares || ''}
                             onChange={setInformacoesComplementares}/>
            <CustomButtonGroup>
                <CustomButton color="primary" type="submit">Salvar</CustomButton>
                <CustomButton type="button" color="neutral" onClick={cancel}>Cancelar</CustomButton>
            </CustomButtonGroup>
        </form>
    )
}