import React from "react";
import {TableBody, TableCell, TableRow, Theme} from "@mui/material";
import DatatableTableBodyRow from "./datatable-table-body-row";
import {RowOperation} from "./row-operation";
import {SxProps} from "@mui/system";

const classes = {
    datatableCursorPointer: {
        cursor: 'pointer'
    }
};

interface DatatableTableBodyProps {
    data: any[];
    dataColumns: string[];
    dataTypes: string[];
    dataValues?: ((item: any, dataColumn: string, columnIndex: number) => any)[];
    editData: undefined | ((item: any, index: number) => void);
    rowOperations?: RowOperation[];
    rowClass?: (index: number) => SxProps<Theme>;
}

const DatatableTableBody = React.memo(({
                                           data,
                                           dataColumns,
                                           dataTypes,
                                           dataValues,
                                           editData,
                                           rowOperations,
                                           rowClass
                                       }: DatatableTableBodyProps) => {

    const editRow = (item: any, index: number) => {
        if (editData) {
            editData(item, index);
        }
    }

    const cursorClass = (editData ? classes.datatableCursorPointer : null);

    const renderTableBodyRow = () => {
        if (data && data.length > 0) {
            return data.map((item, index) => (
                <DatatableTableBodyRow key={index} item={item} dataColumns={dataColumns} dataTypes={dataTypes}
                                       rowIndex={index}
                                       dataValues={dataValues} editData={() => editRow(item, index)} rowClass={rowClass}
                                       cursorClass={cursorClass} rowOperations={rowOperations}/>
            ))
        }
        return (
            <TableRow>
                <TableCell colSpan={99}>
                    Nenhum registro encontrado
                </TableCell>
            </TableRow>
        )
    }

    return (
        <TableBody>
            {renderTableBodyRow()}
        </TableBody>
    )

})

export default DatatableTableBody;