import React from "react";
import {TableCell, TableSortLabel} from "@mui/material";
import {DirectionEnum} from "../util/direction-enum";

interface DatatableTableHeaderSortProps {
    onClick: () => void;
    active: boolean;
    direction: DirectionEnum;
    item: string;
}

const DatatableTableHeaderSort = ({onClick, active, direction, item}: DatatableTableHeaderSortProps) => {

    return (
        <TableCell>
            <TableSortLabel active={active} direction={direction} onClick={onClick}>
                {item}
            </TableSortLabel>
        </TableCell>
    )

}

export default React.memo(DatatableTableHeaderSort);