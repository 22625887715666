import React, {useCallback, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {Delete as DeleteIcon} from "@mui/icons-material";
import {Datatable} from "../index";
import {PaymentTypeService} from "./payment-type.service";
import {useNavigate} from "react-router-dom";
import {changePageTitle} from "../typescript/redux/actions";

export function PaymentTypeList() {

    let navigate = useNavigate();
    const [pageParams, setPageParams] = useState(null);
    const dispatch = useDispatch();

    const findAll = useCallback(async (number, size, sort, filter) => {
        const items = await PaymentTypeService.findAll(number, size, sort, filter);
        setPageParams({
            number: number,
            size: size,
            sort: sort,
            filter: filter,
            items: items
        });
    }, []);

    useEffect(() => {
        dispatch(changePageTitle("Tipos de pagamento"));
        findAll(0, parseInt(process.env.REACT_APP_PAGE_SIZE), "id,asc", '').then();
    }, [dispatch, findAll]);

    const editItem = (item) => {
        navigate('/paymenttype/' + item.id);
    }

    const insertItem = () => {
        navigate('/paymenttype');
    }

    const deleteItem = (item) => {
        PaymentTypeService.delete(item.id).then(() => {
            findAll().then();
        }).catch(() => {

        })
    }

    const headerOperations = [
        {color: () => "primary", onClick: insertItem, label: () => 'Inserir'}
    ]

    const rowOperations = [
        {color: () => "primary", onClick: deleteItem, label: () => 'Excluir', icon: () => <DeleteIcon/>},
    ]

    return pageParams && (
        <Datatable data={pageParams.items.content} totalData={pageParams.items.totalElements}
                   dataColumnNames={['ID', 'Descrição']}
                   dataColumns={['id', 'descricao']}
                   dataTypes={['number', 'text']}
                   number={pageParams.number} size={pageParams.size} sort={pageParams.sort} filter={pageParams.filter}
                   findAll={findAll}
                   editData={editItem} headerOperations={headerOperations} rowOperations={rowOperations}/>
    );
}