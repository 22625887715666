import {useDispatch} from "react-redux";
import React, {useState} from "react";
import {
    CategoryDetail,
    ForeignKey
} from "../index";
import {useNavigate} from "react-router-dom";
import CustomTextField from "../typescript/custom-text-field/custom-text-field";
import CustomButton from "../typescript/custom-button/custom-button";
import CustomButtonGroup from "../typescript/custom-button-group/custom-button-group";
import {setAlert} from "../typescript/redux/actions";
import {DateUtil} from "../typescript/util/date-util";
import {ProductService} from "./product.service";
import {CategoryService} from "../category/category.service";

export function UpdateProductValue() {

    let navigate = useNavigate();

    const [percentual, setPercentual] = useState('');
    const [startDate, setStartDate] = useState(DateUtil.toJavaSqlDate(new Date()));
    const [endDate, setEndDate] = useState(DateUtil.toJavaSqlDate(DateUtil.addYear(new Date(), 1)));
    const [category, setCategory] = useState(CategoryService.createCategory());
    const dispatch = useDispatch();

    const update = (event) => {
        event.preventDefault();
        event.stopPropagation();
        ProductService.updateValues(percentual, startDate, endDate, category).then(() => {
            dispatch(setAlert({show: true, message: 'Atualização realizada com sucesso', severity: 'success'}));
            navigate('/products');
        }, () => {
            dispatch(setAlert({show: true, message: 'Erro ao atualizar valores', severity: 'error'}));
        })
    }

    const cancel = () => {
        navigate('/products');
    }

    const onChangeCategories = async (id) => {
        setCategory({id: id})
        if (id) {
            const category = await CategoryService.findOne(id);
            if (category) {
                setCategory(category);
            }
        }
    }

    const onSelectCategory = (category) => {
        if (category) {
            setCategory(category);
        }
    }

    const findCategories = (page, size, sort, filter) => {
        return CategoryService.findAll(page, size, sort, filter);
    }

    return (
        <form onSubmit={update}>
            <CustomTextField fullWidth autoFocus required label="Reajuste (%)" value={percentual}
                             onChange={(event) => setPercentual(event.target.value)}/>
            <ForeignKey fullWidth required label="Categoria" value={category} fieldKey={'id'}
                        fieldDescription={'descricao'} labelDescription='Descrição' onChange={onChangeCategories}
                        onSelect={onSelectCategory} findAll={findCategories}
                        dataColumnNames={['ID', 'Descrição']} dataColumns={['id', 'descricao']}
                        dataTypes={['number', 'text']} addComponent={<CategoryDetail/>}/>
            <CustomTextField fullWidth required label="Data inicial da validade do reajuste" value={startDate}
                             type="date"
                             onChange={(event) => setStartDate(event.target.value)}/>
            <CustomTextField fullWidth required label="Data final da validade do reajuste" value={endDate} type="date"
                             onChange={(event) => setEndDate(event.target.value)}/>
            <CustomButtonGroup>
                <CustomButton color="primary" type="submit">Atualizar</CustomButton>
                <CustomButton type="button" color="neutral" onClick={cancel}>Cancelar</CustomButton>
            </CustomButtonGroup>
        </form>
    )
}