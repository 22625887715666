import React, {useEffect, useState} from "react";
import {ComissionProductService} from "./comission-product.service";
import {ForeignKey} from "../index";
import CustomTextField from "../typescript/custom-text-field/custom-text-field";
import CustomButton from "../typescript/custom-button/custom-button";
import CustomButtonGroup from "../typescript/custom-button-group/custom-button-group";
import {ProductService} from "../product/product.service";

export function ComissionProductItemDetail(props) {

    const [item, setItem] = useState(ComissionProductService.createComissionProductItem);

    useEffect(() => {
        setItem(props.item);
    }, [props.item]);

    const save = (event) => {
        event.preventDefault();
        event.stopPropagation();
        props.onClose(item);
    }

    const cancel = () => {
        props.onClose();
    }

    const setQuantity = (event) => {
        setItem({
            ...item,
            quantity: event.target.value
        })
    }

    const onSelectProduct = (product) => {
        if (product) {
            setItem({
                ...item,
                product: product
            })
        }
    }

    const findProducts = (page, size, sort, filter) => {
        return ProductService.findAllSimple(page, size, sort, filter);
    }

    const onChangeProduct = async (id) => {
        setItem({
            ...item,
            product: {
                id: id
            }
        })
        if (id) {
            const product = await ProductService.findOne(id);
            if (product) {
                setItem({
                    ...item,
                    product: product
                })
            }
        }
    }

    return (
        <form onSubmit={save}>
            <CustomTextField type="number" fullWidth required label="Quantidade" value={item.quantity}
                             onChange={setQuantity}/>
            <ForeignKey fullWidth required label="Produto" value={item.product} fieldKey={'id'}
                        fieldDescription={'descricao'} labelDescription='Descrição' onChange={onChangeProduct}
                        onSelect={onSelectProduct} findAll={findProducts}
                        dataColumnNames={['ID', 'Descrição']} dataColumns={['id', 'descricao']}
                        dataTypes={['number', 'text']}/>
            <CustomButtonGroup>
                <CustomButton color="primary" type="submit">Salvar</CustomButton>
                <CustomButton type="button" color="neutral" onClick={cancel}>Cancelar</CustomButton>
            </CustomButtonGroup>
        </form>
    )
}