import React, {FormEvent, useCallback, useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import CampaignDetail from "./campaign-detail";
import {Campaign} from "./campaign";
import {CampaignService} from "./campaign.service";
import CustomButtonGroup from "../../custom-button-group/custom-button-group";
import CustomButton from "../../custom-button/custom-button";
import {setAlert} from "../../redux/actions";
import {useDispatch} from "react-redux";

const CampaignDetailPage = () => {

    const {id} = useParams();
    let navigate = useNavigate();
    const dispatch = useDispatch();

    const [item, setItem] = useState<Campaign>(CampaignService.createCampaign);

    const onClose = () => {
        navigate('/campaigns');
    }

    const findOne = useCallback(async (id: number) => {
        const result = await CampaignService.findOne(id);
        setItem(result);
    }, []);

    useEffect(() => {
        findOne(Number(id)).then();
    }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

    const save = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        CampaignService.save(item).then((result) => {
            onClose();
        }).catch((error) => {
            dispatch(setAlert({
                show: true,
                message: 'Erro ao salvar registro - ' + error.message,
                severity: 'error'
            }));
        })
    }

    if (item) {
        return (
            <form onSubmit={save}>
                <CampaignDetail item={item} setItem={setItem}/>
                <CustomButtonGroup>
                    <CustomButton color="primary" type="submit">Salvar</CustomButton>
                    <CustomButton type="button" color="neutral" onClick={onClose}>Cancelar</CustomButton>
                </CustomButtonGroup>
            </form>
        )
    }

    return null;

};

export default React.memo(CampaignDetailPage);